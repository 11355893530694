import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

// local imports
import "../../../styles/Whatsappconfigaration.scss";
import Footer from "../../Footer";
import { loadSubscription } from "../../../redux/action";
import ConfigurationTemplateEmail from "./ConfigurationTemplateEmail";
import ConfigurationFileEmail from "./ConfigurationFileEmail";
import { toast } from "react-toastify";
import CommonNavFile from "../../CommonNavFile";
import * as HeaderData from '../../HeaderAuthentication';
import { loadPostEmailConfigData } from "../../../redux/ModalActions/whatsappConfig_Action";

function AddEmailConfig() {
  let dispatch = useDispatch();
  const location = useLocation();
  const { subcriptionList } = useSelector((state) => state.data);
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [toggleshift, setToggleshift] = useState({ style: "" }); 

  // #region initian value
  const [initStates, setInitStates] = useState({
    emailName: "", receiverType: "", to: "", time: "", frequency: "",
    emailId: "", password: "", smtP_Port: "", sp_Name: "", sp_FlagID: "",
    header_Type: "", header_Text: "", header_Image: "", subject: "",
    active: "1", createdBy: resPassData.fullName, createdOn: "",
    body: "", footer: "", navigateToPage: "", attachment_Type: "",
    attachment_Data: "", cc: "", bcc: "", subscriptionID: "", remarks: "",
    button_text: "",
    childItems: []
  })
  // #endregion initian value

  // #region input handler
  const handleFormInput = (e) => {
    const { name, value } = e.target;
    setInitStates({ ...initStates, [name]: value })
  }
  // #endregion

  const [userList, setUserList] = useState([]);
  const [templateName, setTemplateName] = useState('');

  useEffect(() => {
    if (subcriptionList) {
      let optiondata = [];
      subcriptionList.forEach((data) => {
        if (data.subscriptionId != null) {
          optiondata.push({
            value: data.subscriptionId,
            label: data.subscriptionId,
            client: data?.company?.companyName,
          });
        }
      });
      setUserList(optiondata);
    }
  }, [subcriptionList]);

  const getOptionValue = ({ label }) => (
    <div>
      <div>{label.value}</div>
    </div>
  );

  const getOptionLabel = ({ label, client }) => `${label} ${client}`;

  const formatOptionLabel = ({ label, client }) => (
    <div className="menu-item-column">
      <div style={{ fontSize: "11px" }}>
        <b>{label}</b>
      </div>
      <div style={{ fontSize: "10px" }}>{client}</div>
    </div>
  );

  const [selectedSubscribe, setSelectedSubscribe] = useState({
    value: "",
    label: "",
  });
  const [selectHeaderType, setSelectHeaderType] = useState({ value: '', label: '' })
  const [selectRecieverType, setSelectRecieverType] = useState({ value: '', label: '' })
  const [selectFrequency, setSelectFrequency] = useState({ value: '', label: '' })
  const [selectAttachmentType, setSelectAttachmentType] = useState({ value: '', label: '' })

  const setFilterUserId = (selected) => {
    initStates.subscriptionID = selected.value;
    setSelectedSubscribe(selected);
  };

  useEffect(() => {
    dispatch(loadSubscription());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.state) {
      const { templateName } = location.state;
      setTemplateName(templateName)
    }
    setSelectHeaderType({ value: 'none', label: 'none' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  // #region function for edit template name
  const [editTemplateName, setEditTemplateName] = useState(false)
  // #endregion


  const handleDropDown = (data, type) => {
    let initState = { ...initStates };
    if (type === "headerType") {
      setSelectHeaderType(data)
      setLocalImage('')
      initState.header_Type = data.value;
      initState.header_Text = '';
    } else if (type === "recieverType") {
      setSelectRecieverType(data)
      initState.receiverType = data.value;
      initState.to = '';
    } else if (type === "frequency") {
      setSelectFrequency(data)
      initState.frequency = data.value;
    } else if(type === "attachments"){
      setSelectAttachmentType(data)
      initState.attachment_Type = data.value;
    }
    setInitStates(initState);
  }

  const [veriables, setVeriables] = useState([])
  const [localImage, setLocalImage] = useState("");

  // const [messageBody, setMessageBody] = useState('')
  const [enteredMsg, setEnteredMsg] = useState('')

  const handleBody = (e) => {
    setEnteredMsg(e.target.value)
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
    let initState = { ...initStates };
    initState.body = e.target.value;
    setInitStates(initState);
  }

  const setVeriablesValue = () => {
    let list = [...veriables]
    let value = 1;
    if(list.length !== 0){
      value = list.length + 1
    }

    list.push(`{{${value}}}`);
    setVeriables(list)

    // let formattedString = list.map(item => `${item.variables}: ${item.variables_Value}`).join('\n');
    // setMessageBody(formattedString)
  }

  // #region hanld veriable input
  const handleVeriableInput = (e, i) => {
    let list = [...veriables];
    const { value } = e.target;
    list[i] = value;
    setVeriables(list);
  }

  const deleteVeriable = (indexToRemove) => {
    const updatedVariables = veriables.filter((item, index) => index !== indexToRemove);
    setVeriables(updatedVariables)
  }
  // #endregion

  // #region button option
  const [openButtonVeriable, setOpenButtonVeriable] = useState(false)
  const addBtnPrameters = () => {
    setOpenButtonVeriable(true)
  }
  const removeBtnPrameters = () => {
    setOpenButtonVeriable(false)
    let initState = { ...initStates };
    initState.button_text = '';
    initState.navigateToPage = '';
    setInitStates(initState);
  }
  // #region button option

  // Page navigation
  const navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  // #region save btn function
  const [validation, setValidation] = useState(false);
  const [senderConfig, setSenderConfig] = useState(false)
  const saveDataAndContinue = () => {
    if(!templateName || !initStates.body || !initStates.subject
      || (initStates.button_text && !initStates.navigateToPage)){
      setValidation(true);
      return;
    } else if(!initStates.subscriptionID ){
      toast.error("Please select subscription");
      return;
    } else {
      setValidation(false);
      setSenderConfig(true)
    }
    
  }

  const saveTemplateDetails = () => {
    initStates.notificationName = templateName;
    initStates.variableDetails = veriables;
    initStates.createdOn = new Date();
    if(!initStates.notificationName || !initStates.subscriptionID || !initStates.subject || !initStates.body 
      || !initStates.receiverType || (initStates.receiverType === "Particular" && !initStates.to)
      || !initStates.frequency || !initStates.time 
      || (initStates.button_text && !initStates.navigateToPage)){
      setValidation(true);
      return;
    } else {
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(initStates),
      }
      dispatch(loadPostEmailConfigData(postRequest, goToBackPage))
    }
  }
  const backToTemplateConfig = () => {
    setSenderConfig(false)
  }
  // #endregion

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <CommonNavFile toggleshift={toggleshift} setToggleshift={setToggleshift} />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <Row className="notification-template-header">
            <Col md={4} className="cat-col">
              <div className="page-header-text-div">
                <div className="back-btn" onClick={goToBackPage}>
                  <img
                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                    alt=""
                  />
                </div>
                <h6 className="page-header-text">Email Push Notification Template</h6>
              </div>
            </Col>
            <Col md={8} className="cat-col">
              <div
                className="search-bar-header"
                style={{ float: "right" }}
              >
                <div className="page-top-bar-dropdowns page-top-Filters">
                  <div className="page-top-bar-dropdowns">
                    <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      options={userList}
                      placeholder="Select Subscription Id"
                      getOptionValue={getOptionValue}
                      getOptionLabel={getOptionLabel}
                      value={selectedSubscribe.label && selectedSubscribe}
                      // onInputChange={userList.label}
                      formatOptionLabel={formatOptionLabel}
                      onChange={setFilterUserId}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {!senderConfig ?
            <ConfigurationTemplateEmail editTemplateName={editTemplateName} templateName={templateName}
            setTemplateName={setTemplateName} setEditTemplateName={setEditTemplateName}
            saveDataAndContinue={saveDataAndContinue} selectHeaderType={selectHeaderType}
            handleDropDown={handleDropDown} initStates={initStates} handleFormInput={handleFormInput}
            setLocalImage={setLocalImage} localImage={localImage} handleBody={handleBody}
            enteredMsg={enteredMsg} veriables={veriables} setVeriablesValue={setVeriablesValue}
            validation={validation}
            handleVeriableInput={handleVeriableInput} deleteVeriable={deleteVeriable} openButtonVeriable={openButtonVeriable}
            addBtnPrameters={addBtnPrameters} removeBtnPrameters={removeBtnPrameters} goToBackPage={goToBackPage} selectAttachmentType={selectAttachmentType}
            />
            :
            <ConfigurationFileEmail backToTemplateConfig={backToTemplateConfig} saveTemplateDetails={saveTemplateDetails}
            selectRecieverType={selectRecieverType} handleDropDown={handleDropDown} initStates={initStates} handleFormInput={handleFormInput}
            selectFrequency={selectFrequency} setInitStates={setInitStates} veriables={veriables} enteredMsg={enteredMsg} validation={validation}
            />
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AddEmailConfig;
