import { baseURL } from "../../Components/BaseUrl";
import * as types from "../ModalActionTypes/whatsappConfig_ActionType";
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import * as swal from "../../Components/Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToataldataCount } from "../action";

// #region set payload whatsapp comfig data 
const getWhatsappConfigData = (whatsAppConfigData) => ({
    type: types.Get_Whatsapp_Config_Data,
    payload: whatsAppConfigData,
  });
const postWhatsappConfigData = () => ({
    type: types.Post_Whatsapp_Config_Data,
  });
const getSingleWhatsappConfigData = (singleWhatsAppConfigData) => ({
    type: types.Get_SingleWhatsapp_Config_Data,
    payload: singleWhatsAppConfigData
  });
// #endregion
// #region set payload for email configuration
const getEmailConfigData = (emailConfigData) => ({
  type: types.Get_Email_Config_Data,
  payload: emailConfigData,
});
const postEmailConfigData = () => ({
  type: types.Post_Email_Config_Data,
});
// #endregion

// #region service call for function
// #region service call for whatsapp comfig functinality
export const loadWhatsappConfigData = (currentPage, PageSize, setIsLoading) => {
    return function (dispatch) {
        fetch(
          `${baseURL}/api/Essae/GetALL?CurrentPage=${currentPage}&PageSize=${PageSize}`,
          HeaderAuthentication.getRequestOptions
        )
          .then((resp) => resp.json())
          .then((resp) => {
            console.log(resp)
            if (resp.success) {
              dispatch(getWhatsappConfigData(resp.result));
              dispatch(getToataldataCount(resp.recordCount))
            } else {
              dispatch(getWhatsappConfigData([]));
            }
            setIsLoading(false)
          })
          .catch((err) => {});
      };
}

export const loadPostWhatsappConfigData = (postRequest, goToBackPage) => {
  return function (dispatch) {
      fetch(
        `${baseURL}/api/Essae/AddPushNotifications`,
        postRequest
      )
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.success) {
            dispatch(postWhatsappConfigData());
            swal.showAlert("Success", "Added Successfully", "success", goToBackPage);
          } else {
            toast.error(resp.errorMessage);
          }
        })
        .catch((err) => {
          toast.error(err.errorMessage);
        });
    };
}

export const loadSingleWhatsappConfigData = (templateId) => {
  return function (dispatch) {
      fetch(
        `${baseURL}/api/Essae/Get/${templateId}`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp)
          if (resp.success) {
            dispatch(getSingleWhatsappConfigData(resp.result));
          } else {
            dispatch(getSingleWhatsappConfigData({}));
          }
        })
        .catch((err) => {});
    };
}

export const loadVeriableData = (templateId, setVeriables) => {
  return function (dispatch) {
      fetch(
        `${baseURL}/api/Essae/GetvaridPushNotifications/${templateId}`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp)
          if (resp.success) {
            setVeriables(resp.result)
            // dispatch(getWhatsappConfigData(resp.result));
            // dispatch(getToataldataCount(resp.recordCount))
          } else {
            setVeriables(resp.result)
            // dispatch(getWhatsappConfigData([]));
          }
        })
        .catch((err) => {});
    };
}
// #endregion

// #region service call for email configaration
export const loadEmailConfigData = (currentPage, PageSize, setIsLoading) => {
  return function (dispatch) {
      fetch(
        `${baseURL}/api/Essae/GetEmailconfiguration?CurrentPage=${currentPage}&PageSize=${PageSize}`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp)
          if (resp.success) {
            dispatch(getEmailConfigData(resp.result));
            dispatch(getToataldataCount(resp.recordCount))
          } else {
            dispatch(getEmailConfigData([]));
          }
          setIsLoading(false)
        })
        .catch((err) => {});
    };
};

export const loadPostEmailConfigData = (postRequest, goToBackPage) => {
  return function (dispatch) {
      fetch(
        `${baseURL}/api/Essae/PostEmailconfiguration`,
        postRequest
      )
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.success) {
            dispatch(postEmailConfigData());
            swal.showAlert("Success", "Template Added Successfully", "success", goToBackPage);
          } else {
            toast.error(resp.errorMessage);
          }
        })
        .catch((err) => {
          toast.error(err.errorMessage);
        });
    };
}
// #endregion
// #endregion