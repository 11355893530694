import '../../styles/AddUserDetails.scss'
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import '../../styles/AddUserModel.scss'
import { useState } from "react";
import { Link } from 'react-router-dom';
import SalesBee from "../../images/MatricLogo.svg";
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';
import SbCancelBtn from '../SbButtons/SbCancelBtn';

const triggers = [
    { value: "Days", label: "Days" },
    { value: "Hours", label: "Hours" }
]

const AddEscalationMatrix = (props) => {

    const [moduleName, setModuleName] = useState({ value: "", label: "" });
    const [level, setLevel] = useState({ value: "", label: "" });
    const [role, setRole] = useState({ value: "", label: "" });
    const [designation, setDesignation] = useState({ value: "", label: "" });
    const [userName, setUserName] = useState({ value: "", label: "" });
    const [triggerWhen, setTriggerWhe] = useState({ value: "", label: "" });
    const [value, setValue] = useState("");

    const [activeKey, setActiveKey] = useState('');

    const [showMachineDetailsForm, setShowMachineDetailsForm] = useState(true);
    const [showMachineDimensionsForm, setShowMachineDimensionsForm] = useState(false);
    const [showMachineImagesForm, setShowMachineImagesForm] = useState(false);
    const [showMachineDetails, setShowMachineDetails] = useState(false);
    const [showMachineDimensions, setShowMachineDimensions] = useState(false);
    const [showAmcDetails, setShowAmcDetails] = useState(false);
    const [showAmcDetailsForm, setShowAmcDetailsForm] = useState(false);
    const [showMachineDetailsHeader, setShowMachineDetailsHeader] = useState(true);
    const [amcDetailsHeader, setShowAmcDetailsHeader] = useState(false);
    const [showMachineDimensionHeader, setShowMachineDimensionHeader] = useState(false);
    const [showMachineImageHeader, setShowMachineImageHeader] = useState(false);
    const [activeMenu, setActiveMenu] = useState(1);

    const toggleMachineDetails = () => {
        setShowMachineDetailsForm(true)
        setShowMachineDimensionsForm(false);
        setShowAmcDetailsForm(false)
        setShowMachineImagesForm(false);
        setShowMachineDetails(false)
        setShowAmcDetails(false)
        setShowMachineDimensions(false)
        setShowMachineDetailsHeader(true)
        setShowAmcDetailsHeader(false)
        setShowMachineDimensionHeader(false)
        setShowMachineImageHeader(false)
        setActiveMenu(1);
    }
    const toggleAmcDetails = () => {
        setShowAmcDetailsForm(true)
        setShowMachineDimensionsForm(false);
        setShowMachineDetailsForm(false)
        setShowMachineImagesForm(false);
        setShowMachineDetails(true);
        setShowAmcDetails(false)
        setShowMachineDimensions(false)
        setShowAmcDetailsHeader(true)
        setActiveMenu(2);
        setActiveKey('0')

        setShowMachineDetailsHeader(false)
        setShowMachineDimensionHeader(false)
        setShowMachineImageHeader(false)
    };
    const toggleMachineImages = () => {
        setShowMachineImagesForm(true);
        setShowMachineDetailsForm(false);
        setShowMachineDimensionsForm(false);
        setShowAmcDetails(false)
        setShowMachineDetails(true)
        setShowMachineDimensions(true)
        setShowAmcDetails(true)
        setShowAmcDetailsForm(false)
        setShowMachineImageHeader(true)
        setActiveMenu(4);
        setActiveKey('2')

        setShowMachineDetailsHeader(false)
        setShowAmcDetailsHeader(false)
        setShowMachineDimensionHeader(false)
    };

    const closeModel = () => {
        props.handleClose();
    };

    return (
        <div
            show={props.show}
            onHide={props.handleClose}
        >
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className='machines-logo-div'>
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineDetails}>
                        <div className={`add-user-details-menu-numbers ${activeMenu === 1 ? 'active' : 'inactive'}`} >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name">Escalation Matrix</div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    {showMachineDetailsHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Escalation Matrix Details</h4>
                            <p>Please enter escalation matrix infomation and proceed to the next step.</p>
                        </div>
                    </div>}
                    <div style={{ position: " relative" }} className="amc-single-image">

                        {showMachineDetailsForm && <form>
                            {/* <div className="row"> */}

                            <div className="col-lg-10 col">
                                <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Module Name' className={`${moduleName.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="Module Name"
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Designation' className={`${level.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="Level"
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>                                       </div>
                                    </div>
                                </div>

                                <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Role' className={`${role.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="Role"
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Designation' className={`${designation.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="Designation"
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                </div>

                                <div className="row add-user-image-container-div">
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='User Name' className={`${userName.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="User Name"
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Trigger When' className={`${triggerWhen.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={triggers}
                                                    placeholder="Trigger When"
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>                                            </div>
                                    </div>
                                    <div className="col-lg-4 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Value' className={`${value !== "" ? "float-input-visible" : "float-hidden float-input"}`}>
                                                <input
                                                    type="text"
                                                    name="clientEmail"
                                                    className="form-control add-user-input"
                                                    placeholder="Value"
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-page-save-cancel">
                                    <SBSaveUpdateBtn btnName="Save" />
                                    <SbCancelBtn
                                        btnName="Cancel"
                                    // onClickEffect={() => {
                                    //     props.handleClose();
                                    // }}
                                    />
                                </div>
                            </div>
                        </form>}
                    </div>
                </div >

            </div >
        </div >
    );
}

export default AddEscalationMatrix;