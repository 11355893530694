import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Col, Row, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/TravelExpenseVoucher.scss";
import SbAddBtn, { SBSaveUpdateBtn, SbDownloadBtn, SbAddExpenseBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import AccessDenied from "../../../AccessDenied";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from "react-multi-select-component";


// const defaultUser = clientOptions.find(option => option.label === 'Siva Balan P');
function TravelExpenseFinalVoucher() {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedVerifier, setSelectedVerifier] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [mydatashow, setmyDataShow] = useState(false);
    const [vchrshow, setvchrshow] = useState(false);
    const [activeTab, setActiveTab] = useState("manager");
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [myshowSaveButton, setmyShowSaveButton] = useState(true);
    const [myinnershowSaveButton, setmyinnerShowSaveButton] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedVoucher, setSelectedVoucher] = useState({});
    const [myselectedVoucher, setmysetSelectedVoucher] = useState({});



    const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
    const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    const twoLevelClaim = loginDetails?.subscription?.twoLevelClaim;
    const loggedInRole = loginDetails?.desingation?.role?.roleID;
    const [filter, setFilter] = useState(false);
    const [filterUserId, setFilterUserId] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [myshowForm, setmyShowForm] = useState(false);


    //* CLEAR SELECT START *//
    const clearSerachBySelect = () => {
        setFilterUserId("");

    };
    const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);
    const handleClose4 = () => setShow4(false);
    const handleClose5 = () => setShow5(false);
    const handleShow2 = () => setShow2(true);
    const handleShow3 = () => setShow3(true);
    const handleShow4 = () => setShow4(true);
    const handleShow5 = () => setShow5(true);
    const mysaveBtn = () => {
        setmyShowForm(false);

    }
    const {
        employeeCode = "AFW053",
        trackerVoucherNo = "AFW053/04/23/001",
        navVoucherNo = "HO-IM-ACC-220001203",
        region = "West-1",
        employeeName = "Kaustubh Rahatwal",
        trackerVoucherDate = "30-04-23",
        grade = "M-2",
        designation = "Accounts Assistant",
        tourName = "Mumbai To Pondy",
        tourPeriod = "01/04/2023 To 10/04/2023",
        dept = "Accounts & Finance",
        branch = "Mumbai",
        purposeOfTour = "For Backbone Meeting",
        glAccounts = [
            { glAccountNo: "44343003", glAccountName: "LODGING AND BOARDING", noOfMembers: 1, locationCode: "Mumbai", amount: 2500.00 },
            { glAccountNo: "", glAccountName: "Hotel Name. ( ABC Hotel Ltd. VL000001)", noOfMembers: 1, locationCode: "Delhi", amount: 2500.00 },
            { glAccountNo: "44343005", glAccountName: "Tour Conveyance Charges", noOfMembers: 1, locationCode: "Mumbai", amount: 500.00 },
            { glAccountNo: "44370101", glAccountName: "Staff Welfare", noOfMembers: 1, locationCode: "Mumbai", amount: 1000.00 },
            { glAccountNo: "44343007", glAccountName: "Tour Sundry Expenses", noOfMembers: 1, locationCode: "Mumbai", amount: 0 }
        ],
        totalAmount = 6500.00
    } = myselectedVoucher;


    const vouchers = [

        {
            voucherNo: '001',
            voucherDate: '2024-03-21',
            navVoucherNo: 'NAV001',
            employeeName: 'Devaraj (D123)',
            voucherAmount: '5000.00',
            tourName: 'Mumbai to Delhi',
            tourPeriod: '2024-06-01 to 2024-06-05',
            tourPurpose: 'Client Meeting',
            voucherStatus: 'Approved by (Kamalakar)'
        },
        {
            voucherNo: '002',
            voucherDate: '2024-04-05',
            navVoucherNo: 'NAV002',
            employeeName: 'Somasekhar (SS456)',
            voucherAmount: '3000.00',
            tourName: 'Mumbai to Bangalore',
            tourPeriod: '2024-06-10 to 2024-06-12',
            tourPurpose: 'Tech Conference',
            voucherStatus: 'Pending from (Sanjay) '
        },
        {
            voucherNo: '003',
            voucherDate: '2024-06-09',
            navVoucherNo: 'NAV003',
            employeeName: 'Rajeev (R189)',
            voucherAmount: '4000.00',
            tourName: 'Delhi To Pune',
            tourPeriod: '2024-06-15 to 2024-06-17',
            tourPurpose: 'Training Workshop',
            voucherStatus: 'Rejected by (Kamalakar)'
        },
        {
            voucherNo: '004',
            voucherDate: '2024-06-02',
            navVoucherNo: 'NAV004',
            employeeName: 'Mahendra (M345)',
            voucherAmount: '5000.00',
            tourName: 'Bangalore To Chennai',
            tourPeriod: '2024-06-15 to 2024-06-17',
            tourPurpose: 'Training Workshop',
            voucherStatus: 'Rejected by (Kamalakar)'
        },
        {
            voucherNo: '005',
            voucherDate: '2024-05-23',
            navVoucherNo: 'NAV005',
            employeeName: 'Kamalakar (KR255)',
            voucherAmount: '6000.00',
            tourName: 'Bangalore To Vishakapatnam',
            tourPeriod: '2024-06-15 to 2024-06-17',
            tourPurpose: 'Training Workshop',
            voucherStatus: 'Rejected by (Kamalakar)'
        },
        {
            voucherNo: '006',
            voucherDate: '2024-01-10',
            navVoucherNo: 'NAV006',
            employeeName: 'Syam Prasad (SP489)',
            voucherAmount: '5000.00',
            tourName: 'Bangalore To Mumbai',
            tourPeriod: '2024-06-15 to 2024-06-17',
            tourPurpose: 'Training Workshop',
            voucherStatus: 'Rejected by (Kamalakar)'
        },
        {
            voucherNo: '007',
            voucherDate: '2024-06-03',
            navVoucherNo: 'NAV007',
            employeeName: 'Rupesh (R689)',
            voucherAmount: '2000.00',
            tourName: 'Mumbai To Pune',
            tourPeriod: '2024-06-15 to 2024-06-17',
            tourPurpose: 'Training Workshop',
            voucherStatus: 'Approved by (Sanjay)'
        },
        {
            voucherNo: '008',
            voucherDate: '2024-02-13',
            navVoucherNo: 'NAV008',
            employeeName: 'Gopal Krishna (GK129)',
            voucherAmount: '8000.00',
            tourName: 'Pune To Gujarat',
            tourPeriod: '2024-06-15 to 2024-06-17',
            tourPurpose: 'Client Meeting',
            voucherStatus: 'Rejected by (Ramesh)'
        },
        {
            voucherNo: '009',
            voucherDate: '2024-06-13',
            navVoucherNo: 'NAV009',
            employeeName: 'Gopal Krishna (GK129)',
            voucherAmount: '8000.00',
            tourName: 'Business Meeting',
            tourPeriod: '2024-06-15 to 2024-06-17',
            tourPurpose: 'Client Meeting',
            voucherStatus: 'Rejected by (Surendra)'
        },
        {
            voucherNo: '010',
            voucherDate: '2024-05-03',
            navVoucherNo: 'NAV010',
            employeeName: 'Ramesh (RN389)',
            voucherAmount: '2000.00',
            tourName: 'Gujarat To Bangalore',
            tourPeriod: '2024-06-15 to 2024-06-17',
            tourPurpose: 'Training Workshop',
            voucherStatus: 'Pending from (Surendra)'
        }

    ]

    const fnlVoucher = () => {
        navigate('/travelmanagement/TravelExpenseDetailedVoucher');
    };
    const [expenses, setExpenses] = useState([
        { expenseType: 'Hotel', numUsers: 4, location: 'Mumbai', amount: 4000.00 },
    ]);

    const myuserDropdownByManager = [
        { value: 'User 1', label: 'User 1' },
        { value: 'User 2', label: 'User 2' },
        { value: 'User 3', label: 'User 3' },
        { value: 'User 4', label: 'User 4' }
        // Add more objects as needed
    ];
    const handleMultiSelectChange = (selectedOptions) => {
        setFilterUserId(selectedOptions);
    };
    // State initialization and handling

    const handleAddExpense = () => {
        // Add new expense to the expenses array
        const newExpense = {
            expenseType: expenseType,
            numUsers: 0, // Placeholder for number of users
            location: '', // Placeholder for location
            amount: 0, // Placeholder for amount
        };
        setExpenses([...expenses, newExpense]);

        // Reset form fields
        setExpenseType('');
        setStartDate(null);
        setEndDate(null);
        setSelectedAccount(null);

        // Hide the form after adding expense
        setShowForm(false);
    };
    const myhandleClose = () => {
        setShowForm(false);
    };

    const userDropdownvoucher = [
        { value: 'vo1', label: 'NAV001' },
        { value: 'vo2', label: 'NAV002' },
        { value: 'vo3', label: 'NAV003' }
    ]
    const myuserDropdown = [
        { value: 'Devaraj', label: 'Devaraj (D123)' },
        { value: 'Somasekhar', label: 'Somasekhar (SS456)' },
        { value: 'Rajeev', label: 'Rajeev (R189)' }
    ];
    const userDropdown = [
        { value: 'hotel', label: 'Hotel' },
        { value: 'conveyance', label: 'Conveyance Charges' },
        { value: 'staff', label: 'Staff Welfare' },
        { value: 'sundry', label: 'Sundry Expense' }
    ];
    const accts = [
        { value: '1', label: '44343003-Lodging And Boarding' },
        { value: '2', label: '44343005-Tour Conveyance Charges' },
        { value: '3', label: '44370101-Staff Welfare' },
        { value: '4', label: '44343007-Tour Sundry Expenses' }
    ];
    const [fromDate, setFromDate] = useState(
        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    );
    // const navigate = useNavigate();
    // const handleViewClick = (voucherNo) => {
    //     navigate(`/travelmanagement/TravelExpenseVoucherDetails/${voucherNo}`);
    // };

    const [toDate, setToDate] = useState(new Date());
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { userDropdownByManager } = useSelector((state) => state.data);
    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    };

    const [expenseType, setExpenseType] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);

    const setSelectSearchDrop = (data, type) => {

    };


    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const handleChange = (selectedOption) => {
        if (!disableSelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const handlemyChange = (selectedOption) => {
        if (!disablemySelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleShowForm();
        myhandleShowForm();
    };
    const [disableSelectOnChange, setDisableSelectOnChange] = useState(false);
    const [disablemySelectOnChange, setDisablemySelectOnChange] = useState(false);


    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setRefresh((prev) => !prev);
    };

    const [selectedOption, setSelectedOption] = useState('actual');
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [showElements, setShowElements] = useState(true);


    const handleDelete = (index) => {
        const updatedTableData = tableData.filter((_, i) => i !== index);
        setTableData(updatedTableData);
        if (updatedTableData.length === 0) {
            setIsTableVisible(false); // Hide the table if there are no rows left
        }
    };
    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                <input className="mots-lst"
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null} // Prevent the default checkbox behavior
                />
                <label>{props.label}</label>
            </components.Option>
        );
    };

    const exportExcelClaim = () => {

    };

    const handleUserChange = (selectedOption) => {
        setSelectedUser(selectedOption);
    };

    const handleVerifierChange = (selectedOption) => {
        setSelectedVerifier(selectedOption);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };
    const adhandleShow = () => {
        // Your logic to add table data or show modal
        console.log("Adding table data or showing modal");
    };
    const handleClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShow(false);
    };
    const mydatahandleshow = (myselectedVoucher) => {
        setmysetSelectedVoucher(myselectedVoucher);
        setmyDataShow(true);
        setShow5(true);
    }
    const myvchrhandleClose = () => {
        setmyDataShow(false);
        setmyShowForm(false);
    };
    const myshowhandleClose = () => {
        setvchrshow(false);
        setmyShowForm(false);
        setShow(false);
        setmyDataShow(false);
    };
    const vchrhandleClose = () => {

        setvchrshow(false);
        setmyShowForm(false);

    };
    const edithandleShow = (voucher) => {
        setSelectedVoucher(voucher);
        setvchrshow(true);
        setShow3(true);
    };
    const handleShow = () => {

        setShow(true);
        setShowElements(true);
        setShowSaveButton(true);
        setmyShowSaveButton(false);
        setmyinnerShowSaveButton(true);
        setDisableSelectOnChange(false);
        setDisablemySelectOnChange(false);
        setRefresh((prev) => !prev);

    };


    const handleShowForm = () => {
        // setShow(false);
        // setShowForm(true);
        // setmyShowForm(false);
        setmyShowForm(true);
        setmyDataShow(false);
        setShow(false);
        // setShowForm(true);
        // Toggle the form visibility or handle the form show logic here
        setRefresh(prevState => !prevState);
    };
    const myhandleShowForm = () => {

        setmyShowForm(true);
        setvchrshow(false);
    };

    console.log(tableData, "tableData")

    return (
        <div id="main-page">
            {userDetails !== null && userDetails !== "" ? (
                <>
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    <div className="page-wrapper">
                        <div className={`main-body ${toggleshift.style}`}>
                            <div className="page-content usrhrchy">
                                <Row className="search-row usrhrchy">
                                    <Col md={4} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToBackPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">TRAVEL EXPENSE FINAL VOUCHER</h6>
                                        </div>
                                    </Col>
                                    <Col md={4} className="cat-col" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <SBSaveUpdateBtn btnName="Detailed Voucher" onClickEffect={fnlVoucher} />
                                    </Col>
                                    <Col md={4} className="cat-col">
                                        <div
                                            className="search-bar-header"
                                            style={{ float: "right" }}
                                        >
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                {/* <div
                                                    className="header-filter-btn"
                                                    onClick={() => setFilter(!filter)}
                                                >
                                                    <p className="card-head">
                                                        <i className="bi bi-sliders" />
                                                        &nbsp;&nbsp;Filters
                                                    </p>
                                                </div> */}
                                                <SbAddBtn
                                                    onClickEffect={handleShow}
                                                />
                                            </div>
                                        </div>

                                    </Col>


                                </Row>

                                {filter && (
                                    <div className="page-filter-section">
                                        <div style={{ width: "100%" }}>
                                            <Row className="filters-row">
                                                <Col md={3}>
                                                    <div className="clients-filter-input-boxs filter-box-div">

                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                padding: "0px 5px",
                                                            }}
                                                        >
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={userDropdown}
                                                                placeholder="Select User"
                                                                value={
                                                                    filterUserId === ""
                                                                        ? ""
                                                                        : userDropdown.value
                                                                }
                                                                onInputChange={userDropdown.label}
                                                                onChange={(data) =>
                                                                    setFilterUserId(data.value)
                                                                }
                                                                isSearchable={true}
                                                            />
                                                        </div>

                                                    </div>
                                                </Col>
                                                <Col md={9}>
                                                    <div className="clients-filter-input-boxs filter-box-div usrhrchy">
                                                        <SbCancelBtn
                                                            onClickEffect={clearSerachBySelect}
                                                            btnName="Clear"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                )}


                                <div className="usrhrchy table-responsive spares-scrollbar trvlexpnse">

                                    <table className="table spares-table-data">
                                        <thead className="thead-light spares-thead">
                                            <tr>
                                                <th>Voucher No</th>
                                                {/* <th>Voucher Date</th> */}
                                                <th>NAV Voucher No</th>
                                                <th>Employee Name (Code)</th>
                                                <th>Voucher Amount</th>
                                                <th>Tour Name</th>
                                                <th>Tour Period</th>
                                                <th>Tour Purpose</th>
                                                <th>Voucher Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vouchers.map((item, index) => (
                                                <tr key={index} className="spare-table-row-data">
                                                    <td>{item.voucherNo}</td>
                                                    {/* <td>{new Date(item.voucherDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td> */}
                                                    <td>{item.navVoucherNo}</td>
                                                    <td>{item.employeeName}</td>
                                                    <td>{item.voucherAmount}</td>
                                                    <td>{item.tourName}</td>
                                                    <td>{item.tourPeriod}</td>
                                                    <td>{item.tourPurpose}</td>
                                                    <td>{item.voucherStatus}</td>
                                                    <td className="myhrchy-dta">

                                                        <button className="btn spare-edit-link" onClick={() => edithandleShow(item)}>
                                                            <i className="fa fa-edit"></i> Edit
                                                        </button>
                                                        {/* <Link to={`/travelmanagement/TravelExpenseVoucherDetails/${item.voucherNo}`}> */}
                                                        <button className="btn spare-edit-link" onClick={() => mydatahandleshow(item)}>
                                                            <i className="fa fa-eye"></i> View
                                                        </button>
                                                        {/* </Link> */}
                                                        <button className="btn spare-edit-link">
                                                            <i className="fa fa-trash"></i> Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>





                                </div>
                                <div className="download-and-pagination">
                                    <SbDownloadBtn onClickEffect={exportExcelClaim} />
                                </div>
                            </div>

                        </div>

                    </div>
                    <Footer />

                    {/* <Modal
                        show={show}
                        onHide={handleClose}
                        className="main_model add-edit-model amc-model travexpse"

                    >
                        <div className="add-edit-heading">

                            <h6 className="add-edit-head">Travel Expense Final Voucher</h6>
                            <i className="fa fa-times" onClick={handleClose} />
                        </div>
                        <form onSubmit={handleFormSubmit}>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">

                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={2} className="cat-col">
                                            <h6>Employee :</h6>
                                        </Col>
                                        <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            <FloatingLabel label='Select Employee' className="float-hidden float-select">
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={myuserDropdown}
                                                    placeholder="Select Employee"
                                                    value={myuserDropdown.find(option => option.value === selectedAccount)}
                                                    onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>

                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={2} className="cat-col">
                                            <h6>Tour Name :</h6>
                                        </Col>
                                        <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <FloatingLabel label='Enter Tour Name' className="float-hidden float-input">
                                                <Form.Control
                                                    type="text"
                                                    name="clientEmail2"
                                                    className="form-control form add-input"
                                                    placeholder="Enter Tour Name"
                                                    value={expenseType}
                                                    onChange={(e) => setExpenseType(e.target.value)}
                                                />
                                            </FloatingLabel>

                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={2} className="cat-col">
                                            <h6>Tour Period :</h6>
                                        </Col>
                                        <Col md={10} className="cat-col">


                                            <div className="date-picker-container mydats">
                                                <Row>
                                                    <Col md={6} className="frm">
                                                        <div className="date-picker">

                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => handleDateChange([date, endDate])}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                selectsStart
                                                                className="select from-date date-filter"
                                                                placeholderText="From"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={new Date()}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6} className="frm-to">
                                                        <div className="date-picker">

                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => handleDateChange([startDate, date])}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                selectsEnd
                                                                className="select to-date date-filter"
                                                                placeholderText="To"
                                                                dateFormat="dd/MM/yyyy"
                                                                minDate={startDate}
                                                                maxDate={new Date()}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </div>

                                        </Col>

                                    </Row>
                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={2} className="cat-col">
                                            <h6>Purpose :</h6>
                                        </Col>
                                        <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            <FloatingLabel label='Enter Tour Purpose' className="float-hidden float-input">
                                                <Form.Control
                                                    type="text"
                                                    name="clientEmail"
                                                    className="form-control form add-input"
                                                    placeholder="Enter Tour Purpose"

                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>


                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={2} className="cat-col">
                                            <h6>NAV Voucher No :</h6>
                                        </Col>
                                        <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <FloatingLabel label='Select NAV Voucher No' className="float-hidden float-select">
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={userDropdownvoucher}
                                                    placeholder="Select NAV Voucher No"
                                                    value={userDropdownvoucher.find(option => option.value === selectedAccount)}
                                                    onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>


                                        </Col>
                                    </Row>

                                    <SbAddExpenseBtn className='trvadbtn' onClickEffect={handleShowForm} />


                                    <table className="thead-light spares-thead mytbusrhrchy mt-3">
                                        <thead className="my-tbhd">
                                            <tr>
                                                <th>Expense Type</th>
                                                <th>No. of Members</th>
                                                <th>Location</th>
                                                <th>Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[
                                                { expenseType: 'Hotel', numUsers: 4, location: 'Mumbai', amount: 4000 },
                                                { expenseType: 'conveyance', numUsers: 2, location: 'Bangalore', amount: 3000 },
                                            ].map((row, index) => (
                                                <tr key={index}>
                                                    <td style={{ cursor: 'pointer' }}>
                                                        {row.expenseType}
                                                    </td>
                                                    <td>{row.numUsers}</td>
                                                    <td>{row.location}</td>
                                                    <td>{row.amount}</td>
                                                    <td>
                                                        {showElements && (
                                                            <span className="material-symbols-outlined mybtnclos" onClick={() => handleDelete(index)}>
                                                                Cancel
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp">

                                        {showSaveButton && <SBSaveUpdateBtn btnName="Save" />}
                                        {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}

                                    </div>
                                </div>

                            </div>




                        </form>
                    </Modal> */}

                    {/* <Modal
                        show={vchrshow}
                        onHide={vchrhandleClose}
                        className="main_model add-edit-model amc-model travexpse"

                    >
                        <div className="mydtaadd">
                            <div className="mydtaedt">
                                <h6 className="add-edit-head">Travel Expense Final Voucher</h6>
                                <i className="fa fa-times" onClick={vchrhandleClose} />
                            </div>

                            <div className="invs-dta">
                                <div className="container add-edit">
                                    <Row className="levllst" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={3} className="cat-col mylst-dta">
                                            <h6>Voucher No : </h6>
                                            <p>{selectedVoucher.voucherNo}</p>
                                        </Col>
                                        <Col md={3} className="cat-col mylst-dta">
                                            <h6>Voucher Date : </h6>
                                            <p>{new Date(selectedVoucher.voucherDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</p>
                                        </Col>
                                        <Col md={3} className="cat-col mylst-dta">
                                            <h6>Voucher Status : </h6>
                                            <p>{selectedVoucher.voucherStatus}</p>
                                        </Col>

                                    </Row>
                                </div>


                            </div>


                        </div>

                        <form onSubmit={handleFormSubmit}>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">

                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={2} className="cat-col">
                                            <h6>Employee :</h6>
                                        </Col>
                                        <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            <FloatingLabel label='Select Employee' className="float-hidden float-select">
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={myuserDropdown}
                                                    placeholder="Select Employee"
                                                    value={myuserDropdown.find(option => option.value === selectedAccount)}
                                                    onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>

                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={2} className="cat-col">
                                            <h6>Tour Name :</h6>
                                        </Col>
                                        <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <FloatingLabel label='Enter Tour Name' className="float-hidden float-input">
                                                <Form.Control
                                                    type="text"
                                                    name="clientEmail"
                                                    className="form-control form add-input"
                                                    placeholder="Enter Tour Name"
                                               
                                                />
                                            </FloatingLabel>

                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={2} className="cat-col">
                                            <h6>Tour Period :</h6>
                                        </Col>
                                        <Col md={10} className="cat-col">


                                            <div className="date-picker-container mydats">
                                                <Row>
                                                    <Col md={6} className="frm">
                                                        <div className="date-picker">

                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => handleDateChange([date, startDate])}
                                                                startDate={startDate}

                                                                selectsStart
                                                                className="select from-date date-filter"
                                                                placeholderText="From"
                                                                dateFormat="dd/MM/yyyy"
                                                                minDate={startDate}

                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6} className="frm-to">
                                                        <div className="date-picker">

                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => handleDateChange([endDate, date])}

                                                                endDate={endDate}
                                                                selectsEnd
                                                                className="select to-date date-filter"
                                                                placeholderText="To"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={endDate}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </div>

                                        </Col>

                                    </Row>
                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={2} className="cat-col">
                                            <h6>Purpose :</h6>
                                        </Col>
                                        <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            <FloatingLabel label='Enter Tour Purpose' className="float-hidden float-input">
                                                <Form.Control
                                                    type="text"
                                                    name="clientEmail2"
                                                    className="form-control form add-input"
                                                    placeholder="Enter Tour Purpose"
                                               
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>


                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={2} className="cat-col">
                                            <h6>NAV Voucher No :</h6>
                                        </Col>
                                        <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <FloatingLabel label='Select NAV Voucher No' className="float-hidden float-select">
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={userDropdownvoucher}
                                                    placeholder="Select NAV Voucher No"
                                                    value={userDropdownvoucher.find(option => option.value === selectedAccount)}
                                                    onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>


                                        </Col>
                                    </Row>
                                    <SbAddExpenseBtn className='trvadbtn' onClickEffect={myhandleShowForm} />

                                    <table className="thead-light spares-thead mytbusrhrchy mt-3">
                                        <thead className="my-tbhd">
                                            <tr>
                                                <th>Expense Type</th>
                                                <th>No. of Members</th>
                                                <th>Location</th>
                                                <th>Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[
                                                { expenseType: 'Hotel', numUsers: 4, location: 'Mumbai', amount: 4000 },
                                                { expenseType: 'conveyance', numUsers: 2, location: 'Bangalore', amount: 3000 },
                                            ].map((row, index) => (
                                                <tr key={index}>
                                                    <td style={{ cursor: 'pointer' }}>
                                                        {row.expenseType}
                                                    </td>
                                                    <td>{row.numUsers}</td>
                                                    <td>{row.location}</td>
                                                    <td>{row.amount}</td>
                                                    <td>
                                                        {showElements && (
                                                            <span className="material-symbols-outlined mybtnclos" onClick={() => handleDelete(index)}>
                                                                Cancel
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp">

                                        {showSaveButton && <SBSaveUpdateBtn btnName="Save" />}
                                        {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={vchrhandleClose} />}

                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal>
                    <Modal
                        show={myshowForm}
                        onHide={myvchrhandleClose}
                        className="main_model add-edit-model amc-model travexpse"

                    >
                        <div className="mydtaadd">
                            <div className="mydtaedt">
                                <h6 className="add-edit-head">Travel Expense Final Voucher</h6>
                                <i className="fa fa-times" onClick={myvchrhandleClose} />
                            </div>
                            <div className="add-inputs usr-hrchy">
                                <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={2} className="cat-col">
                                        <h6>Expense Type :</h6>
                                    </Col>
                                    <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <FloatingLabel label='Select Expense Type' className="float-hidden float-select">
                                            <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                options={userDropdown}
                                                placeholder="Select Expense Type"
                                                value={userDropdown.find(option => option.value === selectedAccount)}
                                                onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                isSearchable={true}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={2} className="cat-col">
                                        <h6>Members :</h6>
                                    </Col>
                                    <Col md={10} className="cat-col">
                                        <div style={{ maxWidth: "auto" }}>

                                            <MultiSelect
                                                options={myuserDropdownByManager}
                                                value={filterUserId}
                                                onChange={handleMultiSelectChange}
                                                labelledBy="Select"
                                                disableSearch={true} 
                                                disableClearAll={true} 
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={2} className="cat-col">
                                        <h6>Location :</h6>
                                    </Col>
                                    <Col md={10} className="cat-col">
                                        <FloatingLabel label='Enter Location' className="float-hidden float-input">
                                            <Form.Control
                                                type="text"
                                                className="react-input-container"
                                                placeholder="Enter Location"
                                           
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={2} className="cat-col">
                                        <h6>Amount :</h6>
                                    </Col>
                                    <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <FloatingLabel label='Enter Amount' className="float-hidden float-input">
                                            <Form.Control
                                                type="text"
                                                className="react-input-container"
                                                placeholder="Enter Amount"
                                           
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp">
                                    {showSaveButton && <SBSaveUpdateBtn btnName="Add To List" />}
                                    {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={myshowhandleClose} />}
                                </div>
                            </div>
                        </div>
                    </Modal>


                    <Modal
                        show={mydatashow}
                        onHide={myvchrhandleClose}
                        className="main_model add-edit-model amc-model travexpse"
                    >
                        <div className="add-edit-heading">
                            <h6 className="add-edit-head">Travel Expense Final Voucher</h6>
                            <i className="fa fa-times" onClick={myvchrhandleClose} />
                        </div>
                        <div className="container add-edit invs-dta">
                            <Row className="levllst mb-2">
                                <Col md={3} className="cat-col mylst-dta"><h6>Employee Code:</h6><p>{employeeCode}</p></Col>
                                <Col md={4} className="cat-col mylst-dta"><h6>Tracker Voucher No.:</h6><p>{trackerVoucherNo}</p></Col>
                                <Col md={3} className="cat-col mylst-dta"><h6>NAV Voucher No.:</h6><p>{navVoucherNo}</p></Col>
                                <Col md={2} className="cat-col mylst-dta"><h6>Region:</h6><p>{region}</p></Col>
                            </Row>
                            <Row className="levllst mb-2">
                                <Col md={3} className="cat-col mylst-dta"><h6>Employee Name:</h6><p>{employeeName}</p></Col>
                                <Col md={4} className="cat-col mylst-dta"><h6>Tracker Voucher Date:</h6><p>{trackerVoucherDate}</p></Col>
                                <Col md={2} className="cat-col mylst-dta"><h6>Grade:</h6><p>{grade}</p></Col>
                                <Col md={3} className="cat-col mylst-dta"><h6>Designation:</h6><p>{designation}</p></Col>
                            </Row>
                            <Row className="levllst mb-2">
                                <Col md={3} className="cat-col mylst-dta"><h6>Tour Name:</h6><p>{tourName}</p></Col>
                                <Col md={4} className="cat-col mylst-dta"><h6>Tour Period:</h6><p>{tourPeriod}</p></Col>
                                <Col md={3} className="cat-col mylst-dta"><h6>Dept:</h6><p>{dept}</p></Col>
                                <Col md={2} className="cat-col mylst-dta"><h6>Branch:</h6><p>{branch}</p></Col>
                            </Row>
                            <Row className="levllst mb-2">
                                <Col md={12} className="cat-col mylst-dta"><h6>Purpose of Tour:</h6><p>{purposeOfTour}</p></Col>
                            </Row>
                            <div className="gl-accounts mt-4">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>GL Account No</th>
                                            <th>GL Account Name</th>
                                            <th>No of Members</th>
                                            <th>Location Code</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {glAccounts.map((account, index) => (
                                            <tr key={index}>
                                                <td>{account.glAccountNo}</td>
                                                <td>{account.glAccountName}</td>
                                                <td>{account.noOfMembers}</td>
                                                <td>{account.locationCode}</td>
                                                <td>{account.amount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="4">Total Amount</td>
                                            <td>{totalAmount}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp">

                            
                            {myinnershowSaveButton && <SbCancelBtn btnName="Close" onClickEffect={myvchrhandleClose} />}

                        </div>


                    </Modal> */}

                    <Modal show={show} onHide={handleClose} className="main_model add-edit-model amc-model travexpse trvl-modal">
                        <div className="add-edit-heading">

                            <h6 className="add-edit-head">Add Travel Expense Final Voucher</h6>
                            <i className="fa fa-times" onClick={handleClose} />
                        </div>

                        <Modal.Body>

                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">

                                    <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={3} className="cat-col">
                                            <h6>Employee :</h6>
                                        </Col>
                                        <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            <FloatingLabel label='Select Employee' className="float-hidden float-select">
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={myuserDropdown}
                                                    placeholder="Select Employee"
                                                    value={myuserDropdown.find(option => option.value === selectedAccount)}
                                                    onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>

                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={3} className="cat-col">
                                            <h6>Tour Name :</h6>
                                        </Col>
                                        <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <FloatingLabel label='Enter Tour Name' className="float-hidden float-input">
                                                <Form.Control
                                                    type="text"
                                                    name="clientEmail2"
                                                    className="form-control form add-input"
                                                    placeholder="Enter Tour Name"
                                                    value={expenseType}
                                                    onChange={(e) => setExpenseType(e.target.value)}
                                                />
                                            </FloatingLabel>

                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={3} className="cat-col">
                                            <h6>Tour Period :</h6>
                                        </Col>
                                        <Col md={9} className="cat-col">
                                            <div className="date-picker-container mydats">
                                                <Row>
                                                    <Col md={6} className="frm">
                                                        <div className="date-picker">

                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => handleDateChange([date, endDate])}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                selectsStart
                                                                className="select from-date date-filter"
                                                                placeholderText="From"
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={new Date()}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6} className="frm-to">
                                                        <div className="date-picker">

                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => handleDateChange([startDate, date])}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                selectsEnd
                                                                className="select to-date date-filter"
                                                                placeholderText="To"
                                                                dateFormat="dd/MM/yyyy"
                                                                minDate={startDate}
                                                                maxDate={new Date()}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </div>

                                        </Col>

                                    </Row>
                                    <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={3} className="cat-col">
                                            <h6>Purpose :</h6>
                                        </Col>
                                        <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            <FloatingLabel label='Enter Tour Purpose' className="float-hidden float-input">
                                                <Form.Control
                                                    type="text"
                                                    name="clientEmail"
                                                    className="form-control form add-input"
                                                    placeholder="Enter Tour Purpose"

                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>


                                    <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={3} className="cat-col">
                                            <h6>NAV Voucher No :</h6>
                                        </Col>
                                        <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <FloatingLabel label='Select NAV Voucher No' className="float-hidden float-select">
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={userDropdownvoucher}
                                                    placeholder="Select NAV Voucher No"
                                                    value={userDropdownvoucher.find(option => option.value === selectedAccount)}
                                                    onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>


                                        </Col>
                                    </Row>

                                    <SbAddExpenseBtn className='trvadbtn' onClickEffect={handleShow2} />

                                    <table className="thead-light spares-thead mytbusrhrchy mt-3">
                                        <thead className="my-tbhd">
                                            <tr>
                                                <th>Expense Type</th>
                                                <th>No. of Members</th>
                                                <th>Location</th>
                                                <th>Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[
                                                { expenseType: 'Hotel', numUsers: 4, location: 'Mumbai', amount: 4000.00 },
                                                { expenseType: 'conveyance', numUsers: 2, location: 'Bangalore', amount: 3000.00 },
                                            ].map((row, index) => (
                                                <tr key={index}>
                                                    <td style={{ cursor: 'pointer' }}>
                                                        {row.expenseType}
                                                    </td>
                                                    <td>{row.numUsers}</td>
                                                    <td>{row.location}</td>
                                                    <td>{row.amount.toFixed(2)}</td>
                                                    <td>
                                                        {showElements && (
                                                            <span className="material-symbols-outlined mybtnclos" onClick={() => handleDelete(index)}>
                                                                Cancel
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">

                                        {showSaveButton && <SBSaveUpdateBtn btnName="Save" onClickEffect={mysaveBtn} />}
                                        {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}

                                    </div>
                                </div>

                            </div>
                        </Modal.Body>

                    </Modal>
                    <Modal show={show2} onHide={handleClose2} className="main_model add-edit-model amc-model travexpse trvl-modal">
                        <div className="add-edit-heading">

                            <h6 className="add-edit-head">Add Travel Expense Final Voucher</h6>
                            <i className="fa fa-times" onClick={handleClose2} />
                        </div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>Add Travel Expense Final Voucher</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                            <div className="add-inputs usr-hrchy">
                                <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={3} className="cat-col">
                                        <h6>Expense Type :</h6>
                                    </Col>
                                    <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <FloatingLabel label='Select Expense Type' className="float-hidden float-select">
                                            <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                options={userDropdown}
                                                placeholder="Select Expense Type"
                                                value={userDropdown.find(option => option.value === selectedAccount)}
                                                onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                isSearchable={true}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={3} className="cat-col">
                                        <h6>Members :</h6>
                                    </Col>
                                    <Col md={9} className="cat-col">
                                        <div style={{ maxWidth: "auto" }}>

                                            <MultiSelect
                                                options={myuserDropdownByManager}
                                                value={filterUserId}
                                                onChange={handleMultiSelectChange}
                                                labelledBy="Select"
                                                disableSearch={true}
                                                disableClearAll={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={3} className="cat-col">
                                        <h6>Location :</h6>
                                    </Col>
                                    <Col md={9} className="cat-col">
                                        <FloatingLabel label='Enter Location' className="float-hidden float-input">
                                            <Form.Control
                                                type="text"
                                                className="react-input-container"
                                                placeholder="Enter Location"

                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={3} className="cat-col">
                                        <h6>Amount :</h6>
                                    </Col>
                                    <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <FloatingLabel label='Enter Amount' className="float-hidden float-input">
                                            <Form.Control
                                                type="text"
                                                className="react-input-container"
                                                placeholder="Enter Amount"

                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">
                                    {showSaveButton && <SBSaveUpdateBtn btnName="Add To List" onClickEffect={mysaveBtn} />}
                                    {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose2} />}
                                </div>
                            </div>
                        </Modal.Body>

                    </Modal>

                    <Modal show={show3} onHide={handleClose3} className="main_model add-edit-model amc-model travexpse trvl-modal">
                        <div className="add-edit-heading">

                            <h6 className="add-edit-head">Edit Travel Expense Final Voucher</h6>
                            <i className="fa fa-times" onClick={handleClose3} />
                        </div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>
                                Edit Travel Expense Final Voucher
                            </Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>

                            <div className="container add-edit">
                                <Row className="levllst" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={4} className="cat-col mylst-dta">
                                        <h6>Voucher No : </h6>
                                        <p>{selectedVoucher.voucherNo}</p>
                                    </Col>
                                    {/* <Col md={3} className="cat-col mylst-dta">
                                                <h6>Voucher Date : </h6>
                                                <p>{new Date(selectedVoucher.voucherDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</p>
                                            </Col> */}
                                    <Col md={6} className="cat-col mylst-dta">
                                        <h6>Voucher Status : </h6>
                                        <p>{selectedVoucher.voucherStatus}</p>
                                    </Col>

                                </Row>
                            </div>

                            <form onSubmit={handleFormSubmit}>
                                <div className="container add-edit">
                                    <div className="add-inputs usr-hrchy">

                                        <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={3} className="cat-col">
                                                <h6>Employee :</h6>
                                            </Col>
                                            <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                                <FloatingLabel label='Select Employee' className="float-hidden float-select">
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={myuserDropdown}
                                                        placeholder="Select Employee"
                                                        value={myuserDropdown.find(option => option.value === selectedAccount)}
                                                        onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>

                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={3} className="cat-col">
                                                <h6>Tour Name :</h6>
                                            </Col>
                                            <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <FloatingLabel label='Enter Tour Name' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder="Enter Tour Name"

                                                    />
                                                </FloatingLabel>

                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={3} className="cat-col">
                                                <h6>Tour Period :</h6>
                                            </Col>
                                            <Col md={9} className="cat-col">
                                                <div className="date-picker-container mydats">
                                                    <Row>
                                                        <Col md={6} className="frm">
                                                            <div className="date-picker">

                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date) => handleDateChange([date, startDate])}
                                                                    startDate={startDate}

                                                                    selectsStart
                                                                    className="select from-date date-filter"
                                                                    placeholderText="From"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    minDate={startDate}

                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="frm-to">
                                                            <div className="date-picker">

                                                                <DatePicker
                                                                    selected={endDate}
                                                                    onChange={(date) => handleDateChange([endDate, date])}

                                                                    endDate={endDate}
                                                                    selectsEnd
                                                                    className="select to-date date-filter"
                                                                    placeholderText="To"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    maxDate={endDate}
                                                                />
                                                            </div>
                                                        </Col>

                                                    </Row>

                                                </div>

                                            </Col>

                                        </Row>
                                        <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={3} className="cat-col">
                                                <h6>Purpose :</h6>
                                            </Col>
                                            <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                                <FloatingLabel label='Enter Tour Purpose' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail2"
                                                        className="form-control form add-input"
                                                        placeholder="Enter Tour Purpose"

                                                    />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>


                                        <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={3} className="cat-col">
                                                <h6>NAV Voucher No :</h6>
                                            </Col>
                                            <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <FloatingLabel label='Select NAV Voucher No' className="float-hidden float-select">
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={userDropdownvoucher}
                                                        placeholder="Select NAV Voucher No"
                                                        value={userDropdownvoucher.find(option => option.value === selectedAccount)}
                                                        onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>


                                            </Col>
                                        </Row>
                                        <SbAddExpenseBtn className='trvadbtn' onClickEffect={handleShow4} />

                                        <table className="thead-light spares-thead mytbusrhrchy mt-3">
                                            <thead className="my-tbhd">
                                                <tr>
                                                    <th>Expense Type</th>
                                                    <th>No. of Members</th>
                                                    <th>Location</th>
                                                    <th>Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {[
                                                    { expenseType: 'Hotel', numUsers: 4, location: 'Mumbai', amount: 4000.00 },
                                                    { expenseType: 'conveyance', numUsers: 2, location: 'Bangalore', amount: 3000.00 },
                                                ].map((row, index) => (
                                                    <tr key={index}>
                                                        <td style={{ cursor: 'pointer' }}>
                                                            {row.expenseType}
                                                        </td>
                                                        <td>{row.numUsers}</td>
                                                        <td>{row.location}</td>
                                                        <td>{row.amount.toFixed(2)}</td>
                                                        <td>
                                                            {showElements && (
                                                                <span className="material-symbols-outlined mybtnclos" onClick={() => handleDelete(index)}>
                                                                    Cancel
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">

                                            {showSaveButton && <SBSaveUpdateBtn btnName="Save" />}
                                            {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose3} />}

                                        </div>
                                    </div>
                                </div>
                            </form>

                        </Modal.Body>

                    </Modal>

                    <Modal show={show4} onHide={handleClose4} className="main_model add-edit-model amc-model travexpse trvl-modal">
                        <div className="add-edit-heading">

                            <h6 className="add-edit-head">Edit Travel Expense Detailed Voucher</h6>
                            <i className="fa fa-times" onClick={handleClose4} />
                        </div>

                        {/* <Modal.Header closeButton>

                            <Modal.Title>Edit Travel Expense Detailed Voucher</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                            <div className="add-inputs usr-hrchy">
                                <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={3} className="cat-col">
                                        <h6>Expense Type :</h6>
                                    </Col>
                                    <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <FloatingLabel label='Select Expense Type' className="float-hidden float-select">
                                            <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                options={userDropdown}
                                                placeholder="Select Expense Type"
                                                value={userDropdown.find(option => option.value === selectedAccount)}
                                                onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                isSearchable={true}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={3} className="cat-col">
                                        <h6>Members :</h6>
                                    </Col>
                                    <Col md={9} className="cat-col">
                                        <div style={{ maxWidth: "auto" }}>

                                            <MultiSelect
                                                options={myuserDropdownByManager}
                                                value={filterUserId}
                                                onChange={handleMultiSelectChange}
                                                labelledBy="Select"
                                                disableSearch={true}
                                                disableClearAll={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={3} className="cat-col">
                                        <h6>Location :</h6>
                                    </Col>
                                    <Col md={9} className="cat-col">
                                        <FloatingLabel label='Enter Location' className="float-hidden float-input">
                                            <Form.Control
                                                type="text"
                                                className="react-input-container"
                                                placeholder="Enter Location"

                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Col md={3} className="cat-col">
                                        <h6>Amount :</h6>
                                    </Col>
                                    <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <FloatingLabel label='Enter Amount' className="float-hidden float-input">
                                            <Form.Control
                                                type="text"
                                                className="react-input-container"
                                                placeholder="Enter Amount"

                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">
                                    {showSaveButton && <SBSaveUpdateBtn btnName="Add To List" />}
                                    {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose4} />}
                                </div>
                            </div>
                        </Modal.Body>

                    </Modal>
                    <Modal show={show5} onHide={handleClose5} className="main_model add-edit-model amc-model travexpse trvl-modalview">
                        <div className="add-edit-heading">

                            <h6 className="add-edit-head">View Travel Expense Final Voucher</h6>
                            <i className="fa fa-times" onClick={handleClose5} />
                        </div>
                        {/* <Modal.Header closeButton>

                            <Modal.Title>View Travel Expense Final Voucher</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                            <div className="container add-inputs usr-hrchy">
                                <Row className="levllst mb-3">
                                    <Col md={3} className="cat-col mylst-dta"><h6>Employee Code:</h6><p>{employeeCode}</p></Col>
                                    <Col md={4} className="cat-col mylst-dta"><h6>Tracker Voucher No.:</h6><p>{trackerVoucherNo}</p></Col>
                                    <Col md={3} className="cat-col mylst-dta"><h6>NAV Voucher No.:</h6><p>{navVoucherNo}</p></Col>
                                    <Col md={2} className="cat-col mylst-dta"><h6>Region:</h6><p>{region}</p></Col>
                                </Row>
                                <Row className="levllst mb-3">
                                    <Col md={4} className="cat-col mylst-dta"><h6>Employee Name:</h6><p>{employeeName}</p></Col>
                                    <Col md={3} className="cat-col mylst-dta"><h6>Tracker Voucher Date:</h6><p>{trackerVoucherDate}</p></Col>
                                    <Col md={2} className="cat-col mylst-dta"><h6>Grade:</h6><p>{grade}</p></Col>
                                    <Col md={3} className="cat-col mylst-dta"><h6>Designation:</h6><p>{designation}</p></Col>
                                </Row>
                                <Row className="levllst mb-3">
                                    <Col md={3} className="cat-col mylst-dta"><h6>Tour Name:</h6><p>{tourName}</p></Col>
                                    <Col md={4} className="cat-col mylst-dta"><h6>Tour Period:</h6><p>{tourPeriod}</p></Col>
                                    <Col md={3} className="cat-col mylst-dta"><h6>Dept:</h6><p>{dept}</p></Col>
                                    <Col md={2} className="cat-col mylst-dta"><h6>Branch:</h6><p>{branch}</p></Col>
                                </Row>
                                <Row className="levllst mb-3">
                                    <Col md={12} className="cat-col mylst-dta"><h6>Purpose of Tour:</h6><p>{purposeOfTour}</p></Col>
                                </Row>
                                <div className="gl-accounts mt-4">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>GL Account No</th>
                                                <th>GL Account Name</th>
                                                <th>No of Members</th>
                                                <th>Location</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {glAccounts.map((account, index) => (
                                                <tr key={index}>
                                                    <td>{account.glAccountNo}</td>
                                                    <td>{account.glAccountName}</td>
                                                    <td>{account.noOfMembers}</td>
                                                    <td>{account.locationCode}</td>
                                                    <td>{account.amount.toFixed(2)}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="4">Total Amount</td>
                                                <td>{totalAmount.toFixed(2)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">


                                {myinnershowSaveButton && <SbCancelBtn btnName="Close" onClickEffect={handleClose5} />}

                            </div>

                        </Modal.Body>

                    </Modal>
                </>
            ) : (
                <AccessDenied />
            )
            }
        </div >
    );
}

export default TravelExpenseFinalVoucher;
