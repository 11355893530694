import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import Modal from "react-bootstrap/Modal";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { React_Google_API_Key } from "../GoogleApiKey";

const libraries = ["places"];

const defaultLatLng = {
  lat: 28.6448,
  lng: 77.216721,
};

function GoogleMapAddressModel(props) {
  const [markerPosition, setMarkerPosition] = useState(
    props.latLan ?? defaultLatLng
  );
  const [address, setAddress] = useState(props.address);

  const searchBoxRef = useRef(null);
  const mapRef = useRef(null);

  const containerStyle = {
    width: "100%",
    height: "370px",
  };

  const inputStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `260px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `0px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `12px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: "absolute",
    top: "10px",
    right: "60px",
  };

  const onLoadSearchBox = useCallback((ref) => {
    searchBoxRef.current = ref;
  }, []);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setMarkerPosition({ lat, lng });
      setAddress(place.formatted_address);
      mapRef.current.panTo({ lat, lng });
    }
  };

  const onMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });

    // Geocode the new position to get the address
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        setAddress(results[0].formatted_address);
      }
    });
  };

  useEffect(() => {
    props.setLocation(address);
    props.setLatLan(markerPosition);
  }, [markerPosition, address]);

  const addressFromGoogle = () => {
    if (markerPosition.lat && markerPosition.lng && address !== "") {
      props.openGoogleMaps();
    }
  };

  console.log("MarkerPosition", markerPosition);
  console.log("Address", address);

  return (
    <div>
      <div>
        <Modal
          show={props.googleMapsModel}
          className="main_model google-maps-model"
        >
          <div className="add-user-heading">
            <h6 className="add-user-head">GOOGLE MAPS</h6>

            <button
              type="button"
              className="google-address-ok"
              onClick={addressFromGoogle}
              data-bs-dismiss="modal"
            >
              OK
            </button>
            <i className="fa fa-times" onClick={props.openGoogleMaps} />
          </div>
          <div className="google-map-box">
            <LoadScript
              googleMapsApiKey={React_Google_API_Key}
              libraries={libraries}
              loadingElement={<div>Loading...</div>}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={markerPosition}
                zoom={12}
                clickableIcons={true}
                onLoad={(map) => (mapRef.current = map)}
              >
                <>
                  <StandaloneSearchBox
                    onLoad={onLoadSearchBox}
                    onPlacesChanged={onPlacesChanged}
                  >
                    <input
                      type="text"
                      placeholder={
                        props.address !== "" ? props.address : "Search..."
                      }
                      style={inputStyle}
                      // value={address}
                      // onChange={props.searchLocation}
                    />
                  </StandaloneSearchBox>
                  <Marker
                    position={markerPosition}
                    draggable
                    onDragEnd={onMarkerDragEnd}
                  />
                </>
              </GoogleMap>
            </LoadScript>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default GoogleMapAddressModel;
