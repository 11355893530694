import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import SkeletonActivity from "../Skeleton Loading/SkeletonActivity";

function ActivitySlider(props) {
  // slider code
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 0, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  // slider code

  // Getting Screen Size
  const [screenDimensions, setScreenDimensions] = useState(window.screen.width);
  const getScreenDimensions = (e) => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setScreenDimensions(width);
  };

  const [todaydate, setTodaydate] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setTodaydate(new Date());
    }, 1000);
    window.addEventListener("resize", getScreenDimensions);

    return () => {
      window.removeEventListener("resize", getScreenDimensions);
    };
  });
  // Getting Screen Size

  return (
    <div>
      {props.isLoading ? (
        <>
          <SkeletonActivity />
          <SkeletonActivity />
          <SkeletonActivity />
          <SkeletonActivity />
        </>
      ) : props.recentActivities !== "" ? (
        props.recentActivities
          .filter((val) =>
            val?.fullName.toLowerCase().includes(props.searchVal)
          )
          .map((data, i) => (
            <div key={i} className="act-right-body">
              <Row style={{ width: "100%" }} className="active-user-row">
                <Col md={2} className="td active-img-col">
                  <div className="table-user-image">
                    <div className="active-user-image">
                      <img
                        className="table-data-user-img"
                        src={
                          data?.profileIcon
                            ? `${baseURL}/${data?.profileIcon}`
                            : "../images/Admin1.png"
                        }
                        alt=""
                      />
                      {data?.fullName && (
                        <OverlayTrigger
                          delay={{
                            hide: 450,
                            show: 300,
                          }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {data?.checkOutTime === null
                                ? "Active"
                                : "In Active"}
                            </Tooltip>
                          )}
                          placement="top"
                        >
                          <div className="blink-effect-dashboard">
                            <div
                              className={`check-in-status ${
                                data?.checkOutTime === null
                                  ? "status-active"
                                  : "status-inactive"
                              }`}
                            ></div>
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                    <h6>{data.fullName ?? ""}</h6>
                  </div>
                </Col>
                <Col md={10} className="td">
                  <div
                    style={{
                      padding: "0px 5px",
                    }}
                  >
                    <div className="td-user-location">
                      {data?.activityType === "Re-Check-In" ||
                      data?.activityType === "Check-In" ? (
                        <>
                          <p className="card-head">
                            <strong>
                              {data?.activityType} @ &nbsp;
                              {data?.checkInTime}{" "}
                            </strong>
                          </p>
                          {/* <br /> */}
                          <p
                            style={{
                              fontSize: "11px",
                              marginTop: "10px",
                            }}
                          >
                            {data?.cordLoc?.location}
                          </p>
                        </>
                      ) : data?.activityType === "Check-Out" ? (
                        <>
                          <p className="card-head">
                            <strong>
                              {data?.activityType} @ &nbsp;
                              {data?.checkOutTime}
                            </strong>
                          </p>
                          {/* <br /> */}
                          <p
                            style={{
                              fontSize: "11px",
                              marginTop: "10px",
                            }}
                          >
                            {data?.cordLoc?.location}
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            {data?.activityType != null && (
                              <p className="card-head">
                                <strong> {data?.activityType} with </strong>
                              </p>
                            )}
                            <p className="card-head">
                              <strong>
                                {data?.clientName}. &nbsp; Last Seen @ &nbsp;
                                {moment(data?.date).format("hh:mm a")}
                              </strong>
                            </p>{" "}
                          </p>
                          {/* <br /> */}
                          <p
                            style={{
                              fontSize: "11px",
                              marginTop: "10px",
                            }}
                          >
                            {data?.cordLoc?.location}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="act-list-div">
                      <div className="user-activity-images">
                        <div className="parent" style={{ width: "100%" }}>
                          <Carousel
                            responsive={responsive}
                            autoPlay={screenDimensions <= 1024 ? true : false}
                            swipeable={false}
                            draggable={false}
                            // showDots={true}
                            infinite={true}
                            partialVisible={false}
                            className="act-list-div"
                            dotListclassName="custom-dot-list-style"
                          >
                            <div className="act-icon">
                              {/* <Link
                                to={`/trackbyuser/${data?.userID}/${data?.checkOutTime}`}
                                className="activity-page-link sb-ds-link"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "userID",
                                    data?.userID
                                  );
                                }}
                              >
                                <div className="activity-icons">
                                  <i className="bi bi-geo-alt-fill fa"></i>
                                </div>
                                <p>Location</p>
                              </Link> */}
                              <Link
                                className="activity-page-link sb-ds-link"
                                // onClick={() => {
                                //   const mapUrl = `https://www.openstreetmap.org/search?query=${
                                //     data?.cordLoc?.location ?? "NA"
                                //   }`;
                                //   window.open(mapUrl, "_blank");
                                // }}
                                onClick={() => {
                                  const mapUrl = `https://www.google.com/maps/place/${
                                    data?.cordLoc?.location ?? "NA"
                                  }`;
                                  window.open(mapUrl, "_blank");
                                }}
                              >
                                <div className="activity-icons">
                                  <i className="bi bi-geo-alt-fill fa"></i>
                                </div>
                                <p>Location</p>
                              </Link>
                            </div>

                            <div className="act-icon">
                              <Link
                                to="/attendance/fullday"
                                className="activity-page-link sb-ds-link"
                              >
                                <div className="activity-icons">
                                  <i className="fa fa-clock-o"></i>
                                </div>

                                <p>{data?.checkInTime}</p>
                              </Link>
                            </div>

                            <div className="act-icon sb-ds-link">
                              <div className="activity-icons">
                                <i className="fa fa-refresh"></i>
                              </div>
                              <p>{data?.minsHrsAgo}</p>
                            </div>

                            <div className="act-icon sb-ds-link">
                              <div className="activity-icons">
                                <i className="fa fa-signal"></i>
                              </div>
                              <p>{data?.mobileNetwork}</p>
                            </div>

                            <div className="act-icon sb-ds-link">
                              <div className="activity-icons">
                                <i className="fa fa-battery-full"></i>
                              </div>
                              <p>{data?.batteryStrength}</p>
                            </div>

                            <div className="act-icon">
                              <Link
                                to={`/meetings`}
                                className="attendance-page-link sb-ds-link"
                              >
                                <div className="activity-icons meeting">
                                  <i className="fa fa-handshake-o"></i>
                                </div>

                                <p>Meetings</p>
                              </Link>
                              <span className="recent-activity-meeting-count">
                                {data?.meetingCount}
                              </span>
                            </div>
                          </Carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ))
      ) : (
        <div className="no-activity-found">
          <h6 className="no-active-time">
            {moment(todaydate).format("hh:mm, a")}
          </h6>
          <p className="no-active-date">
            {moment(todaydate).format("ddd, MMM D")}
          </p>

          <img src="../../../images/DefaultImages/NoActivity.png" alt="" />
          <p className="no-active-text">No Active User Found</p>
        </div>
      )}
    </div>
  );
}

export default ActivitySlider;
