import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Meetings.scss";
import ReactPaginate from "react-paginate";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import AccessDenied from "../../AccessDenied";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import UpGradeSubscriptionModal from "./UpGradeSubscriptionModal";
import { loadPageBreadCome } from "../../redux/action";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import SubscriptionDetailsTable from "./PrivatePageTables/SubscriptionDetailsTable";
import Footer from "../Footer";

function SubscriptionDetails() {
  let dispatch = useDispatch();
  const location = useLocation();
  const { subTypes } = location.state || { subTypes: '' };
  const defaultSubType = subTypes ? subTypes : 0;
  console.log(defaultSubType, 'sub')

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  // const [fromDate, setFromDate] = useState(new Date());
  const initialDate = new Date('01/01/1990');
  const [fromDate, setFromDate] = useState(initialDate);
  const [toDate, setToDate] = useState(new Date());
  const [allSubscriptionDetails, setAllSubscriptionDetails] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [subType, setSubType] = useState(defaultSubType);
  const [clientId, setClientId] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [daysLeft, setDaysLeft] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);

  let tableHeader = [
    { name: "TYPE", align: "left", width: "120px" },
    { name: "COMPANY DETAILS", align: "left", width: "150px" },
    { name: "CONTACT DETAILS", align: "left", width: "250px" },
    { name: "SUBSCRIPTION DETAILS", align: "left", width: "250px" },
    { name: "EXPIRY DETAILS", align: "left", width: "250px" },
    { name: "ACTION", align: "center", width: "100px" },
  ];

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // let subscriptionId = JSON.parse(
  //   sessionStorage.getItem("usdtls")
  // ).subscriptionId;

  let daysLeftdropdown = [
    {
      value: -1,
      label: "Select Expiry",
    },
    {
      value: 0,
      label: "Expired",
    },
    {
      value: 7,
      label: "Expire In Week",
    },
    {
      value: 30,
      label: "Expire In Month",
    },
    {
      value: 90,
      label: "Expire In 3 Month",
    },
  ];

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [userID, setUserID] = useState("");

  // Lazy Loading
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  // console.log("PAGENO", currentPage);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  useEffect(() => {
    let breadCome = { mainpage: "Subscription Details" }
    dispatch(loadPageBreadCome(breadCome))
    fetchDropdowns();
  }, [subType]);

  const fetchDropdowns = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
      // body: JSON.stringify(userNamePassword),
    };
    fetch(
      `${baseURL}/api/Subscription/BindCompanyDropdown?SubscriptionTypeID=${subType}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setCompanyList(
            res.result.map((data) => {
              return {
                value: data.subscriptionID,
                label: data.companyName,
              };
            })
          );
        } else {
          setCompanyList([]);
        }
      });
    fetch(
      `${baseURL}/api/Subscription/BindSubscriptionTypeDropdown`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("SUB-TYPE", res.result);
        if (res.success) {
          setSubscriptionTypes(
            res.result.map((data) => {
              return {
                value: data.subscriptionTypeID,
                label: data.subscriptionName,
              };
            })
          );
        } else {
          setSubscriptionTypes([]);
        }
      });
  };

  // GET SUBSCRIPTION PAGE START
  useEffect(() => {
    fetchAllSubscriptionDetails();
  }, [PageSize, currentPage, fromDate, toDate, subType, daysLeft, companyName]);

  const fetchAllSubscriptionDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
      // body: JSON.stringify(userNamePassword),
    };
    fetch(
      `${baseURL}/api/Subscription/GetAllSubscription?SubscriptionID=&SubscriptionTypeID=${subType}&SubscriptionName=${subscriptionName}&Email=${email}&CompanyName=${companyName}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&DaysLeft=${daysLeft}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAllSubscriptionDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false)
        } else {
          setAllSubscriptionDetails("");
          setIsLoading(false);
          setLoading(false)
        }
      });
  };

  // GET SUBSCRIPTION PAGE END

  const [openFilterTab, setOpenFilterTab] = useState(false);
  const clearSerachBySelect = () => {
    setSubType(0);
    setDaysLeft(-1);
    setCompanyName("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  // Open Upgrade Modal
  const [showModal, setShowModal] = useState(false);

  const [subScribeId, setSubScribeId] = useState("")
  const openModal = (subId) => {
    setSubScribeId(subId)
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  // Open Upgrade Modal

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  // Navigate to Back Page
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1)
  }
  // Navigate to Back Page

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="meetings-table">
                  <div style={{ position: "relative" }}>
                    <Row className='search-row'>
                      <Col md={3} className='cat-col'>
                        <div className='page-header-text-div'>
                          <div
                            className="back-btn"
                            onClick={goToBackPage}
                          >
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className='page-header-text' >SUBSCRIPTION DETAILS</h6>
                        </div>
                      </Col>
                      <Col md={9} className='cat-col'>
                        <div className="search-bar-header" style={{ float: "right" }}>
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div className="header-filter-btn" onClick={() => setOpenFilterTab(!openFilterTab)}>
                              <p className="card-head">
                                <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                              </p>
                            </div>

                            {/* <SbCancelBtn btnName="Back" onClickEffect={goToBackPage} /> */}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {openFilterTab &&
                      <div className="page-filter-section">
                        <div style={{ width: "100%" }}>
                          <Row>
                            <Col md={10}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <div style={{ width: "100%", padding: "0px 5px" }}>
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    options={companyList}
                                    placeholder="Select Company"
                                    value={companyName === "" ? "" : companyList.value}
                                    onInputChange={companyList.label}
                                    onChange={(data) => setCompanyName(data.value)}
                                    isSearchable={true}
                                  // isClearable={true}
                                  />
                                </div>
                                <div style={{ width: "100%", padding: "0px 5px" }}>
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    options={subscriptionTypes}
                                    placeholder="Select Type"
                                    value={subType === "" ? "" : subscriptionTypes.value}
                                    onInputChange={subscriptionTypes.label}
                                    onChange={(data) => setSubType(data.value)}
                                    isSearchable={true}
                                  // isClearable={true}
                                  />
                                </div>
                                <div style={{ width: "100%", padding: "0px 5px" }}>
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    // defaultValue={optionsRoleList[0].label}
                                    options={daysLeftdropdown}
                                    placeholder="Select Expiry"
                                    value={daysLeft === "" ? "" : daysLeftdropdown.value}
                                    onInputChange={daysLeftdropdown.label}
                                    onChange={(data) => setDaysLeft(data.value)}
                                    isSearchable={true}
                                  // isClearable={true}
                                  />
                                </div>
                                {/* <div
                                  style={{
                                    width: "100%",
                                    padding: "0px 5px",
                                  }}
                                  className="input-group from-to-date-select"
                                >
                                  <span
                                    className="input-group-text"
                                    id="from-label"
                                  >
                                    From
                                  </span>
                                  <span className="date-picker-with-icon">
                                    <DatePicker
                                      selected={fromDate}
                                      className="select from-date"
                                      id="from-datepicker"
                                      dateFormat="dd/MM/yyyy"
                                      selectsEnd
                                      // startDate={fromDate}
                                      endDate={toDate}
                                      onChange={(date) => setFromDate(date)}
                                      maxDate={new Date()}
                                    />
                                    <i className="fa fa-calendar-o calander-icon" />
                                  </span>
                                </div> */}

                                {/* <div
                                  style={{
                                    width: "100%",
                                    padding: "0px 5px",
                                  }}
                                  className="input-group from-to-date-select"
                                >
                                  <span
                                    className="input-group-text"
                                    id="to-label"
                                  >
                                    To
                                  </span>
                                  <span className="date-picker-with-icon">
                                    <DatePicker
                                      selected={toDate}
                                      className="select to-date"
                                      id="from-datepicker"
                                      dateFormat="dd/MM/yyyy"
                                      selectsEnd
                                      // startDate={fromDate}
                                      endDate={toDate}
                                      minDate={fromDate}
                                      onChange={(date) => setToDate(date)}
                                      maxDate={new Date()}
                                    />
                                    <i className="fa fa-calendar-o calander-icon" />
                                  </span>
                                </div> */}
                                <div
                                  style={{
                                    width: "100%",
                                    padding: "0px 5px",
                                  }}
                                  className="input-group from-to-date-select"
                                >
                                  <span
                                    className="input-group-text"
                                    id="from-label"
                                  >
                                    From - To
                                  </span>
                                  <span className="date-picker-with-icon">
                                    <DatePicker
                                      selected={fromDate}
                                      className="select from-date date-filter"
                                      id="date-range-picker"
                                      dateFormat="dd/MM/yyyy"
                                      selectsRange
                                      startDate={initialDate}
                                      endDate={toDate}
                                      onChange={(date) =>
                                        setSelectSearchDrop(date)
                                      }
                                    // maxDate={new Date()}
                                    />
                                  </span>
                                  <span>
                                    <i
                                      className="fa fa-calendar-o calander-icon"
                                      style={{ right: "15px" }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col md={2}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <SbCancelBtn onClickEffect={clearSerachBySelect}
                                  btnName="Clear" />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    }
                  </div>

                  <SubscriptionDetailsTable
                    tableHeader={tableHeader}
                    allSubscriptionDetails={allSubscriptionDetails}
                    openModal={openModal}
                    loading={loading}
                    setPageSize={setPageSize}
                    PageSize={PageSize}
                    setLoading={setLoading}
                    totalData={totalData}
                  />

                  {/* <div className="table-responsive meetings-scrollbar">
                    <table className="table meetings-list-table">
                      <thead className="thead-light meetings-list-thead">
                        <tr>
                          <th>TYPE</th>
                          <th>COMPANY DETAILS</th>
                          <th>CONTACT DETAILS</th>
                          <th>SUBSCRIPTION DETAILS</th>
                          <th>EXPIRY DETAILS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allSubscriptionDetails !== "" ? (
                          allSubscriptionDetails.map((data, i) => (
                            <tr className="meeting-list-row-data" key={i}>
                              <td>
                                <p>
                                  <b>
                                    {data?.subscriptionType?.subscriptionName}
                                  </b>
                                </p>
                                <p>Cycle : {data?.subscriptionType?.cycle}</p>
                                <p>
                                  User Limit :{" "}
                                  {data?.subscriptionType?.userLimit}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <b>Company Name :</b>
                                  {data?.company?.companyName}
                                </p>
                                <p>
                                  <b>Client Name :</b>{" "}
                                  {data?.company?.clientName}
                                </p>
                                <p>
                                  <b>Email :</b> {data?.company?.email}
                                </p>
                              </td>
                              <td>
                                <b>Admin Name :</b> {data?.adminFullName} <br />
                                <b>Admin Contact :</b> {data?.adminCountryCode}
                                {data?.adminContact} <br />
                                <b>Admin Email :</b> {data?.adminEmail} <br />
                              </td>
                              <td>
                                <b>Subscribed Date :</b>{" "}
                                {moment(data?.subscribedDate).format(
                                  "DD/MM/YYYY"
                                )}
                                <br />
                                <b>Start Date : </b>{" "}
                                {moment(data?.startdate).format("DD/MM/YYYY")}
                                <br />
                                <b>Expiry Date : </b>{" "}
                                {moment(data?.expirydate).format("DD/MM/YYYY")}
                                <br />
                                <b>Max User Count :</b> {data?.maxUserCount}
                              </td>

                              <td>
                                <b>Subscription Days : </b>
                                {data?.subscriptionPeriod}
                                <br />
                                <b>Subscription Days Complete : </b>
                                {data?.daysComplete}
                                <br />
                                <b>Subscription Days Left : </b>
                                {data?.daysleft}
                                <br />
                                <b>Subscription Expired : </b>
                                NA
                              </td>
                              <td>
                              <SbCancelBtn btnName='Upgrade Subscription' onClickEffect={() =>  openModal(data.subscriptionId)} />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h6 className="no-data-found">
                            ------ NO DATA FOUND ------
                          </h6>
                        )}
                      </tbody>
                    </table>
                  </div> */}
                  {/* <div className="download-and-pagination">
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div> */}
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* Update Subscription */}
            {showModal &&
              <UpGradeSubscriptionModal
                show={showModal}
                handleClose={closeModal}
                subscriptionId={subScribeId}
                setSubScribeId={setSubScribeId}
              />}
            {/* Update Subscription */}

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default SubscriptionDetails;
