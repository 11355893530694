import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";

export default function PaymentsTable(props) {
  return (
    <div>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer
          //   onScroll={(e) =>
          //     handleScroll(
          //       e,
          //       props.users,
          //       props.totalUserCount,
          //       props.setLoading,
          //       props.setPageSize,
          //       props.PageSize
          //     )
          //   }
          >
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="payment-status-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {props.tableHeader.map((data, i) => (
                    <TableCell key={i} align={data?.align} sx={data.width}>
                      {data?.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tableData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Link to="/vhd/paymentdetails" className="color-green">
                        {data?.payment_No}
                      </Link>
                    </TableCell>
                    <TableCell>{data?.payment_Date}</TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.vendor_Code}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.vendor_Name}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.inv_Amount}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.paid_Amount}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.pending_Amount}
                    </TableCell>
                    <TableCell style={{ minWidth: "200px" }}>
                      {data?.naration}
                    </TableCell>
                    <TableCell align="center">
                      <div className={`payment ${data?.status}`}>
                        {data?.status}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <Link to="/vhd/paymentdetails">
                        <div className="color-green hv-center">
                          <i className="fa fa-eye" />
                          &nbsp;
                          <span>View</span>
                        </div>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
