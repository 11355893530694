import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/CityClassification.scss";
import SbAddBtn, { SBSaveUpdateBtn, SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import AccessDenied from "../../../AccessDenied";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";





const clientOptions = [
  { value: '1', label: 'Siva Balan P' },
  { value: '2', label: 'Dinesh L' },
  // ...other options
];

const reportedByOptions = [
  { value: '1', label: 'Siva Balan P' },
  { value: '2', label: 'Shrikant Rajendra Satav' },
  { value: '3', label: 'Valera Kalpen Krishnakant' },
  { value: '4', label: 'Somanagouda Patil' },
  { value: '5', label: 'Naresh P' },
  { value: '6', label: 'Srikanth S' },
  { value: '7', label: 'Vinayak Gudekar' },

];

const assignToOptions = [
  { value: 'user4', label: 'User 4' },
  { value: 'user5', label: 'User 5' },
  { value: 'user6', label: 'User 6' }
];

const productOptions = [
  { value: 'product1', label: 'Product 1' },
  { value: 'product2', label: 'Product 2' },
  { value: 'product3', label: 'Product 3' }
];

const warrantyOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' }
];

const priorityOptions = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' }
];

const exportExcelClaim = () => {


};







// const defaultUser = clientOptions.find(option => option.label === 'Siva Balan P');
function CityClassification() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [actionUser, setActionUser] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("manager");
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [filterMeeting, setFilterMeeting] = useState();
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [myshowSaveButton, setmyShowSaveButton] = useState(true);
  const [myinnershowSaveButton, setmyinnerShowSaveButton] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  //   const { userDropdown } = useSelector((state) => state.data);
  const [filterUserId, setFilterUserId] = useState("");

  const clearSerachBySelect = () => {
    setFilterUserId("");

  };
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  //* CLEAR SELECT START *//

  const userDropdown = [
    { value: '1', label: 'Mumbai' },
    { value: '2', label: 'New Delhi' },
    { value: '3', label: 'Bangalore' },
    { value: '4', label: 'Kolkata' },
    { value: '5', label: 'Chennai' },

    // Add more options as needed
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };




  const [cities, setCities] = useState(['Dharwad', 'Sangali', 'Miraj', 'Islampur', 'Satara', 'Karad', 'Kolar', 'Chikmagalur', 'Ballari', 'Warangal', 'Nellore', 'Kakinada', 'Rajamahendravaram', 'Eluru', 'Faridabad', 'Akola', 'Solapur', 'Thane']);
  const [checkedState, setCheckedState] = useState(new Array(cities.length).fill(false));
  const [cityClasses, setCityClasses] = useState(new Array(cities.length).fill(''));

  const handleCheckboxChange = (index) => {
    const newState = [...checkedState];
    newState[index] = !newState[index];
    setCheckedState(newState);
  };

  const handleButtonClick = (index, className) => {
    console.log(`Move ${cities[index]} To ${className}`);
    const newClassState = [...cityClasses];
    newClassState[index] = className;
    setCityClasses(newClassState);

    const newCheckedState = [...checkedState];
    newCheckedState[index] = false;
    setCheckedState(newCheckedState);
  };
  console.log(tableData, "tableData")

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          <div className="page-wrapper">
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content usrhrchy">
                <Row className="search-row usrhrchy">
                  <Col md={4} className="cat-col">
                    <div className="page-header-text-div">
                      <div className="back-btn" onClick={() => goToBackPage()}>
                        <img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" />
                      </div>
                      <h6 className="page-header-text">CITY CLASSIFICATION</h6>
                    </div>
                  </Col>
                  <Col md={8} className="cat-col">
                    <div className="ctysrchdrp">
                      <div className="input-div">
                        <FloatingLabel label="Select City" className="float-hidden float-select">
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={userDropdown}
                            placeholder="Select City"
                            value={userDropdown.find(option => option.value === filterUserId)}
                            onChange={(data) => setFilterUserId(data ? data.value : "")}
                            isSearchable={true}
                          />
                        </FloatingLabel>
                      </div>
                      <div className="clients-filter-input-boxs filter-box-div usrhrchy">
                        <SbCancelBtn onClickEffect={clearSerachBySelect} btnName="Clear" />
                      </div>
                    </div>
                  </Col>

                </Row>
                <Row className="search-row usrhrchy ctyclass">

                  <Col md={5} className="cat-col">
                    <h6 className="cty-cnt">Class D Cities</h6>
                    <div className="usrhrchy table-responsive spares-scrollbar">

                      <table className="table spares-table-data">
                        <thead className="thead-light spares-thead">
                          <tr>
                            <th><input type="checkbox" /></th>
                            <th>City Name</th>

                          </tr>
                        </thead>
                        <tbody>
                          {cities.map((name, index) => (
                            <tr key={index} className="spare-table-row-data">
                              <td>
                                <input
                                  type="checkbox"
                                  checked={checkedState[index]}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                              </td>
                              <td>{name}</td>

                            </tr>
                          ))}
                        </tbody>
                      </table>



                    </div>
                  </Col>
                  {/* <Col md={2} className="cat-col ctybtn">
                            
                            <button className="btn movetobtn mt-4"> Move To B</button>
                            <button className="btn movetobtn mt-4"> Move To C</button>
                        </Col> */}
                  <Col md={7} className="cat-col">
                    <div className="usrhrchy table-responsive spares-scrollbar clsa">
                      <Row>
                        <Col md={3} className="cat-col mvt-btn">
                          <button className="btn movetobtn mt-4"> Move To A
                            {/* <span class="material-symbols-outlined rght">
arrow_right_alt
</span> */}
                          </button>

                        </Col>
                        <Col md={9} className="cat-col">
                          <h6 className="cty-cnt clsa">Class A Cities</h6>
                          <table className="table spares-table-data clasa">
                            <thead className="thead-light spares-thead">
                              <tr className="clsc-nme">
                                <th>City Name</th>
                                <th>Move To D</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="spare-table-row-data">
                                <td>
                                  Mumbai
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  New Delhi
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Bangalore
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Kolkata
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Chennai
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3} className="cat-col mvt-btn">
                          <button className="btn movetobtn mt-4"> Move To B
                            {/* <span class="material-symbols-outlined rght">
arrow_right_alt
</span> */}
                          </button>
                        </Col>
                        <Col md={9} className="cat-col">
                          <h6 className="cty-cnt">Class B Cities</h6>
                          <table className="table spares-table-data clasa">
                            <thead className="thead-light spares-thead">
                              <tr className="clsc-nme">
                                <th>City Name</th>
                                <th>Move To D</th>
                              </tr>
                            </thead>
                            <tbody>

                              <tr className="spare-table-row-data">
                                <td>
                                  Ahmedabad
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Pune
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Nagapur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Hyderabad
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Agra
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Varanasi
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Lucknow
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Chandigarh
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Jodhpur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Mysore
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Udaipur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Jaipur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Indore
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Kochi
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Trivandrum
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Srinagar
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Kathmandu (Nepal)
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3} className="cat-col mvt-btn">
                          <button className="btn movetobtn mt-4"> Move To C
                            {/* <span class="material-symbols-outlined rght">
arrow_right_alt
</span> */}
                          </button>
                        </Col>
                        <Col md={9} className="cat-col">
                          <h6 className="cty-cnt">Class C Cities</h6>
                          <table className="table spares-table-data clasa">
                            <thead className="thead-light spares-thead">
                              <tr className="clsc-nme">
                                <th>City Name</th>
                                <th>Move To D</th>
                              </tr>
                            </thead>

                            <tbody>

                              <tr className="spare-table-row-data">
                                <td>
                                  Cuttack
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Shillong
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Silchar
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Darjeeling
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Gangtok
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Jamshedpur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Patna
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Bhubaneshwar
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Guwahati
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Ranchi
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Pokhara(Nepal)
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>

                              <tr className="spare-table-row-data">
                                <td>
                                  Aurangabad
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Kolhapur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Nasik
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Sholapur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Rajkot
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Jabalpur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Baroda
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span> </button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Surat
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Bhopal
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Goa
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Raipur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>

                              <tr className="spare-table-row-data">
                                <td>
                                  Gorakhpur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span> </button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Ludhiana
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Kanpur
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Amritsar
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Allahabad
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Jalandhar
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Simla
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Jammu
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Dehradun
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Hubli
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Belgaum
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>


                              <tr className="spare-table-row-data">
                                <td>
                                  Mangalore
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Tirupathi
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Trichy
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Madurai
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Vishakapatnam
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Ooty
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Kodaikanal
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Coimbatore
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                              <tr className="spare-table-row-data">
                                <td>
                                  Vijayawada
                                </td>
                                <td>
                                  <button className="btn movetobtn"> <span class="material-symbols-outlined">
                                    arrow_left_alt
                                  </span></button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <div className="download-and-pagination">
                  <SbDownloadBtn onClickEffect={exportExcelClaim} />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default CityClassification;
