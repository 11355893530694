import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import Select from "react-select";

// local import
import SbCancelBtn from '../../SbButtons/SbCancelBtn';
import { SBSaveUpdateBtn, SbAddCustomBtn } from '../../SbButtons/SbAddBtn';
import { attachmentTypes, headerType } from "../../DropdownsData";
import emptyImage from '../../../images/No-Image.jpg';
import MenuFormat from '../../MenuFormat';

function ConfigurationTemplateEmail(props) {

  // #region upload image file
  const hiddenFileInput = useRef(null);

  // const [imgFile, setImgFile] = useState("");

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    if (event.target.files[0]) {
      const fileUploaded = event.target.files[0];
      // setImgFile(fileUploaded);

      const reader = new FileReader();
      reader.readAsDataURL(fileUploaded);
      reader.onloadend = () => {
        props.setLocalImage(reader.result);
      };
    }
  };

  const deleteImage = () => {
    props.setLocalImage('');
    // setImgFile('')
  }
  // #endregion

  // #region Attchments
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const menuData = MenuFormat(resPassData?.subscription?.modules);

  const [selectedValue, setSelectedValue] = useState('');
  const [selectedSubValue, setSelectedSubValue] = useState('')
  const selectAttachmentData = (e, item, submenus) => {
    if (e.target.checked) {
      setSelectedValue(item)
      props.initStates.attachment_Data = item
    } else {
      setSelectedSubValue('')
      setSelectedValue('')
      props.initStates.attachment_Data = ""
    }
  }
  const selectAttachmentSubData = (e, item) => {
    if (e.target.checked) {
      props.initStates.attachment_Data = item
      setSelectedSubValue(item)
    } else {
      props.initStates.attachment_Data = "";
      setSelectedSubValue('')
    }
  }
  // #endregion Attchments

  useEffect(() => {
    if(props.initStates.attachment_Data){
      menuData.forEach(item => {
        if(item.name === props.initStates.attachment_Data){
          setSelectedValue(item.name)
        } else {
          item.subNames.forEach(subItem => {
            if(subItem.name === props.initStates.attachment_Data){
              setSelectedValue(item.name)
              setSelectedSubValue(subItem.name)
            }
          })
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initStates, menuData])
  

  return (
    <div className="page-content">
      <Row className="search-row">
        <Col md={3} className="cat-col">
          <div className="page-header-text-div">
            {!props.editTemplateName ?
              <><h6 className="page-header-text">{props.templateName}</h6>&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-edit" onClick={() => props.setEditTemplateName(!props.editTemplateName)}></i></>
              :
              <div className="edit-template">
                <input
                  type="text"
                  className="form-control form add-product-input"
                  placeholder='Template Name'
                  name="TemplateName"
                  value={props.templateName}
                  onChange={(e) => props.setTemplateName(e.target.value)}
                  tabIndex={0}
                />&nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-save" onClick={() => props.setEditTemplateName(!props.editTemplateName)}></i>
              </div>
            }
          </div>
        </Col>
        <Col md={9} className="cat-col">
          <div className="search-bar-header" style={{ float: "right" }}>
            <div className="page-top-bar-dropdowns page-top-Filters">
              <SbCancelBtn btnName="Back" onClickEffect={props.goToBackPage} />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="template-row">
        <Col md={8} className=" pl-0 pr-1">
          <div className="template-form">
            <div className="mi-template-header-row">
              <div>
                <p className="card-head">Add Template</p>
              </div>
              <div className="search-bar-header" style={{ float: "right" }}>
                <div className="page-top-bar-dropdowns page-top-Filters">
                  <SBSaveUpdateBtn btnName="Save & Continue" onClickEffect={props.saveDataAndContinue}
                    disableProp={!props.initStates.body} />
                </div>
              </div>
            </div>
            <hr className="m-0" />
            <div className='template-div'>
              <div className="mi-template-header-option">
                <div>
                  <p className="card-head">Header - <span className="optional-color">Optional</span></p>
                </div>
                <div><p>Add a title or choose which type of media you'll use for this header.</p></div>
                <div className="header-option-div">
                  <div className="">
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={headerType}
                      placeholder="Header Type"
                      value={props.selectHeaderType.value ? props.selectHeaderType : headerType.value}
                      onInputChange={headerType.label}
                      onChange={(data) => props.handleDropDown(data, "headerType")}
                      isSearchable={false}
                      tabIndex={1}
                    />
                  </div>
                  {props.selectHeaderType.value === "Header Text" &&
                    <div style={{ paddingLeft: '10px', width: '50%' }}>
                      <input
                        type="text"
                        className="form-control form add-product-input"
                        placeholder="Enter header text"
                        name="header_Text"
                        value={props.initStates.header_Text || ""}
                        onChange={props.handleFormInput}
                        tabIndex={2}
                      />
                      {props.validation && props.initStates.header_Type === "Header Text" &&
                        !props.initStates.header_Text && (
                          <small id={`Error`} className="form-text text-muted ">
                            Please Enter header text
                          </small>
                        )}
                    </div>
                  }
                  {props.selectHeaderType.value === "Header Image" &&
                    <div className="header_image_div">
                      {props.localImage && <span><i className="fa fa-times-circle" onClick={deleteImage}></i></span>}
                      <input
                        id="getFile"
                        type="file"
                        name="filetobase64"
                        accept="image/*"
                        className="btn-primary upload"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                        tabIndex={2}
                      />
                      <img src={props.localImage ? props.localImage : emptyImage} alt="" onClick={uploadClick} className="header_image" />
                    </div>
                  }
                </div>
              </div>
              <div className="mi-template-header-option">
                <div>
                  <p className="card-head">Subject</p>
                </div>
                <div><p>Add subject to the email.</p></div>
                <div className="" style={{ width: '100%' }}>
                  <input
                    type="text"
                    className="form-control form add-product-input"
                    placeholder="Enter the subject here"
                    name="subject"
                    value={props.initStates.subject || ""}
                    onChange={props.handleFormInput}
                    tabIndex={5}
                  />
                  {props.validation && !props.initStates.subject && (
                    <small id={`Error`} className="form-text text-muted ">
                      Please Enter template subject
                    </small>
                  )}
                </div>
              </div>
              <div className="mi-template-header-option">
                <div>
                  <p className="card-head">Body</p>
                </div>
                <div><p>Enter the text for your message.</p></div>
                <div className="mb-3" style={{ width: '100%' }}>
                  <div className="textareabody">
                    <textarea
                      // rows="1"
                      name="description"
                      wrap="hard"
                      className="form-control form-textarea template-textarea"
                      id="descriptionArea"
                      maxLength={1024}
                      placeholder=""
                      value={props.enteredMsg}
                      onChange={props.handleBody}
                      tabIndex={3}
                      style={{ border: 'none', outline: 'none', resize: 'none', overflowY: 'hidden' }}
                    />
                    {props.veriables.length !== 0 && props.veriables.map((vrbl, index) => (
                      <div key={index} className='color-primary-text'>{`{{${index + 1}}}`}</div>
                    ))}
                  </div>
                  <div className="d-flex character-view">
                    <div><p>characters: 100/1024</p></div>
                    {props.veriables.length < 8 && <div><span onClick={props.setVeriablesValue} className="var_btn">+ Add Variables</span></div>}
                  </div>
                  <br />
                  <div className="mi-veriable-samples">
                    <div>
                      <p className="card-head">Samples for body content</p>
                    </div>
                    <div><p>To help us review your message template. Please add an example for each veriable in your body text. Do not use react customer information</p></div>
                    <div>
                      <p className="card-head">Body</p>
                    </div>
                    <div>
                      {props.veriables.length !== 0 &&
                        props.veriables.map((variable, index) => (
                          <div className="veriable-sample-input" key={index}>
                            <p>{`{{${index + 1}}}`}</p>&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              type="text"
                              className="form-control form add-product-input"
                              placeholder={`Enter content for {{${index + 1}}}`}
                              name={index + 1}
                              value={variable.includes('{{') ? '' : variable}
                              onChange={(e) => props.handleVeriableInput(e, index)}
                              tabIndex={4 + (index)}
                            />&nbsp;&nbsp;&nbsp;&nbsp;
                            <span><i className="fa fa-trash acces-denied-head" onClick={() => props.deleteVeriable(index)}></i></span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mi-template-header-option">
                <div>
                  <p className="card-head">Footer - <span className="optional-color">Optional</span></p>
                </div>
                <div><p>Add a short line of text to the bottom of your template.</p></div>
                <div className="mb-3" style={{ width: '100%' }}>
                  <input
                    type="text"
                    className="form-control form add-product-input"
                    placeholder="Enter footer text"
                    name="footer"
                    value={props.initStates.footer || ""}
                    onChange={props.handleFormInput}
                    tabIndex={5}
                  />
                </div>
              </div>
              <div className="mi-template-header-option">
                <div>
                  <p className="card-head">Buttons - <span className="optional-color">Optional</span></p>
                </div>
                <div><p>Create button that customer take action.</p></div>
                <div className="header-option-div">
                  <div className="">
                    <SbAddCustomBtn btnName={`${props.openButtonVeriable ? 'Remove Button' : 'Add Button'}`} onClickEffect={props.openButtonVeriable ? props.removeBtnPrameters : props.addBtnPrameters} />
                  </div>
                  {props.openButtonVeriable &&
                    <>
                      <div style={{ paddingLeft: '10px', width: '20%' }}>
                        <input
                          type="text"
                          className="form-control form add-product-input"
                          placeholder="Enter button text"
                          name="button_text"
                          value={props.initStates.button_text || ""}
                          onChange={props.handleFormInput}
                          tabIndex={6}
                        />
                      </div>
                      <div style={{ paddingLeft: '10px', width: '50%' }}>
                        <input
                          type="text"
                          className="form-control form add-product-input"
                          placeholder="Enter url"
                          name="navigateToPage"
                          value={props.initStates.navigateToPage || ""}
                          onChange={props.handleFormInput}
                          tabIndex={7}
                        />
                        {props.validation && props.initStates.button_text &&
                          !props.initStates.navigateToPage && (
                            <small id={`Error`} className="form-text text-muted ">
                              Please Enter navigate to url.
                            </small>
                          )}
                      </div>
                    </>
                  }
                </div>
              </div>
              <div className="mi-template-header-option">
                <div>
                  <p className="card-head">Attachments - <span className="optional-color">Optional</span></p>
                </div>
                <div><p>Add attachments here.</p></div>
                <div className="header-option-div">
                  <div className="">
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={attachmentTypes}
                      placeholder="Attachment Type"
                      value={props.selectAttachmentType.value ? props.selectAttachmentType : attachmentTypes.value}
                      onInputChange={attachmentTypes.label}
                      onChange={(data) => props.handleDropDown(data, "attachments")}
                      isSearchable={false}
                      tabIndex={1}
                    />
                  </div>

                </div>
              </div>
              {props.selectAttachmentType.value === "Detailed Data" &&
                <div className="mi-template-header-option">
                  <div>
                    <p className="card-head">Attachments Data</p>
                  </div>
                  <div><p>select any one of below option which data you want to send.</p></div>
                  <div className="header-option-div">
                    <div className="">
                      <div className='sb-matrix-div'>
                        <Row >
                          {menuData.map((menus, i) => (
                            <Col md={3} key={i} >
                              <div className='sb-matrix-div'>
                                <input
                                  type="checkbox"
                                  className="styled-checkbox" id={"styled-checkbox-" + i}
                                  checked={selectedValue === menus.name}
                                  // selected={notifValue.includes(item.value)}
                                  onChange={(e) => selectAttachmentData(e, menus.name, menus.subNames)}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ fontSize: '16px', fontWeight: '700' }}>{menus.name}</span>
                                {(menus.subNames.length !== 0 && selectedValue === menus.name) &&
                                  <div className='submenu_modal'>
                                    {menus.subNames.map((subMenu, i) => (
                                      <div key={i}>
                                        <input
                                          type="checkbox"
                                          className="styled-checkbox" id={"styled-checkbox-" + i}
                                          checked={selectedSubValue === subMenu.name}
                                          onChange={(e) => selectAttachmentSubData(e, subMenu.name)}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{ fontSize: '16px', fontWeight: '700' }}>{subMenu.name}</span>
                                      </div>
                                    ))}
                                  </div>
                                }
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </div>

                  </div>
                </div>
              }
            </div>
          </div>
        </Col>
        <Col md={4} className="pl-1 pr-0">
          <div className="template-preview">
            <div className="preview-header-text-div">
              <h6 className="page-header-text">Message Preview</h6>
            </div>
            <div className="config-preview-body">
              <div className='subject-text'>
                <InputGroup className="mb-1">
                  {/* <InputGroup.Text id="basic-addon1">@</InputGroup.Text> */}
                  <Form.Control
                    placeholder="Subject"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={props.initStates.subject}
                    readOnly
                  />
                </InputGroup>
              </div>
              <div className='email-msg-text'>
                {(props.localImage || props.initStates.header_Text || props.enteredMsg || props.veriables.length !== 0
                  || props.initStates.footer) &&
                  <div className="emaitext-body">
                    {props.localImage && <div>
                      <img src={props.localImage} alt="" className="header_preview_image" />
                    </div>
                    }
                    {props.initStates.header_Text && <p className="preview-header">{props.initStates.header_Text}</p>}
                    <div>
                      {props.enteredMsg && <p className="card-head">{props.enteredMsg}</p>}
                    </div>
                    {props.veriables.length !== 0 && props.veriables.map((vrbl, index) => (
                      <div key={index} className='color-primary-text'>{vrbl}</div>
                    ))}
                    {props.initStates.button_text &&
                      <div className='pt-3 pb-3'>
                        <SBSaveUpdateBtn btnName={props.initStates.button_text} />
                      </div>
                    }
                    {props.initStates.footer &&
                      <div><p className="footer-text"><em>{props.initStates.footer}</em></p></div>}
                    {props.initStates.attachment_Type &&
                      <div>
                        <hr className="m-0" /><div className="email_attachment_preview"><i className="fa fa-file-text-o" aria-hidden="true"></i><p>{props.initStates.attachment_Data}.docx</p></div>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ConfigurationTemplateEmail;