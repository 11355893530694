import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SkeletonClients } from "../Skeleton Loading/SkeletonClients";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import { Link } from "react-router-dom";

function JointMeetingTable(props) {
  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          //   onScroll={(e) =>
          //     handleScroll(
          //       e,
          //       props.clientData,
          //       totalCount,
          //       props.setLoading,
          //       props.setPageSize,
          //       props.PageSize
          //     )
          //   }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="client-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((header, i) => (
                  <TableCell
                    key={i}
                    align={header.align}
                    style={{ minWidth: `${header.width}` }}
                  >
                    {header.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <>
                {props.tableHeader.map((_, i) => (
                  <SkeletonClients key={i} />
                ))}
              </>
            ) : (
              <>
                {props.clientData && props.clientData.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        --- NO DATA FOUND ---
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow align="center" className="client-list-row-data">
                      <TableCell>
                        <Link to="/JointMeeting/1">
                          <div className="users-list-name">
                            <div>
                              {/* {data.logo ? (
                              <img
                                className="table-data-users-imgages company-logo"
                                src={`${baseURL}/${data.logo}`}
                                alt="client-profile"
                              />
                            ) : (
                              <div
                                className="table-data-users-imgages default-image"
                                style={{ backgroundColor: getRandomColor(i) }}
                              >
                                {data?.clientName.charAt(0).toUpperCase()}
                              </div>
                            )} */}
                            </div>
                            <div>
                              <div
                                className="table-row-head-Text"
                                style={{ cursor: "pointer" }}
                                //   onClick={(e) => props.handleView(data?.clientID)}
                              >
                                <strong> Samson</strong>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </TableCell>
                      <TableCell>
                        JR5Q+MF4, Sirsi, Karnataka 581402, India
                      </TableCell>
                      <TableCell>My Visit</TableCell>
                      <TableCell>20/5/2024</TableCell>
                      <TableCell>in 1 hour 15 min</TableCell>
                      <TableCell align="center">Pending</TableCell>
                      <TableCell align="center">
                        <SBSaveUpdateBtn btnName="Join Meeting" />
                      </TableCell>
                    </TableRow>
                    <TableRow align="center" className="client-list-row-data">
                      <TableCell>
                        <div className="users-list-name">
                          <div></div>
                          <div>
                            <div
                              className="table-row-head-Text"
                              style={{ cursor: "pointer" }}
                            >
                              <strong> Samson</strong>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        JR5Q+MF4, Sirsi, Karnataka 581402, India
                      </TableCell>
                      <TableCell>My Visit</TableCell>
                      <TableCell>20/5/2024</TableCell>
                      <TableCell>in 1 hour 15 min</TableCell>
                      <TableCell align="center">Pending</TableCell>
                      <TableCell align="center">
                        <SBSaveUpdateBtn btnName="Re Schedule" />
                      </TableCell>
                    </TableRow>
                    <TableRow align="center" className="client-list-row-data">
                      <TableCell>
                        <div className="users-list-name">
                          <div></div>
                          <div>
                            <div
                              className="table-row-head-Text"
                              style={{ cursor: "pointer" }}
                            >
                              <strong> Samson</strong>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        JR5Q+MF4, Sirsi, Karnataka 581402, India
                      </TableCell>
                      <TableCell>My Visit</TableCell>
                      <TableCell>20/5/2024</TableCell>
                      <TableCell>in 1 hour 15 min</TableCell>
                      <TableCell align="center">Started</TableCell>
                      <TableCell align="center">
                        {/* <SBSaveUpdateBtn btnName="Re Schedule" /> */}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default JointMeetingTable;
