import React from "react";
import { Link } from "react-router-dom";
import "../../../styles/VhdDashboard.scss";

export default function VHDCardComponent(props) {
  return (
    <div className="card card-vhd-data">
      <div className="card-body-data">
        <Link className="link-url" to={props.linkUrl}>
          <div className="vhd-card-row row">
            <div className="col-md-4 vhd-card-img">{props.cardIcon}</div>
            {/* <div className="divider-line"></div> */}
            <div className="col-md-8 vhd-card-right-data">
              <div className="vhd-card">
                <div className="card-heading">{props.cardHeading}</div>
                <div className="card-data">{props.cardData}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
