import React, { useEffect, useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { Col, FloatingLabel, Modal, Row } from "react-bootstrap";
import "../../../styles/category.scss";
import { useDispatch } from "react-redux";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import EmailTablePage from "./EmailTablePage";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import CommonNavFile from "../../CommonNavFile";
import { loadEmailConfigData } from "../../../redux/ModalActions/whatsappConfig_Action";

var currentPage = 1
function EmailConfig() {
  let dispatch = useDispatch();
  // const { clientCategoryData } = useSelector((state) => state.data);
  // const { clientReportingCategoryData } = useSelector((state) => state.data);

  let tableHeader = [
    { name: "Notification Name", align: "left", width: "250px" },
    { name: "Receiver Type", align: "left", width: "150px" },
    { name: "Send To", align: "left", width: "150px" },
    { name: "Sent Time", align: "left", width: "100px" },
    { name: "Frequency", align: "left", width: "100px" },
    { name: "Sp_Name", align: "left", width: "150px" },
    { name: "Sp_FlagID", align: "left", width: "150px" },
    { name: "STATUS", align: "center", width: "100px" },
    { name: "ACTION", align: "center", width: "150px" },
  ];

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [lazyLoading, setLazyLoading] = useState(false);
  const [PageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(true);


  // #region add template modal
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [templateName, setTemplateName] = useState('')

  const openVeriableModal = () => {
    setShowTemplateModal(true)
  }
  const handleVeriableModal = () => {
    setShowTemplateModal(false)
  }
  // #endregion

  // #region page loadFunction
  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PageSize])
  // #endregion

  // #region fetch table data
  const fetchTableData = () => {
    dispatch(loadEmailConfigData(currentPage, PageSize, setIsLoading))
  }
  // #endregion

  // Page navigation
  const navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };

  const navigateAddTemplate = (e) => {
    e.preventDefault();
    if (!templateName) {
      return;
    }
    navigate("/settings/AddEmailConfig", { state: { templateName: templateName } });
  }
  // page navigation

  // #region edit template function
  const editConfigureTemplate = (templateId) => {
    // dispatch(loadSingleEmailConfigData(templateId))
    navigate("/settings/AddWhatsappConfig", { state: { templateId: templateId } });
  }
  // #end region

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <CommonNavFile toggleshift={toggleshift} setToggleshift={setToggleshift} />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <Row className="search-row">
              <Col md={3} className="cat-col">
                <div className="page-header-text-div">
                  <div className="back-btn" onClick={goToBackPage}>
                    <img
                      src="../../../../images/DefaultImages/back-btn-arrow.svg"
                      alt=""
                    />
                  </div>
                  <h6 className="page-header-text">Email Push Notifications</h6>
                </div>
              </Col>
              <Col md={5} className="cat-col">
              </Col>
              <Col md={4} className="cat-col">
                <div className="search-bar-header" style={{ float: "right" }}>
                  <div className="page-top-bar-dropdowns page-top-Filters">
                    <SbAddBtn onClickEffect={openVeriableModal} />
                  </div>
                </div>
              </Col>
            </Row>

            <EmailTablePage
              tableHeader={tableHeader}
              editConfigureTemplate={editConfigureTemplate}
              lazyLoading={lazyLoading}
              isLoading={isLoading}
              setPageSize={setPageSize}
              PageSize={PageSize}
              setLoading={setLazyLoading}
            />
          </div>
        </div>

        {/* modal for adding veriables */}
        <Modal
          show={showTemplateModal}
          onHide={handleVeriableModal}
          className="main_model add-edit-model"
        >
          <div className="add-edit-heading">
            <h6 className="add-edit-head">Email Template Name</h6>
            <i className="fa fa-times" onClick={handleVeriableModal} />
          </div>
          <form onSubmit={navigateAddTemplate}>
            <div className="container add-edit">
              <div className="product-inputs">
                <div className="mb-2">
                  <FloatingLabel label='Template Name' className={`${templateName ? "float-input-visible" : "float-hidden float-input"}`}>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="Template Name"
                      value={templateName}
                      onChange={(e) => setTemplateName(e.target.value)}
                    />
                  </FloatingLabel>
                </div>
              </div>
            </div>
            <div className="btns-save-cancel">
            <SBSaveUpdateBtn btnName="Continue" />
            <SbCancelBtn btnName="Cancel" onClickEffect={handleVeriableModal} />
          </div>
          </form>
        </Modal>
        {/* modal for adding veriables */}
      </div>
      <Footer />
    </div>
  );
}

export default EmailConfig;
