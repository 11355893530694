import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Col, Row, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { MultiSelect } from "react-multi-select-component";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/LocalTourMap.scss";
import SbAddBtn, { SBSaveTourBtn, SBSaveUpdateBtn, SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import AccessDenied from "../../../AccessDenied";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import TextField from '@mui/material/TextField';
import { Container } from "@mui/material";
import dayjs from 'dayjs';



const transportoptions = [
    { value: '1', label: 'Own Car' },
    { value: '2', label: 'Own Bike' },
    { value: '3', label: 'Bus' },
    { value: '4', label: 'Auto' },
    { value: '5', label: 'Cab' },
    { value: '6', label: 'Metro' },
    { value: '7', label: 'Train' },
    { value: '8', label: 'Bike' },

]


const mySelectOptions = [
    { value: 'person1', label: 'Person 1' },
    { value: 'person2', label: 'Person 2' },
    { value: 'person3', label: 'Person 3' },
    // Add more options as needed
];

const tremployees = [
    { value: 'employee1', label: 'Akshay Jaiswal (SRW072)' },
    { value: 'employee2', label: 'Praveen Prakash (SRW075)' }

];

function LocalTourRequest() {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedVerifier, setSelectedVerifier] = useState(null);
    const [mytableData, setTableData] = useState([]);
    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState("manager");
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [myshowSaveButton, setmyShowSaveButton] = useState(true);
    const [myinnershowSaveButton, setmyinnerShowSaveButton] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [myamount, setmyAmount] = useState('300.00');
    const newhandleInputChange = (event) => {
        setmyAmount(event.target.value);
    };

    const handleOnChange = (selected) => {
        setSelectedOptions(selected);
    };
    const myhandleInputChange = (selectedOption) => {
        setFormValues({
            ...formValues,
            reasonDetails: selectedOption,
        });
    };

    const [selectedStateFrom, setSelectedStateFrom] = useState(null);
    const [selectedCityFrom, setSelectedCityFrom] = useState(null);

    const [selectedStateTo, setSelectedStateTo] = useState(null);
    const [selectedCityTo, setSelectedCityTo] = useState(null);

    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [amount, setAmount] = useState('');
    const [mydatamount, setmydtaAmount] = useState('78.00');
    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedCity(null);
        // additional logic based on selected state
    };

    const handleInputChange = (selectedOption, action) => {
        if (action.name === 'fromcity') {
            setSelectedFromCity(selectedOption);
            setSelectedToCity(selectedOption);
        } else if (action.name === 'tocity') {
            setSelectedToCity(selectedOption);
            if (selectedOption.value === selectedFromCity?.value && selectedFromCity?.value !== selectedToCity?.value) {
                setSelectedFromCity(selectedOption);
            }
        }
    };
    const handleFromCityChange = (selectedOption) => {
        setSelectedFromCity(selectedOption);

        if (selectedOption.value === selectedToCity?.value) {
            setSelectedToCity(selectedOption);
        }
    };

    const handleToCityChange = (selectedOption) => {
        setSelectedToCity(selectedOption);

        if (selectedOption.value === selectedFromCity?.value && selectedFromCity?.value !== selectedToCity?.value) {
            setSelectedFromCity(selectedOption);
        }
    };

    const handleCityChangeFrom = (selectedOption) => {
        setSelectedCityFrom(selectedOption);

        if (selectedCityTo && selectedOption && selectedOption.value === selectedCityTo.value) {
            setSelectedCityTo(null);
        }
    };

    const handleInputChangeTo = (selectedOption) => {
        setSelectedCityTo(selectedOption);
    };

    const handleCityChangeTo = (selectedOption) => {
        setSelectedCityTo(selectedOption);

        if (selectedCityFrom && selectedOption && selectedOption.value === selectedCityFrom.value) {
            setSelectedCityFrom(null);
        }
    };

    const [formValues, setFormValues] = useState({
        fromPlace: '',
        toPlace: '',
        noOfPersons: [],
        reasonDetails: '',
        customerName: '',
        modeOfTravel: { value: null },
        kms: '',
        gpsKms: '',
        travelAmountOwn: '',
        travelAmountSpent: ''
    });

    useEffect(() => {
        const savedData = localStorage.getItem('travelExpenseData');
        if (savedData) {
            try {
                const parsedData = JSON.parse(savedData);
                if (Array.isArray(parsedData)) {
                    setMyTableData(parsedData);
                } else {
                    console.error('Saved data is not an array:', parsedData);
                    setMyTableData([]);
                }
            } catch (error) {
                console.error('Error parsing saved data:', error);
                setMyTableData([]);
            }
        }
    }, []);
    const resetForm = () => {
        setFormValues({
            fromPlace: '',
            toPlace: '',
            modeOfTravel: '',
            reasonDetails: '',
            customerName: '',
            travelAmountSpent: '',
            travelAmountOwn: '',
            kms: '',
            gpsKms: '',
        });
        setSelectedOption('');
    };
    const [requestedBy, setRequestedBy] = useState('Akshay Jaiswal');

    // Get today's date
    const today = new Date();
    const formattedDate = today.toLocaleDateString();


    const handleOptionChange = (e) => setSelectedOption(e.target.value);
    const handleDateChange = (date) => setStartDate(date);
    const handleTimeChange = (time) => {
        setSelectedTime(time);

    }
    const lnchhandleTimeChange = (time) => {

        setlnchSelectedTime(time);
    }

    const handleFileChange = (e) => setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
    const [selectedToCity, setSelectedToCity] = useState(null);

    const [focused, setFocused] = useState(false);

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };
    const mynewtableData = [
        {
            date: '13/06/2024',
            time: '10:00 AM',
            from: 'Home (Check in)',
            to: 'Kandivali Station',
            mypersons: 1,
            expense: 'Travel',
            mode: 'Auto',
            details: 'Injector Dismantle',
            descript: '',
            customerName: 'Global Hospital',
            amount: '23.00',
            lunch: '',
            kms: 2,
            kmsGPS: 1.8,
        },
        {
            date: '13/06/2024',
            time: '10.30 AM',
            from: 'Dadar Station',
            to: 'Global Hospital',
            mypersons: 1,
            expense: 'Travel',
            mode: 'Cab',
            details: 'Injector Dismantle',
            descript: '',
            customerName: 'Global Hospital',
            amount: '55.00',
            lunch: '',
            kms: 3,
            kmsGPS: 3.3,
        },
        {
            date: '13/06/2024',
            time: '01.00 PM',
            from: '',
            to: '',
            mypersons: '2',
            expense: 'Lunch',
            mode: '',
            details: '',
            descript: '',
            customerName: '',
            amount: '',
            lunch: '300.00',
            kms: '',
            kmsGPS: '',
        },
        {
            date: '14/06/2024',
            time: '10.00 AM',
            from: '',
            to: '',
            mypersons: '',
            expense: 'Spares Purchase',
            mode: '',
            details: '',
            descript: 'Notepads, Carrybags and Pens',
            customerName: '',
            amount: '100.00',
            lunch: '',
            kms: '',
            kmsGPS: '',
        },
        {
            date: '14/06/2024',
            time: '',
            from: '',
            to: '',
            mypersons: '',
            expense: 'Other Expenses',
            mode: '',
            details: '',
            descript: 'Asthalin Tubes, Disposable Face Masks',
            customerName: '',
            amount: '25.00',
            lunch: '',
            kms: '',
            kmsGPS: '',
        },
        // Add more items as needed
    ];


    const [mymytableData, setMyTableData] = useState([]);


    const handleSave = () => {
        // Format startDate if needed
        const formattedDate = startDate ? startDate.toLocaleDateString() : '';

        // Format selectedTime if it's a Date object or convert to a string
        const formattedTime = selectedTime instanceof Date ? selectedTime.toLocaleTimeString([], { hour12: false }) : '';

        const data = {
            date: formattedDate,
            time: formattedTime,
            from: formValues.fromPlace,
            to: formValues.toPlace,
            mypersons: formValues.noOfPersons,
            details: formValues.reasonDetails,
            mode: formValues.modeOfTravel,
            customerName: formValues.customerName,
            amount: formValues.travelAmountSpent || formValues.travelAmountOwn,
            lunch: selectedOption === 'Lunch' ? 'Yes' : 'No',
            kms: formValues.kms,
            kmsGPS: formValues.gpsKms,
            selectedOption,
            startDate,
            selectedTime,
            ...formValues
        };

        const updatedTableData = [...mymytableData, data];

        try {
            setMyTableData(updatedTableData);
            localStorage.setItem('travelExpenseData', JSON.stringify(updatedTableData));
            resetForm(); // Reset form after saving
            handleClose(); // Close modal or form after saving
        } catch (error) {
            console.error('Error updating table data:', error);
            // Handle error gracefully
        }
    };


    const handleSelectChange = (selectedOptions) => {
        setFormValues({
            ...formValues,
            reasonDetails: selectedOption,
        });
    };

    const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
    const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    const twoLevelClaim = loginDetails?.subscription?.twoLevelClaim;
    const loggedInRole = loginDetails?.desingation?.role?.roleID;
    const [filter, setFilter] = useState(false);
    const [filterUserId, setFilterUserId] = useState("");
    const [selectedmyPersons, setSelectedmyPersons] = useState({});
    //* CLEAR SELECT START *//
    const clearSerachBySelect = () => {
        setFilterUserId("");

    };

    const [setFile, setsetFile] = useState();
    const [time, setTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));

    const [selectedTime, setSelectedTime] = useState(null);
    const [lnchselectedTime, setlnchSelectedTime] = useState(null);

    const [startDate, setStartDate] = useState();
    const [lnchstartDate, setlnchStartDate] = useState();
    const [sparestartDate, setspareStartDate] = useState();
    const [otherexpensestartDate, setotherexpensestartDate] = useState();

    const userDropdown = [
        { value: 'hotel', label: 'Hotel' },
        { value: 'conveyance', label: 'Conveyance Charges' },
        { value: 'staff', label: 'Staff Welfare' },
        { value: 'sundry', label: 'Sundry Expense' }
    ];
    const accts = [
        { value: '1', label: '44343003-Lodging And Boarding' },
        { value: '2', label: '44343005-Tour Conveyance Charges' },
        { value: '3', label: '44370101-Staff Welfare' },
        { value: '4', label: '44343007-Tour Sundry Expenses' }
    ];
    const usrslst = [
        { value: 'usr1', label: 'Approver1' },
        { value: 'usr2', label: 'Approver2' },
        { value: 'usr3', label: 'Approver3' },
        { value: 'usr4', label: 'Approver4' }
    ];
    const myusrslst = [
        { value: 'user1', label: 'Approver1' },
        { value: 'user2', label: 'Approver2' },
        { value: 'user3', label: 'Approver3' },
        { value: 'user4', label: 'Approver4' }
    ];
    const sourcestate = [

        { value: 'mhr', label: 'Maharashtra' }

    ]

    const cities = [
        { value: 'mumb', label: 'Mumbai' },
        { value: 'pun', label: 'Pune' },
        { value: 'nas', label: 'Nasik' }

    ];
    const tocities = [
        { value: 'mumb', label: 'Mumbai' },
        { value: 'bgl', label: 'Bangalore' },
        { value: 'del', label: 'Delhi' },
        { value: 'kol', label: 'Kolkata' },
        { value: 'hyd', label: 'Hyderabad' }
        // Add more cities as needed
    ];
    const mycities = [
        { value: 'mumb', label: 'Mumbai' },
        { value: 'bgl', label: 'Bangalore' },
        { value: 'del', label: 'Delhi' },
        { value: 'kol', label: 'Kolkata' },
        { value: 'hyd', label: 'Hyderabad' },
        { value: 'ker', label: 'Kerala' },
        { value: 'che', label: 'Chennai' }
        // Add more cities as needed
    ];
    const mytocities = [
        { value: 'mumb', label: 'Mumbai' },
        { value: 'bgl', label: 'Bangalore' },
        { value: 'del', label: 'Delhi' },
        { value: 'kol', label: 'Kolkata' },
        { value: 'hyd', label: 'Hyderabad' },
        { value: 'ker', label: 'Kerala' },
        { value: 'che', label: 'Chennai' }
        // Add more cities as needed
    ];

    // const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(today.getDate() + 2);

    // const [startDate, setStartDate] = useState(tomorrow);
    const [mystartDate, setmyStartDate] = useState(tomorrow);
    const [myendDate, setmyEndDate] = useState(dayAfterTomorrow);
    const [endDate, setEndDate] = useState();

    const handleStartDateChange = (date) => {
        setStartDate();
        setlnchStartDate();
        setspareStartDate();
        setotherexpensestartDate();
        setmyStartDate(date);

    };

    const handleEndDateChange = (date) => {
        setEndDate();
        setmyEndDate(date);
    };
    const [checked, setChecked] = useState(true);

    const [myshowForm, setmyShowForm] = useState(false);
    const mysaveBtn = () => {
        setmyShowForm(false);

    }
    const edithandleShow = (voucher) => {

        setShow(true);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    };

    const [expenseType, setExpenseType] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);



    const handleChange = (selectedOption) => {
        if (!disableSelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const handlemyChange = (selectedOption) => {
        if (!disablemySelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const [disableSelectOnChange, setDisableSelectOnChange] = useState(false);
    const [disablemySelectOnChange, setDisablemySelectOnChange] = useState(false);


    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setRefresh((prev) => !prev);
    };

    const [selectedOption, setSelectedOption] = useState('Travel');
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [showElements, setShowElements] = useState(true);
    const [selectedOptionchk, setSelectedOptionchk] = useState('Normal Item');


    const handleDelete = (index) => {
        const updatedTableData = mytableData.filter((_, i) => i !== index);
        setTableData(updatedTableData);
        if (updatedTableData.length === 0) {
            setIsTableVisible(false); // Hide the table if there are no rows left
        }
    };
    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                <input className="mots-lst"
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null} // Prevent the default checkbox behavior
                />
                <label>{props.label}</label>
            </components.Option>
        );
    };

    const exportExcelClaim = () => {

    };

    const handleUserChange = (selectedOption) => {
        setSelectedUser(selectedOption);
    };

    const handleVerifierChange = (selectedOption) => {
        setSelectedVerifier(selectedOption);
    };

    const [selectedFromCity, setSelectedFromCity] = useState(null);
    const adhandleShow = () => {
        // Your logic to add table data or show modal
        console.log("Adding table data or showing modal");
    };
    const handleClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
        setShowElements(true);
        setShowSaveButton(true);
        setmyShowSaveButton(false);
        setmyinnerShowSaveButton(true);
        setDisableSelectOnChange(false);
        setDisablemySelectOnChange(false);
        setRefresh((prev) => !prev);

    };


    return (
        <div id="main-page">
            {userDetails !== null && userDetails !== "" ? (
                <>
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    <div className="page-wrapper">
                        <div className={`main-body ${toggleshift.style}`}>
                            <div className="page-content usrhrchy lcltrcnt">
                                <Row className="search-row usrhrchy">
                                    <Col md={8} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToBackPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">LOCAL TOUR (Tour within city / within cities of same state)</h6>
                                        </div>
                                    </Col>

                                    <Col md={4} className="cat-col">
                                        <div className="mytr-cnt" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                            <h6>Tour Requested By:</h6>
                                            <div className="mb-0" style={{ marginLeft: '5px' }}>
                                                <label>{requestedBy}</label>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                                <Container>
                                    <Row>
                                        <Col md={12} className="mt-4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <form >
                                                <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Col md={4} className="cat-col">
                                                        <h6>Tour Employee Name :</h6>
                                                    </Col>
                                                    <Col md={8} className="cat-col">
                                                        <div className="slctusr mb-3">
                                                            <FloatingLabel label="Select Employee Name" className="usrhrchy-lbl float-hidden float-select">
                                                                <Select
                                                                    className="react-select-container-list model-select"
                                                                    classNamePrefix="react-select-list"
                                                                    value={formValues.reasonDetails}
                                                                    onChange={myhandleInputChange}
                                                                    options={tremployees}
                                                                    placeholder="Select Employee Name"
                                                                />
                                                            </FloatingLabel>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Col md={4} className="cat-col">
                                                        <h6>Tour Name :</h6>
                                                    </Col>
                                                    <Col md={8} className="cat-col">
                                                        <Row className="levllst mb-2">

                                                            <Col className="cat-col dtcnt mn-cnt">
                                                                {/* <div className="lclmndta mymncnt"> */}
                                                                <h6>From</h6>

                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    options={sourcestate}
                                                                    placeholder="Select State"
                                                                    onChange={handleStateChange}
                                                                />

                                                                <Select
                                                                    id="fromcity"
                                                                    name="fromcity"
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    options={cities}
                                                                    value={selectedFromCity}
                                                                    placeholder="Select City"
                                                                    onChange={handleInputChange}
                                                                />

                                                                {/* </div> */}
                                                            </Col>


                                                            <Col className="cat-col dtcnt mn-incnt">
                                                                {/* <div className="lclmndta mymncntdta slctcty"> */}
                                                                <h6>To</h6>

                                                                <Form.Control
                                                                    type="text"
                                                                    name="state"
                                                                    className="form-control form add-input mybldta-inpttxt kmgps"
                                                                    placeholder=""
                                                                    value={selectedState ? selectedState.label : ''}
                                                                    readOnly
                                                                    onChange={handleInputChange}
                                                                    style={{ height: '35px' }}
                                                                />


                                                                <Select
                                                                    id="tocity"
                                                                    name="tocity"
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    options={cities}
                                                                    value={selectedToCity}// Display selected option from 'From' or 'To' city
                                                                    placeholder="Select City"
                                                                    onChange={handleInputChange}
                                                                />



                                                                {/* </div> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                </Row>
                                                <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Col md={4} className="cat-col">
                                                        <h6>Tour Period :</h6>
                                                    </Col>
                                                    <Col md={8} className="cat-col">
                                                        <div className="mncntmain">

                                                            <div className="lclmndta mymncntdta">
                                                                <h6>From</h6>
                                                                <DatePicker
                                                                    selected={mystartDate}
                                                                    minDate={tomorrow}
                                                                    onChange={handleStartDateChange}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className="form-control form add-input strt"

                                                                    placeholderText="Select start date"
                                                                />
                                                            </div>
                                                            <div className="lclmndta mymncntdta">
                                                                <h6 style={{ marginLeft: '10px' }}>To</h6>
                                                                <DatePicker
                                                                    selected={myendDate}
                                                                    minDate={dayAfterTomorrow}
                                                                    onChange={handleEndDateChange}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className="form-control form add-input strt"

                                                                    placeholderText="Select to date"
                                                                />
                                                            </div>

                                                        </div>

                                                    </Col>

                                                </Row>


                                                <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Col md={4} className="cat-col">
                                                        <h6>Purpose of Tour :</h6>
                                                    </Col>
                                                    <Col md={8} className="cat-col">
                                                        <Form.Control
                                                            as="textarea"
                                                            className="react-input-container"
                                                            placeholder=""
                                                            style={{ height: '65px' }}
                                                        />
                                                    </Col>
                                                </Row>


                                            </form>
                                            <div className="cat-col mylvlmotbtn mt-4 mb-4" style={{ float: 'right' }}>

                                                <SBSaveTourBtn btnName="Add Tour Details" onClickEffect={handleShow} />
                                            </div>

                                        </Col>




                                    </Row>
                                </Container>

                                <div style={{ overflowX: 'auto' }}>
                                    <Col md={12} className="cat-col" >

                                        <table className="table spares-table-data lcltable">
                                            <thead className="thead-light spares-thead">
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>No. of Persons</th>
                                                    <th>Expense Type</th>
                                                    <th>MOT</th>
                                                    <th>Reason / Details</th>
                                                    <th>Description</th>
                                                    <th>Customer Name</th>
                                                    <th>Amount <span className="myrscnt">(Rs.)</span></th>
                                                    <th>Lunch</th>
                                                    <th>Kms</th>
                                                    <th>Kms (As per GPS)</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {mynewtableData.map((item, index) => (
                                                    <tr key={index} className="spare-table-row-data">
                                                        <td>{item.date}</td>
                                                        <td>{item.time}</td>
                                                        <td>{item.from}</td>
                                                        <td>{item.to}</td>
                                                        <td className="algn-tabcnt">{item.mypersons}</td>
                                                        <td>{item.expense}</td>
                                                        <td>{item.mode}</td>
                                                        <td>{item.details}</td>
                                                        <td>{item.descript}</td>
                                                        <td>{item.customerName}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.lunch}</td>
                                                        <td>{item.kms}</td>
                                                        <td className="algn-tabcntint">{item.kmsGPS}</td>
                                                        <td className="myhrchy-dta algn-tabcnt">
                                                            {/* <button className="btn spare-edit-link">
                                                            <i className="fa fa-edit"></i> Edit
                                                        </button>
                                                        <button className="btn spare-edit-link">
                                                            <i className="fa fa-eye"></i> View
                                                        </button> */}
                                                            <button className="btn spare-edit-link">
                                                                <i className="fa fa-trash"></i> Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </Col>
                                </div>

                                <div className="mylclrecqst" style={{ marginTop: '2%' }}>
                                    <Row>
                                        <Col md={12} className="mt-4">

                                            <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={12} className="cat-col">
                                                    <div className="myinrbildta">

                                                        <div className="form-group-custom">
                                                            <Form.Label className="label-fixed">Travel Amount Claimed <span className="myrscnt">(Rs.)</span> :</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="amount"
                                                                className="form-control form add-input mybldta-inpttxt kmgps"
                                                                value={'78.00'}
                                                                readOnly
                                                                style={{ height: '35px', padding: '20px' }}
                                                            />
                                                        </div>
                                                        <div className="form-group-custom2">
                                                            <Form.Label className="label-fixed">Lunch Amount Claimed <span className="myrscnt">(Rs.)</span> :</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="amount"
                                                                className="form-control form add-input mybldta-inpttxt kmgps"
                                                                value={'300.00'}
                                                                readOnly
                                                                style={{ height: '35px', padding: '20px' }}
                                                            />
                                                        </div>
                                                        <div className="form-group-custom3">
                                                            <Form.Label className="label-fixed">Spares Purchase Amount Claimed <span className="myrscnt">(Rs.)</span> :</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="amount"
                                                                className="form-control form add-input mybldta-inpttxt kmgps"
                                                                value={'100.00'}
                                                                readOnly
                                                                style={{ height: '35px', padding: '20px' }}
                                                            />
                                                        </div>
                                                        <div className="form-group-custom4">
                                                            <Form.Label className="label-fixed">Other Expenses Amount Claimed <span className="myrscnt">(Rs.)</span> :</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="amount"
                                                                className="form-control form add-input mybldta-inpttxt kmgps"
                                                                value={'25.00'}
                                                                readOnly
                                                                style={{ height: '35px', padding: '20px' }}
                                                            />
                                                        </div>
                                                        <div className="form-group-custom5">
                                                            <Form.Label className="label-fixed">Total Amount Claimed <span className="myrscnt">(Rs.)</span> :</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="amount"
                                                                className="form-control form add-input mybldta-inpttxt kmgps"
                                                                value={'503.00'}
                                                                readOnly
                                                                style={{ height: '35px', padding: '20px' }}
                                                            />
                                                        </div>

                                                    </div>
                                                </Col>
                                                <Col md={4} className="cat-col mt-4 advccsh">
                                                    {/* <div className="mybldta">
                                                        <h6 className="mybldta-txt3">Advance Cash <span className="myrscnt">(Rs.)</span> :</h6>
                                                        <Form.Control
                                                            type="number"
                                                            name="amount"
                                                            className="form-control form add-input mybldta-inpttxt3"
                                                            placeholder=""
                                                            value={myamount}
                                                            onChange={newhandleInputChange}
                                                            style={{ height: '35px' }}
                                                        />
                                                    </div> */}
                                                    <div className="form-group-custom6">
                                                        <Form.Label className="mylabel-fixed">Advance Cash <span className="myrscnt">(Rs.)</span> :</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name="amount"
                                                            className="form-control form add-input myinptcnt"
                                                            value={myamount}
                                                            onChange={newhandleInputChange}
                                                            style={{ height: '35px', padding: '10px' }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>


                                    <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">
                                        <SBSaveUpdateBtn btnName="Submit" onClickEffect={handleSave} />
                                        <SbCancelBtn btnName="Reset" onClickEffect={handleClose} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <Footer />

                    <Modal show={show} onHide={handleClose} className={`main_model add-edit-model amc-model travexpse ${selectedOption === 'Lunch' ? 'lunch-modal' : ''} ${selectedOption === 'Spares Purchase' ? 'lunch-modal' : ''} ${selectedOption === 'Other Expenses' ? 'lunch-modal' : ''}`}>
                        <div className="add-edit-heading">
                            <h6 className="add-edit-head">Expense Type</h6>
                            <div>
                                <Form.Check
                                    inline
                                    className="travelBy-radio-btn"
                                    name="travelingBy"
                                    type="radio"
                                    label="Travel"
                                    value="Travel"
                                    checked={selectedOption === 'Travel'}
                                    onChange={handleOptionChange}
                                />
                                <Form.Check
                                    inline
                                    className="travelBy-radio-btn"
                                    name="travelingBy"
                                    type="radio"
                                    label="Lunch"
                                    value="Lunch"
                                    checked={selectedOption === 'Lunch'}
                                    onChange={handleOptionChange}
                                />
                                <Form.Check
                                    inline
                                    className="travelBy-radio-btn"
                                    name="travelingBy"
                                    type="radio"
                                    label="Spares Purchase"
                                    value="Spares Purchase"
                                    checked={selectedOption === 'Spares Purchase'}
                                    onChange={handleOptionChange}
                                />
                                <Form.Check
                                    inline
                                    className="travelBy-radio-btn"
                                    name="travelingBy"
                                    type="radio"
                                    label="Other Expenses"
                                    value="Other Expenses"
                                    checked={selectedOption === 'Other Expenses'}
                                    onChange={handleOptionChange}
                                />
                            </div>
                            <i className="fa fa-times" onClick={handleClose} />
                        </div>
                        <Modal.Body>
                            {selectedOption === 'Travel' && (
                                <div className="container add-edit">
                                    <div className="add-inputs usr-hrchy lclmncnt">
                                        <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={2} className="cat-col">
                                                <h6>Date :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form add-input strt"
                                                    placeholderText="Select start date"
                                                />
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <h6>Time :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col">

                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DesktopTimePicker
                                                        label=""
                                                        value={selectedTime}
                                                        onChange={handleTimeChange}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        ampm={true}
                                                        inputFormat="hh:mm:ss A"
                                                        className="form-control form add-input tmedta"
                                                        InputProps={{
                                                            style: { height: '35px' },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3">
                                            <Col md={2} className="cat-col">
                                                <h6>From Place :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col">

                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={mycities}
                                                    placeholder="Select from place"
                                                    style={{ height: '35px' }}
                                                />

                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <h6>To Place :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col">

                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={mytocities}
                                                    placeholder="Select to place"
                                                    style={{ height: '35px' }}
                                                />

                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={2} className="cat-col">
                                                <h6>No. of Persons :</h6>
                                            </Col>
                                            <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Select
                                                    options={mySelectOptions}
                                                    value={formValues.noOfPersons}
                                                    onChange={(selectedOptions) => setFormValues({ ...formValues, noOfPersons: selectedOptions })}
                                                    isMulti
                                                    placeholder="Select number of persons"
                                                    className="form-control form add-input myprsns"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={2} className="cat-col">
                                                <h6>Reason / Details :</h6>
                                            </Col>
                                            <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Form.Control
                                                    as="textarea"
                                                    name="reasonDetails"
                                                    className="form-control form add-input"
                                                    placeholder=""
                                                    // value='reasonDetails'
                                                    // onChange={handleInputChange}
                                                    style={{ height: '35px' }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={2} className="cat-col">
                                                <h6>Customer Name :</h6>
                                            </Col>
                                            <Col md={10} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Form.Control
                                                    type="text"
                                                    name="customerName"
                                                    className="form-control form add-input"
                                                    placeholder=""
                                                    // value={formValues.customerName}
                                                    // onChange={handleInputChange}
                                                    style={{ height: '35px' }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={2} className="cat-col">
                                                <h6>Mode of Travel :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <FloatingLabel label='Select MOT' className="float-hidden float-select">
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={transportoptions}
                                                        placeholder="Select MOT"
                                                        value={transportoptions.find(option => option.value === formValues.modeOfTravel)}
                                                        onChange={(data) => setFormValues({ ...formValues, modeOfTravel: data ? data.value : null })}
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <h6>KMs :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Form.Control
                                                    type="number"
                                                    name="kms"
                                                    className="form-control form add-input"
                                                    placeholder=""
                                                    // value={formValues.kms}
                                                    // onChange={handleInputChange}

                                                    style={{ height: '35px' }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            <Col md={2} className="cat-col">
                                                <h6>KMs ( As per GPS):</h6>
                                            </Col>
                                            <Col md={4} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Form.Control
                                                    type="number"
                                                    name="gpsKms"
                                                    className="form-control form add-input kmgps"
                                                    placeholder=""
                                                    // value={formValues.gpsKms}
                                                    // onChange={handleInputChange}
                                                    readOnly
                                                    style={{ height: '35px', filter: 'grayscale(100%)', background: 'none', boxShadow: 'none' }}
                                                />
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <h6>Rate Per KM :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Form.Control
                                                    type="number"
                                                    name="ratePerKm"
                                                    className="form-control form add-input kmgps"
                                                    placeholder=""
                                                    value={formValues.ratePerKm}
                                                    onChange={handleInputChange}
                                                    style={{ height: '35px', filter: 'grayscale(100%)', background: 'none', boxShadow: 'none' }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            <Col md={2} className="cat-col">
                                                {/* <div className="mybldta"> */}
                                                <h6>Upload Bill / Ticket :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col">
                                                <input type="file" name="billTicket" className="mybillattach" onChange={handleFileChange} />
                                                {/* </div> */}

                                            </Col>


                                            <Col md={2} className="cat-col">
                                                <h6>Upload Meter Image :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col">
                                                <input type="file" name="meterImage" className="mybillattach-img" onChange={handleFileChange} />

                                            </Col>

                                        </Row>

                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={2} className="cat-col">
                                                <h6>Travel Amount <span className="myrscnt">(Rs.)</span> (Own car/Own bike) :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col">
                                                <Form.Control
                                                    type="number"
                                                    name="travelAmountOwn"
                                                    className="form-control form add-input kmgps"
                                                    placeholder=""
                                                    // value={formValues.travelAmountOwn}
                                                    // onChange={handleInputChange}
                                                    style={{ height: '35px', filter: 'grayscale(100%)', background: 'none', boxShadow: 'none' }}
                                                />

                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <h6>Travel Amount <span className="myrscnt">(Rs.)</span> (As spent) :</h6>
                                            </Col>
                                            <Col md={4} className="cat-col">
                                                <Form.Control
                                                    type="number"
                                                    name="travelAmountSpent"
                                                    className="form-control form add-input"
                                                    placeholder=""
                                                    // value={formValues.travelAmountSpent}
                                                    // onChange={handleInputChange}
                                                    style={{ height: '35px' }}
                                                />

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            )}
                            {selectedOption === 'Lunch' && (
                                <div className="container add-edit">
                                    <div className="add-inputs usr-hrchy lclmncnt">
                                        {/* Lunch form content */}
                                        <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Date :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col">
                                                <DatePicker
                                                    selected={lnchstartDate}
                                                    onChange={(date) => setlnchStartDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form add-input strt"
                                                    placeholderText="Select date"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Time :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col">
                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DesktopTimePicker
                                                        label=""
                                                        value={lnchselectedTime}
                                                        onChange={lnchhandleTimeChange}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        ampm={true}
                                                        inputFormat="hh:mm:ss A"
                                                        className="form-control form add-input tmedta"
                                                        style={{ height: '35px' }}
                                                    />
                                                </LocalizationProvider> */}
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DesktopTimePicker
                                                        label=""
                                                        value={lnchselectedTime}
                                                        onChange={lnchhandleTimeChange}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                variant="outlined"
                                                                className="form-control form add-input tmedta"
                                                                style={{ height: '35px' }}
                                                            />
                                                        )}
                                                        ampm={true}
                                                        inputFormat="hh:mm:ss A"
                                                    />
                                                </LocalizationProvider>

                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Lunch Person(s) :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Select
                                                    options={mySelectOptions}
                                                    value={formValues.noOfPersons}
                                                    onChange={(selectedOptions) => setFormValues({ ...formValues, noOfPersons: selectedOptions })}
                                                    isMulti
                                                    placeholder="Select person"
                                                    className="form-control form add-input myprsns"
                                                />
                                            </Col>

                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Lunch Amount <span className="myrscnt">(Rs.)</span> :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Form.Control
                                                    type="number"
                                                    name="amount"
                                                    className="form-control form add-input kmgps"
                                                    placeholder=""
                                                    value={'amount'}
                                                    readOnly
                                                    onChange={handleInputChange}
                                                    style={{ height: '35px' }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Upload Bill :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <input type="file" name="billTicket" onChange={handleFileChange} />

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            )}
                            {selectedOption === 'Spares Purchase' && (
                                <div className="container add-edit">
                                    <div className="add-inputs usr-hrchy lclmncnt">
                                        {/* Lunch form content */}
                                        <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Date :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col">
                                                <DatePicker
                                                    selected={sparestartDate}
                                                    onChange={(date) => setspareStartDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form add-input strt"
                                                    placeholderText="Select date"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Item Description :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Form.Control
                                                    as="textarea"
                                                    name="reasonDetails"
                                                    className="form-control form add-input"
                                                    placeholder=""
                                                    // value={formValues.reasonDetails}
                                                    // onChange={handleInputChange}
                                                    style={{ height: '65px' }}
                                                />
                                            </Col>

                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            <Col md={4} className="cat-col">
                                                <h6>Amount <span className="myrscnt">(Rs.)</span> :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Form.Control
                                                    type="number"
                                                    name="amount"
                                                    className="form-control form add-input"
                                                    placeholder=""
                                                    // value={'amount'}
                                                    // onChange={handleInputChange}
                                                    style={{ height: '35px' }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Upload Bill :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <input type="file" name="billTicket" className="mybillattach" onChange={handleFileChange} />

                                            </Col>

                                        </Row>

                                    </div>
                                </div>
                            )}
                            {selectedOption === 'Other Expenses' && (
                                <div className="container add-edit">
                                    <div className="add-inputs usr-hrchy lclmncnt">
                                        {/* Lunch form content */}
                                        <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Date :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col">
                                                <DatePicker
                                                    selected={otherexpensestartDate}
                                                    onChange={(date) => setotherexpensestartDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form add-input strt"
                                                    placeholderText="Select date"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Description :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Form.Control
                                                    as="textarea"
                                                    name="reasonDetails"
                                                    className="form-control form add-input"
                                                    placeholder=""
                                                    // value={formValues.reasonDetails}
                                                    // onChange={handleInputChange}
                                                    style={{ height: '65px' }}
                                                />
                                            </Col>

                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            <Col md={4} className="cat-col">
                                                <h6>Amount <span className="myrscnt">(Rs.)</span> :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Form.Control
                                                    type="number"
                                                    name="amount"
                                                    className="form-control form add-input"
                                                    placeholder=""
                                                    // value={'amount'}
                                                    // onChange={handleInputChange}
                                                    style={{ height: '35px' }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Col md={4} className="cat-col">
                                                <h6>Upload Bill :</h6>
                                            </Col>
                                            <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <input type="file" name="billTicket" className="mybillattach" onChange={handleFileChange} />

                                            </Col>

                                        </Row>

                                    </div>
                                </div>
                            )}

                            <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">
                                <SBSaveUpdateBtn btnName="Add To List" onClickEffect={handleSave} />
                                <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />
                            </div>


                        </Modal.Body>
                    </Modal>
                </>
            ) : (
                <AccessDenied />
            )
            }
        </div >
    );
}

export default LocalTourRequest;
