import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  OverLayData,
  truncateString,
} from "../../ReUseFunctions/ReuseFunctions";

export default function ApprovalMatrixTable(props) {
  return (
    <div>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer
          //   onScroll={(e) =>
          //     handleScroll(
          //       e,
          //       props.users,
          //       props.totalUserCount,
          //       props.setLoading,
          //       props.setPageSize,
          //       props.PageSize
          //     )
          //   }
          >
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="approver-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {props.tableHeader.map((data, i) => (
                    <TableCell key={i} align={data?.align} sx={data.width}>
                      {data?.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tableData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <div className="color-green"> {data?.setp_ID}</div>
                    </TableCell>
                    <TableCell>{data?.step_Order}</TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.approver_Code}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.approver_Name}
                    </TableCell>
                    <TableCell>{data?.approver_Type}</TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      <div> {data?.subscriptionId}</div>
                    </TableCell>

                    {/* <TableCell align="center" sx={{ width: "120px" }}>
                      <div className={`approver ${data.status}`}>
                        <span className="status-text">{data?.status}</span>
                      </div>
                    </TableCell> */}
                    {/* <TableCell align="center">
                      <div
                        className="change-status"
                        // onClick={() => {
                        //   props.openCloseAddEditModel();
                        //   props.setPurchaseOrderID(2);
                        // }}
                      >
                        <span className="hv-center">
                          <i className="fa fa-edit" />
                          Change
                        </span>
                      </div>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
