import { useState } from 'react';
import '../../styles/AddUserModel.scss'
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { FloatingLabel } from 'react-bootstrap';
import Select from "react-select";

const EditAnnualContract = (props) => {
    // const [show, setShow] = useState(false);
    const [amcNum, setAmcNum] = useState("")
    const [amcStartDate, setAmcStartDate] = useState("")
    const [amcEndDate, setAmcEndDate] = useState("")
    const [amcType, setAmcType] = useState({ value: "", label: "" })
    const [invoiceDate, setInvoiceDate] = useState("")
    const [productCode, setProductCode] = useState("")
    const [machineName, setMachineName] = useState("")
    const [companyname, setCompanyname] = useState("")
    const [email, setEmail] = useState("")
    const [serialNum, setSerialname] = useState("")
    const [contactNum, setContactNum] = useState("")
    const [installationDate, setInstallationDate] = useState("")
    const [address, setAddress] = useState("")
    const [installedBy, setInstalledBy] = useState({ value: "", label: "" })
    const [serviceDuration, setServiceDuration] = useState("")
    const closeModel = () => {
        props.handleCloseEdit();
    };


    return (
        <div>
            <div
                show={props.show}
                onHide={props.handleClose}
            >
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content add-machine-page-container">
                        <div>
                            <div className="bubble-image">
                                <img src="../../images/BG/about_s4_bubble.png" alt="" />
                            </div>
                            <div className="circle-image">
                                <img src="../../images/BG/choose_lines.svg" alt="" />
                            </div>
                            {/* <div className="left-image">
                                <img src="../../images/BG/leftImage.png" alt="" />
                            </div> */}
                            <div className="lines-image">
                                <img src="../../images/BG/lines.png" alt="" />
                            </div>
                            {/* <div className="right-image">
                                <img src="../../images/BG/ser_shap_2.png" alt="" />
                            </div> */}
                        </div>
                        <div className="modal-header add-user-title">
                            <div className="back-btn" onClick={closeModel} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                            <h4 className="modal-title">UPDATE AMC</h4>
                            {/* <button type="button" className="btn-close" aria-label="Close" onClick={closeModel}></button> */}
                        </div>
                        <div className="px-0 modal-body">
                            <div className="container-fluid add-user-container">
                                <div className="row">
                                    <div className="col-lg-12 col">
                                        <div>
                                            <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="AMC Number"
                                                            className={`${amcNum
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="AMC Number"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="AMC Start Date"
                                                            className={`${amcStartDate
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="date"
                                                                id="amcStartDate"
                                                                class="form-control add-user-input"
                                                                placeholder="AMC Start Date"
                                                            />

                                                        </FloatingLabel>
                                                    </div>

                                                </div>
                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="AMC End Date"
                                                            className={`${amcEndDate
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="date"
                                                                className="form-control add-user-input"
                                                                placeholder="AMC End Date"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="AMC Type"
                                                            className={`${amcType.value !== ""
                                                                ? "float-visible-select"
                                                                : "float-hidden float-select"
                                                                }`}
                                                        >
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                placeholder="AMC Type"
                                                                isSearchable={true}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row add-user-image-container-div">

                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Invoice Date"
                                                            className={`${invoiceDate
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="Date"
                                                                className="form-control add-user-input"
                                                                placeholder="Invoice Date"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Product Code"
                                                            className={`${productCode
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Product Code"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Product/Machine Name"
                                                            className={`${machineName
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Product/Machine Name"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Company Name"
                                                            className={`${companyname
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Company Name"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row add-user-image-container-div">

                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Company Email"
                                                            className={`${email
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Company Email"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Company Serial Number"
                                                            className={`${serialNum
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Company Serial Number"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Phone Number"
                                                            className={`${contactNum
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Phone Number"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Installation Date"
                                                            className={`${installationDate
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="date"
                                                                className="form-control add-user-input"
                                                                placeholder="Installation Date"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row add-user-image-container-div">
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Address"
                                                            className={`${address
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <textarea
                                                                rows="3"
                                                                cols="20"
                                                                name="instruction"
                                                                wrap="hard"
                                                                className="form-control form-textarea add-product-input"
                                                                id="descriptionArea"
                                                                placeholder="Address"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col d-block">
                                                    <div >
                                                        <div className="mb-3">
                                                            <FloatingLabel
                                                                label="Installed By"
                                                                className={`${installedBy.value !== ""
                                                                    ? "float-visible-select"
                                                                    : "float-hidden float-select"
                                                                    }`}
                                                            >
                                                                <Select
                                                                    className="react-select-container-list model-select"
                                                                    classNamePrefix="react-select-list"
                                                                    placeholder="Installed By"
                                                                    isSearchable={true}
                                                                />
                                                            </FloatingLabel>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="mb-3">
                                                            <FloatingLabel
                                                                label="Service Duration (no.of days)"
                                                                className={`${serviceDuration
                                                                    ? "float-input-visible"
                                                                    : "float-hidden float-input"
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="form-control add-user-input"
                                                                    placeholder="Service Duration (no.of days)"
                                                                />
                                                            </FloatingLabel>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <div>
                                                            <button type="button" className="btn btn-add-product-img">
                                                                <span
                                                                    style={{
                                                                        color: "#04A96D",
                                                                        fontSize: "13px",
                                                                        fontWeight: "700",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="d-flex justify-content-center"
                                                                        onDragOver={(e) => e.preventDefault()}
                                                                    // onDrop={handleDrop}
                                                                    >

                                                                    </div>

                                                                    <div
                                                                        className="drop-area"
                                                                        onDragOver={(e) => e.preventDefault()}
                                                                    // onDrop={handleDrop}
                                                                    >
                                                                        <h6>Upload Images</h6>
                                                                        <br />
                                                                        {/* <img
                                                                            src="../../../images/DefaultImages/UploadImages.svg"
                                                                            alt=""
                                                                        />
                                                                        <p>Drag and drop images here</p>
                                                                        <p>OR</p> */}
                                                                        <SbCancelBtn
                                                                            btnName="Browse"
                                                                        // onClickEffect={uploadMultiple}
                                                                        />
                                                                        <p style={{ fontSize: "11px" }}>
                                                                            Supports Only JPG, JPEG, PNG
                                                                        </p>
                                                                    </div>
                                                                    {/* )} */}
                                                                </span>
                                                            </button>
                                                            <input
                                                                id="getFile"
                                                                type="file"
                                                                multiple
                                                                name="filetobase64"
                                                                accept="image/*"
                                                                className="btn-primary upload"
                                                                // ref={multipleFileInput}
                                                                // onChange={handleChangeMultipleImg}
                                                                style={{ display: "none" }}
                                                            />
                                                        </div>
                                                        {/* {selectLength && (
                                                            <small id={`Error`} className="form-text text-muted ">
                                                                Please Select maximum 6 images
                                                            </small>
                                                        )} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row add-user-image-container-div">


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-page-save-cancel">
                                    <SBSaveUpdateBtn btnName="Update" />
                                    <SbCancelBtn
                                        btnName="Cancel"
                                        onClickEffect={() => {
                                            closeModel();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EditAnnualContract;

