import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/BaseFare.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SbAddBtn from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Footer from "../Footer";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import '../../styles/ServiceRequest.scss'
import AddEscalationMatrix from "./AddEscalationMatrix";

function ServiceRequestEscalation() {
    let tableHeader = [
        { name: "MODULE NAME", align: "left", width: "150px" },
        { name: "LEVEL", align: "left", width: "150px" },
        { name: "ROLE", align: "left", width: "150px" },
        { name: "DESIGNATION", align: "left", width: "150px" },
        { name: "USER NAME", align: "left", width: "150px" },
        { name: "TRIGGER DAY/HM", align: "left", width: "150px" },
    ]

    const escalationData = [
        {
            moduleName: "Service Request",
            level: "L-1",
            role: "admin",
            designation: "admin",
            userName: "dummy",
            userCode: "SMRT-77",
            triggerDay: "2 days"
        }
    ]

    let dispatch = useDispatch();

    // PAGINATION START
    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [show, setShow] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const handleClose = () => {
        setShow(false);
    };


    const [openFilterTab, setOpenFilterTab] = useState(false);

    // Page navigation
    let navigate = useNavigate();
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation

    return (
        <div id="main-page">
            {!show &&
                <>
                    {/* SIDEBAR START */}
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    {/* SIDEBAR END */}

                    {/* TOPBAR START */}
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    {/* TOPBAR END */}

                    {/* MAIN BODY START */}
                    <div className="page-wrapper">
                        {/* PAGE CONTENT START */}
                        <div className={`main-body ${toggleshift.style}`}>

                            <div className="page-content">
                                <div style={{ position: "relative" }}>
                                    <Row className='search-row'>
                                        <Col md={3} className='cat-col'>
                                            <div className='page-header-text-div'>
                                                <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                                                <h6 className='page-header-text' >SERVICE REQUEST ESCALATION</h6>
                                            </div>
                                        </Col>
                                        {/* <Col md={5} className='cat-col'>
                                            <div className='input-div'>
                                                <input type="search" placeholder='Find a Product' className='form-control setting-input'
                                                id="metricInfo_search_bar" value={searchValue} onChange={(e) => setSearchData(e.target.value)}
                                                />
                                                <i className='fa fa-search search-icon' ></i>
                                            </div>
                                        </Col> */}
                                        <Col md={9} className="cat-col">
                                            <div className="search-bar-header" style={{ float: "right" }}>
                                                <div className="page-top-bar-dropdowns page-top-Filters">
                                                    <div
                                                        className="header-filter-btn"
                                                        onClick={() => setOpenFilterTab(!openFilterTab)}
                                                    >
                                                        <p className="card-head">
                                                            <i className="bi bi-sliders" />
                                                            &nbsp;&nbsp;Filters
                                                        </p>
                                                    </div>
                                                    <SbAddBtn
                                                        onClickEffect={() => setShow(!show)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    {openFilterTab &&
                                        <div className="page-filter-section">
                                            <div style={{ width: "100%" }}>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="clients-filter-input-boxs filter-box-div">
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    padding: "0px 5px",
                                                                }}
                                                            >
                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    // options={userReferralsDropdown}
                                                                    placeholder="Select User"
                                                                    // value={user}
                                                                    // onInputChange={userReferralsDropdown.label}
                                                                    // onChange={(data) =>
                                                                    //     setSelectSearchDrop(data, "user")
                                                                    // }
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    padding: "0px 5px",
                                                                }}
                                                            >
                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    // options={productOptions}
                                                                    placeholder="Select Product"
                                                                    // value={product}
                                                                    // onInputChange={productOptions.label}
                                                                    // onChange={(data) =>
                                                                    //     setSelectSearchDrop(data, "product")
                                                                    // }
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                            <div style={{
                                                                width: "100%",
                                                                padding: "0px 5px",
                                                            }}>
                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    // options={optionsStatusList}
                                                                    placeholder="Select Status"
                                                                    // value={status}
                                                                    // onInputChange={optionsStatusList.label}
                                                                    // onChange={(data) =>
                                                                    //     setSelectSearchDrop(data, "status")
                                                                    // }
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <div className="clients-filter-input-boxs filter-box-div">
                                                            <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                                                                <span className="input-group-text" id="from-label">
                                                                    From - To
                                                                </span>
                                                                <span className="date-picker-with-icon">
                                                                    <DatePicker
                                                                        // selected={fromDate}
                                                                        className="select from-date"
                                                                        id="date-range-picker"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        selectsRange
                                                                        // startDate={fromDate}
                                                                        // endDate={toDate}
                                                                        // onChange={(data) => setSelectSearchDrop(data, "date")}
                                                                        maxDate={new Date()}
                                                                    />
                                                                </span>
                                                                <span><i className="fa fa-calendar-o calander-icon" style={{ right: "15px" }} /></span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="clients-filter-input-boxs filter-box-div">
                                                            <SbCancelBtn
                                                                // onClickEffect={clearSerachBySelect}
                                                                btnName="Clear"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    }
                                </div>


                                <div className="sb-table-div sb-table-setion d-flex">
                                    <div className="arrow-container">
                                        <div className="arrow-up"></div>
                                        <div className="arrow-line"></div>
                                        <div className="arrow-text">Escalation Path</div>
                                    </div>
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                                        <TableContainer sx={{ maxHeight: 440 }}
                                        // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                                        >
                                            <Table stickyHeader aria-label="sticky table table-responsive" className='basefare-table'>
                                                <TableHead className='custom-table-header'>
                                                    <TableRow>
                                                        {tableHeader.map((name, i) => (
                                                            < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                                                style={{ minWidth: name.width }}>
                                                                {name.name}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                {isLoading ? (
                                                    <TableBody>
                                                        {tableHeader.map((name, i) => (
                                                            <SkeletonTableData key={i} />
                                                        ))}
                                                    </TableBody>
                                                ) : (
                                                    <>
                                                        {escalationData && escalationData.length === 0 ?
                                                            <TableBody>
                                                                <TableRow align="center" >
                                                                    <TableCell align="center" colSpan={tableHeader.length}>
                                                                        <div style={{ padding: "20px 20px" }}>
                                                                            <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                                            <span>No Data Found...!</span>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                            :
                                                            <TableBody>
                                                                {escalationData && escalationData.map((data, i) => (
                                                                    <TableRow align="center" key={i} className='client-list-row-data'>
                                                                        <TableCell>
                                                                            {data.moduleName}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.level}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.role}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.designation}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.userName}<br />
                                                                            {data.userCode}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.triggerDay}
                                                                        </TableCell>

                                                                    </TableRow>
                                                                ))}

                                                                {/* {loading &&
                                                                    <OnScrollLoading />
                                                                } */}
                                                            </TableBody>

                                                        }
                                                    </>
                                                )}
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </div>
                            </div>
                        </div>

                        {/* MAIN BODY END */}
                    </div>
                    <Footer />
                </>}
            {/* {show && (
                <AddServiceRequestEscalation
                    show={show}
                    handleClose={handleClose}
                />
            )} */}
            {show && (
                <AddEscalationMatrix
                    show={show}
                    handleClose={handleClose}
                />
            )}
        </div>

    );
}

export default ServiceRequestEscalation;


