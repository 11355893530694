import '../../styles/AddUserDetails.scss'
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import '../../styles/AddUserModel.scss'
import { useEffect, useRef, useState } from "react";
import productimg1 from "../../images/DefaultImages/emptyProduct.svg";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SalesBee from "../../images/MatricLogo.svg";
import AccordianPage from './AccordianPage';
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';
import { useDispatch, useSelector } from 'react-redux';
import { addMachinesData, loadClientDropDown, loadProdCategoryDrop, loadProdSubCategory, loadSingleMachine } from '../../redux/action';
// import Geocode from "react-geocode";
import { baseURL } from '../BaseUrl';
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";

const warrentyStatus = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
];


const AmcDetails = (props) => {

    const machineId = props.machineId
    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
    let dispatch = useDispatch();
    const { prodCategoryDrop } = useSelector((state) => state.data);
    const { prodSubcategory } = useSelector((state) => state.data);
    const { clientDropdown } = useSelector((state) => state.data);
    const formattedClientDropdown = clientDropdown.map(option => ({
        ...option,
        label: `${option.label} - ${option.name}`
    }));
    const { getSingleMachineData } = useSelector((state) => state.data);
    console.log(getSingleMachineData, 'getSingleMachineData')

    const hiddenFileInput = useRef(null);
    const multipleFileInput = useRef(null);
    const [machineCode, setMachineCode] = useState("")
    const [machineName, setMachineName] = useState("")
    const [category, setCategory] = useState({ value: "", label: "" })
    const [subCategory, setSubCategory] = useState({ value: "", label: "" })
    const [company, setCompany] = useState({ value: "", label: "" })
    const [warrentyStatue, setWarrentyStatus] = useState({ value: "", label: "" })
    const [amc, setAmc] = useState({ value: "", label: "" })
    const [brand, setBrand] = useState("")
    const [capacity, setCapacity] = useState("")
    const [height, setHeight] = useState("")
    const [width, setWidth] = useState("")
    const [length, setLength] = useState("")
    const [weight, setWeight] = useState("")
    const [mrp, setMrp] = useState("")
    const [retailPrice, setRetailPrice] = useState("")
    const [location, setLocation] = useState("")
    const [description, setDescription] = useState("")
    const [instruction, setInstruction] = useState("")

    const [activeKey, setActiveKey] = useState('');

    const [showMachineDetailsForm, setShowMachineDetailsForm] = useState(true);
    const [showMachineDimensionsForm, setShowMachineDimensionsForm] = useState(false);
    const [showMachineImagesForm, setShowMachineImagesForm] = useState(false);
    const [showMachineDetails, setShowMachineDetails] = useState(false);
    const [showMachineDimensions, setShowMachineDimensions] = useState(false);
    const [showAmcDetails, setShowAmcDetails] = useState(false);
    const [showAmcDetailsForm, setShowAmcDetailsForm] = useState(false);
    const [showMachineDetailsHeader, setShowMachineDetailsHeader] = useState(true);
    const [amcDetailsHeader, setShowAmcDetailsHeader] = useState(false);
    const [showMachineDimensionHeader, setShowMachineDimensionHeader] = useState(false);
    const [showMachineImageHeader, setShowMachineImageHeader] = useState(false);
    const [activeMenu, setActiveMenu] = useState(1);

    const toggleMachineDetails = () => {
        setShowMachineDetailsForm(true)
        setShowMachineDimensionsForm(false);
        setShowAmcDetailsForm(false)
        setShowMachineImagesForm(false);
        setShowMachineDetails(false)
        setShowAmcDetails(false)
        setShowMachineDimensions(false)
        setShowMachineDetailsHeader(true)
        setShowAmcDetailsHeader(false)
        setShowMachineDimensionHeader(false)
        setShowMachineImageHeader(false)
        setActiveMenu(1);
    }
    const toggleAmcDetails = () => {
        setShowAmcDetailsForm(true)
        setShowMachineDimensionsForm(false);
        setShowMachineDetailsForm(false)
        setShowMachineImagesForm(false);
        setShowMachineDetails(true);
        setShowAmcDetails(false)
        setShowMachineDimensions(false)
        setShowAmcDetailsHeader(true)
        setActiveMenu(2);
        setActiveKey('0')

        setShowMachineDetailsHeader(false)
        setShowMachineDimensionHeader(false)
        setShowMachineImageHeader(false)
    };
    const toggleMachineDimensions = () => {
        setShowMachineDimensionsForm(true);
        setShowMachineDetailsForm(false);
        setShowMachineImagesForm(false);
        setShowMachineDetails(true)
        setShowAmcDetails(true)
        setShowMachineDimensions(false)
        setShowAmcDetailsForm(false)
        setShowMachineDimensionHeader(true)
        setActiveMenu(3);

        setShowMachineDetailsHeader(false)
        setShowAmcDetailsHeader(false)
        setShowMachineImageHeader(false)
        setActiveKey('1')
    };
    const toggleMachineImages = () => {
        setShowMachineImagesForm(true);
        setShowMachineDetailsForm(false);
        setShowMachineDimensionsForm(false);
        setShowAmcDetails(false)
        setShowMachineDetails(true)
        setShowMachineDimensions(true)
        setShowAmcDetails(true)
        setShowAmcDetailsForm(false)
        setShowMachineImageHeader(true)
        setActiveMenu(4);
        setActiveKey('2')

        setShowMachineDetailsHeader(false)
        setShowAmcDetailsHeader(false)
        setShowMachineDimensionHeader(false)
    };

    useEffect(() => {
        dispatch(loadProdCategoryDrop());
        dispatch(loadClientDropDown())
    }, []);

    const handleDropDown = (data, type) => {
        if (type === "category") {
            dispatch(loadProdSubCategory(data.value));
            setCategory(data);
        }
        else if (type === "subCategory") {
            setSubCategory(data);
        }
        else if (type === "warrenty") {
            setWarrentyStatus(data);
        }
        else if (type === "company") {
            setCompany(data);
        }

    }

    useEffect(() => {
        if (machineId) {
            dispatch(loadSingleMachine(machineId))
        }
    }, [machineId])

    useEffect(() => {
        if (machineId && getSingleMachineData) {
            if (Object.keys(getSingleMachineData).length !== 0) {
                setMachineCode(getSingleMachineData[0].machineCode)
                setMachineName(getSingleMachineData[0].machineName)
                setMrp(getSingleMachineData[0].mrp)
                setRetailPrice(getSingleMachineData[0].retailPrice)
                setInstruction(getSingleMachineData[0].instruction)
                setDescription(getSingleMachineData[0].description)
                setBrand(getSingleMachineData[0].brand)
                setLocation(getSingleMachineData[0].location)
                setLength(getSingleMachineData[0].length)
                setWidth(getSingleMachineData[0].width)
                setHeight(getSingleMachineData[0].height)
                setWeight(getSingleMachineData[0].weight)
                setCapacity(getSingleMachineData[0].capacity)
                prodCategoryDrop.map((category) => {
                    if (category.label === getSingleMachineData[0]?.category) {
                        setCategory(category);
                    }
                });
                prodSubcategory.map((subCategory) => {
                    if (subCategory.label === getSingleMachineData[0]?.subCategory) {
                        setSubCategory(subCategory);
                    }
                });
                formattedClientDropdown.map((company) => {
                    if (company.label === getSingleMachineData[0]?.client) {
                        setCompany(company);
                    }
                });
                warrentyStatus.map((warrenty) => {
                    if (warrenty.value === getSingleMachineData[0]?.warrentyStatus) {
                        setWarrentyStatus(warrenty);
                    }
                });
            }
        }
    }, [machineId, getSingleMachineData])

    useEffect(() => {

    }, [])

    const [imgFile, setImgFile] = useState("");
    const [localImage, setLocalImage] = useState("");

    const uploadClick = () => {
        hiddenFileInput.current.click();
    };

    const uploadMultiple = (e) => {
        e.preventDefault();
        multipleFileInput.current.click();
    };

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        setImgFile(fileUploaded);
        // setImgFileName(event.target.files[0].name);

        const reader = new FileReader();
        reader.readAsDataURL(fileUploaded);
        reader.onloadend = () => {
            setLocalImage(reader.result);
        };
    };

    const deleteUploadLogo = () => {
        setLocalImage("");
    };

    const [selectLength, setSelectLength] = useState(false);
    const [localGallery, setLocalGallery] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const handleChangeMultipleImg = (event) => {
        if (event) {
            let images = [...localGallery];
            const fileUploaded = Array.from(event.target.files);
            if (fileUploaded.length + galleryImages.length + images.length > 6) {
                setSelectLength(true);
            } else {
                setSelectLength(false);
                if (images.length === 0) {
                    fileUploaded.map((file) => {
                        images.push(file);
                    });
                } else {
                    let filterImages = fileUploaded.filter((file) =>
                        images.every((files) => !files.name.includes(file.name))
                    );
                    if (filterImages.length != 0) {
                        filterImages.map((file) => {
                            images.push(file);
                        });
                    }
                }
            }
            setLocalGallery(images);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleFiles(files);
    };

    const handleFiles = (files) => {
        let images = [...localGallery];
        const fileUploaded = Array.from(files);
        if (fileUploaded.length + galleryImages.length + images.length > 6) {
            setSelectLength(true);
        } else {
            setSelectLength(false);
            if (images.length === 0) {
                fileUploaded.map((file) => {
                    images.push(file);
                });
            } else {
                let filterImages = fileUploaded.filter((file) =>
                    images.every((files) => !files.name.includes(file.name))
                );
                if (filterImages.length != 0) {
                    filterImages.map((file) => {
                        images.push(file);
                    });
                }
            }
        }
        setLocalGallery(images);
    };

    const deleteImage = (name) => {
        setSelectLength(false);
        setLocalGallery(localGallery.filter((data) => data.name !== name.name));
    };

    const deleteGalImage = (name) => {
        setSelectLength(false);
        setGalleryImages(galleryImages.filter((data) => data !== name));
    };

    const [latLan, setLatLan] = useState({
        lat: null,
        lng: null,
    });
    const [address, setAddress] = useState(null);

    useEffect(() => {
        getLocation();
    }, []);

    const getLocation = () => {
        if (!navigator.geolocation) {
            alert("Geolocation is not supported for your browser");
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                setLatLan({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            });
        }
    };

    // useEffect(() => {
    //     if (latLan.lat !== null && latLan.lng !== null) {
    //         Geocode.fromLatLng(latLan.lat, latLan.lng)
    //             .then((response) => {
    //                 const address = response.results[0].formatted_address;
    //                 setLocation(address);
    //             })
    //             .catch((error) => {
    //                 console.error(error);
    //             });
    //     }
    // }, [latLan]);

    const addMachineData = (e) => {
        e.preventDefault();

        const data = {
            "userID": resPassData.userId,
            "subscriptionID": resPassData.subscriptionId,
            "machineName": machineName,
            "machineCode": machineCode,
            "category": category.label,
            "subCategory": subCategory.label,
            "client": company.label,
            "warrentyStatus": warrentyStatue.label,
            // "awcStatus": amc.label,
            "brand": brand,
            "capacity": capacity,
            "height": height,
            "width": width,
            "length": length,
            "weight": weight,
            "mrp": mrp,
            "retailPrice": retailPrice,
            "location": location,
            "description": description,
            "instruction": instruction,
        }
        let postMachine = {
            method: "POST",
            mode: 'cors',
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(data)
        }
        console.log(data, 'data')
        dispatch(addMachinesData(postMachine, closeModel))
    }



    const closeModel = () => {
        setMachineCode("")
        setMachineName("")
        setCategory("")
        setSubCategory("")
        setCompany("")
        setWarrentyStatus("")
        setBrand("")
        setCapacity("")
        setHeight("")
        setWidth("")
        setLength("")
        setWeight("")
        setMrp("")
        setRetailPrice("")
        setLocation("")
        setDescription("")
        setInstruction("")
        props.setMachineId("")
        props.handleClose();
    };

    return (
        <div
            show={props.show}
            onHide={props.handleClose}
        >
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className='machines-logo-div'>
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineDetails}>
                        <div className={`add-user-details-menu-numbers ${activeMenu === 1 ? 'active' : 'inactive'}`} >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name"> Machine Details</div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleAmcDetails}>
                        <div className="add-user-details-menus">
                            <div
                                // className="add-user-details-menu-numbers active"
                                className={`add-user-details-menu-numbers ${activeMenu === 2 ? 'active' : 'inactive'}`}
                            >
                                <p className="add-user-details-menu-number-one">
                                    <span>02</span>
                                </p>
                                <div className="add-user-details-menu-name">AMC Details</div>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineDimensions}>
                        <div className="add-user-details-menus">
                            <div
                                //  className="add-user-details-menu-numbers active"
                                className={`add-user-details-menu-numbers ${activeMenu === 3 ? 'active' : 'inactive'}`}
                            >
                                <p className="add-user-details-menu-number-one">
                                    <span>03</span>
                                </p>
                                <div className="add-user-details-menu-name">Dimensions</div>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineImages}>
                        <div className="add-user-details-menus">
                            <div
                                // className="add-user-details-menu-numbers active" 
                                className={`add-user-details-menu-numbers ${activeMenu === 4 ? 'active' : 'inactive'}`}
                            >
                                <p className="add-user-details-menu-number-one">
                                    <span>04</span>
                                </p>
                                <div className="add-user-details-menu-name">Images</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    {showMachineDetailsHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Machine Details</h4>
                            <p>Please enter machine infomation and proceed to the next step.</p>
                        </div>
                    </div>}
                    {amcDetailsHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={toggleMachineDetails} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Machine AMC</h4>
                            <p>Please enter amc infomation and proceed to the next step.</p>
                        </div>
                    </div>}
                    {showMachineDimensionHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={toggleAmcDetails} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Machine Dimension</h4>
                            <p>Please enter machine infomation and proceed to the next step.</p>
                        </div>
                    </div>}
                    {showMachineImageHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={toggleMachineDimensions} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Machine Images</h4>
                            <p>Please upload machine images and proceed to the next.</p>
                        </div>
                    </div>}
                    <div style={{ position: " relative" }} className="amc-single-image">

                        {showMachineDetailsForm && <form>
                            <div className="row">
                                <div className="col-lg-3 col">
                                    <div className="add-user-main-logo-image">
                                        <img
                                            className="add-user-images"
                                            src={localImage ? localImage : productimg1}
                                            // src={productimg1}
                                            alt="Profile Images"
                                        />
                                        <div>
                                            <button
                                                type="button"
                                                className="btn upload-logo"
                                                onClick={uploadClick}
                                            >
                                                <i className="bi bi-camera-fill" />
                                            </button>
                                            {localImage && (
                                                <div
                                                    className="delete-upload-logo"
                                                    onClick={deleteUploadLogo}
                                                >
                                                    <i className="fa fa-trash-o"></i>
                                                </div>
                                            )}
                                            <input
                                                id="getFile"
                                                type="file"
                                                name="filetobase64"
                                                accept="image/*"
                                                className="btn-primary upload"
                                                ref={hiddenFileInput}
                                                onChange={handleChange}
                                                style={{ display: "none" }}
                                            />
                                        </div>
                                    </div>
                                    <p style={{ fontSize: "10px", textAlign: "center" }}>
                                        Supports Only JPG, JPEG, PNG
                                    </p>
                                </div>
                                <div className="col-lg-9 col">
                                    <div>
                                        <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Machine Code/Number"
                                                        className={`${machineCode
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control add-user-input"
                                                            placeholder="Machine Code/Number"
                                                            name="productCode"
                                                            value={machineCode}
                                                            onChange={(e) => { setMachineCode(e.target.value) }}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Machine Name"
                                                        className={`${machineName
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control add-user-input"
                                                            placeholder="Machine Name"
                                                            value={machineName}
                                                            onChange={(e) => { setMachineName(e.target.value) }}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Select Category"
                                                        className={`${category.value !== ""
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            isSearchable={true}
                                                            placeholder="Select Category"
                                                            options={prodCategoryDrop}
                                                            value={category.value ? category : ""}
                                                            onInputChange={prodCategoryDrop.label}
                                                            onChange={(data) => handleDropDown(data, "category")}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Select Sub Category"
                                                        className={`${subCategory.value !== ""
                                                            ? "float-visible-select"
                                                            : "float-hidden float-select"
                                                            }`}
                                                    >
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            placeholder="Sub Category"
                                                            isSearchable={true}
                                                            options={prodSubcategory}
                                                            value={subCategory.value ? subCategory : ""}
                                                            onInputChange={prodSubcategory.label}
                                                            onChange={(data) => handleDropDown(data, "subCategory")}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="MRP"
                                                        className={`${mrp ? "float-input-visible" : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control add-user-input"
                                                            placeholder="MRP"
                                                            name="mrp"
                                                            value={mrp}
                                                            onChange={(e) => { setMrp(e.target.value) }}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Retail Price"
                                                        className={`${retailPrice ? "float-input-visible" : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control add-user-input"
                                                            placeholder="Retail Price"
                                                            name="price"
                                                            value={retailPrice}
                                                            onChange={(e) => { setRetailPrice(e.target.value) }}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row add-user-image-container-div">
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Instruction"
                                                        className={`${instruction
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <textarea
                                                            rows="3"
                                                            cols="20"
                                                            name="instruction"
                                                            wrap="hard"
                                                            className="form-control form-textarea add-product-input"
                                                            id="descriptionArea"
                                                            placeholder="Instruction"
                                                            value={instruction}
                                                            onChange={(e) => { setInstruction(e.target.value) }}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col">
                                                <div className="mb-3">
                                                    <FloatingLabel
                                                        label="Description"
                                                        className={`${description
                                                            ? "float-input-visible"
                                                            : "float-hidden float-input"
                                                            }`}
                                                    >
                                                        <textarea
                                                            rows="3"
                                                            cols="20"
                                                            name="description"
                                                            wrap="hard"
                                                            className="form-control form-textarea add-product-input"
                                                            id="descriptionArea"
                                                            placeholder="Description"
                                                            value={description}
                                                            onChange={(e) => { setDescription(e.target.value) }}
                                                        />
                                                    </FloatingLabel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='machines-next-arrow-button'>
                                <span class="material-symbols-outlined" onClick={toggleAmcDetails}>
                                    arrow_circle_right
                                </span>
                            </div>
                        </form>}

                        <div className="d-flex justify-content-between" style={{ padding: "0px 25px" }}>

                            <div className="col-lg-5 col">
                                {showAmcDetailsForm && <form>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Company"
                                            className={`${company.value !== ""
                                                ? "float-visible-select"
                                                : "float-hidden float-select"
                                                }`}
                                        >
                                            <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                placeholder="Company"
                                                isSearchable={true}
                                                options={formattedClientDropdown}
                                                value={company.value && company}
                                                onChange={(data) => { handleDropDown(data, "company") }}
                                                onInputChange={formattedClientDropdown.label}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Warrenty Status"
                                            className={`${warrentyStatue.value !== ""
                                                ? "float-visible-select"
                                                : "float-hidden float-select"
                                                }`}
                                        >
                                            <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                placeholder="Warrenty Status"
                                                isSearchable={true}
                                                options={warrentyStatus}
                                                value={warrentyStatue.value && warrentyStatue}
                                                onChange={(data) => { handleDropDown(data, "warrenty") }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3 d-flex align-items-center ">
                                        <div style={{ width: "100%" }}>
                                            <FloatingLabel
                                                label="AMC"
                                                className={`${amc.value !== ""
                                                    ? "float-visible-select"
                                                    : "float-hidden float-select"
                                                    }`}
                                            >
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="AMC"
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>
                                        </div>
                                        <div className="amc-add-icon">
                                            <span className="amc-plus-icon">
                                                <i className="fa fa-plus" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Brand"
                                            className={`${brand ? "float-input-visible" : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Brand"
                                                name="brand"
                                                value={brand}
                                                onChange={(e) => { setBrand(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Location"
                                            className={`${location
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <textarea
                                                rows="3"
                                                cols="20"
                                                name="instruction"
                                                wrap="hard"
                                                className="form-control form-textarea add-product-input"
                                                id="descriptionArea"
                                                placeholder="Location"
                                                value={location}
                                                readOnly
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className='machines-next-arrow-button'>
                                        <span class="material-symbols-outlined" onClick={toggleMachineDimensions}>
                                            arrow_circle_right
                                        </span>
                                    </div>
                                </form>}
                                {showMachineDimensionsForm && <form>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Length"
                                            className={`${length
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Length"
                                                name="length"
                                                value={length}
                                                onChange={(e) => { setLength(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Width"
                                            className={`${width ? "float-input-visible" : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Width"
                                                name="width"
                                                value={width}
                                                onChange={(e) => { setWidth(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Height"
                                            className={`${height
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Height"
                                                name="height"
                                                value={height}
                                                onChange={(e) => { setHeight(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Weight"
                                            className={`${weight
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Weight"
                                                name="weight"
                                                value={weight}
                                                onChange={(e) => { setWeight(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Capacity"
                                            className={`${capacity ? "float-input-visible" : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Capacity"
                                                name="capacity"
                                                value={capacity}
                                                onChange={(e) => { setCapacity(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className='machines-next-arrow-button'>
                                        <span class="material-symbols-outlined" onClick={toggleMachineImages}>
                                            arrow_circle_right
                                        </span>
                                    </div>
                                </form>}
                                {showMachineImagesForm && <form>
                                    <div className="mb-3">
                                        <button type="button" className="btn btn-add-machine-img">
                                            <span
                                                style={{
                                                    color: "#04A96D",
                                                    fontSize: "13px",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <div
                                                    className="d-flex justify-content-center"
                                                    onDragOver={(e) => e.preventDefault()}
                                                    onDrop={handleDrop}
                                                >
                                                    <>
                                                        {galleryImages.length !== 0 && (
                                                            <>
                                                                {galleryImages.map((data, i) => (
                                                                    <div className="product-image-box" key={i}>
                                                                        <img
                                                                            className="product-images"
                                                                            src={`${baseURL}/${data}`}
                                                                            alt="Spare Images"
                                                                        />
                                                                        <div
                                                                            className="delete-image"
                                                                            onClick={() => deleteGalImage(data)}
                                                                        >
                                                                            <i
                                                                                className="fa fa-times-circle delete-icon"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        )}
                                                    </>
                                                    <>
                                                        {localGallery &&
                                                            localGallery.map((data, i) => (
                                                                <div className="product-image-box" key={i}>
                                                                    <img
                                                                        className="product-images"
                                                                        src={
                                                                            localGallery
                                                                                ? URL.createObjectURL(data)
                                                                                : productimg1
                                                                        }
                                                                        alt="Spare Images"
                                                                    />
                                                                    <div
                                                                        className="delete-image"
                                                                        onClick={() => deleteImage(data)}
                                                                    >
                                                                        <i
                                                                            className="fa fa-times-circle delete-icon"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </>
                                                </div>

                                                {(galleryImages.length !== 0 ||
                                                    localGallery.length !== 0) &&
                                                    galleryImages.length + localGallery.length < 6 && (
                                                        <div className="drop-btn">
                                                            <p>You can Upload up to 6 Images</p>
                                                            <p>Supports Only JPG, JPEG, PNG</p>
                                                            <SbCancelBtn
                                                                btnName="Browse"
                                                                onClickEffect={uploadMultiple}
                                                            />
                                                        </div>
                                                    )}
                                                {galleryImages.length === 0 &&
                                                    localGallery.length === 0 && (
                                                        <div
                                                            className="drop-area"
                                                            onDragOver={(e) => e.preventDefault()}
                                                            onDrop={handleDrop}
                                                        >
                                                            <h6>Upload Product Images</h6>
                                                            <br />
                                                            <img
                                                                src="../../../images/DefaultImages/UploadImages.svg"
                                                                alt=""
                                                            />
                                                            <p>Drag and drop images here</p>
                                                            <p>OR</p>
                                                            <SbCancelBtn
                                                                btnName="Browse"
                                                                onClickEffect={uploadMultiple}
                                                            />
                                                            <p style={{ fontSize: "11px" }}>
                                                                Supports Only JPG, JPEG, PNG
                                                            </p>
                                                        </div>
                                                    )}
                                            </span>
                                        </button>
                                        <input
                                            id="getFile"
                                            type="file"
                                            multiple
                                            name="filetobase64"
                                            accept="image/*"
                                            className="btn-primary upload"
                                            style={{ display: "none" }}
                                            ref={multipleFileInput}
                                            onChange={handleChangeMultipleImg}
                                        />
                                    </div>
                                    {selectLength && (
                                        <small id={`Error`} className="form-text text-muted ">
                                            Please Select maximum 6 images
                                        </small>
                                    )}
                                    <div className="add-page-save-cancel">
                                        <SBSaveUpdateBtn
                                            btnName="Save"
                                            onClickEffect={addMachineData}
                                        />
                                        <SbCancelBtn
                                            btnName="Cancel"
                                        // onClickEffect={() => {
                                        //     props.handleClose();
                                        // }}
                                        />
                                    </div>

                                </form >}
                            </div>
                            {/* <AccordianPage
                            showMachineDetails={showMachineDetails}
                            showAmcDetails={showAmcDetails}
                            showMachineDimensions={showMachineDimensions}
                            activeKey={activeKey} /> */}

                            <div className="col-lg-6 col machine-details-cards">
                                <Accordion defaultActiveKey="0" activeKey={activeKey} flush>
                                    <Card>
                                        {showMachineDetails && (
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header >
                                                    Machine Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="amc-second-container">
                                                        <div className="amc-second-container-labels">
                                                            <span>
                                                                <p>Machine Code</p>
                                                                : <b>{machineCode}</b>
                                                            </span>
                                                            <span>
                                                                <p>Machine Name</p>
                                                                : <b>{machineName}</b>
                                                            </span>
                                                            <span>
                                                                <p>Category</p>
                                                                : <b>{category.label ?? "NA"}</b>
                                                            </span>
                                                            <span>
                                                                <p>SubCategory</p>
                                                                : <b>{subCategory.label ?? "NA"}</b>
                                                            </span>
                                                            <span>
                                                                <p>MRP</p>
                                                                : <b>{mrp}</b>
                                                            </span>
                                                            <span>
                                                                <p>Retail Price</p>
                                                                : <b>{retailPrice}</b>
                                                            </span>
                                                            <span>
                                                                <p>Description</p>
                                                                : <b>{description}</b>
                                                            </span>
                                                            <span>
                                                                <p>Instruction</p>
                                                                : <b>{instruction}</b>
                                                            </span>
                                                        </div>
                                                        <div >
                                                            <img
                                                                className="pan-card-image"
                                                                src={productimg1}
                                                                alt=""
                                                                style={{ height: "200px", width: "200px", padding: "5px" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                        {showAmcDetails && (
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header >
                                                    AMC Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="amc-second-container">
                                                        <div>
                                                            <div className="amc-second-container-labels">
                                                                <span>
                                                                    <p>Company</p>
                                                                    : <b>{company.label}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Warrenty Status</p>
                                                                    : <b>{warrentyStatue.label}</b>
                                                                </span>
                                                                <span>
                                                                    <p>AMC</p>
                                                                    : <b>{amc.label}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Brand</p>
                                                                    : <b>{brand}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Location</p>
                                                                    : <b>{location}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                        {showMachineDimensions && (
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    Machine Dimensions
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="amc-second-container">
                                                        <div>
                                                            <div className="amc-second-container-labels">
                                                                <span>
                                                                    <p>Length</p>
                                                                    : <b>{length}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Width</p>
                                                                    : <b>{width}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Height</p>
                                                                    : <b>{height}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Weight</p>
                                                                    : <b>{weight}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Capacity</p>
                                                                    : <b>{capacity}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                    </Card>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div >

            </div >
        </div >
    );
}

export default AmcDetails;