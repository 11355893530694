import React, { useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import Footer from "../Footer";
import "../../styles/CommonStyle.scss";
import "../../styles/TravelDesk.scss";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import uuid from "react-uuid";

const travelDeskOptions = [
  {
    id: uuid(),
    name: "Travel Request",
    description: "One-stop solution to manage online and offline travel.",
    icon: "luggage",
    // url: "travelrequest",
    url: "LocalTourRequest",
    active: true
  },
  {
    id: uuid(),
    name: "Travel Approval",
    description: "Approve, Reject and Modify travel plan.",
    icon: "fact_check",
    url: "travelapproval",
    active: true
  },
  {
    id: uuid(),
    name: "Travel Desk",
    description: "Get travel plan status & tickets confirmations.",
    icon: "help_center",
    url: "traveldesk",
    active: true
  },
  {
    id: uuid(),
    name: "Payments",
    description: "Reimbursements and payments done right.",
    icon: "payments",
    url: "travelpayments",
    active: true
  },
  {
    id: uuid(),
    name: "Expense",
    description: "End-to-end expense reporting automation.",
    icon: "folder_managed",
    url: "TravelExpenseDetailedVoucher",
    active: true
  },
  {
    id: uuid(),
    name: "Analytics",
    description: "Comprehensive travel and expense management analytics.",
    icon: "analytics",
    url: "travelrequest",
    active: false
  }
]

export default function TravelManagement() {

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  let navigate = useNavigate();

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}

        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="user-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">TRAVEL MANAGEMENT</h6>
                  </div>
                </Col>
                <Col md={9} className="cat-col"></Col>
              </Row>
            </div>
            {/* TRAVEL DESK MAIN PAGE */}
            <div className="travel-desk-page">
              <div className="travel-desk-page-body">
                <>
                  <div className="travel-card-row">
                    {travelDeskOptions.map((travelDeskList, i) => (
                      <div className="travel-card-col">
                        <Link
                          className={`card card-traveldesk ${!travelDeskList.active && "div-disabled"}`}
                          to={`/travelmanagement/${travelDeskList.url}`}
                        >
                          <div className="card-body">
                            <div className="card-icon">
                              <span className="material-symbols-outlined">
                                {travelDeskList.icon}
                              </span>
                            </div>
                            <div className="card-content">
                              <h4>{travelDeskList.name}</h4>
                              <p>{travelDeskList.description}</p>
                            </div>
                          </div>
                        </Link>

                      </div>
                    ))}
                  </div>
                </>
              </div>
            </div>
            {/* TRAVEL DESK MAIN PAGE */}
          </div>
          {/* MAIN BODY END */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
