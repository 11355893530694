import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Col, Row, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/ExpenseType.scss";
import SbAddBtn, { SBSaveUpdateBtn, SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import AccessDenied from "../../../AccessDenied";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const clientOptions = [
    { value: '1', label: 'Siva Balan P' },
    { value: '2', label: 'Dinesh L' },
    // ...other options
];

const reportedByOptions = [
    { value: '1', label: 'Siva Balan P' },
    { value: '2', label: 'Shrikant Rajendra Satav' },
    { value: '3', label: 'Valera Kalpen Krishnakant' },
    { value: '4', label: 'Somanagouda Patil' },
    { value: '5', label: 'Naresh P' },
    { value: '6', label: 'Srikanth S' },
    { value: '7', label: 'Vinayak Gudekar' },

];





// const defaultUser = clientOptions.find(option => option.label === 'Siva Balan P');
function ExpenseType() {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedVerifier, setSelectedVerifier] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState("manager");
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [myshowSaveButton, setmyShowSaveButton] = useState(true);
    const [myinnershowSaveButton, setmyinnerShowSaveButton] = useState(true);
    const [refresh, setRefresh] = useState(false);


    const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
    const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    const twoLevelClaim = loginDetails?.subscription?.twoLevelClaim;
    const loggedInRole = loginDetails?.desingation?.role?.roleID;
    const [filter, setFilter] = useState(false);
    const [filterUserId, setFilterUserId] = useState("");
    //* CLEAR SELECT START *//
    const clearSerachBySelect = () => {
        setFilterUserId("");

    };
    const [title, setTitle] = useState();
    const edithandleShow = () => {
        setTitle("Edit Expense Approver");

        setShow(true);
    };
    const userDropdown = [
        { value: 'hotel', label: 'Hotel' },
        { value: 'conveyance', label: 'Conveyance Charges' },
        { value: 'staff', label: 'Staff Welfare' },
        { value: 'sundry', label: 'Sundry Expense' }
    ];
    const accts = [
        { value: '1', label: '44343003-Lodging And Boarding' },
        { value: '2', label: '44343005-Tour Conveyance Charges' },
        { value: '3', label: '44370101-Staff Welfare' },
        { value: '4', label: '44343007-Tour Sundry Expenses' }
    ];
    const usrslst = [
        { value: 'usr1', label: 'Approver1' },
        { value: 'usr2', label: 'Approver2' },
        { value: 'usr3', label: 'Approver3' },
        { value: 'usr4', label: 'Approver4' }
    ];
    const myusrslst = [
        { value: 'user1', label: 'Approver1' },
        { value: 'user2', label: 'Approver2' },
        { value: 'user3', label: 'Approver3' },
        { value: 'user4', label: 'Approver4' }
    ];
    const [checked, setChecked] = useState(true); // Initialize checked to true to set the default checked state



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    };

    const [expenseType, setExpenseType] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);



    const handleChange = (selectedOption) => {
        if (!disableSelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const handlemyChange = (selectedOption) => {
        if (!disablemySelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const [disableSelectOnChange, setDisableSelectOnChange] = useState(false);
    const [disablemySelectOnChange, setDisablemySelectOnChange] = useState(false);


    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setRefresh((prev) => !prev);
    };

    const [selectedOption, setSelectedOption] = useState('actual');
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [showElements, setShowElements] = useState(true);
    const [selectedOptionchk, setSelectedOptionchk] = useState('Normal Item');


    const handleDelete = (index) => {
        const updatedTableData = tableData.filter((_, i) => i !== index);
        setTableData(updatedTableData);
        if (updatedTableData.length === 0) {
            setIsTableVisible(false); // Hide the table if there are no rows left
        }
    };
    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                <input className="mots-lst"
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null} // Prevent the default checkbox behavior
                />
                <label>{props.label}</label>
            </components.Option>
        );
    };

    const exportExcelClaim = () => {

    };

    const handleUserChange = (selectedOption) => {
        setSelectedUser(selectedOption);
    };

    const handleVerifierChange = (selectedOption) => {
        setSelectedVerifier(selectedOption);
    };

    const handleOptionChange = (e) => {
        setSelectedOptionchk(e.target.value);
        setSelectedOption(e.target.value);
        if (e.target.value === 'Normal Item') {
            setChecked(true);
        } else {
            setChecked(false);
        }
    };
    const adhandleShow = () => {
        // Your logic to add table data or show modal
        console.log("Adding table data or showing modal");
    };
    const handleClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShow(false);
    };
    const handleShow = () => {
        setTitle("Add Expense Approver");
        setShow(true);
        setShowElements(true);
        setShowSaveButton(true);
        setmyShowSaveButton(false);
        setmyinnerShowSaveButton(true);
        setDisableSelectOnChange(false);
        setDisablemySelectOnChange(false);
        setRefresh((prev) => !prev);

    };



    const handleAddButtonClick = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        if (selectedOption && ((selectedOption === 'verifier' && selectedVerifier) || (selectedOption === 'manager' && selectedUser))) {

            let newData = {}

            if (selectedOption === 'verifier' && selectedVerifier) {
                newData = {
                    name: selectedVerifier.label,
                    type: selectedOption,
                    priority: tableData.length + 1 // Set the priority as the next number in the sequence
                };
            } else if (selectedOption === 'manager' && selectedUser) {

                newData = {
                    name: selectedVerifier.label,
                    type: selectedOption,
                    priority: tableData.length + 1 // Set the priority as the next number in the sequence
                };
            }
            setTableData([...tableData, newData]);

            setIsTableVisible(true); // Show the table when a new row is added

            // Reset selections
            setSelectedUser(null);
            setSelectedVerifier(null);
            setSelectedOption('');

            // Trigger onClickEffect
            // Call your effect function here
        } else {
            // alert('Please select a user before proceeding.');
        }
    };

    console.log(tableData, "tableData")

    return (
        <div id="main-page">
            {userDetails !== null && userDetails !== "" ? (
                <>
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    <div className="page-wrapper">
                        <div className={`main-body ${toggleshift.style}`}>
                            <div className="page-content usrhrchy">
                                <Row className="search-row usrhrchy">
                                    <Col md={4} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToBackPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">EXPENSE APPROVER</h6>
                                        </div>
                                    </Col>
                                    <Col md={4} className="cat-col"></Col>
                                    <Col md={4} className="cat-col">
                                        <div
                                            className="search-bar-header"
                                            style={{ float: "right" }}
                                        >
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                <div
                                                    className="header-filter-btn"
                                                    onClick={() => setFilter(!filter)}
                                                >
                                                    <p className="card-head">
                                                        <i className="bi bi-sliders" />
                                                        &nbsp;&nbsp;Filters
                                                    </p>
                                                </div>
                                                <SbAddBtn
                                                    onClickEffect={handleShow}
                                                />
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                                {filter && (
                                    <div className="page-filter-section">
                                        <div style={{ width: "100%" }}>
                                            <Row className="filters-row">
                                                <Col md={3}>
                                                    <div className="clients-filter-input-boxs filter-box-div">

                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                padding: "0px 5px",
                                                            }}
                                                        >
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={userDropdown}
                                                                placeholder="Select User"
                                                                value={
                                                                    filterUserId === ""
                                                                        ? ""
                                                                        : userDropdown.value
                                                                }
                                                                onInputChange={userDropdown.label}
                                                                onChange={(data) =>
                                                                    setFilterUserId(data.value)
                                                                }
                                                                isSearchable={true}
                                                            />
                                                        </div>

                                                    </div>
                                                </Col>
                                                <Col md={9}>
                                                    <div className="clients-filter-input-boxs filter-box-div usrhrchy">
                                                        <SbCancelBtn
                                                            onClickEffect={clearSerachBySelect}
                                                            btnName="Clear"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                )}
                                <div className="usrhrchy table-responsive spares-scrollbar">
                                    <table className="table spares-table-data">
                                        <thead className="thead-light spares-thead">
                                            <tr>
                                                <th>Expense Approver</th>
                                                <th>GL Account</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[
                                                { name: 'Hotel', account: '44343003-Lodging And Boarding' },
                                                { name: 'Conveyance Charges', account: '44343005-Tour Conveyance Charges' },
                                                { name: 'Staff Welfare', account: '44370101-Staff Welfare' },
                                                { name: 'Sundry Expense', account: '44343007-Tour Sundry Expenses' }
                                            ].map((item, index) => (
                                                <tr key={index} className="spare-table-row-data">
                                                    <td>{item.name}</td>
                                                    <td>{item.account}</td>
                                                    <td className="myhrchy-dta">
                                                        <button className="btn spare-edit-link" onClick={() => edithandleShow(item)}>
                                                            <i className="fa fa-edit"></i> Edit
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="download-and-pagination">
                                        <SbDownloadBtn onClickEffect={exportExcelClaim} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />

                    <Modal
                        show={show}
                        onHide={handleClose}
                        className="main_model add-edit-model amc-model expensty expnse expmymodal">
                        <div className="add-edit-heading">
                            <h6 className="add-edit-head">{title}</h6>
                            <i className="fa fa-times" onClick={handleClose} />
                        </div>

                        <form>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">
                                    <Row className="levllst mb-3 mt-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={3} className="cat-col">
                                            <h6>Expense Approver :</h6>
                                        </Col>
                                        <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    className="react-input-container"
                                                    placeholder="Expense Approver"
                                                    value={expenseType}
                                                    onChange={(e) => setExpenseType(e.target.value)}
                                                    style={{ padding: "5px 10px", lineHeight: "normal", height: "35px" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={3} className="cat-col">
                                            <h6>GL Account :</h6>
                                        </Col>
                                        <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <FloatingLabel label="Select GL Account No / Name" className="float-hidden float-select">
                                                <Select
                                                    className="react-select-container-list"
                                                    classNamePrefix="react-select-list"
                                                    options={accts}
                                                    placeholder="Select GL Account No / Name"
                                                    value={accts.find(option => option.value === selectedAccount)}
                                                    onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={3} className="cat-col">

                                        </Col>
                                        <Col md={9} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <div className="usrhrchy-rdbtns">
                                                <div>
                                                    <Form.Check
                                                        inline
                                                        className="travelBy-radio-btn"
                                                        name="travelingBy"
                                                        type="radio"
                                                        label="Specific Item"
                                                        value="Specific Item"
                                                        checked={selectedOption === 'Specific Item'}
                                                        onChange={handleOptionChange}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        className="travelBy-radio-btn"
                                                        name="travelingBy"
                                                        type="radio"
                                                        label="Liquor"
                                                        value="Liquor"
                                                        checked={selectedOption === 'Liquor'}
                                                        onChange={handleOptionChange}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        className="travelBy-radio-btn"
                                                        name="travelingBy"
                                                        type="radio"
                                                        label="Normal Item"
                                                        value="Normal Item"
                                                        checked={selectedOptionchk === 'Normal Item'}
                                                        onChange={handleOptionChange}
                                                    />
                                                </div>

                                            </div>

                                        </Col>
                                        {(selectedOption === 'Specific Item' || selectedOption === 'Liquor') && (
                                            <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={3} className="cat-col">
                                                    <h6>Approver :</h6>
                                                </Col>
                                                <Col md={6} className="cat-col">
                                                    {selectedOption === 'Specific Item' && (
                                                        <Select
                                                            className="react-select-container-list"
                                                            classNamePrefix="react-select-list"
                                                            options={usrslst}
                                                            placeholder="Select Approver"
                                                            value={usrslst.find(option => option.value === selectedAccount)}
                                                            onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                    {selectedOption === 'Liquor' && (
                                                        <Select
                                                            className="react-select-container-list"
                                                            classNamePrefix="react-select-list"
                                                            options={myusrslst}
                                                            placeholder="Select Approver"
                                                            value={myusrslst.find(option => option.value === selectedAccount)}
                                                            onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                        {(selectedOptionchk === 'Normal Item') && (
                                            <Row className="levllst mb-2 mt-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={3} className="cat-col">
                                                    <h6>Approver :</h6>
                                                </Col>
                                                <Col md={6} className="cat-col">
                                                    <h6>User Hierarchy</h6>
                                                </Col>
                                            </Row>
                                        )}
                                    </Row>

                                </div>
                            </div>
                            <div className="popup-bottom-btn usrhrchy btns-save-cancel">
                                {showSaveButton && <SBSaveUpdateBtn btnName="Save" />}
                                {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}
                            </div>
                        </form>
                    </Modal>

                </>
            ) : (
                <AccessDenied />
            )
            }
        </div >
    );
}

export default ExpenseType;
