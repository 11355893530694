import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

function SignInOtpTimer({ expiryTimeInSeconds, onTimerExpire, showTimer, setExpireTime, getOtpForSuperAdmin }) {

    const [seconds, setSeconds] = useState(expiryTimeInSeconds);

    console.log(showTimer, "showTimer")

    useEffect(() => {
        if (expiryTimeInSeconds > 0) {
            const timer = setInterval(() => {
                setExpireTime((prevSeconds) => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(timer);
        } else {
            onTimerExpire();
        }
    }, [expiryTimeInSeconds, onTimerExpire]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const remainingSeconds = timeInSeconds % 60;
        const formattedTime =
            minutes.toString().padStart(2, '0') +
            ':' +
            remainingSeconds.toString().padStart(2, '0');
        return formattedTime;
    };

    return (
        // <div className='sign-in-resend-otp-button'>
        //      Didn't recieve code ?
        //     <Button className='sign-in-resend-otp' onClick={getOtpForSuperAdmin} disabled={showTimer}>&nbsp; Request again </Button>
        //     {showTimer && <span>{formatTime(seconds)}</span>}
        // </div>
        <div className='sign-in-resend-otp-button'>
            {showTimer ? (
                <span>OTP Valid till {formatTime(expiryTimeInSeconds)}</span>
            ) : (
                <>
                    <span>Didn't receive code?</span>
                    <Button className='sign-in-resend-otp' onClick={getOtpForSuperAdmin} disabled={showTimer}>
                        &nbsp; Request again
                    </Button>
                </>
            )}
        </div>
    );
}

export default SignInOtpTimer;