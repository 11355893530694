import React, { useEffect, useState } from "react";
import "../../../styles/BaseFare.scss";
import AddEditFormComponent from "../../AddEditFormComponent";
import { useNavigate } from "react-router-dom";
import {
  loadDepartmentDrop,
  loadDepasrtDesignation,
  loadUserDropdown,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import {
  policySetToOption,
  policyAppliesToOption,
  policyOptions,
  limitRestrictionOptions,
} from "../../DropdownsData";
import ExpenseSettingForm from "./ExpenseSettingForm";

export default function ExpensesLimitSettings(props) {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let editReimburseID = props.editReimburseID;
  let setReimburseID = props.reimburseID;

  let dispatch = useDispatch();
  const { departDesignation } = useSelector((state) => state.data);
  const { departmentDrop } = useSelector((state) => state.data);
  const { userDropdown } = useSelector((state) => state.data);

  const [motDepartment, setMotDepartment] = useState({ value: "", label: "" });
  const [designations, setDesignations] = useState([]);
  const [users, setUsers] = useState([]);
  const [localLimitToOptions, setLocalLimitToOptions] = useState([]);
  const [outStationLimitToOptions, setOutStationLimitToOptions] = useState([]);
  const [statusFlag, setStatusFlag] = useState(true);
  const [localPolicyApplyTo, setLocalPolicyApplyTo] = useState(1);
  const [OSPolicyApplyTo, setOSPolicyApplyTo] = useState(1);
  const [attachmentRequiredLocal, setAttachmentRequiredLocal] = useState(false);
  const [attachmentRequiredOs, setAttachmentRequiredOS] = useState(false);
  const [remarksRequiredLocal, setRemarksRequiredLocal] = useState(false);
  const [remarksRequiredOS, setRemarksRequiredOS] = useState(false);
  const [departmentID, setDepartmentID] = useState(null);

  const [warningMessage, setWarningMessage] = useState(
    "Your exceeding the limit. Please enter valid amount."
  );

  // #region set policy settin
  const setPolicyApplyTo = (value, type) => {
    if (type === "LOCAL") {
      setLocalPolicyApplyTo(value);
    } else if (type === "OUTSTATION") {
      setOSPolicyApplyTo(value);
    }
  };
  // #endregion

  // #region function for selecting local limit options
  const handleLocalPolicyBy = (event) => {
    if (event.target.value === "No Limit" && event.target.checked) {
      setLocalLimitToOptions([event.target.value]);
    } else if (!localLimitToOptions.includes("No Limit")) {
      if (event.target.checked) {
        setLocalLimitToOptions([...localLimitToOptions, event.target.value]);
      } else {
        setLocalLimitToOptions(
          localLimitToOptions.filter((data) => data !== event.target.value)
        );
      }
    } else if (event.target.value === "No Limit" && !event.target.checked) {
      //   alert("You have selected No Limit");
      setLocalLimitToOptions([]);
    } else {
      setLocalLimitToOptions(["No Limit"]);
    }
  };
  // #endregion

  // #region function for selecting outstation limit options
  const handleOutStationPolicyBy = (event) => {
    if (event.target.value === "No Limit" && event.target.checked) {
      setOutStationLimitToOptions([event.target.value]);
    } else if (!outStationLimitToOptions.includes("No Limit")) {
      if (event.target.checked) {
        setOutStationLimitToOptions([
          ...outStationLimitToOptions,
          event.target.value,
        ]);
      } else {
        setOutStationLimitToOptions(
          outStationLimitToOptions.filter((data) => data !== event.target.value)
        );
      }
    } else if (event.target.value === "No Limit" && !event.target.checked) {
      //   alert("You have selected No Limit");
      setOutStationLimitToOptions([]);
    } else {
      setOutStationLimitToOptions(["No Limit"]);
    }
  };
  // #endregion

  useEffect(() => {
    dispatch(loadDepartmentDrop());
    dispatch(loadDepasrtDesignation(departmentID?.value ?? 0));
    dispatch(loadUserDropdown());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentID]);

  const handleDesignation = (selectedlist) => {
    setDepartmentID(selectedlist);
  };

  const [postItemLocal, setPostItemLocal] = useState({
    customeClaimLimitID:
      editReimburseID !== "" && editReimburseID !== null
        ? Number(editReimburseID)
        : 0,
    subscriptionID: resPassData.subscriptionId,
    addedByID: resPassData.userId,
    addedByUserFullName: resPassData.fullName,
    status: 1,
    designationID: 0,
    departmentID: 0,
    DailyLimit: 0,
    WeeklyLimit: 0,
    MonthlyLimit: 0,
    othersCliaimLimit: 0,
    osLocalConvinienceCliaimLimit: 0,
    osFoodCliaimLimit: 0,
    osHotelCliaimLimit: 0,
    osOthersCliaimLimit: 0,
  });

  const [postItemOS, setPostItemOS] = useState({
    customeClaimLimitID:
      editReimburseID !== "" && editReimburseID !== null
        ? Number(editReimburseID)
        : 0,
    subscriptionID: resPassData.subscriptionId,
    addedByID: resPassData.userId,
    addedByUserFullName: resPassData.fullName,
    status: 1,
    designationID: 0,
    departmentID: 0,
    DailyLimit: 0,
    WeeklyLimit: 0,
    MonthlyLimit: 0,
    othersCliaimLimit: 0,
    osLocalConvinienceCliaimLimit: 0,
    osFoodCliaimLimit: 0,
    osHotelCliaimLimit: 0,
    osOthersCliaimLimit: 0,
  });

  const handleOSInput = (e) => {
    const { name, value } = e.target;
    if (value) {
      setPostItemOS({ ...postItemOS, [name]: +value });
    } else {
      setPostItemOS({ ...postItemOS, [name]: value });
    }
  };

  const handleLocalInput = (e) => {
    const { name, value } = e.target;
    if (value) {
      setPostItemLocal({ ...postItemLocal, [name]: +value });
    } else {
      setPostItemLocal({ ...postItemLocal, [name]: value });
    }
  };

  const handleSelectChange = (value, type) => {
    if (type === "designation") {
      setDesignations(value);
    } else if (type === "users") {
      setUsers(value);
    }
  };

  const habdleEnableStatus = (e) => {
    // if (e === 1) {
    //   setStatusFlag(true);
    //   postItem.status = 1;
    // } else if (e === 0) {
    //   setStatusFlag(false);
    //   postItem.status = 0;
    // }
  };

  const addCustomClaimLimit = () => {
    console.log("POSTING-DATA:");
  };

  const closeAddModal = () => {
    props.setReimburseID("");
    props.handleClose();
    props.setEditReimburseID("");
    props.setClickType("");
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <AddEditFormComponent
      handleClose={goToBackPage}
      formName={"Expense Settings"}
      formSubTitle={
        "Dedicated policy for a category that will override general expense rules"
      }
      childrens={
        <div className="add-edit-part">
          <form>
            <div className="container">
              <div className="expense-policy-settings">
                <div className="row">
                  <div className="col-md-6 p-0">
                    <ExpenseSettingForm
                      head="LOCAL"
                      motDepartment={motDepartment}
                      policyOptions={policyOptions}
                      handleSelectChange={handleSelectChange}
                      handleInputChange={handleLocalInput}
                      policySetToOption={policySetToOption}
                      handlePolicyBy={handleLocalPolicyBy}
                      limitRestrictionOptions={limitRestrictionOptions}
                      policyLimitToOptions={localLimitToOptions}
                      postItem={postItemLocal}
                      warningMessage={warningMessage}
                      setWarningMessage={setWarningMessage}
                      attachmentRequired={attachmentRequiredLocal}
                      setAttachmentRequired={setAttachmentRequiredLocal}
                      remarksRequired={remarksRequiredLocal}
                      setRemarksRequired={setRemarksRequiredLocal}
                      policyAppliesToOption={policyAppliesToOption}
                      policyApplyTo={localPolicyApplyTo}
                      setPolicyApplyTo={setPolicyApplyTo}
                      departmentDrop={departmentDrop}
                      departmentID={departmentID}
                      handleDesignation={handleDesignation}
                      departDesignation={departDesignation}
                      designations={designations}
                      userDropdown={userDropdown}
                      users={users}
                    />
                  </div>
                  <div className="col-md-6 p-0">
                    <ExpenseSettingForm
                      head="OUTSTATION"
                      motDepartment={motDepartment}
                      policyOptions={policyOptions}
                      handleSelectChange={handleSelectChange}
                      handleInputChange={handleOSInput}
                      policySetToOption={policySetToOption}
                      handlePolicyBy={handleOutStationPolicyBy}
                      limitRestrictionOptions={limitRestrictionOptions}
                      policyLimitToOptions={outStationLimitToOptions}
                      postItem={postItemOS}
                      warningMessage={warningMessage}
                      setWarningMessage={setWarningMessage}
                      attachmentRequired={attachmentRequiredOs}
                      setAttachmentRequired={setAttachmentRequiredOS}
                      remarksRequired={remarksRequiredOS}
                      setRemarksRequired={setRemarksRequiredOS}
                      policyAppliesToOption={policyAppliesToOption}
                      policyApplyTo={OSPolicyApplyTo}
                      setPolicyApplyTo={setPolicyApplyTo}
                      departmentDrop={departmentDrop}
                      departmentID={departmentID}
                      handleDesignation={handleDesignation}
                      departDesignation={departDesignation}
                      designations={designations}
                      userDropdown={userDropdown}
                      users={users}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  {setReimburseID && (
                    <span className="enable-status d-flex">
                      <h6>Enable</h6>
                      <span className="enable-radio d-flex">
                        <div className="form-check enable-radio-status">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={statusFlag}
                            onChange={() => habdleEnableStatus(1)}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check enable-radio-status">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={!statusFlag}
                            onChange={() => habdleEnableStatus(0)}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </form>
          <div className="btns-save-cancel">
            {!props.clickType && (
              <SBSaveUpdateBtn
                onClickEffect={addCustomClaimLimit}
                btnName={setReimburseID ? "Update" : "Save"}
              />
            )}
            <SbCancelBtn btnName="Cancel" onClickEffect={closeAddModal} />
          </div>
        </div>
      }
    />
  );
}
