import { useEffect, useState } from "react";

function MenuFormat(allSubcriptions) {
  const [menuItems, setMenuItems] = useState([]);
  const [submenuAtt, setSubmenuAtt] = useState([]);
  const [submenuClm, setSubmenuClm] = useState([]);
  const [submenuStg, setSubmenuStg] = useState([]);
  const [submenuCtnr, setSubmenuCtnr] = useState([]);
  const [submenuVhd, setSubmenuVhd] = useState([]);

  useEffect(() => {
    let activities = [];
    let subActAtt = [];
    let subActClm = [];
    let subActStng = [];
    let subActCntr = [];
    let subActVhd = [];
    if (allSubcriptions?.length !== 0) {
      allSubcriptions?.forEach((item) => {
        if (item.parentModuleName === null) {
          activities.push({
            name: item.moduleName,
            subNames: [],
            status: false,
            icon: item.moduleIcon,
          });
        } else if (item.parentModuleName === "Human Resource") {
          subActAtt.push({
            name: item.moduleName,
            status: false,
            icon: item.moduleIcon,
          });
        } else if (item.parentModuleName === "Claims") {
          subActClm.push({
            name: item.moduleName,
            status: false,
            icon: item.moduleIcon,
          });
        } else if (item.parentModuleName === "Settings") {
          subActStng.push({
            name: item.moduleName,
            status: false,
            icon: item.moduleIcon,
          });
        } else if (item.parentModuleName === "Connector") {
          subActCntr.push({
            name: item.moduleName,
            status: false,
            icon: item.moduleIcon,
          });
        } else if (item.parentModuleName === "VHD") {
          subActVhd.push({
            name: item.moduleName,
            status: false,
            icon: item.moduleIcon,
          });
        }
      });
    } else {
      activities.push({ name: "Dashboard", subNames: [] });
    }
    setMenuItems(activities);
    setSubmenuAtt(subActAtt);
    setSubmenuClm(subActClm);
    setSubmenuStg(subActStng);
    setSubmenuCtnr(subActCntr);
    setSubmenuVhd(subActVhd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (menuItems) {
      // let list = [...menuItems]
      menuItems.forEach((activities) => {
        if (activities.name === "Human Resource") {
          activities.subNames = submenuAtt;
        }
        if (activities.name === "Claims") {
          activities.subNames = submenuClm;
        }
        if (activities.name === "Settings") {
          activities.subNames = submenuStg;
        }
        if (activities.name === "Connector") {
          activities.subNames = submenuCtnr;
        }
        if (activities.name === "VHD") {
          activities.subNames = submenuVhd;
        }
      });
      // setMenuItems(list)
    }
  }, [menuItems, submenuAtt, submenuClm, submenuStg, submenuCtnr, submenuVhd]);
  return menuItems;
}

export default MenuFormat;
