export const approverTableData = [
  {
    setp_ID: "12728",
    step_Order: "46445",
    approver_Code: "HT-568",
    approver_Name: "Vinod Kumar",
    approver_Type: "Manager",
    subscriptionId: "0056",
    // status: "pending",
  },
];

export const paymentDetailsTableData = [
  {
    si_DocNum: "604",
    ap_DocNum: "952",
    invoice_Amount: "5900",
    remarks: "IT Infutech Inv 85/2024-25",
  },
  {
    si_DocNum: "604",
    ap_DocNum: "952",
    invoice_Amount: "5900",
    remarks: "IT Infutech Inv 85/2024-25",
  },
];

export const paymentStatusTableData = [
  {
    payment_No: "101",
    payment_Date: "27/08/2024",
    vendor_Code: "CFS/320/2024-25",
    vendor_Name: "Computer Exchange Pvt Ltd",
    inv_Amount: "5900",
    paid_Amount: "4900",
    pending_Amount: "1000",
    naration: "IT Infutech Inv 85/2024-25",
    status: "paid",
  },
  {
    payment_No: "101",
    payment_Date: "27/08/2024",
    vendor_Code: "CFS/320/2024-25",
    vendor_Name: "Computer Exchange Pvt Ltd",
    inv_Amount: "5900",
    paid_Amount: "4900",
    pending_Amount: "1000",
    naration: "IT Infutech Inv 85/2024-25",
    status: "paid",
  },
  {
    payment_No: "101",
    payment_Date: "27/08/2024",
    vendor_Code: "CFS/320/2024-25",
    vendor_Name: "Computer Exchange Pvt Ltd",
    inv_Amount: "5900",
    paid_Amount: "4900",
    pending_Amount: "1000",
    naration: "IT Infutech Inv 85/2024-25",
    status: "pending",
  },
  {
    payment_No: "101",
    payment_Date: "27/08/2024",
    vendor_Code: "CFS/320/2024-25",
    vendor_Name: "Computer Exchange Pvt Ltd",
    inv_Amount: "5900",
    paid_Amount: "4900",
    pending_Amount: "1000",
    naration: "IT Infutech Inv 85/2024-25",
    status: "pending",
  },
];

export const invDetailsTableData = [
  {
    inv_Doc_No: "101",
    invDoc_Date: "27/08/2024",
    po_DocEntry: "12728",
    poDoc_Date: "27/08/2024",
    vendor_Ref_No: "CFS/320/2024-25",
    vendor_Name: "Computer Exchange Pvt Ltd",
    inv_Amount: "5264",
    dept_status: "approved",
    fin_status: "pending",
  },
  {
    inv_Doc_No: "101",
    invDoc_Date: "27/08/2024",
    po_DocEntry: "12728",
    poDoc_Date: "27/08/2024",
    vendor_Ref_No: "CFS/320/2024-25",
    vendor_Name: "Computer Exchange Pvt Ltd",
    inv_Amount: "5264",
    dept_status: "approved",
    fin_status: "approved",
  },
  {
    inv_Doc_No: "101",
    invDoc_Date: "27/08/2024",
    po_DocEntry: "12728",
    poDoc_Date: "27/08/2024",
    vendor_Ref_No: "CFS/320/2024-25",
    vendor_Name: "Computer Exchange Pvt Ltd",
    inv_Amount: "5264",
    dept_status: "pending",
    fin_status: "pending",
  },
];

export const poDetailsTableData = [
  {
    line_No: "0",
    item_Name: "Transportation Charges",
    qty: "1",
    discount_Percent: "0%",
    unit_Rate: "3400",
    line_Total: "3400",
    tax_Code: "NCGSG5",
    tax_Percent: "5%",
    tax_Amount: 170,
    gross_Total: 3570,
    uom: "Manual",
    status: "accepted",
  },
  {
    line_No: "1",
    item_Name: "Transportation Charges",
    qty: "1",
    discount_Percent: "0%",
    unit_Rate: "3400",
    line_Total: "3400",
    tax_Code: "NCGSG5",
    tax_Percent: "5%",
    tax_Amount: 170,
    gross_Total: 3570,
    uom: "Manual",
    status: "cancelled",
  },
  {
    line_No: "2",
    item_Name: "Transportation Charges",
    qty: "1",
    discount_Percent: "0%",
    unit_Rate: "3400",
    line_Total: "3400",
    tax_Code: "NCGSG5",
    tax_Percent: "5%",
    tax_Amount: 170,
    gross_Total: 3570,
    uom: "Manual",
    status: "pending",
  },
  {
    line_No: "3",
    item_Name: "Transportation Charges",
    qty: "1",
    discount_Percent: "0%",
    unit_Rate: "3400",
    line_Total: "3400",
    tax_Code: "NCGSG5",
    tax_Percent: "5%",
    tax_Amount: 170,
    gross_Total: 3570,
    uom: "Manual",
    status: "pending",
  },
  {
    line_No: "4",
    item_Name: "Transportation Charges",
    qty: "1",
    discount_Percent: "0%",
    unit_Rate: "3400",
    line_Total: "3400",
    tax_Code: "NCGSG5",
    tax_Percent: "5%",
    tax_Amount: 170,
    gross_Total: 3570,
    uom: "Manual",
    status: "pending",
  },
  {
    line_No: "5",
    item_Name: "Transportation Charges",
    qty: "1",
    discount_Percent: "0%",
    unit_Rate: "3400",
    line_Total: "3400",
    tax_Code: "NCGSG5",
    tax_Percent: "5%",
    tax_Amount: 170,
    gross_Total: 3570,
    uom: "Manual",
    status: "pending",
  },
  {
    line_No: "6",
    item_Name: "Transportation Charges",
    qty: "1",
    discount_Percent: "0%",
    unit_Rate: "3400",
    line_Total: "3400",
    tax_Code: "NCGSG5",
    tax_Percent: "5%",
    tax_Amount: 170,
    gross_Total: 3570,
    uom: "Manual",
    status: "pending",
  },
];

export const poTableData = [
  {
    po_DocEntry: "12728",
    Doc_No: "101",
    date: "27/08/2024",
    vendor_Ref_No: "CFS/320/2024-25",
    vendor_Name: "Computer Exchange Pvt Ltd",
    ship_To:
      "1757/A, 34th Cross Banashankari 2nd Stage, Bangalore-560070 KT - IN",
    bill_To:
      "No.1442, 21st cross, 5th main7th sector HSR layout Bengaluru-560102 KT - IN",
    po_Value: 3740,
    tax_Amount: 170,
    doc_Total: 3910,
    remarks:
      "	Towards Cab chgs from 15th to 17th may 24 for Balasubramanian & Surendra Gupta",
    status: "pending",
  },
  {
    po_DocEntry: "12728",
    Doc_No: "101",
    date: "27/08/2024",
    vendor_Ref_No: "CFS/320/2024-25",
    vendor_Name: "Computer Exchange Pvt Ltd",
    ship_To:
      "1757/A, 34th Cross Banashankari 2nd Stage, Bangalore-560070 KT - IN",
    bill_To:
      "No.1442, 21st cross, 5th main7th sector HSR layout Bengaluru-560102 KT - IN",
    po_Value: 3740,
    tax_Amount: 170,
    doc_Total: 3910,
    remarks:
      "	Towards Cab chgs from 15th to 17th may 24 for Balasubramanian & Surendra Gupta",
    status: "accepted",
  },
];
export const vendorTableData = [
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V0468301",
    r1: 923,
    r2: 914,
    r3: 911,
    contactPerson: "Vinay Kumar",
    mobile: "9945134090	",
    email: "blr-bo@computerexchangeindia.com",
    location:
      "No.1441, 21st cross, 5th main7th sector HSR layout Bengaluru-560102 KT - IN.",
    status: "active",
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V0468301",
    r1: 923,
    r2: 914,
    r3: 911,
    contactPerson: "Vinay Kumar",
    mobile: "9945134090	",
    email: "blr-bo@computerexchangeindia.com",
    location:
      "No.1442, 21st cross, 5th main7th sector HSR layout Bengaluru-560102 KT - IN.",
    status: "inactive",
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V0468301",
    r1: 923,
    r2: 914,
    r3: 911,
    contactPerson: "Vinay Kumar",
    mobile: "9945134090	",
    email: "blr-bo@computerexchangeindia.com",
    location:
      "No.1443, 21st cross, 5th main7th sector HSR layout Bengaluru-560102 KT - IN.",
    status: "active",
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V0468301",
    r1: 923,
    r2: 914,
    r3: 911,
    contactPerson: "Vinay Kumar",
    mobile: "9945134090	",
    email: "blr-bo@computerexchangeindia.com",
    location:
      "No.1444, 21st cross, 5th main7th sector HSR layout Bengaluru-560102 KT - IN.",
    status: "active",
  },
];

export const branchTableData = [
  {
    name: "Karnataka",
    code: "01",
    poc: {
      name: "Dimpu",
      mobile: "08544545454",
      email: "dimpu@VhdBranch.com",
    },
    country: "India",
    zone: "South",
    state: "Karnataka",
    city: "Mysore",
    shortLocation:
      "#809, First Floor, Contour Road, Gokulam 3rd Stage 570002 A29,",
    location:
      "#809, First Floor, Contour Road,Gokulam 3rd Stage 570002 A29, FIEE Complex, Phase-II,Okhla Industrial Area, South Delhi, 110020 A-29, Phase-II, Okhla Industrial Area, South Delhi, FIEE Complex, 110020",
    status: "active",
  },
  {
    name: "Karnataka",
    code: "01",
    poc: {
      name: "Dimpu",
      mobile: "08544545454",
      email: "dimpu@VhdBranch.com",
    },
    country: "India",
    zone: "South",
    state: "Karnataka",
    city: "Mysore",
    shortLocation:
      "#809, First Floor, Contour Road, Gokulam 3rd Stage 570002 A29,",
    location:
      "#809, First Floor, Contour Road,Gokulam 3rd Stage 570002 A29, FIEE Complex, Phase-II,Okhla Industrial Area, South Delhi, 110020 A-29, Phase-II, Okhla Industrial Area, South Delhi, FIEE Complex, 110020",
    status: "inactive",
  },
];

export const dashboardTableData = [
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 3689.56,
    pending: 2236.22,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },

  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },

  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
  {
    name: "Computer Exchange Pvt Ltd",
    code: "V04683",
    po: 23,
    invoice: 14,
    payment: 4789.56,
    pending: 1236.23,
  },
];
