import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";

function CustomerFeedBackTable(props) {
    return (
        <div className="sb-table-div sb-table-setion">
            <Paper
                sx={{ width: "100%", overflow: "hidden" }}
                className="table-main-div"
            >
                <TableContainer
                    sx={{ maxHeight: 440 }}
                // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                >
                    <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="basefare-table"
                    >
                        <TableHead className="custom-table-header">
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    <TableCell
                                        key={i}
                                        align={name.align}
                                        className={`thead-cell-products`}
                                        style={{ minWidth: name.width }}
                                    >
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                            <TableBody>
                                {props.tableHeader.map((name, i) => (
                                    <SkeletonTableData key={i} />
                                ))}
                            </TableBody>
                        ) : (
                            <>
                                {props.customerFeedBack && props.customerFeedBack.length === 0 ? (
                                    <TableBody>
                                        <TableRow align="center">
                                            <TableCell
                                                align="center"
                                                colSpan={props.tableHeader.length}
                                            >
                                                <div style={{ padding: "20px 20px" }}>
                                                    <img
                                                        src="../../../../images/DefaultImages/NodataFound.jpg"
                                                        alt=""
                                                        style={{ width: "10%" }}
                                                    />
                                                    <br />
                                                    <span>No Data Found...!</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {props.customerFeedBack &&
                                            props.customerFeedBack.map((data, i) => (
                                                <TableRow
                                                    align="center"
                                                    key={i}
                                                    className="client-list-row-data"
                                                >


                                                    <TableCell>
                                                        <div onClick={props.viewFeedback} style={{ cursor: "pointer" }}>
                                                            <i className="fa fa-user mtdtls-icon" />
                                                            &nbsp;
                                                            {data.contactPerson}
                                                            <br />
                                                            <i className="fa fa-mobile mtdtls-icon" /> &nbsp;
                                                            {data.phone}
                                                            <br />
                                                            <i className="fa fa-envelope mtdtls-icon"></i> &nbsp;
                                                            {data.email}
                                                            <br />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {data.feedback}
                                                    </TableCell>
                                                    <TableCell>
                                                        {data.date}<br />
                                                        {data.time}
                                                    </TableCell>
                                                    <TableCell>
                                                        {data.productService}
                                                    </TableCell>
                                                    <TableCell>
                                                        {data.feedbackType}
                                                    </TableCell>
                                                    {/* <TableCell>
                                                        <button
                                                            className="btn client-history-link"
                                                            onClick={props.handleViewAttachments}
                                                        >
                                                            <i className="fa fa-eye"></i>&nbsp; View
                                                        </button>
                                                    </TableCell> */}
                                                </TableRow>
                                            ))}
                                        {/* {props.loading &&
                                              <OnScrollLoading />
                                          } */}
                                    </TableBody>
                                )}
                            </>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default CustomerFeedBackTable;
