import React, { useState, useEffect } from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/HotelAccomodationandTransitExpenses.scss";
import { SBSaveUpdateBtn, SbAddTransitExpensesBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import AccessDenied from "../../../AccessDenied";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";


// const defaultUser = clientOptions.find(option => option.label === 'Siva Balan P');
function HotelAccomodationandTransitExpenses() {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedVerifier, setSelectedVerifier] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [show, setShow] = useState(false);
    const [mydatashow, setmyDataShow] = useState(false);
    const [vchrshow, setvchrshow] = useState(false);
    const [activeTab, setActiveTab] = useState("manager");
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [myshowSaveButton, setmyShowSaveButton] = useState(true);
    const [myinnershowSaveButton, setmyinnerShowSaveButton] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedVoucher, setSelectedVoucher] = useState({});
    const [myselectedVoucher, setmysetSelectedVoucher] = useState({});



    const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
    const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    const twoLevelClaim = loginDetails?.subscription?.twoLevelClaim;
    const loggedInRole = loginDetails?.desingation?.role?.roleID;
    const [filter, setFilter] = useState(false);
    const [filterUserId, setFilterUserId] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [myshowForm, setmyShowForm] = useState(false);

    //* CLEAR SELECT START *//
    const clearSerachBySelect = () => {
        setFilterUserId("");

    };


    const [transitExpenses, setTransitExpenses] = useState([
        { id: 1, mode: 'Rail I - AC', withBill: 'At Actuals', withoutBill: '', actuals: true },
        { id: 2, mode: 'Rail II - AC', withBill: 'At Actuals', withoutBill: '', actuals: true },
        { id: 3, mode: 'Rail - Sleeper', withBill: 'At Actuals', withoutBill: '350', actuals: false },
    ]);

    const addTransitExpense = () => {
        setTransitExpenses([
            ...transitExpenses,
            { id: Date.now(), mode: '', withBill: '', withoutBill: '', actuals: true }
        ]);
    };

    const removeTransitExpense = (id) => {
        setTransitExpenses(transitExpenses.filter(expense => expense.id !== id));
    };

    const handleInputChange = (id, event) => {
        const { name, value } = event.target;
        setTransitExpenses(transitExpenses.map(expense =>
            expense.id === id ? { ...expense, [name]: value } : expense
        ));
    };

    const [expenses, setExpenses] = useState([
        { expenseType: 'Hotel', numUsers: 4, location: 'Mumbai', amount: 4000 },
    ]);

    const myuserDropdown = [
        { value: 'User 1', label: 'Car' },
        { value: 'User 2', label: 'Bus' },
        { value: 'User 3', label: 'Train' },
        { value: 'User 4', label: 'Flight' }
        // Add more objects as needed
    ];
    const handleMultiSelectChange = (selectedOptions) => {
        setFilterUserId(selectedOptions);
    };
    const userDropdown = [
        { value: 'level0', label: 'Level0' },
        { value: 'level1', label: 'Level1' },
        { value: 'level2', label: 'Level2' },
        { value: 'level3', label: 'Level3' },
        { value: 'level4', label: 'Level4' },
        { value: 'level5', label: 'Level5' },
        { value: 'level6', label: 'Level6' },
        { value: 'level7', label: 'Level7' },
        { value: 'level8', label: 'Level8' },
        { value: 'level9', label: 'Level9' }
    ];
    // State initialization and handling

    const handleAddExpense = () => {
        // Add new expense to the expenses array
        const newExpense = {
            expenseType: expenseType,
            numUsers: 0, // Placeholder for number of users
            location: '', // Placeholder for location
            amount: 0, // Placeholder for amount
        };
        setExpenses([...expenses, newExpense]);

        // Reset form fields
        setExpenseType(null);
        setStartDate(null);
        setEndDate(null);
        setSelectedAccount(null);

        // Hide the form after adding expense
        setShowForm(false);
    };
    const myhandleClose = () => {
        setShowForm(false);
    };
    const myshowhandleClose = () => {
        setmyShowForm(false);
    };
    const vchrhandleClose = () => {
        setvchrshow(false);
    };

    const accts = [
        { value: '1', label: '44343003-Lodging And Boarding' },
        { value: '2', label: '44343005-Tour Conveyance Charges' },
        { value: '3', label: '44370101-Staff Welfare' },
        { value: '4', label: '44343007-Tour Sundry Expenses' }
    ];
    const [fromDate, setFromDate] = useState(
        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    );


    const [toDate, setToDate] = useState(new Date());
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { userDropdownByManager } = useSelector((state) => state.data);
    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    };

    const [expenseType, setExpenseType] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);

    const setSelectSearchDrop = (data, type) => {

    };


    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const handleChange = (selectedOption) => {
        if (!disableSelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const handlemyChange = (selectedOption) => {
        if (!disablemySelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleShow();
    };
    const [disableSelectOnChange, setDisableSelectOnChange] = useState(false);
    const [disablemySelectOnChange, setDisablemySelectOnChange] = useState(false);


    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setRefresh((prev) => !prev);
    };

    const [selectedOption, setSelectedOption] = useState('actual');
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [showElements, setShowElements] = useState(true);


    const handleDelete = (index) => {
        const updatedTableData = tableData.filter((_, i) => i !== index);
        setTableData(updatedTableData);
        if (updatedTableData.length === 0) {
            setIsTableVisible(false); // Hide the table if there are no rows left
        }
    };

    const handleClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShow(false);
    };
    // const mydatahandleshow = (myselectedVoucher) => {
    //     setmysetSelectedVoucher(myselectedVoucher);
    //     setmyDataShow(true);
    // }
    // const myvchrhandleClose = () => {
    //     setmyDataShow(false);
    // };

    // const edithandleShow = (voucher) => {
    //     setSelectedVoucher(voucher);
    //     setvchrshow(true);
    // };
    const handleShow = () => {
        setShow(true);
    };


    // const handleShowForm = () => {
    //     setShowForm(true);
    //     // Toggle the form visibility or handle the form show logic here
    //     setRefresh(prevState => !prevState);
    // };
    // const myhandleShowForm = () => {
    //     setmyShowForm(true);
    //     // Toggle the form visibility or handle the form show logic here
    //     setRefresh(prevState => !prevState);
    // };

    console.log(tableData, "tableData")

    return (
        <div id="main-page">
            {userDetails !== null && userDetails !== "" ? (
                <>
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    <div className="page-wrapper">
                        <div className={`main-body ${toggleshift.style}`}>
                            <div className="page-content usrhrchy">
                                <Row className="search-row usrhrchy">
                                    <Col md={12} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToBackPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">LEVEL HOTEL ACCOMODATION AND TRANSIT EXPENSES</h6>
                                        </div>
                                    </Col>

                                </Row>
                                <div className="container-fluid">
                                    <form className="mt-4" onSubmit={handleFormSubmit}>
                                        <Row className="levllst mb-2">
                                            <Col md={4} className="cat-col">

                                                <h6>Level :</h6>
                                            </Col>
                                            <Col md={6} className="cat-col">
                                                <FloatingLabel label="Select Level" className="float-hidden float-select">
                                                    <Select
                                                        className="react-select-container-list"
                                                        classNamePrefix="react-select-list"
                                                        options={userDropdown}
                                                        placeholder="Select Level"
                                                        value={userDropdown.find(option => option.value === filterUserId)}
                                                        // onChange={(data) => setFilterUserId(data ? data.value : "")}
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>

                                            </Col>
                                        </Row>

                                        <Row className="levllst mb-2">
                                            <Col md={4} className="cat-col">
                                                <h6>Accommodation (arranged by employee) :</h6>
                                            </Col>
                                            <Col md={6} className="cat-col">
                                                {/* <FloatingLabel label='Accommodation' className="float-hidden float-input"> */}
                                                <Form.Control
                                                    type="text"
                                                    name="clientEmail"
                                                    className="form-control form add-input"
                                                    placeholder=""
                                                    style={{ padding: '6px 15px', height: '35px' }}
                                                // value={expenseType}
                                                // onChange={(e) => setExpenseType(e.target.value)}
                                                />
                                                {/* </FloatingLabel> */}
                                            </Col>
                                        </Row>

                                        <Row className="levllst mb-2 mt-4">
                                            <Col md={4} className="cat-col">
                                                <h6>Class of City :</h6>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <h6>A</h6>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <h6>B</h6>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <h6>C</h6>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <h6>D</h6>
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-4">
                                            <Col md={4} className="cat-col">

                                                <h6>Hotel Accomodation (Excl. Tax) :</h6>
                                            </Col>
                                            <Col md={2} className="cat-col ">
                                                <div className="chktrvdta">
                                                    <div>
                                                        <input type="radio" name="cityClassA" value="At Actuals" className="mb-4 trvabtn" />At Actuals
                                                    </div>
                                                    <div>
                                                        <input type="radio" name="cityClassA" value="At Actuals" className="trvabtn" />Max. Amount
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={2} className="cat-col ">
                                                <div className="chktrvdta">
                                                    <div>
                                                        <input type="radio" name="cityClassA" value="At Actuals" className="mb-4 trvabtn" />At Actuals
                                                    </div>
                                                    <div>
                                                        <input type="radio" name="cityClassA" value="At Actuals" className="trvabtn" />Max. Amount
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={2} className="cat-col ">
                                                <div className="chktrvdta">
                                                    <div>
                                                        <input type="radio" name="cityClassA" value="At Actuals" className="mb-4 trvabtn" />At Actuals
                                                    </div>
                                                    <div>
                                                        <input type="radio" name="cityClassA" value="At Actuals" className="trvabtn" />Max. Amount
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <div className="chktrvdta">
                                                    <div>
                                                        <input type="radio" name="cityClassA" value="At Actuals" className="mb-4 trvabtn" />At Actuals
                                                    </div>
                                                    <div>
                                                        <input type="radio" name="cityClassA" value="At Actuals" className="trvabtn" />Max. Amount
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="levllst mb-2">
                                            <Col md={4} className="cat-col">
                                                <h6>Single Occupancy :</h6>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2">
                                            <Col md={4} className="cat-col">
                                                <h6>Double Occupancy :</h6>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2">
                                            <Col md={12} className="cat-col mb-4 mt-4">
                                                <h6>Boarding</h6>
                                            </Col>
                                            <Col md={4} className="cat-col">
                                                <h6>With Bills :</h6>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2">
                                            <Col md={4} className="cat-col">

                                                <h6>Without Bills :</h6>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>

                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>

                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>

                                            </Col>
                                            <Col md={2} className="cat-col">
                                                <FloatingLabel label='' className="float-hidden float-input">
                                                    <Form.Control
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                    // value={expenseType}
                                                    // onChange={(e) => setExpenseType(e.target.value)}
                                                    />
                                                </FloatingLabel>

                                            </Col>
                                        </Row>
                                        <Row className="levllst mb-2 mt-4">
                                            <Col md={4} className="cat-col">
                                                <h6>Transit Expenses :</h6>
                                            </Col>
                                            <Col md={2} className="cat-col">
                                            </Col>
                                            <Col md={2} className="cat-col">
                                            </Col>
                                            <Col md={4} className="cat-col d-flex justify-content-end">
                                                <SbAddTransitExpensesBtn className='trvadbtn' onClickEffect={handleShow} />
                                            </Col>
                                        </Row>

                                        <div>
                                            <table className="thead-light spares-thead mytbusrhrchy mt-4">
                                                <thead className="my-tbhd mdtrvl">
                                                    <tr>
                                                        <th>Mode of Travel</th>
                                                        <th colSpan={2} style={{ textAlign: 'center' }}>Amount (with bill) / At Actuals Amount (without bill) / At Actuals</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transitExpenses.map(expense => (
                                                        <tr key={expense.id}>
                                                            <td>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="mode"
                                                                    className="form-control form add-input"
                                                                    value={expense.mode}
                                                                // onChange={(event) => handleInputChange(expense.id, event)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="withBill"
                                                                    className="form-control form add-input"
                                                                    value={expense.withBill}
                                                                // onChange={(event) => handleInputChange(expense.id, event)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="withoutBill"
                                                                    value={expense.withoutBill}
                                                                    className="form-control form add-input"
                                                                // onChange={(event) => handleInputChange(expense.id, event)}
                                                                />
                                                            </td>
                                                            <td>
                                                                {showElements && (
                                                                    <span className="material-symbols-outlined mybtnclos" onClick={() => handleDelete()}>
                                                                        Cancel
                                                                    </span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mb-4 mt-3">
                                            {showSaveButton && <SBSaveUpdateBtn btnName="Save" />}
                                            {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <Modal
                        show={show}
                        onHide={handleClose}
                        className="main_model add-edit-model amc-model travexpse htlacomd-modal"
                    >
                        <div className="add-edit-heading">
                            <h6 className="add-edit-head">Hotel Accomodation and Transit Expenses</h6>
                            <i className="fa fa-times" onClick={handleClose} />
                        </div>
                        <form>
                            <div className="container add-edit htl-cnt">
                                <div className="add-inputs usr-hrchy">
                                    <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={4} className="cat-col">
                                            <h6>Level :</h6>
                                        </Col>
                                        <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <FloatingLabel label='Select Level' className="float-hidden float-input">
                                                <Form.Control
                                                    type="email"
                                                    className="form-control form add-input"
                                                    placeholder="Select Level"
                                                    value={expenseType}
                                                    onChange={(e) => setExpenseType(e.target.value)}
                                                    readOnly
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>

                                    <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={4} className="cat-col">
                                            <h6>Mode of Transport :</h6>
                                        </Col>
                                        <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <FloatingLabel label='Select Mode of Transport' className="float-hidden float-select">
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={myuserDropdown}
                                                    placeholder="Select Mode of Transport"
                                                    value={myuserDropdown.find(option => option.value === selectedAccount)}
                                                    onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={12} className="cat-col text-center">
                                            <h6>Amount </h6>
                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-2 mt-4">
                                        <Col md={3} className="cat-col">

                                            <h6>With Bill :</h6>
                                        </Col>
                                        <Col md={9} className="cat-col ">
                                            <div className="chktrvdtaactcnt">
                                                <div className="chktrv-cnt">
                                                    <input type="checkbox" name="cityClassA" value="At Actuals" className="chkrtbx" />At Actuals
                                                </div>
                                                <div className="chktrvdtaactcnt">
                                                    <Form.Control
                                                        type="email"

                                                        className="form-control form add-input"
                                                        placeholder="Enter With Bill Amount"
                                                        value={expenseType}
                                                        onChange={(e) => setExpenseType(e.target.value)}
                                                        readOnly
                                                    />
                                                    <label>Rs.</label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-2 mt-4">
                                        <Col md={3} className="cat-col">

                                            <h6>Without Bill :</h6>
                                        </Col>
                                        <Col md={8} className="cat-col ">
                                            <div className="chktrvdtaactcnt">
                                                <div className="chktrv-cnt">
                                                    <input type="checkbox" name="cityClassA" value="At Actuals" className="chkrtbx" />At Actuals
                                                </div>
                                                <div className="chktrvdtaactcnt">
                                                    <Form.Control
                                                        type="email"

                                                        className="form-control form add-input"
                                                        placeholder="Enter Without Bill Amount"
                                                        value={expenseType}
                                                        onChange={(e) => setExpenseType(e.target.value)}
                                                        readOnly
                                                    />
                                                    <label>Rs.</label>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                                <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">

                                    {showSaveButton && <SBSaveUpdateBtn btnName="Add to List" />}
                                    {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}

                                </div>
                            </div>
                        </form>
                    </Modal>
                </>
            ) : (
                <AccessDenied />
            )
            }
        </div >
    );
}

export default HotelAccomodationandTransitExpenses;
