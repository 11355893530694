import React, { useState, useLayoutEffect } from "react";
import "../../../styles/CommonStyle.scss";
import "../../../styles/TravelDesk.scss";
import { Col, Row, Form, FloatingLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { loadAllUserDetails, loadClientDropDown } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import uuid from "react-uuid";
import { NumberValidation } from "../../ValidationFormat";
import { MultiSelect } from "react-multi-select-component";
import TripPlanPreview from "./TripPlanPreview";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  travelingOptionswithIcon,
  tripTypes,
} from "../../DropdownsData";
import { loadCityList } from "../../../redux/action";
import { calculateDaysDifference } from "../../FormattedAmount";

export default function TripPlanner(props) {
  let dispatch = useDispatch();
  const { clientDropdown } = useSelector((state) => state.data);
  const { allUserDetails } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);
  const allUsers = allUserDetails.map((option) => ({
    ...option,
    label: option.fullName,
    value: option.userId,
  }));

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });
  const [defaultTravellers, setDefaultTravellers] = useState(1);
  const [tripPlanPreview, setTripPlanPreview] = useState(false);
  const [advanceCash, setAdvanceCash] = useState(null);
  const [otherOption, setOtherOption] = useState("");
  const [travelByOptions, setTravelByOptions] = useState([]);
  const [clientNames, setClientNames] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [mobilenum, setMobileNum] = useState("");
  const [tripApproxAmount, setTripApproxAmount] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [selectedTravels, setSelectedTravels] = useState([]);
  const [showAlert, setShowAlert] = useState(false);


  const [travelDetails, setTravelDetails] = useState({
    Flight: [
      {
        id: uuid(),
        tripType: "One Way",
        fromLocation: "",
        toLocation: "",
        departureDateTime: "",
        returnDateTime: "",
        travellers: defaultTravellers,
        approxAmount: 0,
        travellersNames: [],
        clientName: "",
      },
    ],
    Train: [
      {
        id: uuid(),
        tripType: "One Way",
        fromLocation: "",
        toLocation: "",
        departureDateTime: "",
        returnDateTime: "",
        travellers: defaultTravellers,
        approxAmount: 0,
        travellersNames: [],
        clientName: "",
      },
    ],
    Bus: [
      {
        id: uuid(),
        tripType: "One Way",
        fromLocation: "",
        toLocation: "",
        departureDateTime: "",
        returnDateTime: "",
        travellers: defaultTravellers,
        approxAmount: 0,
        travellersNames: [],
        clientName: "",
      },
    ],
    Cab: [
      {
        id: uuid(),
        tripType: "One Way",
        fromLocation: "",
        toLocation: "",
        departureDateTime: "",
        returnDateTime: "",
        travellers: defaultTravellers,
        approxAmount: 0,
        travellersNames: [],
        clientName: "",
      },
    ],
    Vehicle: [
      {
        id: uuid(),
        tripType: "One Way",
        fromLocation: "",
        toLocation: "",
        departureDateTime: "",
        returnDateTime: "",
        travellers: defaultTravellers,
        approxAmount: 0,
        approxKM: 0,
        travellersNames: [],
        clientName: "",
      },
    ],
    Hotel: [
      {
        id: uuid(),
        location: "",
        checkInDate: "",
        checkOutDate: "",
        travellers: defaultTravellers,
        approxAmount: 0,
        travellersNames: [],
        clientName: "",
        rooms: "1",
      },
    ],
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadClientDropDown());
    dispatch(loadAllUserDetails());
    dispatch(loadCityList(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const convertDateTimeFormat = (flDate) => {
  //   if (flDate) {
  //     const [datePart, timePart] = flDate.split(" ");
  //     const [day, month, year] = datePart.split("/");
  //     const [hours, minutes] = timePart.split(":");
  //     if (timePart.includes("PM") && hours !== "12") {
  //       hours = String(Number(hours) + 12);
  //     }
  //     const formattedDate = new Date(year, month - 1, day, hours, minutes);
  //     return formattedDate;
  //   }
  // };

  // useEffect(() => {
  //   if (props.travelDetails) {
  //     let updatedDetails = { ...props.travelDetails }; // Create a copy of travelDetails
  //     for (let trlType of props.selectedTravels) {
  //       if (trlType !== "Hotel" && updatedDetails[trlType]) {
  //         updatedDetails[trlType].forEach((data, i) => {
  //           if (
  //             data.tripType == "Round"
  //               ? data.departureDateTime !== "" &&
  //                 typeof data.departureDateTime !== "object" &&
  //                 data.returnDateTime !== "" &&
  //                 typeof data.returnDateTime !== "object"
  //               : data.departureDateTime !== "" &&
  //                 typeof data.departureDateTime !== "object"
  //           ) {
  //             const updatedDepartureDateTime = convertDateTimeFormat(
  //               data.departureDateTime
  //             );

  //             const updatedReturnDateTime =
  //               data.tripType == "Round"
  //                 ? convertDateTimeFormat(data.returnDateTime)
  //                 : " ";
  //             updatedDetails[trlType][i].departureDateTime =
  //               updatedDepartureDateTime;
  //             updatedDetails[trlType][i].returnDateTime = updatedReturnDateTime;
  //           }
  //         });
  //       }
  //     }
  //     setTravelDetails(updatedDetails); // Update travelDetails state
  //   }
  // }, [props.travelDetails]);



  const handleTravelBy = (event, tName) => {
    if (event.target.checked) {
      setTravelByOptions([
        ...travelByOptions,
        {
          name: tName,
          value: event.target.value,
          icon: event.target.name,
          travel: 1,
        },
      ]);
      setSelectedTravels([...selectedTravels, tName]);
    } else {
      setTravelByOptions(
        travelByOptions.filter((data) => data.value !== event.target.value)
      );
      setSelectedTravels(selectedTravels.filter((data) => data !== tName));
    }
  };

  const addAnotherRow = (dataName, travelWay) => {
    let newObject = { ...travelDetails };
    let newData;
    if (
      dataName === "Flight" ||
      dataName === "Train" ||
      dataName === "Bus" ||
      dataName === "Vehicle" ||
      dataName === "Cab"
    ) {
      newData = newObject[dataName].push({
        tripType: travelWay,
        fromLocation: "",
        toLocation: "",
        departureDateTime: "",
        returnDateTime: "",
        travellers: defaultTravellers,
        approxAmount: 0,
        travellersNames: [],
        clientName: "",
        id: uuid(),
      });
    } else if (dataName === "Hotel") {
      newData = newObject[dataName].push({
        location: "",
        checkInDate: "",
        checkOutDate: "",
        travellers: defaultTravellers,
        approxAmount: 0,
        travellersNames: [],
        clientName: "",
        rooms: "1",
        id: uuid(),
      });
    }
    setTravelDetails(newObject);
  };

  const removeRowFromData = (uid, name) => {
    travelDetails[name] = travelDetails[name].filter((data) => data.id !== uid);
    setTravelDetails({ ...travelDetails });
  };

  const handleMultiSelect = (selectedList, type) => {
    if (type === "user") {
      setUserNames(selectedList);
    } else if (type === "client") {
      setClientNames(selectedList);
    }
    // setUserIDs(selectedList.map((d) => d.value));
    // setDefaultTravellers(props.travelBy !== 1 ? selectedList.length + 1 : 1);
    // setTravelDetails({ ...travelDetails });
  };

  const handleTripTypes = (value, name, tripopt) => {
    let newObject = { ...travelDetails };
    travelByOptions.filter((data) => {
      if (data.name === name) {
        data.travel = value;
      }
      return data;
    });
    travelDetails[name].forEach((data) => (data.tripType = tripopt));
    if (value !== 3) {
      newObject[name] = [travelDetails[name][0]];
    }
    setTravelDetails(newObject);
    setTravelByOptions([...travelByOptions]);
  };

  const handleDepartureDateTime = (dateTime, name, param, uid) => {
    travelDetails[name].filter((data) => {
      if (data.id === uid) {
        data[param] = dateTime;
      }
      return data;
    });
    setTravelByOptions([...travelByOptions]);
  };

  const handleAutoInputChange = (input) => {
    const filteredOptions = cityList.filter((option) =>
      option.label.toLowerCase().startsWith(input.toLowerCase())
    );
    return filteredOptions;
  };

  const setSelectedLocation = (value, name, uid, param) => {
    if (value) {
      setShowAlert(false);
      travelDetails[name].filter((data) => {
        if (data.id === uid) {
          data[param] = value;
        }
        return data;
      });
    }
    setTravelDetails({ ...travelDetails });
  };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleTravellerDetails = (selectedList, uid, param, name) => {
    if (selectedList) {
      travelDetails[name].filter((data) => {
        if (data.id === uid) {
          data[param] = selectedList;
        }
        return data;
      });
    }

    let selectedArray = []
    if (selectedUsers.length !== 0) {
      selectedArray = selectedUsers.filter((item) => item.uid !== uid);
    } else {
      selectedArray = [];
    }
    if (selectedList.length !== 0) {
      selectedArray.push({ uid: uid, userList: selectedList })
    }
    // selectedList.forEach(user => {
    //   const userExists = selectedUsers.some(selectedUser => selectedUser.userId === user.userId);
    //   if (!userExists) {
    //     selectedArray.push({
    //         userId: user.userId,
    //         fullName: user.fullName
    //     });
    // }
    // });
    setSelectedUsers(selectedArray)
    setTravelDetails({ ...travelDetails });
  };


  function mergeAndDeduplicateUserLists(data) {
    const allUsers = [];

    // Collect all users from all userLists
    data.forEach(obj => {
      allUsers.push(...obj.userList);
    });

    // Create a map to remove duplicates
    const userMap = new Map();

    allUsers.forEach(user => {
      userMap.set(user.userId, user);
    });

    // Convert map values to an array
    return Array.from(userMap.values());
  }

  const mergedUserList = mergeAndDeduplicateUserLists(selectedUsers);

  const handleTravelDetails = (uid, event, name) => {
    travelDetails[name].filter((data) => {
      if (data.id === uid) {
        if (event.target.name === "travellers" || event.target.name === "rooms") {
          if (
            props.travelBy !== 1
              ? event.target.value <= defaultTravellers &&
              event.target.value > 0
              : event.target.value === 1
          ) {
            data[event.target.name] = event.target.value;
          } else if (props.travelBy === 1) {
            data[event.target.name] = 1;
          } else {
            toast.error(
              `Please enter correct number ${name} of ${event.target.name}, because your team size is ${defaultTravellers} `
            );
            data[event.target.name] = defaultTravellers;
          }
        } else {
          data[event.target.name] = event.target.value;
        }

        // if (data["travellers"]) {
        //   data["rooms"] = Math.round(event.target.value / 2);
        // }
        // return;
      }
      return data;
    });
    setTravelDetails({ ...travelDetails });
  };

  // const validateData = (travelDetails, selectedVals) => {
  //   let stateValue = true;
  //   for (const key of selectedVals) {
  //     if (travelDetails.hasOwnProperty(key)) {
  //       const array = travelDetails[key];

  //       for (const item of array) {
  //         let isValid = true;
  //         if (
  //           key === "Flight" ||
  //           key === "Train" ||
  //           key === "Bus" ||
  //           key === "Cab"
  //         ) {
  //           if (
  //             item.tripType === "" ||
  //             item.fromLocation === "" ||
  //             item.toLocation === "" ||
  //             item.departureDateTime === "" ||
  //             item.travellers === 0 ||
  //             item.travellers > defaultTravellers ||
  //             (item.tripType === "Round" && item.returnDateTime === "")
  //           ) {
  //             isValid = false;
  //           }
  //         } else if (key === "Hotel") {
  //           if (
  //             item.location === "" ||
  //             item.checkInDate === "" ||
  //             item.checkOutDate === "" ||
  //             item.travellers === 0 ||
  //             item.travellers > defaultTravellers ||
  //             item.rooms === ""
  //           ) {
  //             isValid = false;
  //           }
  //         }

  //         if (!isValid) {
  //           stateValue = false;
  //           break;
  //         }
  //       }

  //       if (!stateValue) {
  //         break;
  //       }
  //     } else {
  //       toast.error(`Some field values is missing in ${key}`);
  //       stateValue = false;
  //       break;
  //     }
  //   }
  //   return stateValue;
  // };

  const goToNextTripPlannet = (e) => {
    e.preventDefault();

    let filteredMot = Object.keys(travelDetails)
      .filter(key => selectedTravels.includes(key))
      .reduce((obj, key) => {
        obj[key] = travelDetails[key];
        return obj;
      }, {});
    let keysWithEmptyOtherFields = [];

    for (let key in filteredMot) {
      if (filteredMot[key].some(entry => {
        // Check for empty values except clientName and approxAmount
        return Object.keys(entry).some(field => field !== "clientName" && field !== "returnDateTime" && field !== "approxAmount" && entry[field] === "");
      })) {
        keysWithEmptyOtherFields.push(key);
      }
    }

    if (keysWithEmptyOtherFields.length !== 0) {
      toast.error("Plese enter required field for selected MOT");
      return;
    }
    setTripPlanPreview(true);
    // if (
    //   clientNames &&
    //   mobilenum &&
    //   (props.travelBy !== 1 ? userNames.length > 0 : userNames.length == 0) &&
    //   travelByOptions.length > 0 &&
    //   remarks
    // ) {
    //   let selectedVals = [];
    //   for (const travels of travelByOptions) {
    //     if (travels.name !== "Advance Cash" && travels.name !== "Other") {
    //       selectedVals.push(travels.name);
    //     }
    //     let stateVal = validateData(travelDetails, selectedVals);
    //     if (stateVal) {
    //       setTripPlanPreview(true);
    //     } else {
    //       setTripPlanPreview(false);
    //       setFieldReq(true);
    //       setAlertMessage({
    //         alertType: "fail",
    //         message: "Required",
    //       });
    //     }
    //   }
    // } else {
    //   setFieldReq(true);
    //   setAlertMessage({
    //     alertType: "fail",
    //     message: "Required",
    //   });
    // }
  };

  return (
    <div>
      {/* TRAVEL PLAN DATE AND TRAVEL BY SELECTION */}
      {props.travelPlanShow && !tripPlanPreview ? (
        <div className="full-client-travel-details">
          <div className="page-header">
            <div
              className="travel-next-back-step dflex-j-start"
              onClick={() => props.setTravelPlanShow(false)}
            >
              <span className="material-symbols-outlined">arrow_left</span>
              <span>Back</span>
            </div>
            <h4>TRAVEL PLAN</h4>
            <div
              className="travel-next-back-step dflex-j-start"
              onClick={goToNextTripPlannet}
            >
              <span>Next</span>
              <span className="material-symbols-outlined">arrow_right</span>
            </div>
          </div>
          <Form>
            <div className="planner-details-n-client-details">
              <Row>
                <Col md={6} className="br-1">
                  <div className="select-team-client-details">
                    <div className="title-n-input-field">
                      <div className="title-text planner-name">
                        <span>Planner Name : </span>&nbsp;&nbsp;
                        <span>
                          <b>Dimpu Kumar</b>
                        </span>
                      </div>
                      <div className="title-text p-2">
                        <span>Travel Type : </span>&nbsp;&nbsp;
                        <span>
                          <b>
                            {props.travelBy === 1
                              ? "Single"
                              : props.travelBy === 2
                                ? "Team"
                                : "Partial Team"}
                          </b>
                        </span>
                      </div>
                      <div className="title-text p-2">
                        <span>No. Of Days : </span>&nbsp;&nbsp;
                        <span>
                          <b>
                            {`${calculateDaysDifference(
                              props.fromDate,
                              props.toDate
                            )}-Days`}
                          </b>
                        </span>
                      </div>
                    </div>
                    {props.travelBy !== 1 ? (
                      <>
                        <div className="title-n-input-field">
                          <div className="title-text">
                            <div className="head-text">
                              Team Details : {mergedUserList && <>({mergedUserList.length}) <p>{mergedUserList.map(user => {
                                return user.fullName + ',';
                              })}</p></>}
                            </div>
                            <div className="body-text">
                              {userNames.map(
                                (data) => data.label + ", "
                              ) ?? ""}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      " "
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="select-team-client-details">
                    <div className="title-n-input-field">
                      <FloatingLabel
                        label="Company Name"
                        className={`${clientNames
                          ? "float-visible-select"
                          : "float-hidden float-select"
                          }`}
                      >
                        <MultiSelect
                          className="select-add-user-model"
                          options={clientDropdown}
                          value={clientNames}
                          onChange={(data) => handleMultiSelect(data, "client")}
                          labelledBy={"Select Company"}
                          overrideStrings={{
                            selectSomeItems: "Select Company",
                          }}
                        />
                      </FloatingLabel>
                      {fieldReq &&
                        (clientNames.length === 0 || clientNames == null) ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          Required !
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="title-n-input-field">
                      <FloatingLabel
                        label="Trip Approx. Amount"
                        className={`${tripApproxAmount !== null
                          ? "float-input-visible"
                          : "float-hidden float-input"
                          }`}
                      >
                        <input
                          type="number"
                          autoComplete="off"
                          className="form-control"
                          placeholder="Trip Approx. Amount"
                          value={tripApproxAmount}
                          onChange={(e) => setTripApproxAmount(e.target.value)}
                          onKeyDown={NumberValidation}
                          required
                        />
                      </FloatingLabel>
                      {fieldReq && (mobilenum === "" || mobilenum == null) ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          Required !
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="title-n-input-field remarks-part">
                      <FloatingLabel
                        label="Remarks"
                        className={`${remarks
                          ? "float-input-visible"
                          : "float-hidden float-input"
                          }`}
                      >
                        <textarea
                          rows="3"
                          cols="20"
                          name="usrtxt"
                          wrap="hard"
                          className="form-control form-textarea add-user-input"
                          placeholder="Enter Remarks"
                          value={remarks}
                          onChange={(e) => setRemarks(e.target.value)}
                        />
                      </FloatingLabel>
                      {fieldReq && (remarks === "" || remarks == null) ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          Required !
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="selcting-modeoftravel-with-icon">

                <div className="traveling-selection">
                  <h6><b>MOT</b></h6>
                  <div className="checkbox-selction">
                    {travelingOptionswithIcon.map((item, i) => (
                      <>{item.type === "mot" &&
                        <div key={i} className="travel-checkboxes">
                          <Form.Check
                            inline
                            type="checkbox"
                            id={`inlinecheckbox-${item?.label}-${i}`}
                            className="travelOption-checkbox"
                          // label={item?.label}
                          // value={item?.value}
                          // name={item?.icon}
                          // onChange={(e) => handleTravelBy(e, item.label)}
                          >
                            <Form.Check.Input
                              className="travel-checkbox-input"
                              type="checkbox"
                              label={item?.label}
                              value={item?.value}
                              name={item?.icon}
                              onChange={(e) => handleTravelBy(e, item.label)}
                              checked={selectedTravels.includes(item.label)}
                            />
                            <Form.Check.Label className="chekbox-n-text-n-icon">
                              <div className="text-icon">
                                <span className="material-symbols-outlined">
                                  {item?.icon}
                                </span>
                              </div>
                              <div className="checkbox-text">{item?.label === "Vehicle" ? "Own Bike/Car" : item?.label}</div>
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      }</>
                    ))}
                  </div>
                  {fieldReq && travelByOptions.length === 0 ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      Required !
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="selcting-modeoftravel-with-icon">
                <div className="traveling-selection">
                  <h6><b>Hotel details</b></h6>
                  <div className="checkbox-selction">
                    {travelingOptionswithIcon.map((item, i) => (
                      <>{item.type === "stay" &&
                        <div key={i} className="travel-checkboxes">
                          <Form.Check
                            inline
                            type="checkbox"
                            id={`inlinecheckbox-${item?.label}-${i}`}
                            className="travelOption-checkbox"
                          // label={item?.label}
                          // value={item?.value}
                          // name={item?.icon}
                          // onChange={(e) => handleTravelBy(e, item.label)}
                          >
                            <Form.Check.Input
                              className="travel-checkbox-input"
                              type="checkbox"
                              label={item?.label}
                              value={item?.value}
                              name={item?.icon}
                              onChange={(e) => handleTravelBy(e, item.label)}
                              checked={selectedTravels.includes(item.label)}
                            />
                            <Form.Check.Label className="chekbox-n-text-n-icon">
                              <div className="text-icon">
                                <span className="material-symbols-outlined">
                                  {item?.icon}
                                </span>
                              </div>
                              <div className="checkbox-text">{item?.label}</div>
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      }</>
                    ))}
                  </div>
                  {fieldReq && travelByOptions.length === 0 ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      Required !
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="selcting-modeoftravel-with-icon">
                <div className="traveling-selection">
                  <h6><b>Other Expenses</b></h6>
                  <div className="checkbox-selction">
                    {travelingOptionswithIcon.map((item, i) => (
                      <>{item.type === "other" &&
                        <div key={i} className="travel-checkboxes">
                          <Form.Check
                            inline
                            type="checkbox"
                            id={`inlinecheckbox-${item?.label}-${i}`}
                            className="travelOption-checkbox"
                          // label={item?.label}
                          // value={item?.value}
                          // name={item?.icon}
                          // onChange={(e) => handleTravelBy(e, item.label)}
                          >
                            <Form.Check.Input
                              className="travel-checkbox-input"
                              type="checkbox"
                              label={item?.label}
                              value={item?.value}
                              name={item?.icon}
                              onChange={(e) => handleTravelBy(e, item.label)}
                              checked={selectedTravels.includes(item.label)}
                            />
                            <Form.Check.Label className="chekbox-n-text-n-icon">
                              <div className="text-icon">
                                <span className="material-symbols-outlined">
                                  {item?.icon}
                                </span>
                              </div>
                              <div className="checkbox-text">{item?.label}</div>
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      }</>
                    ))}
                  </div>
                  {fieldReq && travelByOptions.length === 0 ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      Required !
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>

            </div>
            <Row>
              <Col md={12}>
                <div className="fill-booking-details">
                  <div className="heading">
                    <h6>
                      Date : {moment(props.fromDate).format("DD/MM/YYYY")} to{" "}
                      {moment(props.toDate).format("DD/MM/YYYY")}
                    </h6>
                  </div>
                  <div className="travel-book-by-selections">
                    {travelByOptions.map((data, i) => (
                      <div className="traveltype-details" key={i}>
                        <div
                          className={`travel-icon-with-text-n-type ${data.name}`}
                        >
                          <div className="travel-icon">
                            <span className="material-symbols-outlined">
                              {data.icon}
                            </span>
                          </div>
                          <div className="travel-type">
                            <h6>{data.name === "Vehicle" ? "Own Bike/Car" : data.name}</h6>
                          </div>
                          {data.name === "Flight" ||
                            data.name === "Train" ||
                            data.name === "Bus" ||
                            data.name === "Vehicle" ||
                            data.name === "Cab" ? (
                            <div className="trip-type dflex-j-start">
                              {tripTypes.map((item, i) => (
                                <div key={i}>
                                  <Form.Check
                                    inline
                                    label={item?.label}
                                    value={item.value}
                                    className="triptype-radio-btn"
                                    name={`${data.name}-tripTypeOption`}
                                    type="radio"
                                    id={`inlineradio-triptype-${data.name}-${i}}`}
                                    onChange={(e) =>
                                      handleTripTypes(
                                        +e.target.value,
                                        data.name,
                                        item.label
                                      )
                                    }
                                    checked={
                                      data.travel === item.value ? true : false
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          ) : data.name === "Advance Cash" ? (
                            <>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Amount"
                                name="advance-cash"
                                value={advanceCash}
                                onChange={(e) => setAdvanceCash(e.target.value)}
                              // required
                              />

                              {fieldReq &&
                                (advanceCash == null || advanceCash <= 0) ? (
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  className="form-text text-muted "
                                >
                                  {advanceCash == null
                                    ? "Required"
                                    : "Please enter valid amount"}
                                </small>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            data.name === "Others" && (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Others"
                                  name="Others"
                                  value={otherOption}
                                  onChange={(e) =>
                                    setOtherOption(e.target.value)
                                  }
                                // required
                                />
                                {fieldReq &&
                                  (otherOption == null || otherOption === "") ? (
                                  <small
                                    id={`Error${alertMessage.alertType}`}
                                    className="form-text text-muted "
                                  >
                                    Required
                                  </small>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          )}
                        </div>
                        {data.name !== "Advance Cash" &&
                          data.name !== "Others" &&
                          data.name !== "Vehicle" &&
                          data.name !== "Hotel" ? (
                          <div className="travel-from-to-qty-datetime">
                            {travelDetails[data.name].map((trldata, i) => (
                              <div key={trldata.id} className="journey-row">
                                <div className="input-select-boxes-flex">
                                  <div
                                    className="inputs-by-triptype"
                                    style={{ width: "20%" }}
                                  >
                                    <FloatingLabel
                                      label="From"
                                      className={`${trldata.fromLocation
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                        }`}
                                    >
                                      <Typeahead
                                        id="autosuggest-example"
                                        name="fromLocation"
                                        options={cityList}
                                        selected={trldata.fromLocation}
                                        className="custom-auto-select"
                                        onChange={(value) =>
                                          setSelectedLocation(
                                            value,
                                            data.name,
                                            trldata.id,
                                            "fromLocation"
                                          )
                                        }
                                        onInputChange={handleAutoInputChange}
                                        placeholder="From"
                                        disabled={props.clickType}
                                        allowNew
                                      />
                                    </FloatingLabel>
                                    {fieldReq &&
                                      (trldata.fromLocation == null ||
                                        trldata.fromLocation === "") ? (
                                      <small
                                        id={`Error${alertMessage.alertType}`}
                                        className="form-text text-muted "
                                      >
                                        Required !
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className="inputs-by-triptype"
                                    style={{ width: "20%" }}
                                  >
                                    <FloatingLabel
                                      label="To"
                                      className={`${trldata.toLocation
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                        }`}
                                    >
                                      <Typeahead
                                        id="autosuggest-example"
                                        name="toLocation"
                                        options={cityList}
                                        selected={trldata.toLocation}
                                        className="custom-auto-select"
                                        onChange={(value) =>
                                          setSelectedLocation(
                                            value,
                                            data.name,
                                            trldata.id,
                                            "toLocation"
                                          )
                                        }
                                        onInputChange={handleAutoInputChange}
                                        placeholder="To"
                                        disabled={props.clickType}
                                        allowNew
                                      />
                                    </FloatingLabel>
                                    {fieldReq &&
                                      (trldata.toLocation == null ||
                                        trldata.toLocation === "") ? (
                                      <small
                                        id={`Error${alertMessage.alertType}`}
                                        className="form-text text-muted "
                                      >
                                        Required !
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div
                                    className="inputs-by-triptype"
                                    style={{ width: "20%" }}
                                  >
                                    <div className="react-date-time-picker">
                                      <FloatingLabel
                                        label="Departure"
                                        className={`${trldata.departureDateTime
                                          ? "float-visible-select"
                                          : "float-hidden float-select"
                                          }`}
                                      >
                                        <DatePicker
                                          selected={trldata.departureDateTime}
                                          className="select date-time-picker"
                                          id="date-time-picker"
                                          showTimeSelect
                                          dateFormat="dd/MM/yyyy h:mm aa"
                                          name="departureDateTime"
                                          minDate={props.fromDate}
                                          onChange={(date) =>
                                            handleDepartureDateTime(
                                              date,
                                              data.name,
                                              "departureDateTime",
                                              trldata.id
                                            )
                                          }
                                          maxDate={props.toDate}
                                          placeholderText="Departure Date & Time"
                                          timeIntervals={15}
                                        />
                                      </FloatingLabel>
                                    </div>
                                    {fieldReq &&
                                      (trldata.departureDateTime == null ||
                                        trldata.departureDateTime === "") ? (
                                      <small
                                        id={`Error${alertMessage.alertType}`}
                                        className="form-text text-muted "
                                      >
                                        Required !
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {data.travel === 2 && (
                                    <>
                                      <div
                                        className="inputs-by-triptype return-date"
                                        style={{ width: "20%" }}
                                      >
                                        <div className="react-date-time-picker">
                                          <FloatingLabel
                                            label="Return"
                                            className={`${trldata.departureDateTime
                                              ? "float-visible-select"
                                              : "float-hidden float-select"
                                              }`}
                                          >
                                            <DatePicker
                                              selected={trldata.returnDateTime}
                                              className="select date-time-picker"
                                              id="date-time-picker"
                                              showTimeSelect
                                              dateFormat="dd/MM/yyyy h:mm aa"
                                              minDate={props.fromDate}
                                              maxDate={props.toDate}
                                              onChange={(date) =>
                                                handleDepartureDateTime(
                                                  date,
                                                  data.name,
                                                  "returnDateTime",
                                                  trldata.id
                                                )
                                              }
                                              placeholderText="Return Date & Time"
                                              timeIntervals={15}
                                            />
                                          </FloatingLabel>
                                        </div>
                                        {fieldReq &&
                                          (trldata.returnDateTime == null ||
                                            trldata.returnDateTime === "") ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  )}
                                  <div
                                    className="inputs-by-triptype"
                                    style={{ width: "10%" }}
                                  >
                                    <FloatingLabel
                                      label="Travellers"
                                      className={`${trldata.travellers !== null
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                    >
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="No."
                                        name="travellers"
                                        value={trldata.travellers}
                                        onChange={(e) =>
                                          handleTravelDetails(
                                            trldata.id,
                                            e,
                                            data.name
                                          )
                                        }
                                      // required
                                      />
                                    </FloatingLabel>
                                    {fieldReq &&
                                      (trldata.travellers == null ||
                                        trldata.travellers <= 0 ||
                                        trldata.travellers >
                                        defaultTravellers) ? (
                                      <small
                                        id={`Error${alertMessage.alertType}`}
                                        className="form-text text-muted "
                                      >
                                        Required !
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className="inputs-by-triptype"
                                    style={{ width: "20%" }}
                                  >
                                    <FloatingLabel
                                      label="Approx. Amount"
                                      className={`${trldata.approxAmount
                                        ? "float-input-visible"
                                        : "float-hidden float-input"
                                        }`}
                                    >
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Amount"
                                        name="approxAmount"
                                        value={trldata.approxAmount || ""}
                                        onChange={(e) =>
                                          handleTravelDetails(
                                            trldata.id,
                                            e,
                                            data.name
                                          )
                                        }
                                      // required
                                      />
                                    </FloatingLabel>
                                    {fieldReq &&
                                      (trldata.travellers == null ||
                                        trldata.travellers <= 0 ||
                                        trldata.travellers >
                                        defaultTravellers) ? (
                                      <small
                                        id={`Error${alertMessage.alertType}`}
                                        className="form-text text-muted "
                                      >
                                        Required !
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {props.travelBy !== 1 && (
                                    <div
                                      className="inputs-by-triptype"
                                      style={{ width: "28%" }}
                                    >
                                      <FloatingLabel
                                        label="Traveller Names"
                                        className={`${trldata.travellersNames > 0
                                          ? "float-visible-select"
                                          : "float-hidden float-select"
                                          }`}
                                      >
                                        <MultiSelect
                                          className="select-add-user-model"
                                          options={allUsers}
                                          value={trldata.travellersNames}
                                          onChange={(val) =>
                                            handleTravellerDetails(
                                              val,
                                              trldata.id,
                                              "travellersNames",
                                              data.name
                                            )
                                          }
                                          labelledBy={"Select"}
                                          overrideStrings={{
                                            selectSomeItems:
                                              "Select Traveller Names",
                                          }}
                                        />
                                      </FloatingLabel>
                                    </div>
                                  )}
                                  {props.travelBy === 3 && (
                                    <div
                                      className="inputs-by-triptype"
                                      style={{ width: "28%" }}
                                    >
                                      <FloatingLabel
                                        label="Client Name"
                                        className={`${trldata.clientName
                                          ? "float-input-visible"
                                          : "float-hidden float-input"
                                          }`}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="No."
                                          name="clientName"
                                          value={trldata.clientName}
                                          onChange={(e) =>
                                            handleTravelDetails(
                                              trldata.id,
                                              e,
                                              data.name
                                            )
                                          }
                                        // required
                                        />
                                      </FloatingLabel>
                                      {fieldReq &&
                                        (trldata.travellers == null ||
                                          trldata.travellers <= 0 ||
                                          trldata.travellers >
                                          defaultTravellers) ? (
                                        <small
                                          id={`Error${alertMessage.alertType}`}
                                          className="form-text text-muted "
                                        >
                                          Required !
                                        </small>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                  <div
                                    className="inputs-by-triptype"
                                    style={{ width: "5%" }}
                                  >
                                    <div className="ticket-attachment">
                                      <OverlayTrigger
                                        placement={"top"}
                                        delay={{ show: 250, hide: 300 }}
                                        overlay={
                                          <Tooltip id={`tooltip-remarks`}>
                                            Upload Attachments
                                          </Tooltip>
                                        }
                                      >
                                        <div>
                                          <button
                                            type="button"
                                            className="btn upload-ticket"
                                          // onClick={uploadClick}
                                          >
                                            <span className="material-symbols-outlined">
                                              upload
                                            </span>
                                          </button>
                                          <input
                                            id="getFile"
                                            type="file"
                                            name="filetobase64"
                                            accept=".pdf, image/*"
                                            className="btn-primary upload"
                                            // ref={hiddenFileInput}
                                            // onChange={handleChange}
                                            style={{ display: "none" }}
                                          />
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  {i !== 0 && (
                                    <div
                                      className="remove-row"
                                      onClick={(e) =>
                                        removeRowFromData(trldata.id, data.name)
                                      }
                                    >
                                      <span className="material-symbols-outlined">
                                        cancel
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                            {data.travel === 3 && (
                              <div
                                className="inputs-by-triptype plus-icon"
                                onClick={() =>
                                  addAnotherRow(data.value, data.travel)
                                }
                              >
                                <div className="add-another-row">
                                  <span className="material-symbols-outlined">
                                    add_circle
                                  </span>
                                  <span className="add-text">Add</span>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          data.name === "Hotel" ? (
                            <>
                              <div className="checkIn-checkOut-qty-rooms">
                                {travelDetails[data.name].map((trldata, i) => (
                                  <div key={trldata.id} className="journey-row">
                                    <div className="dflex-j-start mb-1">
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "50%" }}
                                      >
                                        <FloatingLabel
                                          label="City / Location"
                                          className={`${trldata.location
                                            ? "float-visible-select"
                                            : "float-hidden float-select"
                                            }`}
                                        >
                                          <Typeahead
                                            id="autosuggest-example"
                                            name="location"
                                            options={cityList}
                                            selected={trldata.location}
                                            className="custom-auto-select"
                                            onChange={(value) =>
                                              setSelectedLocation(
                                                value,
                                                data.name,
                                                trldata.id,
                                                "location"
                                              )
                                            }
                                            onInputChange={
                                              handleAutoInputChange
                                            }
                                            placeholder="Select City / Location"
                                            disabled={props.clickType}
                                            allowNew
                                          />
                                        </FloatingLabel>
                                      </div>
                                      {/* <div
                                        className="inputs-by-triptype"
                                        style={{ width: "50%" }}
                                      >
                                        <FloatingLabel
                                          label="Location"
                                          className={`${
                                            trldata.location
                                              ? "float-input-visible"
                                              : "float-hidden float-input"
                                          }`}
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Location"
                                            name="location"
                                            value={trldata.location}
                                            onChange={(e) =>
                                              handleTravelDetails(
                                                trldata.id,
                                                e,
                                                data.name
                                              )
                                            }
                                            // required
                                          />
                                        </FloatingLabel>
                                      </div> */}
                                      {i !== 0 && (
                                        <div
                                          className="inputs-by-triptype"
                                          style={{ width: "20%" }}
                                          onClick={(e) =>
                                            removeRowFromData(
                                              trldata.id,
                                              data.name
                                            )
                                          }
                                        >
                                          <div className="remove-row">
                                            <span className="material-symbols-outlined">
                                              cancel
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      {fieldReq &&
                                        (trldata.location == null ||
                                          trldata.location === "") ? (
                                        <small
                                          id={`Error${alertMessage.alertType}`}
                                          className="form-text text-muted "
                                        >
                                          Required
                                        </small>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="dflex-j-start gap5 1dflex-alignBase-jbetween">
                                      <div
                                        className="inputs-by-triptype react-date-time-picker"
                                        style={{ width: "25%" }}
                                      >
                                        <FloatingLabel
                                          label="Check-In"
                                          className={`${trldata.checkInDate
                                            ? "float-visible-select"
                                            : "float-hidden float-select"
                                            }`}
                                        >
                                          <DatePicker
                                            selected={trldata.checkInDate}
                                            className="select date-time-picker"
                                            id="date-time-picker"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={props.fromDate}
                                            maxDate={props.toDate}
                                            onChange={(date) =>
                                              handleDepartureDateTime(
                                                date,
                                                data.name,
                                                "checkInDate",
                                                trldata.id
                                              )
                                            }
                                            placeholderText="Check-In Date"
                                          />
                                        </FloatingLabel>
                                        {fieldReq &&
                                          (trldata.checkInDate == null ||
                                            trldata.checkInDate === "") ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        className="inputs-by-triptype react-date-time-picker"
                                        style={{ width: "25%" }}
                                      >
                                        <FloatingLabel
                                          label="Check-Out"
                                          className={`${trldata.checkOutDate
                                            ? "float-visible-select"
                                            : "float-hidden float-select"
                                            }`}
                                        >
                                          <DatePicker
                                            selected={trldata.checkOutDate}
                                            className="select date-time-picker"
                                            id="date-time-picker"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={props.fromDate}
                                            maxDate={props.toDate}
                                            onChange={(date) =>
                                              handleDepartureDateTime(
                                                date,
                                                data.name,
                                                "checkOutDate",
                                                trldata.id
                                              )
                                            }
                                            placeholderText="Check-Out Date"
                                          />
                                        </FloatingLabel>
                                        {fieldReq &&
                                          (trldata.checkOutDate == null ||
                                            trldata.checkOutDate === "") ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {props.travelBy !== 1 && (
                                        <div
                                          className="inputs-by-triptype"
                                          style={{ width: "30%" }}
                                        >
                                          <FloatingLabel
                                            label="Traveller Names"
                                            className={`${trldata.travellersNames > 0
                                              ? "float-visible-select"
                                              : "float-hidden float-select"
                                              }`}
                                          >
                                            <MultiSelect
                                              className="select-add-user-model"
                                              options={allUsers}
                                              value={trldata.travellersNames}
                                              onChange={(val) =>
                                                handleTravellerDetails(
                                                  val,
                                                  trldata.id,
                                                  "travellersNames",
                                                  data.name
                                                )
                                              }
                                              labelledBy={"Select"}
                                              overrideStrings={{
                                                selectSomeItems:
                                                  "Select Traveller Names",
                                              }}
                                            />
                                          </FloatingLabel>
                                          {fieldReq &&
                                            (trldata.travellers == null ||
                                              trldata.travellers <= 0 ||
                                              trldata.travellers >
                                              defaultTravellers) ? (
                                            <small
                                              id={`Error${alertMessage.alertType}`}
                                              className="form-text text-muted "
                                            >
                                              Required !
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )}
                                      {props.travelBy === 3 && (
                                        <div
                                          className="inputs-by-triptype"
                                          style={{ width: "30%" }}
                                        >
                                          <FloatingLabel
                                            label="Client Name"
                                            className={`${trldata.clientName
                                              ? "float-input-visible"
                                              : "float-hidden float-input"
                                              }`}
                                          >
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="No."
                                              name="clientName"
                                              value={trldata.clientName}
                                              onChange={(e) =>
                                                handleTravelDetails(
                                                  trldata.id,
                                                  e,
                                                  data.name
                                                )
                                              }
                                            // required
                                            />
                                          </FloatingLabel>
                                          {fieldReq &&
                                            (trldata.travellers == null ||
                                              trldata.travellers <= 0 ||
                                              trldata.travellers >
                                              defaultTravellers) ? (
                                            <small
                                              id={`Error${alertMessage.alertType}`}
                                              className="form-text text-muted "
                                            >
                                              Required !
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )}
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "15%" }}
                                      >
                                        <FloatingLabel
                                          label="Travellers"
                                          className={`${trldata.travellers
                                            ? "float-input-visible"
                                            : "float-hidden float-input"
                                            }`}
                                        >
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Travellers"
                                            name="travellers"
                                            value={trldata.travellers}
                                            onChange={(e) =>
                                              handleTravelDetails(
                                                trldata.id,
                                                e,
                                                data.name
                                              )
                                            }
                                          // required
                                          />
                                        </FloatingLabel>
                                        {fieldReq &&
                                          (trldata.travellers == null ||
                                            trldata.travellers <= 0 ||
                                            trldata.travellers >
                                            defaultTravellers) ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required !
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "15%" }}
                                      >
                                        <FloatingLabel
                                          label="Rooms"
                                          className={`${trldata.rooms
                                            ? "float-input-visible"
                                            : "float-hidden float-input"
                                            }`}
                                        >
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Rooms"
                                            name="rooms"
                                            value={trldata.rooms}
                                            onChange={(e) =>
                                              handleTravelDetails(
                                                trldata.id,
                                                e,
                                                data.name
                                              )
                                            }
                                          // required
                                          />
                                        </FloatingLabel>
                                        {fieldReq &&
                                          (trldata.rooms == null ||
                                            trldata.rooms <= 0) ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required !
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "20%" }}
                                      >
                                        <FloatingLabel
                                          label="Approx. Amount"
                                          className={`${trldata.approxAmount !== null
                                            ? "float-input-visible"
                                            : "float-hidden float-input"
                                            }`}
                                        >
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Amount"
                                            name="approxAmount"
                                            value={trldata.approxAmount}
                                            onChange={(e) =>
                                              handleTravelDetails(
                                                trldata.id,
                                                e,
                                                data.name
                                              )
                                            }
                                          // required
                                          />
                                        </FloatingLabel>
                                        {fieldReq &&
                                          (trldata.travellers == null ||
                                            trldata.travellers <= 0 ||
                                            trldata.travellers >
                                            defaultTravellers) ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required !
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "5%" }}
                                      >
                                        <div className="ticket-attachment">
                                          <OverlayTrigger
                                            placement={"top"}
                                            delay={{ show: 250, hide: 300 }}
                                            overlay={
                                              <Tooltip id={`tooltip-remarks`}>
                                                Upload Attachments
                                              </Tooltip>
                                            }
                                          >
                                            <div>
                                              <button
                                                type="button"
                                                className="btn upload-ticket"
                                              // onClick={uploadClick}
                                              >
                                                <span className="material-symbols-outlined">
                                                  upload
                                                </span>
                                              </button>
                                              <input
                                                id="getFile"
                                                type="file"
                                                name="filetobase64"
                                                accept=".pdf, image/*"
                                                className="btn-primary upload"
                                                // ref={hiddenFileInput}
                                                // onChange={handleChange}
                                                style={{ display: "none" }}
                                              />
                                            </div>
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div
                                  className="inputs-by-triptype plus-icon"
                                  onClick={() =>
                                    addAnotherRow(data.value, data.travel)
                                  }
                                >
                                  <div className="add-another-row">
                                    <span className="material-symbols-outlined">
                                      add_circle
                                    </span>
                                    <span className="add-text">Add</span>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : data.name === "Vehicle" && (
                            <>
                              <div className="travel-from-to-qty-datetime">
                                {travelDetails[data.name].map((trldata, i) => (
                                  <div key={trldata.id} className="journey-row">
                                    <div className="input-select-boxes-flex">
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "18%" }}
                                      >
                                        <FloatingLabel
                                          label="From"
                                          className={`${trldata.fromLocation
                                            ? "float-visible-select"
                                            : "float-hidden float-select"
                                            }`}
                                        >
                                          <Typeahead
                                            id="autosuggest-example"
                                            name="fromLocation"
                                            options={cityList}
                                            selected={trldata.fromLocation}
                                            className="custom-auto-select"
                                            onChange={(value) =>
                                              setSelectedLocation(
                                                value,
                                                data.name,
                                                trldata.id,
                                                "fromLocation"
                                              )
                                            }
                                            onInputChange={handleAutoInputChange}
                                            placeholder="From"
                                            disabled={props.clickType}
                                            allowNew
                                          />
                                        </FloatingLabel>
                                        {fieldReq &&
                                          (trldata.fromLocation == null ||
                                            trldata.fromLocation === "") ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required !
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "18%" }}
                                      >
                                        <FloatingLabel
                                          label="To"
                                          className={`${trldata.toLocation
                                            ? "float-visible-select"
                                            : "float-hidden float-select"
                                            }`}
                                        >
                                          <Typeahead
                                            id="autosuggest-example"
                                            name="toLocation"
                                            options={cityList}
                                            selected={trldata.toLocation}
                                            className="custom-auto-select"
                                            onChange={(value) =>
                                              setSelectedLocation(
                                                value,
                                                data.name,
                                                trldata.id,
                                                "toLocation"
                                              )
                                            }
                                            onInputChange={handleAutoInputChange}
                                            placeholder="To"
                                            disabled={props.clickType}
                                            allowNew
                                          />
                                        </FloatingLabel>
                                        {fieldReq &&
                                          (trldata.toLocation == null ||
                                            trldata.toLocation === "") ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required !
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "18%" }}
                                      >
                                        <div className="react-date-time-picker">
                                          <FloatingLabel
                                            label="Departure"
                                            className={`${trldata.departureDateTime
                                              ? "float-visible-select"
                                              : "float-hidden float-select"
                                              }`}
                                          >
                                            <DatePicker
                                              selected={trldata.departureDateTime}
                                              className="select date-time-picker"
                                              id="date-time-picker"
                                              showTimeSelect
                                              dateFormat="dd/MM/yyyy h:mm aa"
                                              name="departureDateTime"
                                              minDate={props.fromDate}
                                              onChange={(date) =>
                                                handleDepartureDateTime(
                                                  date,
                                                  data.name,
                                                  "departureDateTime",
                                                  trldata.id
                                                )
                                              }
                                              maxDate={props.toDate}
                                              placeholderText="Departure Date & Time"
                                              timeIntervals={15}
                                            />
                                          </FloatingLabel>
                                        </div>
                                        {fieldReq &&
                                          (trldata.departureDateTime == null ||
                                            trldata.departureDateTime === "") ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required !
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {data.travel === 2 && (
                                        <>
                                          <div
                                            className="inputs-by-triptype return-date"
                                            style={{ width: "18%" }}
                                          >
                                            <div className="react-date-time-picker">
                                              <FloatingLabel
                                                label="Return"
                                                className={`${trldata.departureDateTime
                                                  ? "float-visible-select"
                                                  : "float-hidden float-select"
                                                  }`}
                                              >
                                                <DatePicker
                                                  selected={trldata.returnDateTime}
                                                  className="select date-time-picker"
                                                  id="date-time-picker"
                                                  showTimeSelect
                                                  dateFormat="dd/MM/yyyy h:mm aa"
                                                  minDate={props.fromDate}
                                                  maxDate={props.toDate}
                                                  onChange={(date) =>
                                                    handleDepartureDateTime(
                                                      date,
                                                      data.name,
                                                      "returnDateTime",
                                                      trldata.id
                                                    )
                                                  }
                                                  placeholderText="Return Date & Time"
                                                  timeIntervals={15}
                                                />
                                              </FloatingLabel>
                                            </div>
                                            {fieldReq &&
                                              (trldata.returnDateTime == null ||
                                                trldata.returnDateTime === "") ? (
                                              <small
                                                id={`Error${alertMessage.alertType}`}
                                                className="form-text text-muted "
                                              >
                                                Required
                                              </small>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </>
                                      )}
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "7%" }}
                                      >
                                        <FloatingLabel
                                          label="Travellers"
                                          className={`${trldata.travellers !== null
                                            ? "float-input-visible"
                                            : "float-hidden float-input"
                                            }`}
                                        >
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="No."
                                            name="travellers"
                                            value={trldata.travellers}
                                            onChange={(e) =>
                                              handleTravelDetails(
                                                trldata.id,
                                                e,
                                                data.name
                                              )
                                            }
                                          // required
                                          />
                                        </FloatingLabel>
                                        {fieldReq &&
                                          (trldata.travellers == null ||
                                            trldata.travellers <= 0 ||
                                            trldata.travellers >
                                            defaultTravellers) ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required !
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "18%" }}
                                      >
                                        <FloatingLabel
                                          label="Approx. Amount"
                                          className={`${trldata.approxAmount
                                            ? "float-input-visible"
                                            : "float-hidden float-input"
                                            }`}
                                        >
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Amount"
                                            name="approxAmount"
                                            value={trldata.approxAmount || ""}
                                            onChange={(e) =>
                                              handleTravelDetails(
                                                trldata.id,
                                                e,
                                                data.name
                                              )
                                            }
                                          // required
                                          />
                                        </FloatingLabel>
                                        {fieldReq &&
                                          (trldata.travellers == null ||
                                            trldata.travellers <= 0 ||
                                            trldata.travellers >
                                            defaultTravellers) ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required !
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "10%" }}
                                      >
                                        <FloatingLabel
                                          label="Approx. KM"
                                          className={`${trldata.approxKM
                                            ? "float-input-visible"
                                            : "float-hidden float-input"
                                            }`}
                                        >
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="approxKM"
                                            name="approxKM"
                                            value={trldata.approxKM || ""}
                                            onChange={(e) =>
                                              handleTravelDetails(
                                                trldata.id,
                                                e,
                                                data.name
                                              )
                                            }
                                          // required
                                          />
                                        </FloatingLabel>
                                        {fieldReq &&
                                          (trldata.travellers == null ||
                                            trldata.travellers <= 0 ||
                                            trldata.travellers >
                                            defaultTravellers) ? (
                                          <small
                                            id={`Error${alertMessage.alertType}`}
                                            className="form-text text-muted "
                                          >
                                            Required !
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {props.travelBy !== 1 && (
                                        <div
                                          className="inputs-by-triptype"
                                          style={{ width: "28%" }}
                                        >
                                          <FloatingLabel
                                            label="Traveller Names"
                                            className={`${trldata.travellersNames > 0
                                              ? "float-visible-select"
                                              : "float-hidden float-select"
                                              }`}
                                          >
                                            <MultiSelect
                                              className="select-add-user-model"
                                              options={allUsers}
                                              value={trldata.travellersNames}
                                              onChange={(val) =>
                                                handleTravellerDetails(
                                                  val,
                                                  trldata.id,
                                                  "travellersNames",
                                                  data.name
                                                )
                                              }
                                              labelledBy={"Select"}
                                              overrideStrings={{
                                                selectSomeItems:
                                                  "Select Traveller Names",
                                              }}
                                            />
                                          </FloatingLabel>
                                        </div>
                                      )}
                                      {props.travelBy === 3 && (
                                        <div
                                          className="inputs-by-triptype"
                                          style={{ width: "28%" }}
                                        >
                                          <FloatingLabel
                                            label="Client Name"
                                            className={`${trldata.clientName
                                              ? "float-input-visible"
                                              : "float-hidden float-input"
                                              }`}
                                          >
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="No."
                                              name="clientName"
                                              value={trldata.clientName}
                                              onChange={(e) =>
                                                handleTravelDetails(
                                                  trldata.id,
                                                  e,
                                                  data.name
                                                )
                                              }
                                            // required
                                            />
                                          </FloatingLabel>
                                          {fieldReq &&
                                            (trldata.travellers == null ||
                                              trldata.travellers <= 0 ||
                                              trldata.travellers >
                                              defaultTravellers) ? (
                                            <small
                                              id={`Error${alertMessage.alertType}`}
                                              className="form-text text-muted "
                                            >
                                              Required !
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )}
                                      <div
                                        className="inputs-by-triptype"
                                        style={{ width: "5%" }}
                                      >
                                        <div className="ticket-attachment">
                                          <OverlayTrigger
                                            placement={"top"}
                                            delay={{ show: 250, hide: 300 }}
                                            overlay={
                                              <Tooltip id={`tooltip-remarks`}>
                                                Upload Attachments
                                              </Tooltip>
                                            }
                                          >
                                            <div>
                                              <button
                                                type="button"
                                                className="btn upload-ticket"
                                              // onClick={uploadClick}
                                              >
                                                <span className="material-symbols-outlined">
                                                  upload
                                                </span>
                                              </button>
                                              <input
                                                id="getFile"
                                                type="file"
                                                name="filetobase64"
                                                accept=".pdf, image/*"
                                                className="btn-primary upload"
                                                // ref={hiddenFileInput}
                                                // onChange={handleChange}
                                                style={{ display: "none" }}
                                              />
                                            </div>
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                      {i !== 0 && (
                                        <div
                                          className="remove-row"
                                          onClick={(e) =>
                                            removeRowFromData(trldata.id, data.name)
                                          }
                                        >
                                          <span className="material-symbols-outlined">
                                            cancel
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                                {data.travel === 3 && (
                                  <div
                                    className="inputs-by-triptype plus-icon"
                                    onClick={() =>
                                      addAnotherRow(data.value, data.travel)
                                    }
                                  >
                                    <div className="add-another-row">
                                      <span className="material-symbols-outlined">
                                        add_circle
                                      </span>
                                      <span className="add-text">Add</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <>
          <TripPlanPreview
            tripPlanPreview={tripPlanPreview}
            setTripPlanPreview={setTripPlanPreview}
            clientName={clientNames}
            tripApproxAmount={tripApproxAmount}
            remarks={remarks}
            mobilenum={""}
            userNames={""}
            advanceCash={advanceCash}
            otherOption={otherOption}
            travelDetails={travelDetails}
            travelByOptions={travelByOptions}
            fromDate={moment(props.fromDate).format("DD/MM/YYYY")}
            toDate={moment(props.toDate).format("DD/MM/YYYY")}
          />
        </>
      )}
      {/* TRAVEL PLAN DATE AND TRAVEL BY SELECTION */}
    </div>
  );
}
