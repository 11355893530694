import React, { useState } from "react";
import { MiCustomBtn, SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import TicketDetailsTable from "./TicketDetailsTable";

export default function TripPreviewTable(props) {
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });
  const [rejectPlan, setRejectPlan] = useState(false);
  const [advanceCash, setAdvanceCash] = useState(null);
  const [advanceCashRemarks, setAdvanceCashRemarks] = useState("");

  const [rejectRemarks, setRejectRemarks] = useState("");

  const approveTripPlan = () => {
    console.log("APPROVED");
  };
  const rejectTripPlan = () => {
    if (rejectRemarks !== "" && rejectRemarks !== null) {
      console.log("REJECTED");
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };

  // console.log("travelByOptions", props.travelByOptions);
  // console.log("travelDetails", props.travelDetails);

  return (
    <div>
      {" "}
      <div className="travel-approval-table">
        <div className="container-fluid travel-ticket-details-page">
          <div className="tripId-n-date dflex-j-between">
            <div>
              TRIP ID : <b>#R1035</b>
            </div>
            <div className="ticket-status">
              <span>Status :</span>&nbsp;&nbsp;
              <span className="status pending">
                <i className="fa fa-hourglass-start" /> &nbsp; Pending
              </span>
            </div>
            <div>
              TRAVEL DATE :{" "}
              <b>
                {props.fromDate} to {props.toDate}
              </b>
            </div>
          </div>
          <div className="name-team-client-other-details">
            <div className="team-details-n-client-details">
              <div className="planerName-n-teamDetails">
                <div className="trp-dtls-label-value">
                  <div className="trp-dtls-label">Planner Name : </div>
                  <div className="trp-dtls-value"> Dimpu Kumar Pandey</div>
                </div>
                <div className="trp-dtls-label-value">
                  <div className="trp-dtls-label">Travel Type : </div>
                  <div className="trp-dtls-value">
                    {" "}
                    {props.travelBy == 1
                      ? "Single"
                      : props.travelBy == 2
                      ? "Team"
                      : "Partial Team"}
                  </div>
                </div>
                <div className="trp-dtls-label-value">
                  <div className="trp-dtls-label">Team Details : </div>
                  <div className="trp-dtls-value">
                    {props.userNames.length > 0
                      ? props.userNames.map((data) => data.fullName + ",")
                      : "-"}
                  </div>
                </div>
                <div className="trp-dtls-label-value">
                  <div className="trp-dtls-label">Remarks : </div>
                  <div className="trp-dtls-value"> {props.remarks}</div>
                </div>
              </div>
              <div className="client-details">
                <div className="trp-dtls-label-value">
                  <div className="trp-dtls-label">Client Name : </div>
                  <div className="trp-dtls-value">
                    {" "}
                    {/* {props.clientName} */}
                    {props.clientName.length > 0
                      ? props.clientName.map((data) => data.label + ",")
                      : "-"}
                  </div>
                </div>
                <div className="trp-dtls-label-value">
                  <div className="trp-dtls-label">Trip Approx. Amount : </div>
                  <div className="trp-dtls-value">
                    {" "}
                    {props.tripApproxAmount ?? "NA"}
                  </div>
                </div>
              </div>
            </div>
            <div className="other-details">
              <div className="trp-dtls-label-value">
                <div className="trp-dtls-label">Other : </div>
                <div className="trp-dtls-value">{props.otherOption}</div>
              </div>
            </div>
          </div>
          <div className="advance-cash-aprvd-dtls">
            <div className="particular cash-data">
              <div className="head">Particulars</div>
              <div className="body">Advance Cash</div>
            </div>
            <div className="applied cash-data">
              <div className="head">Applied</div>
              <div className="body color-green">Rs. {props.advanceCash}</div>
            </div>
            {props.loggedInBy === "Admin" ? (
              <>
                <div className="approved cash-data">
                  <div className="head">Approve</div>
                  <div className="body d-flex">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Amount"
                      name="advance-cash"
                      value={advanceCash}
                      onChange={(e) => setAdvanceCash(e.target.value)}
                      // required
                    />
                  </div>
                </div>
                <div className="remarks cash-data">
                  <div className="head">Remarks</div>
                  <div className="body">
                    <textarea
                      rows="3"
                      cols="20"
                      name="cashRemarks"
                      wrap="hard"
                      className="form-control form add-meeting-input location"
                      placeholder="Enter remarks"
                      value={advanceCashRemarks}
                      onChange={(e) => setAdvanceCashRemarks(e.target.value)}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="trip-ticket-details">
            <TicketDetailsTable
              setMotHandle={props.setMotHandle}
              travelByOptions={props.travelByOptions}
              travelDetails={props.travelDetails}
              pageViewBy={props.pageViewBy}
              pageName={props.pageName}
              loggedInBy={props.loggedInBy}
            />
          </div>
          {props.loggedInBy === "Admin" ? (
            <>
              {rejectPlan && (
                <>
                  <div className="data-by-row">
                    <div className="data-label mb-2">
                      <span className="color-green">Remarks :</span>
                    </div>

                    <div className="data-value" style={{ width: "100%" }}>
                      <textarea
                        rows="4"
                        cols="50"
                        name="usrtxt"
                        wrap="hard"
                        className="form-control form add-meeting-input location"
                        placeholder="Enter remark for rejection"
                        value={rejectRemarks}
                        onChange={(e) => setRejectRemarks(e.target.value)}
                      />
                      {fieldReq &&
                      (rejectRemarks === "" || rejectRemarks == null) ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          Required !
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="reject-ok-cancel-btn dflex-j-end mt-3">
                    <MiCustomBtn
                      onClickEffect={rejectTripPlan}
                      btnName="Ok"
                      btnColor="primary"
                    />
                    <MiCustomBtn
                      onClickEffect={() => setRejectPlan(false)}
                      btnName="Cancel"
                      btnColor="secondary"
                    />
                  </div>
                </>
              )}
              <div className="approve-reject-part hv-center">
                <div className="approve-btn-part">
                  <SBSaveUpdateBtn
                    onClickEffect={approveTripPlan}
                    btnName="Approve"
                    disableProp={rejectPlan}
                  />
                </div>
                <div className="reject-btn-part">
                  <SbCancelBtn
                    btnName="Reject"
                    onClickEffect={() => setRejectPlan(true)}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
