/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FloatingLabel, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// local imports
import "../../../styles/Whatsappconfigaration.scss";
import Footer from "../../Footer";
import { loadSubscription } from "../../../redux/action";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import ConfigurationTemplate from "./ConfigurationTemplate";
import ConfigurationFile from "./ConfigurationFile";
import CommonNavFile from "../../CommonNavFile";
import * as HeaderData from '../../HeaderAuthentication';
import { loadPostWhatsappConfigData, loadVeriableData } from "../../../redux/ModalActions/whatsappConfig_Action";

function WhatsappConfig() {
  let dispatch = useDispatch();
  const location = useLocation();
  const { subcriptionList } = useSelector((state) => state.data);
  const { singleWhatsAppConfigData } = useSelector((state) => state.configData);
  var configTemplateId = '';
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [toggleshift, setToggleshift] = useState({ style: "" });

  // #region initian value
  const [initStates, setInitStates] = useState({
    subscriptionID: "", notificationName: "", receiverType: "", to: "",
    time: "", frequency: "", tokenID: "", sp_Name: "", sp_FlagID: "",
    active: "1", createdBy: resPassData.fullName, createdOn: "", body: "",
    footer: "", button_text: "", navigateToPage: "", header_Type: "none", header_Text: "",
    header_Image: "",
    variableDetails: []
  })
  // #endregion initian value

  // #region input handler
  const handleFormInput = (e) => {
    const { name, value } = e.target;
    setInitStates({ ...initStates, [name]: value })
  }
  // #endregion

  const [userList, setUserList] = useState([]);
  const [templateName, setTemplateName] = useState('');

  useEffect(() => {
    if (subcriptionList) {
      let optiondata = [];
      subcriptionList.forEach((data) => {
        if (data.subscriptionId != null) {
          optiondata.push({
            value: data.subscriptionId,
            label: data.subscriptionId,
            client: data?.company?.companyName,
          });
        }
      });
      setUserList(optiondata);
    }
  }, [subcriptionList]);

  const getOptionValue = ({ label }) => (
    <div>
      <div>{label.value}</div>
    </div>
  );

  const getOptionLabel = ({ label, client }) => `${label} ${client}`;

  const formatOptionLabel = ({ label, client }) => (
    <div className="menu-item-column">
      <div style={{ fontSize: "11px" }}>
        <b>{label}</b>
      </div>
      <div style={{ fontSize: "10px" }}>{client}</div>
    </div>
  );

  const [selectedSubscribe, setSelectedSubscribe] = useState({
    value: "",
    label: "",
  });
  

  const setFilterUserId = (selected) => {
    initStates.subscriptionID = selected.value;
    setSelectedSubscribe(selected);
  };

  useEffect(() => {
    dispatch(loadSubscription());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.state) {
      const { templateName, templateId } = location.state;
      configTemplateId = templateId;
      if(templateName){
        setTemplateName(templateName);
      } else if(templateId) {
        if(singleWhatsAppConfigData){
          setInitStates(singleWhatsAppConfigData)
          setTemplateName(singleWhatsAppConfigData?.notificationName)
          setEnteredMsg(singleWhatsAppConfigData.body)
          // console.log(formatDateToIST(singleWhatsAppConfigData.time))
          dispatch(loadVeriableData(templateId, setVeriables))
          if(singleWhatsAppConfigData.button_text || singleWhatsAppConfigData.navigateToPage){
            setOpenButtonVeriable(true)
          }
        }
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  // #region function for edit template name
  const [editTemplateName, setEditTemplateName] = useState(false)
  // #endregion


  const handleDropDown = (data, name) => {
    // let initState = { ...initStates };
    if (name === "header_Type") {
      setInitStates({...initStates, [name]: data, header_Text: ''});
      setLocalImage('')
      // initState.header_Type = data;
      // initState.header_Text = '';
    } else {
      setInitStates({...initStates, [name]: data});
    }
    
    // else if (type === "recieverType") {
    //   // setSelectRecieverType(data)
    //   initState.receiverType = data.value;
    //   initState.to = '';
    // } else if (type === "frequency") {
    //   // setSelectFrequency(data)
    //   initState.frequency = data.value;
    // }
    
  }

  const [showVeriableModal, setShowVeriableModal] = useState(false)

  const openVeriableModal = () => {
    setShowVeriableModal(true)
  }
  const handleVeriableModal = () => {
    setShowVeriableModal(false)
  }

  const [veriables, setVeriables] = useState([])
  const [localImage, setLocalImage] = useState("");
  // const [veriablesData, setVeriablesData] = useState([])

  // const [messageBody, setMessageBody] = useState('')
  const [enteredMsg, setEnteredMsg] = useState('')

  const handleBody = (e) => {
    setEnteredMsg(e.target.value)
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
    let initState = { ...initStates };
    initState.body = e.target.value;
    setInitStates(initState);
  }

  const [veriableName, setVeriableName] = useState('');

  const setVeriablesValue = (e) => {
    e.preventDefault();
    if (!veriableName) {
      toast.warning("Please enter veriable name")
      return;
    }
    let list = [...veriables]

    list.push({ variables: veriableName, variables_Value: '' });
    setVeriables(list)

    // let formattedString = list.map(item => `${item.variables}: ${item.variables_Value}`).join('\n');
    // setMessageBody(formattedString)

    setVeriableName('')
    handleVeriableModal();
  }

  // #region hanld veriable input
  const handleVeriableInput = (e, i) => {
    let list = [...veriables];
    const { value } = e.target;
    list[i].variables_Value = value;
    setVeriables(list);
  }

  const deleteVeriable = (indexToRemove) => {
    const updatedVariables = veriables.filter((item, index) => index !== indexToRemove);
    setVeriables(updatedVariables)
  }
  // #endregion

  // #region button option
  const [openButtonVeriable, setOpenButtonVeriable] = useState(false)
  const addBtnPrameters = () => {
    setOpenButtonVeriable(true)
    if(configTemplateId){
      setInitStates({...initStates, button_text: '', navigateToPage: ''});
    }
  }
  const removeBtnPrameters = () => {
    setOpenButtonVeriable(false)
      setInitStates({...initStates, button_text: singleWhatsAppConfigData.button_text, 
        navigateToPage: singleWhatsAppConfigData.navigateToPage});
  }
  // #region button option

  // Page navigation
  const navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  // #region save btn function
  const [validation, setValidation] = useState(false);
  const [senderConfig, setSenderConfig] = useState(false)
  const saveDataAndContinue = () => {
    if(!templateName || !initStates.body 
      || (initStates.button_text && !initStates.navigateToPage)){
      setValidation(true);
      return;
    } else if(!initStates.subscriptionID ){
      toast.error("Please select subscription");
      return;
    } else {
      setValidation(false);
      setSenderConfig(true)
    }
    
  }

  const saveTemplateDetails = () => {
    initStates.notificationName = templateName;
    initStates.variableDetails = veriables;
    initStates.createdOn = new Date();
    if(!initStates.notificationName || !initStates.subscriptionID || !initStates.body 
      || !initStates.receiverType || (initStates.receiverType === "Particular" && !initStates.to)
      || !initStates.frequency || !initStates.time 
      || (initStates.button_text && !initStates.navigateToPage)){
      setValidation(true);
      return;
    } else {
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(initStates),
      }
      dispatch(loadPostWhatsappConfigData(postRequest, goToBackPage))
    }
  }
  const backToTemplateConfig = () => {
    setSenderConfig(false)
  }
  // #endregion

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <CommonNavFile toggleshift={toggleshift} setToggleshift={setToggleshift} />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <Row className="notification-template-header">
            <Col md={4} className="cat-col">
              <div className="page-header-text-div">
                <div className="back-btn" onClick={goToBackPage}>
                  <img
                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                    alt=""
                  />
                </div>
                <h6 className="page-header-text">Whatsapp Push Notification Template</h6>
              </div>
            </Col>
            <Col md={8} className="cat-col">
              <div
                className="search-bar-header"
                style={{ float: "right" }}
              >
                <div className="page-top-bar-dropdowns page-top-Filters">
                  <div className="page-top-bar-dropdowns">
                    <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      options={userList}
                      placeholder="Select Subscription Id"
                      getOptionValue={getOptionValue}
                      getOptionLabel={getOptionLabel}
                      value={selectedSubscribe.label && selectedSubscribe}
                      // onInputChange={userList.label}
                      formatOptionLabel={formatOptionLabel}
                      onChange={setFilterUserId}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {!senderConfig ?
            <ConfigurationTemplate editTemplateName={editTemplateName} templateName={templateName}
            setTemplateName={setTemplateName} setEditTemplateName={setEditTemplateName}
            saveDataAndContinue={saveDataAndContinue} 
            handleDropDown={handleDropDown} initStates={initStates} handleFormInput={handleFormInput}
            setLocalImage={setLocalImage} localImage={localImage} handleBody={handleBody}
            enteredMsg={enteredMsg} veriables={veriables} openVeriableModal={openVeriableModal}
            handleVeriableInput={handleVeriableInput} deleteVeriable={deleteVeriable} openButtonVeriable={openButtonVeriable}
            validation={validation}
            addBtnPrameters={addBtnPrameters} removeBtnPrameters={removeBtnPrameters} goToBackPage={goToBackPage} />
            :
            <ConfigurationFile backToTemplateConfig={backToTemplateConfig} saveTemplateDetails={saveTemplateDetails}
            handleDropDown={handleDropDown} initStates={initStates} handleFormInput={handleFormInput}
            setInitStates={setInitStates} veriables={veriables} validation={validation} />
          }
        </div>
      </div>
      <Footer />

      {/* modal for adding veriables */}
      <Modal
        show={showVeriableModal}
        onHide={handleVeriableModal}
        className="main_model add-edit-model" >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">ADD VERIABLES</h6>
          <i className="fa fa-times" onClick={handleVeriableModal} />
        </div>
        <form onSubmit={setVeriablesValue}>
          <div className="container add-edit">
            <div className="product-inputs">
              <div className="mb-2">
                <FloatingLabel label='Veriable Name' className={`${veriableName ? "float-input-visible" : "float-hidden float-input"}`}>
                  <input
                    type="text"
                    className="form-control form add-input"
                    placeholder="Veriable Name"
                    value={veriableName}
                    onChange={(e) => setVeriableName(e.target.value)}
                  />
                </FloatingLabel>
              </div>
            </div>
          </div>
          <div className="btns-save-cancel">
          <SBSaveUpdateBtn btnName="Add" />
          <SbCancelBtn btnName="Cancel" onClickEffect={handleVeriableModal} />
        </div>
        </form>
      </Modal>
      {/* modal for adding veriables */}
    </div>
  );
}

export default WhatsappConfig;
