import { useState } from "react";
import Select from "react-select";
import productimg1 from "../../images/DefaultImages/emptyProduct.svg";
import "../../styles/AddUserModel.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { SBSaveUpdateBtn, SbBulkUpload } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import LoadingAnimation from "../LoadingAnimation";
import { baseURL } from "../BaseUrl";

const AddMachineBulk = (props) => {
  // const [show, setShow] = useState(false);
  const closeModel = () => {
    props.handleCloseBulk();
  };

  const [bulkUploadResponse, setBulkUploadResponse] = useState({
    res: null,
    message: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [uploadExcelFile, setUploadExcelFile] = useState("");

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadExcelFile(fileUploaded);
  };
  return (
    <div>
      {/* Button to trigger the modal */}

      {/* Bootstrap Modal */}
      <div
        show={props.showBulk}
        onHide={props.handleCloseBulk}
      // className={`modal ${props.show ? "show" : ""}`} tabIndex="-1" role="dialog" style={{ display: props.show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-fullscreen-xxl-down">
          <div className="modal-content add-machine-page-container">
            <div>
              <div className="bubble-image">
                <img src="../../images/BG/about_s4_bubble.png" alt="" />
              </div>
              <div className="circle-image">
                <img src="../../images/BG/choose_lines.svg" alt="" />
              </div>
              <div className="lines-image">
                <img src="../../images/BG/lines.png" alt="" />
              </div>
            </div>


            <div className="animation-bulk-container">
              <div className="bubble-image-small">
                <img src="../../images/BG/about_s4_bubble.png" alt="" />
              </div>
              <div className="circle-image-small">
                <img src="../../images/BG/choose_lines.svg" alt="" />
              </div>
            </div>


            <div className="modal-header add-user-title">
              <div className="back-btn" onClick={closeModel} >
                <img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" />
              </div>
              <h4 className="modal-title">ADD BULK AMC</h4>
              {/* <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={closeModel}
              ></button> */}
            </div>
            <div className="px-0 modal-body">
              <div className="container-fluid add-bulk-container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-lg-5 col bulk-upload-static-image">
                    <img src="../../images/BG/bulkUpload3.svg" alt="" />
                  </div>
                  <div className="col-lg-4 col">
                    <div className="d-block">
                      <div
                        className="row add-user-image-container-div align-items-baseline"
                        style={{ marginTop: "10px" }}
                      >
                        <div >
                          <div className="mb-3">
                            <input
                              className="form-control add-user-input"
                              id="bulkuserupload"
                              type="file"
                              name="bulkUserUpload"
                              accept=".xls, .xlsx"
                              onChange={handleChange}
                              placeholder="Add File"
                            />
                          </div>
                        </div>

                      </div>
                      <div >
                        <span className="bulk-temp-and-res-message">
                          {isLoading ? (
                            <>
                              <span className="color-green">
                                Checking excel file to upload please wait...
                              </span>
                              &nbsp;
                              <div className="m-1 pt-2">
                                <LoadingAnimation />
                              </div>
                            </>
                          ) : (
                            <span
                              className={
                                bulkUploadResponse.res
                                  ? "upload-res-message success"
                                  : "upload-res-message false"
                              }
                            >
                              {bulkUploadResponse.message}
                            </span>
                          )}
                          <a
                            href={`${baseURL}/TravelizeProData/SUB0000000001/Data/BulkUploadTemplate/Excel_BulkUser_20231027153419210.xlsx`}
                            className="btn template-download"
                            download={"BulkUploadTemplate"}
                          >
                            <i className="fa fa-download" /> &nbsp;Template Download
                          </a>
                        </span>
                      </div>
                      <div >
                        <SbBulkUpload
                          btnName="Bulk Upload"
                        // onClickEffect={addBulkUser}
                        />
                      </div>
                    </div>

                  </div>



                  {/* </div> */}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMachineBulk;


