import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Products.scss";
import "../../styles/AddPage.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import Footer from "../Footer";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import AnnualContractTable from "./AnnualContractTable";
import AnnualContractAttachments from "./AnnualContractAttachments";
import AddBulkAnnualContract from "./AddBulkAnnualContract";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import DatePicker from "react-datepicker";
import Select from "react-select";
import EditAnnualContract from "./EditAnnualContract";
import moment from "moment";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import AddAnnualContractData from "./AddAnnualContractData";
import AddAnnualContract from "./AddAnnualContract";
import { useDispatch, useSelector } from "react-redux";
import { loadAmcData, loadSingleAmcData } from "../../redux/action";

function AnnualContract() {
  let tableHeader = [
    { name: "AMC DETAILS", align: "left", width: "200px" },
    { name: "PRODUCT DETAILS", align: "left", width: "200px" },
    { name: "COMPANY DETAILS", align: "left", width: "200px" },
    { name: "ACTION", align: "left", width: "200px" },
  ];

  let dispatch = useDispatch();

  const { getAmcData } = useSelector((state) => state.data);
  console.log(getAmcData, 'getAmcData')

  const amcData = [
    {
      number: "AMC-12345",
      type: "Premium",
      invoiceDate: "14-02-2024",
      startDate: "14-02-2024",
      endDate: "14-02-2025",
      freeService: "365 days",
      productName: "Mobile",
      code: "M-001",
      serialNo: "SL0012345",
      modalName: "Galaxy S3",
      modalNo: "GS-01",
      companyName: "Cargo Electronics",
      contactPerson: "Dummy",
      email: "cargoelectronics@gmail.com",
      phone: "9848488488",
      address:
        "1st Floor,Mallikarjun Avenue,Koppikar Road,Hubli-580023,D|| Dharwad, Karnataka",
      lat: "12.9813217,77.57657",
      long: "12.9813217,77.57657",
    },
  ];

  let navigate = useNavigate();
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [showBulk, setShowBulk] = useState(false);

  const [menuCollapse, setMenuCollapse] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [PageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterArray, setFilterArray] = useState([]);
  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [toDate, setToDate] = useState(new Date());

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [pageDataSize, setPageDataSize] = useState(0);
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const handleClose = () => {
    setShow(!show);
  };

  const handleCloseBulk = () => {
    setShowBulk(!showBulk);
  };

  const [viewAttachments, setViewAttachments] = useState("");

  const handleViewAttachments = () => {
    navigate("/annualContractAttachments");
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  //* EXPORT FILE START *//

  const [showEdit, setShowEdit] = useState(false)
  const [amcNum, setAmcNum] = useState("")
  const [filteredAmc, setFilteredAmc] = useState([]);
  const EditAmc = (amcId) => {
    setAmcNum(amcId)
    setShow(!show);
    const filteredAmc = getAmcData.filter((data) => { return data.amcNumber === amcId })
    console.log(filteredAmc, 'filteredAmc')
    setFilteredAmc(filteredAmc)
  }
  const handleCloseEdit = () => {
    setShowEdit(false)
  }
  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if (end) {
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      }
      if (start && end == null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "ToDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    }
    setFilterArray(list);
  };

  useEffect(() => {
    dispatch(loadAmcData())
  }, [])

  return (
    <div id="main-page">
      {!show && !showBulk && !viewAttachments && !showEdit && (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div className="clients-table">
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">
                          ANNUAL MAINTENANCE CONTRACT
                        </h6>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                      <div className="input-div">
                        <input
                          type="search"
                          id="metricInfo_search_bar"
                          placeholder="Search"
                          className="form-control setting-input"
                          value={searchValue}
                        //   onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className="fa fa-search search-icon" />
                      </div>
                    </Col>
                    <Col md={4} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setFilter(!filter)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          {/* <SbAddBtn onClickEffect={() => setShow(!show)} /> */}

                          <SbDropDownBtn
                            openAddModal={() => setShow(!show)}
                            // openAddModal={handleClose}
                            addBulkModel={handleCloseBulk}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {filter && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={12}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div style={{ width: "100%", padding: "0px 5px" }}>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  // options={clientDropdown}
                                  placeholder="Select Company"
                                  // value={filterClientName.value && filterClientName}
                                  // onInputChange={clientDropdown.label}
                                  // onChange={(data) =>
                                  //   setSelectSearchDrop(data, "ClientName")
                                  // }
                                  isSearchable={true}
                                />
                              </div>
                              <div style={{ width: "100%", padding: "0px 5px" }}>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  // options={contactByCompanyDrop}
                                  placeholder="Product Name"
                                  // value={filterUser.value && filterUser}
                                  // onInputChange={contactByCompanyDrop.label}
                                  // onChange={(data) =>
                                  //   setSelectSearchDrop(data, "Reported")
                                  // }
                                  isSearchable={true}
                                />
                              </div>
                              <div style={{ width: "100%", padding: "0px 5px" }}>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  // options={priorityStatus}
                                  placeholder="Amc Number"
                                  // value={selectPriority.value && selectPriority}
                                  // onInputChange={priorityStatus.label}
                                  // onChange={(data) =>
                                  //   setSelectSearchDrop(data, "priority")
                                  // }
                                  isSearchable={true}
                                />
                              </div>
                              <div style={{ width: "100%", padding: "0px 5px" }}>
                                {/* {loggedInRole != 2 && ( */}
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  // options={compStatus}
                                  placeholder="Amc Type"
                                  // value={filterCompStatus.value && filterCompStatus}
                                  // onInputChange={compStatus.label}
                                  // onChange={(data) =>
                                  //   setSelectSearchDrop(data, "status")
                                  // }
                                  isSearchable={true}
                                />
                                {/* )} */}
                              </div>

                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={7}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                                className="input-group from-to-date-select"
                              >
                                <span className="input-group-text" id="from-label">
                                  From - To
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={fromDate}
                                    className="select from-date date-filter"
                                    id="date-range-picker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange
                                    startDate={fromDate}
                                    endDate={toDate}
                                    onChange={(date) =>
                                      setSelectSearchDrop(date, "date")
                                    }
                                    maxDate={new Date()}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fa fa-calendar-o calander-icon"
                                    style={{ right: "15px" }}
                                  />
                                </span>
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                                className="input-group from-to-date-select"
                              >
                                <SbCancelBtn
                                  // onClickEffect={clearSerachBySelect}
                                  btnName="Clear"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
                <AnnualContractTable
                  tableHeader={tableHeader}
                  getAmcData={getAmcData}
                  handleViewAttachments={handleViewAttachments}
                  EditAmc={EditAmc}
                  amcNum={amcNum}
                />
              </div>
              <div className="download-and-pagination">
                <SbDownloadBtn
                  btnName="Download"
                // onClickEffect={exportComplaintDetails}
                />
              </div>
            </div>

            <Footer />
          </div>
        </>
      )}
      {/* {show && <AddAnnualContract show={show} handleClose={handleClose} />} */}
      {show && <AddAnnualContractData show={show} handleClose={handleClose} amcNum={amcNum} filteredAmc={filteredAmc} setFilteredAmc={setFilteredAmc} />}
      {showBulk && (
        <AddBulkAnnualContract
          showBulk={showBulk}
          handleCloseBulk={handleCloseBulk}
        />
      )}
      {showEdit && <EditAnnualContract showEdit={showEdit} handleCloseEdit={handleCloseEdit} />}
      {viewAttachments && <AnnualContractAttachments />}
    </div>
  );
}

export default AnnualContract;
