import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  OverLayData,
  truncateString,
} from "../../ReUseFunctions/ReuseFunctions";

export default function PurchaseOrderTable(props) {
  return (
    <div>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer
          //   onScroll={(e) =>
          //     handleScroll(
          //       e,
          //       props.users,
          //       props.totalUserCount,
          //       props.setLoading,
          //       props.setPageSize,
          //       props.PageSize
          //     )
          //   }
          >
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="purchase-order-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {props.tableHeader.map((data, i) => (
                    <TableCell key={i} align={data?.align} sx={data?.style}>
                      {data?.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tableData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {" "}
                      <Link to="/vhd/podetails" className="color-green">
                        {data?.po_DocEntry}
                      </Link>
                    </TableCell>
                    <TableCell>{data?.Doc_No}</TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.date}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.vendor_Ref_No}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.vendor_Name}
                    </TableCell>
                    <TableCell style={{ minWidth: "180px" }}>
                      <div>
                        {truncateString(data?.ship_To, 60)} &nbsp;
                        <OverLayData data={data?.ship_To} />
                      </div>
                    </TableCell>
                    <TableCell style={{ minWidth: "180px" }}>
                      <div>
                        {truncateString(data?.bill_To, 60)} &nbsp;
                        <OverLayData data={data?.bill_To} />
                      </div>
                    </TableCell>
                    <TableCell style={{ minWidth: "100px" }}>
                      {data?.po_Value}
                    </TableCell>
                    <TableCell style={{ minWidth: "100px" }}>
                      {data?.tax_Amount}
                    </TableCell>
                    <TableCell>{data?.doc_Total}</TableCell>
                    <TableCell style={{ minWidth: "180px" }}>
                      <div>
                        {truncateString(data?.remarks, 50)} &nbsp;
                        <OverLayData data={data?.remarks} />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className={`po ${data.status}`}>
                        <span className="status-text">{data.status}</span>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="edit-view-link">
                        {/* <div
                          className="edit-icon-text"
                          onClick={() => {
                            props.openCloseAddEditModel();
                            props.setPurchaseOrderID(2);
                          }}
                        >
                          <span className="hv-center">
                            <i className="fa fa-edit" />
                            Edit
                          </span>
                        </div> */}
                        <div className="view-icon-text">
                          <Link to="/vhd/podetails">
                            <span className="hv-center">
                              <i className="fa fa-eye" />
                              View
                            </span>
                          </Link>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
