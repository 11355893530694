import { useState } from 'react';
import '../../styles/FeedBack.scss'
import EmojiPicker from './EmojiPicker';

const ViewFeedBack = () => {

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState('');
    const [textareaValue, setTextareaValue] = useState('');
    const [messages, setMessages] = useState([]);

    const handleEmojiSelect = (emoji) => {
        setSelectedEmoji(emoji);
        setTextareaValue(textareaValue + emoji.native);
        setShowEmojiPicker(false); // Hide emoji picker after selection
    };

    const handleSendMessage = () => {
        if (textareaValue.trim() !== '') {
            const newMessage = {
                text: textareaValue,
                sender: 'user', // Assuming user is sending the message
                time: new Date().toLocaleTimeString(),
            };
            setMessages([...messages, newMessage]);
            setTextareaValue(''); // Clear the textarea after sending the message
        }
    };

    const startRecording = () => {
        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = 'en-US';
        recognition.onresult = function (event) {
            const speechToText = event.results[0][0].transcript;
            setTextareaValue(prevValue => prevValue + speechToText);
        };
        recognition.start();
    };

    const [recognition, setRecognition] = useState(null);
    const stopRecording = () => {
        if (recognition) {
            recognition.stop();
        }
    };
    return (
        <div className="feedback-view-container">
            <div className="col-sm-4">
                <div className="row heading">
                    <div className="col-sm-3 col-xs-3 heading-avatar">
                        <div className="heading-avatar-icon">
                            <img src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png" alt="" />
                        </div>
                    </div>

                </div>
                <div className="row searchBox">
                    <div className="col-sm-12 searchBox-inner">
                        <div className="form-group has-feedback">
                            <input id="searchText" type="text" className="form-control" name="searchText" placeholder="Search" />
                            <span className="glyphicon glyphicon-search form-control-feedback"></span>
                        </div>
                    </div>
                </div>
                <div className="row sideBar">
                    <div className="row sideBar-body">
                        <div className="col-sm-3 col-xs-3 sideBar-avatar">
                            <div className="avatar-icon">
                                <img src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png" />
                            </div>
                        </div>
                        <div className="col-sm-9 col-xs-9 sideBar-main">
                            <div className="row">
                                <div className="col-sm-8 col-xs-8 sideBar-name">
                                    <span className="name-meta">Dummy
                                    </span>
                                </div>
                                <div className="col-sm-4 col-xs-4 pull-right sideBar-time">
                                    <span className="time-meta pull-right">18:18
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-8 conversation">
                <div className="row heading">
                    <div className="col-sm-2 col-md-1 col-xs-3 heading-avatar">
                        <div className="heading-avatar-icon">
                            <img src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png" alt="" />
                        </div>
                    </div>
                    <div className="col-sm-8 col-xs-7 heading-name">
                        <a className="heading-name-meta">Dummy
                        </a>
                        <span className="heading-online">Online</span>
                    </div>
                    {/* <div className="col-sm-1 col-xs-1  heading-dot pull-right">
                        <i className="fa fa-ellipsis-v fa-2x  pull-right" aria-hidden="true"></i>
                    </div> */}
                </div>
                <div className='row message'>
                    <div className="row message-body">
                        <div className="col-sm-12 message-main-sender">
                            <div className="sender">
                                <div className="message-text">
                                    Hi there, I recently purchased one of your printers, and I'm experiencing some issues with its connectivity. It seems to be disconnecting frequently, and I'm having trouble getting it to stay connected to my network. Can you help me troubleshoot this problem?
                                </div>
                                <span className="message-time pull-right">
                                    Sun
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row message-body">
                        <div className="col-sm-12 message-main-receiver">
                            <div className="receiver">
                                <div className="message-text">
                                    Hello! Thank you for reaching out to us about this issue with your printer's connectivity. We're sorry to hear that you're experiencing difficulties. Let's try a few troubleshooting steps to see if we can resolve the issue. First, could you please confirm if the printer is connected to the power source and turned on?
                                </div>
                                <span className="message-time pull-right">
                                    Sun
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row message-body">
                        <div className="col-sm-12 message-main-sender">
                            <div className="sender">
                                <div className="message-text">
                                    Yes, the printer is connected to the power source, and it's turned on. I've also checked the network connections, and everything seems to be properly set up on my end.
                                </div>
                                <span className="message-time pull-right">
                                    Sun
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row message-body">
                        <div className="col-sm-12 message-main-receiver">
                            <div className="receiver">
                                <div className="message-text">
                                    Thank you for confirming. Let's try restarting the printer and your router to see if that helps. Sometimes, a simple restart can resolve connectivity issues. Please turn off both devices, wait for a few minutes, and then turn them back on. After they've restarted, try reconnecting the printer to your network and let me know if the issue persists.
                                </div>
                                <span className="message-time pull-right">
                                    Sun
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row message-body">
                        <div className="col-sm-12 message-main-sender">
                            <div className="sender">
                                <div className="message-text">
                                    Yes, the printer is connected to the power source, and it's turned on. I've also checked the network connections, and everything seems to be properly set up on my end.
                                </div>
                                <span className="message-time pull-right">
                                    Sun
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row message-body">
                        <div className="col-sm-12 message-main-receiver">
                            <div className="receiver">
                                <div className="message-text">
                                    I've restarted both the printer and the router, but unfortunately, the issue is still occurring. The printer keeps losing its connection to the network intermittently.
                                </div>
                                <span className="message-time pull-right">
                                    Sun
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='row message'>

                    <div className='row message-body'>
                        {messages.map((message, index) => (
                            <div className={`col-sm-12 message-main-sender`}>
                                <div className="sender">
                                    <div className="message-text">
                                        {message.text}
                                    </div>
                                    <span className="message-time pull-right">
                                        {message.time}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>

                </div> */}
                <div className="row reply">
                    <div className="col-sm-1 col-xs-1 reply-emojis" >
                        <i className="fa fa-smile-o fa-2x" onClick={() => setShowEmojiPicker(!showEmojiPicker)}></i>
                    </div>
                    <div className="col-sm-9 col-xs-9 reply-main">
                        <textarea
                            className="form-control"
                            rows="1"
                            id="comment"
                            value={textareaValue}
                            onChange={(e) => setTextareaValue(e.target.value)}
                        />
                    </div>
                    <div className="col-sm-1 col-xs-1 reply-recording">
                        <i className="fa fa-microphone fa-2x" aria-hidden="true" onClick={startRecording}></i>
                    </div>
                    {/* <div className="col-sm-1 col-xs-1 reply-recording" onClick={stopRecording}>
                        <i className="fa fa-stop-circle fa-2x" aria-hidden="true"></i>
                    </div> */}
                    <div className="col-sm-1 col-xs-1 reply-send">
                        <i className="fa fa-send fa-2x" aria-hidden="true" onClick={handleSendMessage}></i>
                    </div>

                </div>
                {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiSelect} />}
            </div>
        </div >
    );
}

export default ViewFeedBack;