import Select from "react-select";
import productimg1 from "../../images/DefaultImages/emptyProduct.svg";
import '../../styles/AddUserModel.scss'
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { useState } from "react";
import { FloatingLabel } from "react-bootstrap";

const EditMachineModel = (props) => {

    // const [show, setShow] = useState(false);
    const [machineCode, setMachineCode] = useState("")
    const [machineName, setMachineName] = useState("")
    const [category, setCategory] = useState({ value: "", label: "" })
    const [subCategory, setSubCategory] = useState({ value: "", label: "" })
    const [company, setCompany] = useState({ value: "", label: "" })
    const [warrentyStatue, setWarrentyStatus] = useState({ value: "", label: "" })
    const [amc, setAmc] = useState({ value: "", label: "" })
    const [brand, setBrand] = useState("")
    const [capacity, setCapacity] = useState("")
    const [height, setHeight] = useState("")
    const [width, setWidth] = useState("")
    const [length, setLength] = useState("")
    const [weight, setWeight] = useState("")
    const [mrp, setMrp] = useState("")
    const [retailPrice, setRetailPrice] = useState("")
    const [location, setLocation] = useState("")
    const [description, setDescription] = useState("")
    const [instruction, setInstruction] = useState("")
    const closeModel = () => {
        props.handleCloseEdit();
    };
    return (
        <div>
            <div
                show={props.show}
                onHide={props.handleClose}
            >
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content add-machine-page-container">
                        <div>
                            <div className="bubble-image">
                                <img src="../../images/BG/about_s4_bubble.png" alt="" />
                            </div>
                            <div className="circle-image">
                                <img src="../../images/BG/choose_lines.svg" alt="" />
                            </div>
                            {/* <div className="left-image">
                                <img src="../../images/BG/leftImage.png" alt="" />
                            </div> */}
                            <div className="lines-image">
                                <img src="../../images/BG/lines.png" alt="" />
                            </div>
                            {/* <div className="right-image">
                                <img src="../../images/BG/ser_shap_2.png" alt="" />
                            </div> */}
                        </div>

                        <div className="modal-header add-user-title">
                            <div className="back-btn" onClick={closeModel} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                            <h4 className="modal-title">UPDATE MACHINE</h4>
                            {/* <button type="button" className="btn-close" aria-label="Close" onClick={closeModel}></button> */}
                        </div>
                        <div className="px-0 modal-body">
                            <div className="container-fluid add-user-container">
                                <div className="row">
                                    <div className="col-lg-3 col">
                                        {/* <div className="main-logo-image"> */}
                                        <div className="add-user-main-logo-image">
                                            <img
                                                className="add-user-images"
                                                // src={localImage ? localImage : profileimg}
                                                src={productimg1}
                                                alt="Profile Images"
                                            />
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn upload-logo"
                                                // onClick={uploadClick}
                                                >
                                                    {/* <img
                                                    src="../images/uploadImage/camera.svg"
                                                    alt="camera"
                                                /> */}
                                                    <i className="bi bi-camera-fill" />
                                                </button>
                                                <input
                                                    id="getFile"
                                                    type="file"
                                                    name="filetobase64"
                                                    accept="image/*"
                                                    className="btn-primary upload"
                                                    // ref={hiddenFileInput}
                                                    // onChange={handleChange}
                                                    style={{ display: "none" }}
                                                />
                                            </div>

                                        </div>
                                        <p style={{ fontSize: "10px", textAlign: "center" }}>
                                            Supports Only JPG, JPEG, PNG
                                        </p>
                                    </div>
                                    <div className="col-lg-9 col">
                                        <div>
                                            <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Machine Code/Number"
                                                            className={`${machineCode
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Machine Code/Number"
                                                                name="productCode"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Machine Name"
                                                            className={`${machineName
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Machine Name"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Select Category"
                                                            className={`${category.value !== ""
                                                                ? "float-visible-select"
                                                                : "float-hidden float-select"
                                                                }`}
                                                        >
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                placeholder="Category"
                                                                isSearchable={true}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row add-user-image-container-div">

                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Select Sub Category"
                                                            className={`${subCategory.value !== ""
                                                                ? "float-visible-select"
                                                                : "float-hidden float-select"
                                                                }`}
                                                        >
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                placeholder="Sub Category"
                                                                isSearchable={true}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Company"
                                                            className={`${company.value !== ""
                                                                ? "float-visible-select"
                                                                : "float-hidden float-select"
                                                                }`}
                                                        >
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                placeholder="Company"
                                                                isSearchable={true}
                                                            />
                                                        </FloatingLabel>
                                                    </div>

                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Warrenty Status"
                                                            className={`${warrentyStatue.value !== ""
                                                                ? "float-visible-select"
                                                                : "float-hidden float-select"
                                                                }`}
                                                        >
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                placeholder="Warrenty Status"
                                                                isSearchable={true}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row add-user-image-container-div">


                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="AMC"
                                                            className={`${amc.value !== ""
                                                                ? "float-visible-select"
                                                                : "float-hidden float-select"
                                                                }`}
                                                        >
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                placeholder="AMC"
                                                                isSearchable={true}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Brand"
                                                            className={`${brand ? "float-input-visible" : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Brand"
                                                                name="brand"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Capacity"
                                                            className={`${capacity ? "float-input-visible" : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Capacity"
                                                                name="capacity"
                                                            />
                                                        </FloatingLabel>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row add-user-image-container-div">
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Length"
                                                            className={`${length
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Length"
                                                                name="length"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Width"
                                                            className={`${width ? "float-input-visible" : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Width"
                                                                name="width"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Height"
                                                            className={`${height
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Height"
                                                                name="height"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row add-user-image-container-div">

                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Weight"
                                                            className={`${weight
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Weight"
                                                                name="weight"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="MRP"
                                                            className={`${mrp ? "float-input-visible" : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="MRP"
                                                                name="mrp"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Retail Price"
                                                            className={`${retailPrice ? "float-input-visible" : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control add-user-input"
                                                                placeholder="Retail Price"
                                                                name="price"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row add-user-image-container-div">
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Location"
                                                            className={`${location
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <textarea
                                                                rows="3"
                                                                cols="20"
                                                                name="instruction"
                                                                wrap="hard"
                                                                className="form-control form-textarea add-product-input"
                                                                id="descriptionArea"
                                                                placeholder="Location"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Description"
                                                            className={`${description
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <textarea
                                                                rows="3"
                                                                cols="20"
                                                                name="description"
                                                                wrap="hard"
                                                                className="form-control form-textarea add-product-input"
                                                                id="descriptionArea"
                                                                placeholder="Description"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <FloatingLabel
                                                            label="Instruction"
                                                            className={`${instruction
                                                                ? "float-input-visible"
                                                                : "float-hidden float-input"
                                                                }`}
                                                        >
                                                            <textarea
                                                                rows="3"
                                                                cols="20"
                                                                name="instruction"
                                                                wrap="hard"
                                                                className="form-control form-textarea add-product-input"
                                                                id="descriptionArea"
                                                                placeholder="Instruction"
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row add-user-image-container-div">
                                                <div className="col-lg-4 col">
                                                    <div className="mb-3">
                                                        <div>
                                                            <button type="button" className="btn btn-add-product-img">
                                                                <span
                                                                    style={{
                                                                        color: "#04A96D",
                                                                        fontSize: "13px",
                                                                        fontWeight: "700",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="d-flex justify-content-center"
                                                                        onDragOver={(e) => e.preventDefault()}
                                                                    // onDrop={handleDrop}
                                                                    >

                                                                    </div>

                                                                    <div
                                                                        className="drop-area"
                                                                        onDragOver={(e) => e.preventDefault()}
                                                                    // onDrop={handleDrop}
                                                                    >
                                                                        <h6>Upload Machine Images</h6>
                                                                        <br />
                                                                        {/* <img
                                                                            src="../../../images/DefaultImages/UploadImages.svg"
                                                                            alt=""
                                                                        />
                                                                        <p>Drag and drop images here</p>
                                                                        <p>OR</p> */}
                                                                        <SbCancelBtn
                                                                            btnName="Browse"
                                                                        // onClickEffect={uploadMultiple}
                                                                        />
                                                                        <p style={{ fontSize: "11px" }}>
                                                                            Supports Only JPG, JPEG, PNG
                                                                        </p>
                                                                    </div>
                                                                    {/* )} */}
                                                                </span>
                                                            </button>
                                                            <input
                                                                id="getFile"
                                                                type="file"
                                                                multiple
                                                                name="filetobase64"
                                                                accept="image/*"
                                                                className="btn-primary upload"
                                                                // ref={multipleFileInput}
                                                                // onChange={handleChangeMultipleImg}
                                                                style={{ display: "none" }}
                                                            />
                                                        </div>
                                                        {/* {selectLength && (
                                                            <small id={`Error`} className="form-text text-muted ">
                                                                Please Select maximum 6 images
                                                            </small>
                                                        )} */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-page-save-cancel">
                                    <SBSaveUpdateBtn btnName="Update" />
                                    <SbCancelBtn
                                        btnName="Cancel"
                                    // onClickEffect={() => {
                                    //     props.handleClose();
                                    // }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EditMachineModel;

