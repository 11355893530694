import { useState } from 'react';
import '../../styles/AddUserModel.scss'
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { Col, Row } from 'react-bootstrap';

const AnnualContractStatus = (props) => {
    // const [show, setShow] = useState(false);
    const [status, setStatus] = useState('Approved'); // State to track the selected status

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };
    const closeModel = () => {
        props.handleShowStatus();
    };
    return (
        <div>
            {/* Button to trigger the modal */}

            {/* Bootstrap Modal */}
            <div
                show={props.showStatus}
                onHide={props.handleShowStatus}
            >
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header add-user-title">
                            <div className="back-btn" onClick={closeModel} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                            <h4 className="modal-title">AMC STATUS</h4>
                            {/* <button type="button" className="btn-close" aria-label="Close" onClick={closeModel}></button> */}
                        </div>
                        <div className="px-0 modal-body">
                            <div className="container-fluid add-user-container">
                                <div className="clients-table">
                                    <Row className='search-row order-details-row'>
                                        <Col md={4} className='cat-col'>
                                            <ul className="order-detail-list">
                                                <li><div className="span1">COMPANY:</div> &nbsp;&nbsp;<div className="span2"><b>Confident Dental</b></div></li>
                                                <li><div className="span1">Email:</div> &nbsp;&nbsp;<div className="span2"><b>ravi@gmail.con</b></div></li>
                                                <li><div className="span1">Phone:</div> &nbsp;&nbsp;<div className="span2"><b>7975525868</b></div></li>
                                                <li><div className="span1">Reported by:</div> &nbsp;&nbsp;<div className="span2"><b>Ravi
                                                </b></div></li>
                                            </ul>
                                        </Col>

                                        <Col md={4} className='cat-col' >
                                            <ul className="order-detail-list" style={{ float: "right" }}>
                                                <li>Complaint #: &nbsp;&nbsp;<b>120</b></li>
                                                <li>Complaint Date:&nbsp;&nbsp;<b>18 Feb 2024</b></li>
                                                <li>Product:&nbsp;&nbsp;<b>ENDOMATE TC2</b></li>
                                                <li>Issue:&nbsp;&nbsp;<b>	Issue with Endomate</b></li>
                                            </ul>
                                        </Col>
                                        <Col md={4} className='cat-col' >
                                            <ul className="order-detail-list" style={{ float: "right" }}>
                                                <li>In Warrenty: &nbsp;&nbsp;<b>Yes</b></li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-3">
                                    <span className="enable-status d-flex">
                                        <h6>Enable</h6>
                                        <span className="enable-radio d-flex">
                                            <div className="form-check enable-radio-status">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    id="approved"
                                                    value="Approved"
                                                    checked={status === 'Approved'}
                                                    onChange={handleStatusChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault1"
                                                >
                                                    Approved
                                                </label>
                                            </div>
                                            <div className="form-check enable-radio-status">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    id="rejected"
                                                    value="Rejected"
                                                    checked={status === 'Rejected'}
                                                    onChange={handleStatusChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault2"
                                                >
                                                    Rejected
                                                </label>
                                            </div>


                                        </span>

                                    </span>
                                    {status === "Rejected" && <div className="row add-user-image-container-div">
                                        <div className="col-lg-4 col">
                                            <div className="mb-3 mt-3">
                                                {/* <FloatingLabel
                                                            label="Remark"
                                                            className={`${instruction
                                                                    ? "float-input-visible"
                                                                    : "float-hidden float-input"
                                                                }`}
                                                        > */}
                                                <textarea
                                                    rows="3"
                                                    cols="20"
                                                    name="instruction"
                                                    wrap="hard"
                                                    className="form-control form-textarea add-product-input"
                                                    id="descriptionArea"
                                                    placeholder="Remark"
                                                // value={instruction || ""}
                                                // onChange={handleProductInput}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="btns-save-cancel">
                                    <SBSaveUpdateBtn btnName="Save" />
                                    <SbCancelBtn
                                        btnName="Cancel"
                                        onClickEffect={() => {
                                            // props.handleClose();
                                            closeModel()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AnnualContractStatus;

