import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import productimg1 from "../../images/DefaultImages/emptyProduct.svg";

function AccordianPage(props) {
    // alert((props.activeKey))
    // const [activeKey, setActiveKey] = useState(props.activeKey);

    // useEffect(()=> {
    //     if(props.activeKey){
    //         setActiveKey(props.activeKey)
    //     }
    // }, [props.activeKey])

    const accordianBody = (activeKey) => {
        if (activeKey) {
            let body = <Accordion defaultActiveKey={`${activeKey}`} flush>
                <Card>
                    {props.showMachineDetails && (
                        <Accordion.Item eventKey="0">
                            <Accordion.Header >
                                Machine Details
                            </Accordion.Header>
                            <Accordion.Body>
                                {/* <div className="amc-second-container-header">
                        <h5>Machine Details</h5>
                    </div> */}
                                <div className="amc-second-container">
                                    <div className="amc-second-container-labels">
                                        <span>
                                            <p>Machine Code</p>
                                            : <b>Mach01</b>
                                        </span>
                                        <span>
                                            <p>Machine Name</p>
                                            : <b>JCB</b>
                                        </span>
                                        <span>
                                            <p>Category</p>
                                            : <b>JCB</b>
                                        </span>
                                        <span>
                                            <p>SubCategory</p>
                                            : <b>JCB</b>
                                        </span>
                                        <span>
                                            <p>MRP</p>
                                            : <b>40,00,00,000</b>
                                        </span>
                                        <span>
                                            <p>Retail Price</p>
                                            : <b>35,00,00,000</b>
                                        </span>
                                        <span>
                                            <p>Description</p>
                                            : <b>NA</b>
                                        </span>
                                        <span>
                                            <p>Instruction</p>
                                            : <b>Careful while using</b>
                                        </span>
                                    </div>
                                    <div >
                                        <img
                                            className="pan-card-image"
                                            src={productimg1}
                                            alt=""
                                            style={{ height: "200px", width: "200px", padding: "5px" }}
                                        />
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                    {props.showAmcDetails && (
                        <Accordion.Item eventKey="1">
                            <Accordion.Header >
                                AMC Details
                            </Accordion.Header>
                            <Accordion.Body>
                                {/* <div className="amc-second-container-header">
                        <h5>AMC Details</h5>
                    </div> */}
                                <div className="amc-second-container">
                                    <div>
                                        <div className="amc-second-container-labels">
                                            <span>
                                                <p>Company</p>
                                                : <b>JCB</b>
                                            </span>
                                            <span>
                                                <p>Warrenty Status</p>
                                                : <b>Experied</b>
                                            </span>
                                            <span>
                                                <p>AMC</p>
                                                : <b>NA</b>
                                            </span>
                                            <span>
                                                <p>Brand</p>
                                                : <b>JCB</b>
                                            </span>
                                            <span>
                                                <p>Location</p>
                                                : <b>02, Bangalore, Kempegowda, Sevashrama, Bengaluru, Karnataka 560009, India</b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                    {props.showMachineDimensions && (
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                Machine Dimensions
                            </Accordion.Header>
                            <Accordion.Body>
                                {/* <div className="amc-second-container-header">
                        <h5>Machine Dimensions</h5>
                    </div> */}
                                <div className="amc-second-container">
                                    <div>
                                        <div className="amc-second-container-labels">
                                            <span>
                                                <p>Length</p>
                                                : <b>7 m</b>
                                            </span>
                                            <span>
                                                <p>Width</p>
                                                : <b>3 m</b>
                                            </span>
                                            <span>
                                                <p>Height</p>
                                                : <b>7 m</b>
                                            </span>
                                            <span>
                                                <p>Weight</p>
                                                : <b>5 ton</b>
                                            </span>
                                            <span>
                                                <p>Capacity</p>
                                                : <b>5 ton</b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </Card>
            </Accordion>
            return body;
        }
    }
    return (
        // <div>
        //   {(props.showMachineDetails || props.showAmcDetails || props.showMachineDimensions) &&
        <div className="col-lg-6 col machine-details-cards">
            {accordianBody(props.activeKey)}
        </div>
        // }
        // </div>
    )
}

export default AccordianPage;
