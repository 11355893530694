import '../../styles/AddUserDetails.scss'
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import '../../styles/AddUserModel.scss'
import { useState } from "react";
import productimg1 from "../../images/DefaultImages/emptyProduct.svg";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SalesBee from "../../images/MatricLogo.svg";
import DatePicker from "react-datepicker";
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';

const AddServiceRequestData = (props) => {

    const [complaintDate, setComplaintDate] = useState(new Date());
    const [clientName, setClientname] = useState({ value: "", label: "" })
    const [clientEmail, setClientEmail] = useState("")
    const [contactNum, setContactNum] = useState("")
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [reportedBy, setReportedBy] = useState({ value: "", label: "" });
    const [assignTo, setAssignTo] = useState({ value: "", label: "" });
    const [machineName, setMachineName] = useState({ value: "", label: "" });
    const [machineCode, setMachineCode] = useState("");
    const [amc, setAmc] = useState({ value: "", label: "" });
    const [issue, setIssue] = useState("");
    const [priority, setPriority] = useState({ value: "", label: "" });
    const [service, setService] = useState("")
    const [location, setLocation] = useState("")

    const [activeKey, setActiveKey] = useState('');

    const [showMachineDetailsForm, setShowMachineDetailsForm] = useState(true);
    const [showMachineDimensionsForm, setShowMachineDimensionsForm] = useState(false);
    const [showMachineImagesForm, setShowMachineImagesForm] = useState(false);
    const [showMachineDetails, setShowMachineDetails] = useState(false);
    const [showMachineDimensions, setShowMachineDimensions] = useState(false);
    const [showAmcDetails, setShowAmcDetails] = useState(false);
    const [showAmcDetailsForm, setShowAmcDetailsForm] = useState(false);
    const [showMachineDetailsHeader, setShowMachineDetailsHeader] = useState(true);
    const [amcDetailsHeader, setShowAmcDetailsHeader] = useState(false);
    const [showMachineDimensionHeader, setShowMachineDimensionHeader] = useState(false);
    const [showMachineImageHeader, setShowMachineImageHeader] = useState(false);
    const [activeMenu, setActiveMenu] = useState(1);

    const toggleMachineDetails = () => {
        setShowMachineDetailsForm(true)
        setShowMachineDimensionsForm(false);
        setShowAmcDetailsForm(false)
        setShowMachineImagesForm(false);
        setShowMachineDetails(false)
        setShowAmcDetails(false)
        setShowMachineDimensions(false)
        setShowMachineDetailsHeader(true)
        setShowAmcDetailsHeader(false)
        setShowMachineDimensionHeader(false)
        setShowMachineImageHeader(false)
        setActiveMenu(1);
    }
    const toggleAmcDetails = () => {
        setShowAmcDetailsForm(true)
        setShowMachineDimensionsForm(false);
        setShowMachineDetailsForm(false)
        setShowMachineImagesForm(false);
        setShowMachineDetails(true);
        setShowAmcDetails(false)
        setShowMachineDimensions(false)
        setShowAmcDetailsHeader(true)
        setActiveMenu(2);
        setActiveKey('0')

        setShowMachineDetailsHeader(false)
        setShowMachineDimensionHeader(false)
        setShowMachineImageHeader(false)
    };
    const toggleMachineImages = () => {
        setShowMachineImagesForm(true);
        setShowMachineDetailsForm(false);
        setShowMachineDimensionsForm(false);
        setShowMachineDetails(true)
        setShowMachineDimensions(true)
        setShowAmcDetails(true);
        setShowAmcDetailsForm(false)
        setShowMachineImageHeader(true)
        setActiveMenu(4);
        setActiveKey('1')

        setShowMachineDetailsHeader(false)
        setShowAmcDetailsHeader(false)
        setShowMachineDimensionHeader(false)
    };

    const closeModel = () => {
        props.handleClose();
    };

    return (
        <div
            show={props.show}
            onHide={props.handleClose}
        >
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className='machines-logo-div'>
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineDetails}>
                        <div className={`add-user-details-menu-numbers ${activeMenu === 1 ? 'active' : 'inactive'}`} >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name">Complaint Details</div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleAmcDetails}>
                        <div className="add-user-details-menus">
                            <div
                                // className="add-user-details-menu-numbers active"
                                className={`add-user-details-menu-numbers ${activeMenu === 2 ? 'active' : 'inactive'}`}
                            >
                                <p className="add-user-details-menu-number-one">
                                    <span>02</span>
                                </p>
                                <div className="add-user-details-menu-name">Issue Details</div>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineImages}>
                        <div className="add-user-details-menus">
                            <div
                                //  className="add-user-details-menu-numbers active"
                                className={`add-user-details-menu-numbers ${activeMenu === 3 ? 'active' : 'inactive'}`}
                            >
                                <p className="add-user-details-menu-number-one">
                                    <span>03</span>
                                </p>
                                <div className="add-user-details-menu-name">Images</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="add-user-details-second-div">
                    {showMachineDetailsHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Complaint Details</h4>
                            <p>Please enter infomation and proceed to the next step.</p>
                        </div>
                    </div>}
                    {amcDetailsHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={toggleMachineDetails} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Issue Details</h4>
                            <p>Please enter issue details and proceed to the next step.</p>
                        </div>
                    </div>}
                    {showMachineImageHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={toggleAmcDetails} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Images</h4>
                            <p>Please upload images and proceed to the next step.</p>
                        </div>
                    </div>}

                    <div style={{ position: " relative" }} className="amc-single-image">

                        {showMachineDetailsForm && <form>
                            {/* <div className="row"> */}

                            <div className="col-lg-10 col">
                                <div>
                                    <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Complaint Date' className={`${complaintDate ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <DatePicker
                                                        selected={complaintDate}
                                                        className={`form-control add-user-input`}
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholder="Complaint Date"
                                                        // onChange={(date) => handleDropDown(date, "ComplaintDate")}
                                                        maxDate={new Date()}
                                                    // readOnly={compaintId}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Client Name' className={`${clientName.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        placeholder="Client Name"
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>                                            </div>
                                        </div>
                                    </div>

                                    <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Client Email' className={`${clientEmail ? "float-input-visible" : "float-hidden float-input"}`}>
                                                    <input
                                                        type="email"
                                                        name="clientEmail"
                                                        className="form-control add-user-input"
                                                        placeholder="Client Email"
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Phone Number' className={`${contactNum ? "float-input-visible" : "float-hidden float-input"}`}>
                                                    <input
                                                        type="tel"
                                                        name="clientPhone"
                                                        className="form-control add-user-input"
                                                        placeholder="Phone Number"
                                                        maxLength={10}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row add-user-image-container-div">
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Reported By' className={`${reportedBy.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        placeholder="Reported By"
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Assign To' className={`${assignTo.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        placeholder="Assign To"
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row add-user-image-container-div">
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Machine Name' className={`${machineName.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        placeholder="Machine Name"
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Machine Code' className={`${machineCode ? "float-input-visible" : "float-hidden float-input"}`}>
                                                    <input
                                                        type="text"
                                                        className="form-control  add-user-input"
                                                        placeholder="Machine Code"
                                                        readOnly={true}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row add-user-image-container-div">
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='AMC' className={`${amc.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        placeholder="AMC"
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='machines-next-arrow-button'>
                                    <span className="material-symbols-outlined" onClick={toggleAmcDetails}>
                                        arrow_circle_right
                                    </span>
                                </div>
                            </div>
                            {/* </div> */}

                        </form>}

                        <div className="d-flex justify-content-between" style={{ padding: "0px 25px" }}>

                            <div className="col-lg-5 col">
                                {showAmcDetailsForm && <form>
                                    <div className="mb-3">
                                        <FloatingLabel label='Priority' className={`${priority.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                            <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                placeholder="Priority"
                                                isSearchable={true}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel label='Issue' className={`${service ? "float-input-visible" : "float-hidden float-input"}`}>
                                            <textarea
                                                rows="4"
                                                name="issue"
                                                wrap="hard"
                                                className="form-control form-textarea add-product-input"
                                                id="descriptionArea"
                                                placeholder="Issue"
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel label='Location' className={`${location ? "float-input-visible" : "float-hidden float-input"}`}>
                                            <textarea
                                                rows="4"
                                                name="address"
                                                wrap="hard"
                                                className="form-control form-textarea add-product-input"
                                                id="descriptionArea"
                                                placeholder="Location"
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className='machines-next-arrow-button'>
                                        <span className="material-symbols-outlined" onClick={toggleMachineImages}>
                                            arrow_circle_right
                                        </span>
                                    </div>
                                </form>}

                                {showMachineImagesForm && <form>
                                    <div className="mb-3">
                                        <button type="button" className="btn btn-add-machine-img">
                                            <span
                                                style={{
                                                    color: "#04A96D",
                                                    fontSize: "13px",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <div
                                                    className="d-flex justify-content-center"
                                                    onDragOver={(e) => e.preventDefault()}
                                                // onDrop={handleDrop}
                                                >

                                                </div>

                                                <div
                                                    className="drop-area"
                                                    onDragOver={(e) => e.preventDefault()}
                                                // onDrop={handleDrop}
                                                >
                                                    <h6>Upload Images</h6>
                                                    <br />
                                                    <img
                                                        src="../../../images/DefaultImages/UploadImages.svg"
                                                        alt=""
                                                    />
                                                    <p>Drag and drop images here</p>
                                                    <p>OR</p>
                                                    <SbCancelBtn
                                                        btnName="Browse"
                                                    // onClickEffect={uploadMultiple}
                                                    />
                                                    <p style={{ fontSize: "11px" }}>
                                                        Supports Only JPG, JPEG, PNG
                                                    </p>
                                                </div>
                                                {/* )} */}
                                            </span>
                                        </button>
                                        <input
                                            id="getFile"
                                            type="file"
                                            multiple
                                            name="filetobase64"
                                            accept="image/*"
                                            className="btn-primary upload"
                                            style={{ display: "none" }}
                                        />
                                    </div>
                                    <div className="add-page-save-cancel">
                                        <SBSaveUpdateBtn btnName="Save" />
                                        <SbCancelBtn
                                            btnName="Cancel"
                                        // onClickEffect={() => {
                                        //     props.handleClose();
                                        // }}
                                        />
                                    </div>
                                </form >}
                            </div>
                            {/* <AccordianPage
                            showMachineDetails={showMachineDetails}
                            showAmcDetails={showAmcDetails}
                            showMachineDimensions={showMachineDimensions}
                            activeKey={activeKey} /> */}

                            <div className="col-lg-6 col machine-details-cards">
                                <Accordion defaultActiveKey="0" activeKey={activeKey} flush>
                                    <Card>
                                        {showMachineDetails && (
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header >
                                                    Complaint Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="amc-second-container">
                                                        <div className="amc-second-container-labels">
                                                            <span>
                                                                <p>Complaint Date</p>
                                                                : <b>04-03-2024</b>
                                                            </span>
                                                            <span>
                                                                <p>Client Name</p>
                                                                : <b>Smart Home</b>
                                                            </span>
                                                            <span>
                                                                <p>Client Email</p>
                                                                : <b>ravi@gmail.com</b>
                                                            </span>
                                                            <span>
                                                                <p>Client Phone Number</p>
                                                                : <b>9989898888</b>
                                                            </span>
                                                            <span>
                                                                <p>Reported By</p>
                                                                : <b>Ravi</b>
                                                            </span>
                                                            <span>
                                                                <p>Assigned To</p>
                                                                : <b>Dummy</b>
                                                            </span>
                                                            <span>
                                                                <p>Machine Name</p>
                                                                : <b>JCB</b>
                                                            </span>
                                                            <span>
                                                                <p>Machine Code</p>
                                                                : <b>JCB-0001</b>
                                                            </span>
                                                            <span>
                                                                <p>AMC</p>
                                                                : <b>NA</b>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                        {showAmcDetails && (
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header >
                                                    Issue Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="amc-second-container">
                                                        <div>
                                                            <div className="amc-second-container-labels">
                                                                <span>
                                                                    <p>Priority</p>
                                                                    : <b>High</b>
                                                                </span>
                                                                <span>
                                                                    <p>Issue</p>
                                                                    : <b>Oil Tank</b>
                                                                </span>
                                                                <span>
                                                                    <p>Location</p>
                                                                    : <b>1st Floor,Mallikarjun Avenue,Koppikar Road,Hubli-580023,D|| Dharwad, Karnataka</b>
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}

                                    </Card>
                                </Accordion>
                            </div>

                        </div>

                    </div>
                </div >

            </div >
        </div >
    );
}

export default AddServiceRequestData;