import React from "react";
import { Link } from "react-router-dom";
import SalesBee from "../images/MatricLogo.svg";

export default function AddEditFormComponent(props) {
  const closeModel = () => {
    props.handleClose();
  };
  return (
    <div>
      <div className="add-user-details-container">
        <div className="add-user-details-first-div">
          <div className="machines-logo-div">
            <div className="logotext">
              <div className="logo profile-mini">
                <Link className="travelize_logo" to="/dashboard">
                  <img
                    src={SalesBee}
                    alt="product-title-img"
                    className="salesbee-logo"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            className="add-user-details-menus"
            // onClick={toggleMachineDetails}
          >
            <div
              className="add-user-details-menu-numbers active"
              //   ${
              // activeMenu === 1 ? "active" : "inactive"
              //   }`}
            >
              <p className="add-user-details-menu-number-one">
                <span>01</span>
              </p>
              <div className="add-user-details-menu-name">
                {" "}
                {props.formName}
              </div>
            </div>
          </div>
        </div>
        <div className="add-user-details-second-div">
          <div className="add-user-details-second-div-header">
            <div className="back-btn" onClick={closeModel}>
              <img
                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                alt=""
              />
            </div>
            <div>
              <h4>{props.formName}</h4>
              <p>{props.formSubTitle}</p>
            </div>
          </div>
          <div className="form-part">{props.childrens}</div>
        </div>
      </div>
    </div>
  );
}
