import React, { useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import Footer from "../Footer";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/JointMeeting.scss";
import JointMeetingTable from "./JointMeetingTable";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";

var tableHeader = [
  { name: "Name", align: "left", width: "250px" },
  { name: "Location", align: "left", width: "250px" },
  { name: "Visit Type", align: "left", width: "100px" },
  { name: "Meeting Date", align: "left", width: "200px" },
  { name: "Meeting Time", align: "left", width: "200px" },
  { name: "Status", align: "center", width: "100px" },
  { name: "ACTION", align: "center", width: "150px" },
];

function JointMeeting() {
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [menuCollapse, setMenuCollapse] = useState(false);
  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">Joint Meetings</h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col">
                  {/* <div className="input-div">
                    <input
                      type="search"
                      id="metricInfo_search_bar"
                      placeholder="Find a Product"
                      className="form-control setting-input"
                      //   value={searchValue}
                      //   onChange={(e) => setSearchData(e.target.value)}
                    />
                    <i className="fa fa-search search-icon" />
                  </div> */}
                </Col>
                {/* <Col md={4} className="cat-col">
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <div
                        className="header-filter-btn"
                        // onClick={() => setFilter(!filter)}
                      >
                        <p className="card-head">
                          <i className="bi bi-sliders" />
                          &nbsp;&nbsp;Filters
                        </p>
                      </div>
                    </div>
                  </div>
                </Col> */}
              </Row>

              {/* {filter && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={priceOther ? 10 : 8}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={prodCategoryDrop}
                                  placeholder="Select Category"
                                  value={
                                    selectCategory === ""
                                      ? ""
                                      : prodCategoryDrop.value
                                  }
                                  onInputChange={prodCategoryDrop.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "category")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={prodSubcategory}
                                  placeholder="Select SubCategory"
                                  value={
                                    selectSubCategory === ""
                                      ? ""
                                      : prodSubcategory.value
                                  }
                                  onInputChange={prodSubcategory.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "subcategory")
                                  }
                                  isSearchable={true}
                                  isDisabled={!selectCategory}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={priceRange}
                                  placeholder="Select Price Range"
                                  value={
                                    selectPrice === "" ? "" : priceRange.value
                                  }
                                  onInputChange={priceRange.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "price")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              {priceOther && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "0px 5px",
                                    }}
                                    className="input-group from-to-date-select"
                                  >
                                    <span className="date-picker-with-icon">
                                      <input
                                        type="text"
                                        className="form-control form add-product-input"
                                        placeholder="Enter Start Price"
                                        name="startPrice"
                                        onChange={(e) =>
                                          handleProductInput(e, "StartPrie")
                                        }
                                      />
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "0px 5px",
                                    }}
                                    className="input-group from-to-date-select"
                                  >
                                    <span className="date-picker-with-icon">
                                      <input
                                        type="text"
                                        className="form-control form add-product-input"
                                        placeholder="Enter End Price"
                                        name="endPrice"
                                        onChange={(e) =>
                                          handleProductInput(e, "EndPrice")
                                        }
                                      />
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )} */}

              {/* Meeting List */}
              {/* <JointMeetingTable tableHeader={tableHeader} /> */}
              {/* <div className="mi-jm-cards-div">
                <div className="mi-jm-card-box">
                  <div className="mi-jm-card"></div>
                </div>
                <div className="mi-jm-card-box">
                  <div className="mi-jm-card"></div>
                </div>
                <div className="mi-jm-card-box">
                  <div className="mi-jm-card"></div>
                </div>
                <div className="mi-jm-card-box">
                  <div className="mi-jm-card"></div>
                </div>
                <div className="mi-jm-card-box">
                  <div className="mi-jm-card"></div>
                </div>
                <div className="mi-jm-card-box">
                  <div className="mi-jm-card"></div>
                </div>
                <div className="mi-jm-card-box">
                  <div className="mi-jm-card"></div>
                </div>
              </div> */}
              <JointMeetingTable tableHeader={tableHeader} />
            </div>
            <div className="download-and-pagination">
              <SbDownloadBtn
                btnName="Download"
                //   onClickEffect={exportComplaintDetails}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default JointMeeting;
