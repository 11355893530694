import { useEffect, useState } from "react";
import { baseURL } from "../BaseUrl";
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";

const ShowCustomeFieldData = ({ meetingId }) => {
  const [customFieldData, setCustomFieldData] = useState(null);

  useEffect(() => {
    if (meetingId) {
      fetch(`${baseURL}/api/CustomeFieldValue/Get?tableID=${meetingId}&customePageID=1`, HeaderAuthentication.getRequestOptions)
        .then((res) => res.json())
        .then((res) => {
          if (res.result.length !== 0) {
            setCustomFieldData(
              res.result.map((data, i) => (
                <div className="meeting-details-custome-fields-container" key={i}>
                  <span>
                    {data.fieldName}: <b>{data.value}</b>
                  </span>
                </div>
              ))
            );
          } else {
            setCustomFieldData(<div>NA</div>);
          }
        })
        .catch((error) => {
          console.error("Error fetching custom field data:", error);
          setCustomFieldData(<div>Error fetching custom field data</div>);
        });
    }
  }, [meetingId]);

  return <div>{customFieldData}</div>;
};

export default ShowCustomeFieldData;
