import React, { useRef, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  OverLayData,
  truncateString,
} from "../../ReUseFunctions/ReuseFunctions";

export default function VendorTable(props) {
  return (
    <div>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer
          //   onScroll={(e) =>
          //     handleScroll(
          //       e,
          //       props.users,
          //       props.totalUserCount,
          //       props.setLoading,
          //       props.setPageSize,
          //       props.PageSize
          //     )
          //   }
          >
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="vendor-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {props.tableHeader.map((data, i) => (
                    <TableCell key={i} align={data?.align} sx={data.width}>
                      {data?.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tableData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.code}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.name}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.contactPerson}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.mobile}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.email}
                    </TableCell>
                    <TableCell style={{ minWidth: "150px" }}>
                      <div>
                        {truncateString(data?.location, 30)} &nbsp;
                        <OverLayData data={data?.location} />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="reporting-to-users color-green cursor-pointer">
                        <i className="fa fa-users" />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className={`${data.status}-status`}>
                        <span className="status-text">{data.status}</span>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="edit-view-link">
                        <div
                          className="edit-icon-text"
                          onClick={() => {
                            props.openCloseAddEditModel();
                            props.setVendorID(2);
                          }}
                        >
                          <span className="hv-center">
                            <i className="fa fa-edit" />
                            Edit
                          </span>
                        </div>
                        {/* <div className="view-icon-text">
                          <span className="hv-center">
                            <i className="fa fa-eye" />
                            View
                          </span>
                        </div> */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
