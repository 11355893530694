import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/BaseFare.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  loadUserBankData,
  loadUserDropdown,
  loadUserReferralsData,
  loadUserReferralsDropDown,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import DatePicker from "react-datepicker";
import moment from "moment";
import UserRefferalsTable from "./UserRefferalsTable";
import Select from "react-select";
import { optionsStatusList } from "../DropdownsData";
import UserRefferalsBank from "./UserRefferalsBank";
import UserBankDetailsModal from "./UserBankDetailsModal";
import Footer from "../Footer";

function UserRefferals() {
  const navigate = useNavigate();

  let tableHeader = [
    { name: "NAME", align: "left", width: "150px" },
    { name: "CONTACT DETAILS", align: "left", width: "200px" },
    { name: "PROJECT NAME", align: "left", width: "150px" },
    { name: "REFERRAL CODE", align: "center", width: "100px" },
    { name: "ADDED ON", align: "center", width: "150px" },
    { name: "STATUS", align: "center", width: "150px" },
    { name: "ACTION", align: "center", width: "150px" },
  ];

  let dispatch = useDispatch();
  const { userReferralsData } = useSelector((state) => state.data);
  const { userReferralsDropdown } = useSelector((state) => state.data);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  useEffect(() => {
    dispatch(loadUserDropdown());
    dispatch(loadUserReferralsDropDown());
    dispatch(loadUserBankData());
  }, []);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const [openFilterTab, setOpenFilterTab] = useState(false);

  // Page navigation
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  //code for edit

  const [updateShow, setUpdateShow] = useState(false);
  const [gfReferelProfileId, setGfReferelProfileId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const addBankDetails = (refId) => {
    setGfReferelProfileId(refId);
    console.log(refId);
    setUpdateShow(!updateShow);
    const filteredData = userReferralsData.filter(
      (data) => data.gfRefProfileID === refId
    );
    setFilteredData(filteredData);
    setOpenBankDetailsModal(false);
  };

  const goToBRefferalInfo = (refId) => {
    navigate("/referenceInfo", { state: { refId: refId } });
  };
  const handleCloseUpdate = () => {
    setGfReferelProfileId("");
    setUpdateShow(!updateShow);
  };

  //code for filter
  const [filterUserId, setFilterUserId] = useState("");
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterArray, setFilterArray] = useState([]);

  //code for search bar

  const [searchValue, setSearchValue] = useState("");

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter((item) => item.key !== "Text");
    } else {
      list = [];
    }
    if (value) {
      list.push({ key: "Text", value: value });
    } else {
      list = filterArray.filter((item) => item.key !== "Text");
    }
    setSearchValue(value);
    setFilterArray(list);
  };

  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "user") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "UserId");
      }
      list.push({ key: "UserId", value: data.value });
      setFilterUserId(data);
    } else if (type === "status") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "Status");
      }
      list.push({ key: "Status", value: data.value });
      setStatus(data);
    } else if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if (end) {
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      }
      if (start && end == null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "ToDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    }
    setFilterArray(list);
  };

  const applyFilters = () => {
    dispatch(
      loadUserReferralsData(filterArray, PageSize, currentPage, setIsLoading)
    );
  };

  useEffect(() => {
    applyFilters(filterArray, setIsLoading);
  }, [filterArray, setIsLoading]);

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setStatus("");
    setFromDate("");
    setToDate("");
    setFilterArray([]); // Clear the filter array
  };

  const [openBankDetailsModal, setOpenBankDetailsModal] = useState(false);
  const handleBankDetails = (refId) => {
    setGfReferelProfileId(refId);
    console.log(refId, "refId");
    setOpenBankDetailsModal(true);
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole === 1 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToSettingPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">Referral - Users</h6>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                      <div className="input-div">
                        <input
                          type="search"
                          placeholder="Find a Referral Code"
                          className="form-control setting-input"
                          id="metricInfo_search_bar"
                          value={searchValue}
                          onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className="fa fa-search search-icon"></i>
                      </div>
                    </Col>
                    <Col md={4} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          {/* <SbAddBtn onClickEffect={() => setShow(!show)} /> */}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={10}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={userReferralsDropdown}
                                  placeholder="Select User"
                                  value={filterUserId}
                                  onInputChange={userReferralsDropdown.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "user")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={optionsStatusList}
                                  placeholder="Select Status"
                                  value={status}
                                  onInputChange={optionsStatusList.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "status")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                                className="input-group from-to-date-select"
                              >
                                <span
                                  className="input-group-text"
                                  id="from-label"
                                >
                                  From - To
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={fromDate}
                                    className="select from-date"
                                    id="date-range-picker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange
                                    startDate={fromDate}
                                    endDate={toDate}
                                    onChange={(data) =>
                                      setSelectSearchDrop(data, "date")
                                    }
                                    maxDate={new Date()}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fa fa-calendar-o calander-icon"
                                    style={{ right: "15px" }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>

                {/*TABLE START */}
                <UserRefferalsTable
                  tableHeader={tableHeader}
                  userReferralsData={userReferralsData}
                  // isLoading={isLoading}
                  goToBRefferalInfo={goToBRefferalInfo}
                  // addBankDetails={addBankDetails}
                  handleBankDetails={handleBankDetails}
                />
                {/*TABLE END */}

                {/* Open bank details modal */}
                {openBankDetailsModal && (
                  <UserBankDetailsModal
                    showModal={openBankDetailsModal}
                    setShowModal={setOpenBankDetailsModal}
                    gfReferelProfileId={gfReferelProfileId}
                    setGfReferelProfileId={setGfReferelProfileId}
                    addBankDetails={addBankDetails}
                  />
                )}
                {/* Open bank details modal */}

                {/* ADD & EDIT PRODUCT MODEL START */}

                {updateShow && (
                  <UserRefferalsBank
                    show={updateShow}
                    gfReferelProfileId={gfReferelProfileId}
                    setGfReferelProfileId={setGfReferelProfileId}
                    handleClose={handleCloseUpdate}
                    applyFilters={applyFilters}
                    filteredData={filteredData}
                  />
                )}
                {/* ADD & EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default UserRefferals;
