import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

function WhatsappTablePage(props) {
  const { totalCount } = useSelector((state) => state.data);
  const { whatsAppConfigData } = useSelector((state) => state.configData);

  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              whatsAppConfigData,
              totalCount,
              props.setLazyLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="client-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((header, i) => (
                  <TableCell
                    key={i}
                    align={header.align}
                    style={{ minWidth: `${header.width}` }}
                  >
                    {header.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <TableBody>{props.tableHeader.map((header, i) => (
                <TableRow key={i}>
                  {props.tableHeader.map((header, i) => (
                    <TableCell
                      key={i}
                    >
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              </TableBody>
            ) : (
              <TableBody>
                {whatsAppConfigData && whatsAppConfigData.length === 0 ? (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={props.tableHeader.length}
                    >
                      --- NO DATA FOUND ---
                    </TableCell>
                  </TableRow>
                ) : (
                  whatsAppConfigData.map((data, i) => (
                    <TableRow
                      align="center"
                      key={i}
                      className="client-list-row-data"
                    >
                      <TableCell>
                        <div className="table-row-head-Text"><strong>{data?.notificationName}</strong></div>
                      </TableCell>
                      <TableCell>
                        <span>{data?.header_Text}</span>
                      </TableCell>
                      <TableCell>
                        <span>{data?.body}</span>
                      </TableCell>
                      <TableCell>{data?.receiverType}</TableCell>
                      <TableCell>
                        <span className="color-green">{data?.to}</span>
                      </TableCell>
                      <TableCell>
                        <span>{data?.time}</span>
                      </TableCell>
                      <TableCell>
                        <span>{data?.frequency}</span>
                      </TableCell>
                      <TableCell align="center">
                        {data?.active === '1' ? (
                          <span className="status-badge complete-status">
                            Active
                          </span>
                        ) : (
                          <span className="status-badge cancel-status">
                            InActive
                          </span>
                        )}
                      </TableCell>
                      <TableCell className="action-class">
                        <button
                          onClick={() =>
                            props.editConfigureTemplate(data?.id)
                          }
                          className="btn client-edit-link"
                        >
                          <i className="fa fa-edit" /> Edit
                        </button>
                        {/* <button
                              onClick={() => {
                                props.setHistoryclientmodel(
                                  !props.historyclientmodel
                                );
                                props.setClientId(data?.clientID);
                              }}
                              className="btn client-history-link"
                            >
                              <i className="fa fa-history" /> History
                            </button> */}
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {props.lazyLoading && <OnScrollLoading />}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default WhatsappTablePage;
