import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState } from 'react';
import SkeletonTableData from '../Skeleton Loading/SkeletonTableData';
import "../../styles/livechat.scss";
import { Link } from 'react-router-dom';

function WhatsappTable(props) {

    const [selectedMessage, setSelectedMessage] = useState(null);

    const openMessage = (data) => {
        setSelectedMessage(data);
    }

    const closeMessage = () => {
        setSelectedMessage(null);
    }

    return (
        <div className="sb-table-div sb-table-setion whatsapp-module-main-div ">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }}
                // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                >
                    <Table stickyHeader aria-label="sticky table table-responsive"
                        // className='basefare-table'
                        className={` whatsapp-table-container${selectedMessage ? ' modal-open' : ""} `}
                    >
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                        style={{ minWidth: name.width }}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                            <TableBody>
                                {props.tableHeader.map((_, i) => (
                                    <SkeletonTableData key={i} />
                                ))}
                            </TableBody>
                        ) : (
                            <>
                                {props.whatsappData && props.whatsappData.length === 0 ?
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell align="center" colSpan={props.tableHeader.length}>
                                                <div style={{ padding: "20px 20px" }}>
                                                    <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                    <span>No Data Found...!</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {props.whatsappData && props.whatsappData.map((data, i) => (
                                            <TableRow align="center" key={i}
                                                className='client-list-row-data '
                                            >
                                                <TableCell>
                                                    <div className="users-list-name" onClick={openMessage}>
                                                        <img
                                                            className="table-data-users-imgages"
                                                            src="../images/Admin1.png"
                                                            alt=""
                                                        />
                                                        <div className="table-row-head-Text">
                                                            <Link>{data?.mobile}</Link>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="table-row-head-Text">
                                                        {data?.date}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="table-row-head-Text">
                                                        {data?.time}
                                                    </div>
                                                </TableCell>
                                                <TableCell><div className="table-row-sub-text">
                                                    {data?.messageSentFrom}
                                                </div></TableCell>
                                                <TableCell>
                                                    {data.message}
                                                </TableCell>
                                                <TableCell>
                                                    <span className="status-badge complete-status">
                                                        {data.status}
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                        {/* {props.loading &&
                                            <OnScrollLoading />
                                        } */}
                                    </TableBody>

                                }
                            </>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
            {selectedMessage && <div className='whatsapp-message-modal'>
                <div className='whatsapp-username' >
                    <div className='whatsapp-user-mobile-num'>
                        <i className="fa fa-whatsapp"></i>
                        <p>9591617840</p>
                    </div>
                    <div onClick={closeMessage}>
                        <i className="fa fa-times"></i>
                    </div>
                </div>
                <hr />
                <div className='whatsapp-user-chats-left'>
                    <div className='whatsapp-user-chats'>
                        <p>hi...</p>
                    </div>
                </div>
                <div className='whatsapp-friend-chats-right'>
                    <div className='whatsapp-friend-chats'>
                        <p>hello</p>
                    </div>
                </div>
                <div className='whatsapp-user-chats-left'>
                    <div className='whatsapp-user-chats'>
                        <p>how are you?</p>
                    </div>
                </div>
                <div className='whatsapp-friend-chats-right'>
                    <div className='whatsapp-friend-chats'>
                        <p>fine</p>
                    </div>
                </div>
                <div className='whatsapp-friend-chats-right'>
                    <div className='whatsapp-friend-chats'>
                        <p>what about you?</p>
                    </div>
                </div>
                <div className='whatsapp-user-chats-left'>
                    <div className='whatsapp-user-chats'>
                        <p>I am also fine</p>
                    </div>
                </div>

            </div>}
        </div>
    )
}

export default WhatsappTable;
