import { useState, useRef } from "react";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";

export const truncateString = (str, num) => {
  return str.length > num ? str.slice(0, num) + "..." : str;
};

export const OverLayData = (props) => {
  let ref = useRef(null);

  return (
    // <Tooltip id={`tooltip-vhd`}>{props.data ?? ""}</Tooltip>
    <OverlayTrigger
      ref={(r) => (ref = r)}
      container={ref.current}
      placement={"auto"}
      trigger="click"
      rootClose
      overlay={
        <Popover id="vhd-popover">
          {" "}
          <Popover.Body>{props.data ?? ""}</Popover.Body>
        </Popover>
      }
    >
      <span className="color-green">
        <i className="fa fa-info-circle" />
      </span>
    </OverlayTrigger>
  );
};
