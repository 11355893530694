import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import { optionsLeaveStatusUpdate } from "../../DropdownsData";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";

export default function POApprovalModel(props) {
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");

  // UPDATE STATUS START
  const updatePoStatus = (e) => {
    e.preventDefault();
    console.log("STATUS", "Status Update");
  };
  // UPDATE STATUS END

  return (
    <div>
      <Modal show={props.approveRejectInvoice} className="main_model">
        <form>
          <div className="add-edit-heading">
            <h6 className="add-lead-head">UPDATE STATUS</h6>
            <i className="fa fa-times" onClick={props.openCloseAddEditModel} />
          </div>
          <div className="container px-4 py-3">
            <div className="update-leave-status">
              <div className="select-status mb-3">
                <FloatingLabel
                  label="Select Status"
                  className={`${
                    status
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={optionsLeaveStatusUpdate}
                    placeholder="Select Status"
                    value={optionsLeaveStatusUpdate.value}
                    onInputChange={optionsLeaveStatusUpdate.label}
                    onChange={(data) => setStatus(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
              </div>
              <div className="select-status mb-2">
                <FloatingLabel
                  label="Remarks"
                  className={`${
                    remarks ? "float-input-visible" : "float-hidden float-input"
                  }`}
                >
                  <textarea
                    rows="3"
                    cols="20"
                    name="remarks"
                    wrap="hard"
                    className="form-control form-textarea add-product-input"
                    id="descriptionArea"
                    placeholder="Remarks"
                    value={remarks}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </div>
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn onClickEffect={updatePoStatus} btnName="Update" />
            <SbCancelBtn
              btnName="Cancel"
              onClickEffect={props.openCloseAddEditModel}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}
