import React, { useState } from "react";
import "../src/styles/Login.scss";
import "./MediaFiles/Media.scss";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "./Components/BaseUrl";
import { Button, Col, Image, Row } from "react-bootstrap";
import travelizeSalesBeeLogo from "./images/MatricLogo.svg";
import travelize from "./images/metric-favicon.svg";
import InternetIssue from "./Components/InternetIssue";

function BarrixLogin() {
  let themeColor = localStorage.getItem("theme");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [loginResponse, setLoginResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let navigate = useNavigate();

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const navigateToDashboard = () => {
    window.location.href = "/";
  };

  const loginValidation = (e) => {
    if (email && password) {
      const userNamePassword = {
        UserName: email,
        Password: password,
      };
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userNamePassword),
      };
      fetch(`${baseURL}/api/Authentication/Login`, requestoption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setLoginResponse(res.success);
            setLoginStatus(`Login ${res.message}`);
            sessionStorage.setItem("usdtls", JSON.stringify(res.result));

            //// CHECK SUBSCRIPTION EXPIRY
            let requestOptionS = {
              method: "GET",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Travelize_Authentication: res.result,
              },
            };
            fetch(
              `${baseURL}/api/DashBoard/GetLoginUserDetails`,
              requestOptionS
            )
              .then((res) => res.json())
              .then((res) => {
                const subscriptionExpiry = res?.result?.subscription?.daysleft;
                if (subscriptionExpiry == 0) {
                  navigate("/subscriptionexpiry");
                } else {
                  const userLoginDetails = res.result;
                  sessionStorage.setItem("loginId", userLoginDetails.userId);
                  sessionStorage.setItem(
                    "usrlgndtls",
                    JSON.stringify(userLoginDetails)
                  );
                  if (
                    userLoginDetails?.desingation?.role?.roleName == "Admin" &&
                    userLoginDetails?.subscriptionId == "SUB0000000001"
                  ) {
                    sessionStorage.setItem("AdminRole", true);
                  }
                  if (
                    userLoginDetails?.desingation?.role?.roleName == "Admin"
                  ) {
                    sessionStorage.setItem("AdminAccess", true);
                  }
                  if (
                    userLoginDetails?.desingation?.role?.roleName == "Manager"
                  ) {
                    sessionStorage.setItem("ManagerAccess", true);
                  }
                  if (!themeColor) {
                    localStorage.setItem("theme", "light");
                  }
                  navigateToDashboard();
                }
              })
              .catch((error) => {
                console.log("ERROR :", error);
                setErrorMessage(error.message);
              });
          } else {
            console.log(res, "res.errorMessage");
            setLoginResponse(res.success);
            setLoginStatus(res.errorMessage);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setErrorMessage(error.message);
        });
    } else {
      setLoginStatus("Username/Password should not be empty!");
    }
    e.preventDefault();
  };

  return (
    <div>
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          <div
            className="main-body"
            style={{ backgroundImage: 'url("images/Login_Page/bg4.jpg")' }}
          >
            <div className="login-page-body">
              <div className="beta-tag">
                {/* <h6>Beta</h6> */}
                <Image
                  src="../images/Client-Logo.svg"
                  alt="MetricInfo"
                  className="sb-login-logo"
                  style={{width:"150px", height:"50px"}}
                />
              </div>
              <Row className="sb-login-row">
                <Col xl={5} lg={5} md={5} className="sb-gif-col col-left">
                  <div className="w-100">
                    <span className="green-Color">Powered By</span>
                    <div className="sb-login-logo-div">
                      {/* <Image
                        src="../images/Client-Logo.svg"
                        alt="MetricInfo"
                        className="sb-login-logo"
                      /> */}
                      <Image
                        src={travelizeSalesBeeLogo}
                        alt="MetricInfo"
                        className="sb-login-logo"
                      />
                    </div>
                    <div className="sb-signin-text">
                      <h3 className="login-head-text">Sign In</h3>
                      <p style={{ fontWeight: "600" }}>to access MetricInfo</p>
                    </div>
                    <div className="sb-form-section">
                      <form onSubmit={loginValidation} className="login-form">
                        <div className="userName-input">
                          <input
                            type="email"
                            className="form-control login-email"
                            id="login-username"
                            placeholder="Username"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <div className="password-input">
                          <input
                            type={passwordType}
                            className="form-control login-password"
                            id="login-password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          {passwordType === "password" ? (
                            <i
                              className="bi bi-eye-slash pass-hide-show"
                              onClick={togglePassword}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye pass-hide-show"
                              onClick={togglePassword}
                            ></i>
                          )}
                        </div>
                        {loginResponse ? (
                          <div align="center" className="pb-2">
                            <span className="login-msg success"></span>
                          </div>
                        ) : (
                          <div align="center" className="pb-2">
                            <span className="login-msg false">
                              {loginStatus}
                            </span>
                          </div>
                        )}
                        <Row className="dflex-j-between">
                          <Col md={6} className="p-0">
                            <div>
                              <Link
                                to="/barixforgotpassword"
                                className="forgot-pass-text"
                              >
                                Forgot Password?
                              </Link>
                            </div>
                          </Col>
                          <Col md={6} className="p-0">
                            <div className="dflex-j-between">
                              <Button
                                type="submit"
                                className="btn btn-login-button"
                              >
                                Login
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <div className="pt-4">
                          <p style={{ fontWeight: "600" }}>
                            Don't have an account ?{" "}
                            <a href="#/Trial" className="login-signup-text">
                              Sign Up
                            </a>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
                <Col xl={2} lg={2} md={2} className="col-right">
                  <div>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                    <div className="travelize-log">
                      <Image
                        src={travelize}
                        alt="MetricInfo Logo"
                        className="sb-trv-logo"
                      />
                    </div>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                  </div>
                </Col>
                <Col xl={5} lg={5} md={5} className="sb-gif-col col-right">
                  <div>
                    <div className="sb-login-bgimg">
                      <Image
                        src={`../images/Login/laptop-motion-${themeColor ? themeColor : "light"
                          }.gif`}
                        // height="350"
                        alt="MetricInfo Motion Logo"
                        className="w-100 lgn-moving-img"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BarrixLogin;
