import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import TextField from "@mui/material/TextField";

import SbCancelBtn from '../../SbButtons/SbCancelBtn';
import { SBSaveUpdateBtn } from '../../SbButtons/SbAddBtn';
import { notificationFrequency, recieverType } from '../../DropdownsData';
import moment from 'moment';

function ConfigurationFile(props) {

    const [date, setDate] = useState(new Date());
  const [selecedTime, setSelecedTime] = useState({ val: null, time: null });
  const [sendingTime, setSendingTime] = useState('');

  const setSelectDate = (date) => {
    console.log(date, "time")
    setDate(date);
    let initState = { ...props.initStates };
    initState.time = moment(date).format("YYYY/MM/DD") + " " + sendingTime
    props.setInitStates(initState);
  }

  const selectTime = (time) => {
    
    setSelecedTime({ val: time, time: `${time.$H}:${time.$m}` })
    let timeS = time.$d.toTimeString().slice(0, 5);
    let initState = { ...props.initStates };
    const currentTime = timeS.slice(0, 2);
    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        setSendingTime("0" + exactTime + " PM");
        initState.time = moment(date).format("YYYY/MM/DD") + " 0" + exactTime + " PM";
      }
    } else if (currentTime === 12) {
      setSendingTime(timeS + " PM");
      initState.time = moment(date).format("YYYY/MM/DD") + " " + timeS + " PM";
    }
    else {
      setSendingTime(timeS + " AM");
      initState.time = moment(date).format("YYYY/MM/DD") + " " + timeS + " AM"
    }
    props.setInitStates(initState);
  };

  return (
    <div className="page-content">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <h6 className="page-header-text">Sender Configuration</h6>
                  </div>
                </Col>
                <Col md={9} className="cat-col">
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <SbCancelBtn btnName="Back" onClickEffect={props.backToTemplateConfig} />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="template-row">
                <Col md={12} className=" pl-0 pr-1">
                  <div className="template-form">
                    <div className="mi-template-header-row">
                      <div>
                        <p className="card-head">Add Reciever Details</p>
                      </div>
                      <div className="search-bar-header" style={{ float: "right" }}>
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <SBSaveUpdateBtn btnName="Continue" onClickEffect={props.saveTemplateDetails} />
                        </div>
                      </div>
                    </div>
                    <hr className="m-0" />
                    <div className="mi-template-header-option">
                      <Row style={{ width: '100%' }}>
                        <Col md={4}>
                          <div>
                            <p className="card-head">Reciever Type</p>
                          </div>
                          {/* <div><p>Select reciever type you want to send.</p></div> */}
                          <div className="header-option-div">
                            <div style={{ width: '100%' }}>
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                name="receiverType"
                                options={recieverType}
                                placeholder="Select Receiver Type"
                                value={recieverType.find(option => option.value === props.initStates.receiverType) || ''}
                                onInputChange={recieverType.label}
                                onChange={(data) => props.handleDropDown(data.value, "receiverType")}
                                isSearchable={false}
                                tabIndex={7}
                              />
                            </div>
                          </div>
                        </Col>
                        {props.initStates.receiverType === "Particular" &&
                        <Col md={6}>
                          <div>
                            <p className="card-head">To</p>
                          </div>
                          <div className="header-option-div" style={{ width: '100%' }}>
                            <input
                              type="tel"
                              className="form-control form add-product-input"
                              placeholder="Enter Mobile Number"
                              name="to"
                              maxLength={10}
                              value={props.initStates.to || ""}
                              onChange={props.handleFormInput}
                              tabIndex={8}
                            />
                          </div>
                          {props.validation && props.initStates.receiverType === "Particular" && 
                            !props.initStates.to  && (
                              <small id={`Error`} className="form-text text-muted ">
                                Please Enter mobile to whome you want to send
                              </small>
                            )}
                        </Col>
}
                      </Row>
                    </div>

                    <div className="mi-template-header-option">
                      <Row style={{ width: '100%' }}>
                        <Col md={4}>
                          <div>
                            <p className="card-head">Frequency</p>
                          </div>
                          {/* <div><p>Select frequency for notification.</p></div> */}
                          <div className="header-option-div">
                            <div style={{ width: '100%' }}>
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                name="receiverType"
                                options={notificationFrequency}
                                placeholder="Select Frequency"
                                value={notificationFrequency.find(option => option.value === props.initStates.frequency) || ''}
                                onInputChange={notificationFrequency.label}
                                onChange={(data) => props.handleDropDown(data.value, "frequency")}
                                isSearchable={false}
                                tabIndex={7}
                              />
                              {props.validation && !props.initStates.frequency  && (
                              <small id={`Error`} className="form-text text-muted ">
                                Please select frequency
                              </small>
                            )}
                            </div>
                          </div>
                        </Col>
                        <Col md={8}>
                          <div>
                            <p className="card-head">Trigger when</p>
                          </div>
                          {/* <div><p>Select date and tim when you want to send notification.</p></div> */}
                          <div className="header-option-div">
                            <div className="meeting-date-picker-with-icon">
                              <DatePicker
                                selected={date}
                                className="select-add-meeting-model meeting-date model-input"
                                dateFormat="dd/MM/yyyy"
                                // selectsEnd
                                // startDate={date}
                                // endDate={toDate}
                                minDate={new Date()}
                                onChange={(date) => setSelectDate(date)}
                              // maxDate={new Date()}
                              />
                              <i className="fa fa-calendar-o calander-icon" />
                            </div>
                            <div style={{ paddingLeft: '10px', width: '50%' }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopTimePicker
                                  // label="Time"
                                  className="form-control select-add-meeting-model"
                                  value={selecedTime.val}
                                  onChange={(time) => {
                                    selectTime(time);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                  minutesStep={5}
                                />
                              </LocalizationProvider>
                              {props.validation && !selecedTime.val  && (
                              <small id={`Error`} className="form-text text-muted ">
                                Please select time, what time you want to send
                              </small>
                            )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mi-template-header-option">
                      <Row style={{ width: '100%' }}>
                        <Col md={4}>
                          <div>
                            <p className="card-head">Token Id</p>
                          </div>
                          {/* <div><p>Add mobile number to whome you want to send.</p></div> */}
                          <div className="header-option-div" style={{ width: '100%' }}>
                            <input
                              type="tel"
                              className="form-control form add-product-input"
                              placeholder="Enter token id"
                              name="tokenID"
                              value={props.initStates.tokenID || ""}
                              onChange={props.handleFormInput}
                              tabIndex={9}
                            />
                          </div>
                        </Col>
                      </Row>

                    </div>
                    <div className="mi-template-header-option">
                      <Row style={{ width: '100%' }}>
                        <Col md={8}>
                          <div>
                            <p className="card-head">SP data</p>
                          </div>
                          {/* <div><p>Select date and tim when you want to send notification.</p></div> */}
                          <div className="header-option-div">
                            <div style={{ width: '50%' }}>
                              <input
                                type="tel"
                                className="form-control form add-product-input"
                                placeholder="Enter SP Name"
                                name="sp_Name"
                                value={props.initStates.sp_Name || ""}
                                onChange={props.handleFormInput}
                                tabIndex={10}
                              />
                            </div>
                            <div style={{ paddingLeft: '10px', width: '50%' }}>
                              <input
                                type="tel"
                                className="form-control form add-product-input"
                                placeholder="Enter Sp Flag"
                                name="sp_FlagID"
                                value={props.initStates.sp_FlagID || ""}
                                onChange={props.handleFormInput}
                                tabIndex={11}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </div>
                  </div>
                </Col>
                {/* <Col md={4} className="pl-1 pr-0">
                  <div className="template-preview">
                    <div className="preview-header-text-div">
                      <h6 className="page-header-text">Preview</h6>
                    </div>
                    <div className="cpnfig-preview-body">
                      {(props.localImage || props.initStates.header_Text || props.enteredMsg || props.veriables.length !== 0
                        || props.initStates.footer) &&
                        <div className="text-body">
                          {props.localImage && <div>
                            <img src={props.localImage} alt="" className="header_preview_image" />
                          </div>
                          }
                          {props.initStates.header_Text && <p className="preview-header">{props.initStates.header_Text}</p>}
                          <div>
                            {props.enteredMsg && <p className="card-head">{props.enteredMsg}</p>}
                          </div>
                          {props.veriables.length !== 0 && props.veriables.map((vrbl, index) => (
                            <div>{vrbl.variables}: {vrbl.variables_Value ? vrbl.variables_Value : `{{${index + 1}}}`}</div>
                          ))}
                          {props.initStates.footer &&
                            <div><p className="footer-text"><em>{props.initStates.footer}</em></p></div>}
                          {props.initStates.button_text &&
                            <div>
                              <hr className="m-0" />
                              <div className="button-preview"><i className="fa fa-paper-plane" aria-hidden="true"></i><p>{props.initStates.button_text}</p></div>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </Col> */}
              </Row>
            </div>
  )
}

export default ConfigurationFile
