import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Col, Row, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/LevelMotMap.scss";
import SbAddBtn, { SBSaveUpdateBtn, SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import AccessDenied from "../../../AccessDenied";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const clientOptions = [
    { value: '8', label: 'Level 8' },
    { value: '7', label: 'Level 7' },
    { value: '6', label: 'Level 6' },
    { value: '5', label: 'Level 5' },
    { value: '4', label: 'Level 4' },
    { value: '3', label: 'Level 3' },
    { value: '2', label: 'Level 2' },
    { value: '1', label: 'Level 1' },
    { value: '0', label: 'Level 0' }

];
const clientownOptions = [
    { value: '8', label: 'Level 8' },
    { value: '7', label: 'Level 7' },
    { value: '6', label: 'Level 6' },
    { value: '5', label: 'Level 5' },
    { value: '4', label: 'Level 4' },
    { value: '3', label: 'Level 3' },
    { value: '2', label: 'Level 2' },
    { value: '1', label: 'Level 1' },
    { value: '0', label: 'Level 0' }

];
const clientMots = [
    { value: 'owncar', label: 'Own Car' },
    { value: 'ownbike', label: 'Own Bike' }
]
const clientUnit = [
    { value: 'perkm', label: 'Per KM' }

]
const reportedByOptions = [
    { value: '1', label: 'Siva Balan P' },
    { value: '2', label: 'Shrikant Rajendra Satav' },
    { value: '3', label: 'Valera Kalpen Krishnakant' },
    { value: '4', label: 'Somanagouda Patil' },
    { value: '5', label: 'Naresh P' },
    { value: '6', label: 'Srikanth S' },
    { value: '7', label: 'Vinayak Gudekar' },

];





// const defaultUser = clientOptions.find(option => option.label === 'Siva Balan P');
function LevelMotMap() {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedownUser, setSelectedownUser] = useState(null);
    const [selectedMot, setSelectedMot] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);

    const [selectedVerifier, setSelectedVerifier] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [show, setShow] = useState(false);
    const [ownshow, setownShow] = useState(false);
    const [editshow, seteditShow] = useState(false);
    const [viewshow, setviewShow] = useState(false);
    const [activeTab, setActiveTab] = useState("levelmotmap");
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [myshowSaveButton, setmyShowSaveButton] = useState(true);
    const [myinnershowSaveButton, setmyinnerShowSaveButton] = useState(true);
    const [refresh, setRefresh] = useState(false);


    const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
    const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    const twoLevelClaim = loginDetails?.subscription?.twoLevelClaim;
    const loggedInRole = loginDetails?.desingation?.role?.roleID;
    const [filter, setFilter] = useState(false);
    const [filterUserId, setFilterUserId] = useState("");
    //* CLEAR SELECT START *//
    const clearSerachBySelect = () => {
        setFilterUserId("");

    };
    const [maxApprovalAmount, setMaxApprovalAmount] = useState('');
    const mysaveBtn = () => {
        // setShowSaveButton(false);
        // setShow(false);
        // setownShow(false);
        // seteditShow(false);
        // setviewShow(false);

    }
    const [ratePerUnit, setRatePerUnit] = useState('');

    const handleRatePerUnitChange = (e) => {
        setRatePerUnit(e.target.value);
    };
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const [selectedRow, setSelectedRow] = useState(null);
    const handleShow = () => {

        setShow(true);
        setownShow(false);
        seteditShow(false);
        setviewShow(false);


        setRefresh((prev) => !prev);

    };
    const myhandleShow = () => {

        setShow(false);
        setownShow(true);
        seteditShow(false);
        setviewShow(false);

        setRefresh((prev) => !prev);

    };
    const userDropdown = [
        { value: 'level0', label: 'Level0' },
        { value: 'level1', label: 'Level1' },
        { value: 'level2', label: 'Level2' },
        { value: 'level3', label: 'Level3' },
        { value: 'level4', label: 'Level4' },
        { value: 'level5', label: 'Level5' },
        { value: 'level6', label: 'Level6' },
        { value: 'level7', label: 'Level7' },
        { value: 'level8', label: 'Level8' },
        { value: 'level9', label: 'Level9' }
    ];
    const lunchlstoptions = [
        { value: 'level0', label: 'Level0' },
        { value: 'level1', label: 'Level1' },
        { value: 'level2', label: 'Level2' },
        { value: 'level3', label: 'Level3' },
        { value: 'level4', label: 'Level4' },
        { value: 'level5', label: 'Level5' },
        { value: 'level6', label: 'Level6' },
        { value: 'level7', label: 'Level7' },
        { value: 'level8', label: 'Level8' },
        { value: 'level9', label: 'Level9' }
    ];
    const mytableData = [
        { level: 'Level 7', amountWithBill: 'Own Car', unit: 'Per KM', ratePerUnit: '12.50' },
        { level: 'Level 6', amountWithBill: 'Own Car', unit: 'Per KM', ratePerUnit: '12.50' },
        { level: 'Level 5', amountWithBill: 'Own Bike', unit: 'Per KM', ratePerUnit: '6.50' },
        { level: 'Level 4', amountWithBill: 'Own Bike', unit: 'Per KM', ratePerUnit: '6.50' },
        { level: 'Level 3', amountWithBill: 'Own Car', unit: 'Per KM', ratePerUnit: '12.50' },
        { level: 'Level 2', amountWithBill: 'Own Car', unit: 'Per KM', ratePerUnit: '12.50' },
        { level: 'Level 1', amountWithBill: 'Own Bike', unit: 'Per KM', ratePerUnit: '6.50' },
        { level: 'Level 0', amountWithBill: 'Own Car', unit: 'Per KM', ratePerUnit: '12.50' },
    ];

    // const handleEditShow = (level) => {
    //     seteditShow(true);
    //     setviewShow(false);
    //     // Handle edit action
    //     console.log(`Edit clicked for ${level}`);
    // };
    const handleChangeUser = (selectedOption) => {
        setSelectedUser(selectedOption);
        setSelectedRow({ ...selectedRow, level: selectedOption.value });
    };


    // const handleEditShow = (row) => {
    //     seteditShow(true);
    //     setSelectedRow(row);
    //     setSelectedownUser(clientownOptions.find(option => option.value === row.level));
    //     setSelectedMot(clientMots.find(option => option.value === row.modeOfTravel));
    //     setSelectedUnit(clientUnit.find(option => option.value === row.unit));
    //     setEditShow(true);
    //     console.log(`Edit clicked for ${row.level}`);
    // };

    const myhandlemyShow = (level) => {
        seteditShow(false);
        setviewShow(true);
        // Handle view action
        console.log(`View clicked for ${level}`);
    };
    const [selectedMOTs, setSelectedMOTs] = useState([]);

    const mots = [
        { value: '1', label: 'Rail-I AC' },
        { value: '2', label: 'Rail-II AC' },
        { value: '3', label: 'Rail-III AC/CC' },
        { value: '4', label: 'Rail-Sleeper' },
        { value: '5', label: 'Air-Economy' },
        { value: '6', label: 'Bus-Volvo AC' },
        { value: '7', label: 'Bus-Luxury' },
        { value: '8', label: 'Bus-Others' },
        { value: '9', label: 'Cab-Shared' },
        { value: '10', label: 'Cab-Special' },
        { value: '11', label: 'Auto' },
        { value: '12', label: 'Own Car' },
        { value: '13', label: 'Own Bike' }
    ];

    // const handleCheckboxChange = (event, value) => {
    //     if (event.target.checked) {
    //         setSelectedMOTs([...selectedMOTs, value]);
    //     } else {
    //         setSelectedMOTs(selectedMOTs.filter(mot => mot !== value));
    //     }
    // };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    };

    const handleClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShow(false);
        seteditShow(false);
        setviewShow(false);
        setRefresh((prev) => !prev);

        setSelectedUser(null);
        setSelectedownUser(null);
        setSelectedUnit(null);
        setSelectedMot(null);
        setIsChecked(false);
        setMaxLimitValue(null);
        setShowSaveButton(true);


    };
    const viewhandleClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShow(false);
        seteditShow(false);
        setviewShow(false);
        setRefresh((prev) => !prev);

        setSelectedUser(null);
        setSelectedownUser(null);
        setSelectedUnit(null);
        setSelectedMot(null);
        setIsChecked(false);
        setMaxLimitValue(null);
        setShowSaveButton(false);

    };
    const edithandleClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShow(false);
        seteditShow(false);
        setviewShow(false);
        setRefresh((prev) => !prev);

        setSelectedUser(null);
        setSelectedownUser(null);
        setSelectedUnit(null);
        setSelectedMot(null);
        setIsChecked(false);
        setMaxLimitValue(null);
        setShowSaveButton(true);

    };
    const ownhandleClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShow(false);
        setownShow(false);
        seteditShow(false);
        setviewShow(false);
        setRefresh((prev) => !prev);

        setSelectedUser(null);
        setSelectedownUser(null);
        setSelectedUnit(null);
        setSelectedMot(null);
        setIsChecked(false);
        setMaxLimitValue(null);
        setShowSaveButton(true);

    };




    const handlemyShow = (name) => {
        setSelectedownUser({ value: name, label: name });
        setSelectedUser({ value: name, label: name });
        setShow(true); // Close any modal or detailed view if open
        setShowElements(false);
        setShowSaveButton(false);
        setDisableSelectOnChange(true);
        setDisablemySelectOnChange(true);
        setRefresh((prev) => !prev);
        setmyShowSaveButton(true);
        setmyinnerShowSaveButton(false);


        // Example: You could perform additional actions related to viewing details
        console.log('View clicked for:', name);
    };

    // const handleChange = (selectedOption) => {
    //     if (!disableSelectOnChange) {
    //         setSelectedUser(selectedOption);

    //     }


    // };

    const handleChange = (selectedOption) => {
        setSelectedownUser(selectedOption);
        setSelectedUser(selectedOption);
        setSelectedMot(selectedOption);
        setSelectedUnit(selectedOption);
        if (parseInt(selectedOption.value) >= 4 && parseInt(selectedOption.value) <= 6) {
            setMaxLimitValue('350');
        } else {
            setMaxLimitValue(null);
        }
    };
    // const handleownChange = (selectedOption) => {
    //     setSelectedownUser(selectedOption);


    // };
    // const handleEditShow = (row) => {
    //     setEditShow(true);
    //     setSelectedRow(row);
    //     setSelectedownUser(clientownOptions.find(option => option.value === row.level));
    //     setSelectedMot(clientMots.find(option => option.value === row.modeOfTravel));
    //     setSelectedUnit(clientUnit.find(option => option.value === row.unit));
    //     console.log(`Edit clicked for ${row.level}`);
    // };
    const handleEditShow = (row) => {

        seteditShow(true);
        setEditShow(true);
        setSelectedRow(row);
        setSelectedownUser(clientownOptions.find(option => option.value === row.level));
        setSelectedMot(clientMots.find(option => option.value === row.modeOfTravel));
        setSelectedUnit(clientUnit.find(option => option.value === row.unit));
        console.log(`Edit clicked for ${row.level}`);
    };
    // const handleownChange = (selectedOption) => {
    //     setSelectedownUser(selectedOption);
    //     setSelectedRow((prevRow) => ({
    //         ...prevRow,
    //         level: selectedOption.value
    //     }));
    // };

    const handleownChange = (selectedOption) => {
        setSelectedownUser(selectedOption);
    };

    // Other change handlers (handleChangeMot, handleChangeUnit) should have similar structure
    // const handleChangeMot = (selectedOption) => {
    //     setSelectedMot(selectedOption);
    //     setSelectedRow((prevRow) => ({
    //         ...prevRow,
    //         modeOfTravel: selectedOption.value
    //     }));
    // };

    // const handleChangeUnit = (selectedOption) => {
    //     setSelectedUnit(selectedOption);
    //     setSelectedRow((prevRow) => ({
    //         ...prevRow,
    //         unit: selectedOption.value
    //     }));
    // };

    const handleChangeMot = (selectedOptionMot) => {
        // setSelectedMot(selectedOptionMot);
        setSelectedMot(selectedOptionMot);
        setSelectedRow({ ...selectedRow, modeOfTravel: selectedOption.value });

    };
    const [editShow, setEditShow] = useState(false);
    const handleChangeUnit = (selectedOptionUnit) => {
        // setSelectedUnit(selectedOptionUnit);
        setSelectedUnit(selectedOptionUnit);
        setSelectedRow({ ...selectedRow, unit: selectedOption.value });
    };
    const handleInputChange = (value) => {
        setMaxLimitValue(value);
    };
    // const handleInputChange = (e) => {

    //     setMaxLimitValue(selectedOption && parseInt(selectedOption.value) <= 3 ? 350 : null); // Update maxLimitValue based on selectedOption
    // };
    const handlemyChange = (selectedOption) => {
        if (!disablemySelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const [disableSelectOnChange, setDisableSelectOnChange] = useState(false);
    const [disablemySelectOnChange, setDisablemySelectOnChange] = useState(false);


    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setRefresh((prev) => !prev);
    };

    const [selectedOption, setSelectedOption] = useState('actual');
    const [selectedOptionMot, setSelectedOptionMot] = useState('false');
    const [selectedOptionUnit, setSelectedOptionUnit] = useState('false');
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [showElements, setShowElements] = useState(true);




    const shouldHideAtActuals = () => {
        return selectedUser && parseInt(selectedUser.value) >= 7 && isChecked;
    };

    const shouldHideLunchamntCnt = () => {
        return selectedUser && parseInt(selectedUser.value) <= 3;
    };

    const [maxLimitValue, setMaxLimitValue] = useState(null);

    const isMaxLimitEditable = () => {
        if (selectedUser && selectedUser.value) {
            const userValue = parseInt(selectedUser.value);
            return userValue >= 0 && userValue <= 3;
        }
        return false;
    };
    // const setMaxLimitForUser = (selectedOption) => {
    //     if (selectedOption && selectedOption.value) {
    //         const userValue = parseInt(selectedOption.value);
    //         switch (userValue) {
    //             case 0:
    //                 setMaxLimitValue(250); // Example value for Level 0
    //                 break;
    //             case 1:
    //                 setMaxLimitValue(300); // Example value for Level 1
    //                 break;
    //             case 2:
    //                 setMaxLimitValue(320); // Example value for Level 2
    //                 break;
    //             case 3:
    //                 setMaxLimitValue(340); // Example value for Level 3
    //                 break;
    //             case 4:
    //                 setMaxLimitValue(360); // Example value for Level 4
    //                 break;
    //             // Add cases for other levels as needed
    //             default:
    //                 setMaxLimitValue(350); // Default value
    //                 break;
    //         }
    //     }
    // };





    // const handleDelete = (index) => {
    //     const updatedTableData = tableData.filter((_, i) => i !== index);
    //     setTableData(updatedTableData);
    //     if (updatedTableData.length === 0) {
    //         setIsTableVisible(false); // Hide the table if there are no rows left
    //     }
    // };
    // const CustomOption = (props) => {
    //     return (
    //         <components.Option {...props}>
    //             <input className="mots-lst"
    //                 type="checkbox"
    //                 checked={props.isSelected}
    //                 onChange={() => null} // Prevent the default checkbox behavior
    //             />
    //             <label>{props.label}</label>
    //         </components.Option>
    //     );
    // };
    // const handleDelete = (index) => {
    //   const newData = [...tableData];
    //   newData.splice(index, 1);
    //   setTableData(newData);
    // };

    // const handleUserChange = (selectedOption) => {
    //     setSelectedUser(selectedOption);
    // };

    // const handleVerifierChange = (selectedOption) => {
    //     setSelectedVerifier(selectedOption);
    // };

    // const handleOptionChange = (e) => {
    //     setSelectedOption(e.target.value);
    // };
    // const adhandleShow = () => {
    //     // Your logic to add table data or show modal
    //     console.log("Adding table data or showing modal");
    // };


    // const handleAddButtonClick = (event) => {
    //     event.preventDefault(); // Prevent the default form submission behavior

    //     if (selectedOption && ((selectedOption === 'verifier' && selectedVerifier) || (selectedOption === 'manager' && selectedUser))) {

    //         let newData = {}

    //         if (selectedOption === 'verifier' && selectedVerifier) {
    //             newData = {
    //                 name: selectedVerifier.label,
    //                 type: selectedOption,
    //                 priority: tableData.length + 1 // Set the priority as the next number in the sequence
    //             };
    //         } else if (selectedOption === 'manager' && selectedUser) {

    //             newData = {
    //                 name: selectedVerifier.label,
    //                 type: selectedOption,
    //                 priority: tableData.length + 1 // Set the priority as the next number in the sequence
    //             };
    //         }
    //         setTableData([...tableData, newData]);

    //         setIsTableVisible(true); // Show the table when a new row is added

    //         // Reset selections
    //         setSelectedUser(null);
    //         setSelectedVerifier(null);
    //         setSelectedOption('');

    //         // Trigger onClickEffect
    //         // Call your effect function here
    //     } else {
    //         // alert('Please select a user before proceeding.');
    //     }
    // };

    console.log(tableData, "tableData")

    return (
        <div id="main-page">
            {userDetails !== null && userDetails !== "" ? (
                <>
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    <div className="page-wrapper">
                        <div className={`main-body ${toggleshift.style}`}>
                            <div className="page-content usrhrchy">
                                <Row className="search-row usrhrchy">
                                    <Col md={3} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToBackPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">LEVEL MOT MAP</h6>
                                        </div>
                                    </Col>
                                    <Col md={7} className="cat-col">
                                        <Tabs
                                            id="uncontrolled-tab-example"
                                            activeKey={activeTab}
                                            onSelect={(tab) => handleTabChange(tab)}
                                            className="local-claim-nav-tab usrhrchy"
                                        >
                                            <Tab eventKey="levelmotmap" title="Level MOT Map"></Tab>
                                            <Tab eventKey="lunchconveyance" title="Local Conveyance Lunch Reimbursement"></Tab>
                                            <Tab eventKey="owntransport" title="Own Transport"></Tab>

                                        </Tabs>
                                    </Col>
                                    {/* <Col md={3} className="cat-col">
                                        <div
                                            className="search-bar-header"
                                            style={{ float: "right" }}
                                        >
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                <div
                                                    className="header-filter-btn"
                                                    onClick={() => setFilter(!filter)}
                                                >
                                                    <p className="card-head">
                                                        <i className="bi bi-sliders" />
                                                        &nbsp;&nbsp;Filters
                                                    </p>
                                                </div>
                                                <SbAddBtn
                                                    onClickEffect={handleShow}
                                                />
                                            </div>
                                        </div>

                                    </Col> */}
                                </Row>

                                {filter && (
                                    <div className="page-filter-section">
                                        <div style={{ width: "100%" }}>
                                            <Row className="filters-row">
                                                <Col md={3}>
                                                    <div className="clients-filter-input-boxs filter-box-div">

                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                padding: "0px 5px",
                                                            }}
                                                        >
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={userDropdown}
                                                                placeholder="Select User"
                                                                value={
                                                                    filterUserId === ""
                                                                        ? ""
                                                                        : userDropdown.value
                                                                }
                                                                onInputChange={userDropdown.label}
                                                                onChange={(data) =>
                                                                    setFilterUserId(data.value)
                                                                }
                                                                isSearchable={true}
                                                            />
                                                        </div>

                                                    </div>
                                                </Col>
                                                <Col md={9}>
                                                    <div className="clients-filter-input-boxs filter-box-div usrhrchy">
                                                        <SbCancelBtn
                                                            onClickEffect={clearSerachBySelect}
                                                            btnName="Clear"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                )}


                                <div className="usrhrchy table-responsive spares-scrollbar">
                                    <table className="table spares-table-data">
                                        {activeTab === "levelmotmap" && (
                                            <>
                                                <Row className="motslst">
                                                    <Col md={6} className="cat-col" >
                                                        <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                            <Col md={2} className="cat-col">
                                                                <h6>Level :</h6>
                                                            </Col>
                                                            <Col md={10} className="cat-col">
                                                                <FloatingLabel label="Select Level" className="float-hidden float-select">
                                                                    <Select
                                                                        className="react-select-container-list"
                                                                        classNamePrefix="react-select-list"
                                                                        options={userDropdown}
                                                                        placeholder="Select Level"
                                                                        value={userDropdown.find(option => option.value === filterUserId)}
                                                                        onChange={(data) => setFilterUserId(data ? data.value : "")}
                                                                        isSearchable={true}
                                                                    />
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>
                                                        <div style={{ border: '1px solid #ded6ff', padding: '20px' }}>
                                                            <h6 style={{ textAlign: 'center' }}>Select MOT(s)</h6>
                                                            <div>
                                                                {mots.map(mot => (
                                                                    <div key={mot.value} style={{ marginBottom: '10px' }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={mot.value}
                                                                            // checked={isChecked}
                                                                            onChange={(event) => handleCheckboxChange(event, mot.value)}
                                                                            style={{ marginRight: '10px' }} // Space between checkbox and label
                                                                        />
                                                                        <label htmlFor={mot.value} style={{ fontSize: '16px' }}>{mot.label}</label> {/* Increased font size */}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className="popup-bottom-btn usrhrchy levlmot">
                                                            {showSaveButton && <SBSaveUpdateBtn btnName="Save" />}
                                                            {myshowSaveButton && <SbCancelBtn btnName="Close" onClickEffect={handleClose} />}
                                                        </div>

                                                    </Col>

                                                </Row>
                                            </>

                                        )}

                                        {activeTab === "lunchconveyance" && (
                                            <>
                                                <Row className="motslst lunchrmbrse">
                                                    <Col md={10} className="cat-col" >
                                                        <div className="lnchreimbrse mt-2 d-flex align-items-center" style={{ paddingLeft: '0px' }}>
                                                            <label style={{ marginRight: '5px', fontSize: '20px' }}>Fixed Amount </label>
                                                            <input type="number" value="125" readOnly style={{ textAlign: 'center', backgroundColor: 'transparent', fontSize: '16px', border: '1px solid #ded6ff', width: '100px' }} />
                                                            <label style={{ marginLeft: '5px', fontSize: '14px' }}>Rs. </label>
                                                            <p style={{ fontSize: '14px' }}>(Amount to be reimbursed without any supporting document)</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={2} className="cat-col mylvlmotbtn">
                                                        <SbAddBtn
                                                            onClickEffect={handleShow}
                                                        />
                                                    </Col>

                                                    <Col md={12} className="cat-col" >
                                                        <table className="table spares-table-data">
                                                            <thead className="thead-light spares-thead">
                                                                <tr>
                                                                    <th>Level</th>
                                                                    <th>Amount with Bill</th>
                                                                    <th>Maximum Limit</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {[
                                                                    { level: 'Level 7', amountWithBill: 'At Actuals', maxLimit: 'N/A' },
                                                                    { level: 'Level 6', amountWithBill: 'At Actuals', maxLimit: '350' },
                                                                    { level: 'Level 5', amountWithBill: 'At Actuals', maxLimit: '350' },
                                                                    { level: 'Level 4', amountWithBill: 'At Actuals', maxLimit: '350' },
                                                                    { level: 'Level 3', amountWithBill: '150', maxLimit: 'N/A' },
                                                                    { level: 'Level 2', amountWithBill: '150', maxLimit: 'N/A' },
                                                                    { level: 'Level 1', amountWithBill: '150', maxLimit: 'N/A' },
                                                                    { level: 'Level 0', amountWithBill: '150', maxLimit: 'N/A' }
                                                                ].map((item, index) => (
                                                                    <tr key={index} className="spare-table-row-data">
                                                                        <td>{item.level}</td>
                                                                        <td>{item.amountWithBill}</td>
                                                                        <td>{item.maxLimit}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {activeTab === "owntransport" && (
                                            <>
                                                <Row className="motslst lunchrmbrse">
                                                    <Col md={10} className="cat-col" >
                                                        <div className="lnchreimbrse mt-2 d-flex align-items-center" style={{ paddingLeft: '0px' }}>
                                                            <label style={{ marginRight: '5px', fontSize: '20px' }}>Own Transport</label>

                                                        </div>
                                                    </Col>
                                                    <Col md={2} className="cat-col mylvlmotbtn">
                                                        <SbAddBtn
                                                            onClickEffect={myhandleShow}
                                                        />
                                                    </Col>

                                                    <Col md={12} className="cat-col" >
                                                        <table className="table spares-table-data">
                                                            <thead className="thead-light spares-thead">
                                                                <tr>
                                                                    <th>Level</th>
                                                                    <th>Mode of Travel</th>
                                                                    <th>Unit</th>
                                                                    <th>Rate per Unit <span className="myrscnt">(Rs.)</span></th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {mytableData.map((item, index) => (
                                                                    <tr key={index} className="spare-table-row-data">
                                                                        <td>{item.level}</td>
                                                                        <td>{item.amountWithBill}</td>
                                                                        <td>{item.unit}</td>
                                                                        <td>{item.ratePerUnit}</td>
                                                                        <td className="myhrchy-dta">
                                                                            <button className="btn spare-edit-link" onClick={() => handleEditShow(item)}>
                                                                                <i className="fa fa-edit"></i> Edit
                                                                            </button>
                                                                            <button className="btn spare-edit-link" onClick={() => myhandlemyShow(item.level)}>
                                                                                <i className="fa fa-eye"></i> View
                                                                            </button>
                                                                            <button className="btn spare-edit-link">
                                                                                <i className="fa fa-trash"></i> Delete
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>

                                                    </Col>
                                                </Row>
                                            </>
                                        )}

                                    </table>

                                    {/* <div className="download-and-pagination">
                                        <SbDownloadBtn onClickEffect={exportExcelClaim} />
                                    </div> */}

                                </div>

                            </div>

                        </div>

                    </div>
                    <Footer />

                    <Modal
                        show={show}
                        onHide={handleClose}
                        className={`main_model add-edit-model amc-model hrschy lvlmot ${activeTab === 'traveldesk' ? 'traveldesk-width' : ''} ${activeTab === 'manager' ? 'attendance-movement' : ''}`}

                    >
                        <div className="add-edit-heading">
                            <h6 className="add-edit-head">LOCAL CONVEYANCE LUNCH REIMBURSEMENT</h6>
                            <i className="fa fa-times" onClick={handleClose} />
                        </div>
                        <form>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">
                                    <>
                                        <Row>
                                            <Col md={12} sm={12}>
                                                <div className="slctusr mb-3">
                                                    <FloatingLabel label="Select Level" className="usrhrchy-lbl float-hidden float-select">
                                                        <Select
                                                            className="react-select-container-list model-select"
                                                            classNamePrefix="react-select-list"
                                                            options={clientOptions}
                                                            value={selectedUser}
                                                            onChange={handleChange}
                                                            placeholder="Select Level"
                                                            isDisabled={disableSelectOnChange}
                                                            styles={{
                                                                dropdownIndicator: (provided) => ({
                                                                    ...provided,
                                                                    display: disableSelectOnChange ? 'none' : 'block',
                                                                }),
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                </div>

                                                {!shouldHideLunchamntCnt() && (
                                                    <div className="lunchamnt">
                                                        <div className="lunchamnt-cnt">
                                                            <h6>Amount with Bill :</h6>
                                                            <div>
                                                                <input
                                                                    type="checkbox"
                                                                    id="actual"
                                                                    name="amount"
                                                                    value="actual"
                                                                    onChange={handleCheckboxChange}
                                                                    checked={isChecked}
                                                                    style={{ marginRight: '10px' }}
                                                                />
                                                                <label htmlFor="actual">At Actuals</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )}

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} sm={12}>
                                                <div style={{ marginTop: '10px', fontSize: '16px', textAlign: 'center' }}>
                                                    {!shouldHideAtActuals() && (
                                                        <div style={{ marginTop: '10px', fontSize: '16px', textAlign: 'center' }}>
                                                            <div className="lunchamnt-cnt">
                                                                <label style={{ marginRight: '5px' }}>Max Limit :</label>
                                                                <div className="lunchamnt-cnt">

                                                                    <input
                                                                        type="number"
                                                                        value={maxLimitValue !== null ? maxLimitValue : '350'}
                                                                        readOnly={!isMaxLimitEditable()}
                                                                        onChange={(e) => handleInputChange(e.target.value)}
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: 'transparent',
                                                                            fontSize: '16px',
                                                                            border: '1px solid #ded6ff',
                                                                            width: '100px'
                                                                        }}
                                                                    />
                                                                    <label style={{ marginLeft: '5px', fontSize: '14px' }}>Rs. </label>
                                                                    <div style={{ display: (selectedUser && parseInt(selectedUser.value) >= 0 && parseInt(selectedUser.value) <= 6 && isChecked) ? 'none' : 'inline-block' }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            id="notapplicable"
                                                                            name="notapplicable"
                                                                            value="notapplicable"
                                                                            style={{ marginRight: '10px', marginLeft: '10px' }}
                                                                        />
                                                                        <label htmlFor="notapplicable">Not Applicable</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                </div>
                            </div>
                            <div className="popup-bottom-btn usrhrchy btns-save-cancel">
                                {showSaveButton && <SBSaveUpdateBtn btnName="Save" onClickEffect={mysaveBtn} />}
                                {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}

                            </div>
                        </form>
                    </Modal>

                    <Modal
                        show={ownshow}
                        onHide={ownhandleClose}
                        className={`main_model add-edit-model amc-model hrschy lvlmot`}

                    >
                        <div className="add-edit-heading">
                            <h6 className="add-edit-head">ADD OWN TRANSPORT</h6>
                            <i className="fa fa-times" onClick={ownhandleClose} />
                        </div>
                        <form>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">
                                    <Row>
                                        <Col md={12} sm={12}>
                                            <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Level :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr mb-3">
                                                        <FloatingLabel label="Select Level" className="usrhrchy-lbl float-hidden float-select">
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                options={clientOptions}
                                                                value={selectedownUser}
                                                                onChange={handleownChange}
                                                                placeholder="Select Level"
                                                                isDisabled={disableSelectOnChange}
                                                                styles={{
                                                                    dropdownIndicator: (provided) => ({
                                                                        ...provided,
                                                                        display: disableSelectOnChange ? 'none' : 'block',
                                                                    }),
                                                                }}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Mode of Travel :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">
                                                        <FloatingLabel label="Select MOT" className="usrhrchy-lbl float-hidden float-select">
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                options={clientMots}
                                                                value={selectedMot}
                                                                onChange={handleChangeMot}
                                                                placeholder="Select MOT"
                                                                isDisabled={disableSelectOnChange}
                                                                styles={{
                                                                    dropdownIndicator: (provided) => ({
                                                                        ...provided,
                                                                        display: disableSelectOnChange ? 'none' : 'block',
                                                                    }),
                                                                }}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Unit :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">
                                                        <FloatingLabel label="Select Unit" className="usrhrchy-lbl float-hidden float-select">
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                options={clientUnit}
                                                                value={selectedUnit}
                                                                onChange={handleChangeUnit}
                                                                placeholder="Select Unit"
                                                                isDisabled={disableSelectOnChange}
                                                                styles={{
                                                                    dropdownIndicator: (provided) => ({
                                                                        ...provided,
                                                                        display: disableSelectOnChange ? 'none' : 'block',
                                                                    }),
                                                                }}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Rate Per Unit <span className="myrscnt">(Rs.)</span> :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">
                                                        <Form.Control
                                                            type="number"
                                                            name="maxApprovalAmount"
                                                            className="form-control form add-input"
                                                            placeholder=""
                                                            style={{ height: '35px' }}
                                                        // value={maxApprovalAmount}
                                                        // onChange={handleChange}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="popup-bottom-btn usrhrchy btns-save-cancel">
                                {showSaveButton && <SBSaveUpdateBtn btnName="Save" onClickEffect={mysaveBtn} />}
                                {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={ownhandleClose} />}

                            </div>
                        </form>
                    </Modal>

                    <Modal
                        show={editshow}
                        onHide={edithandleClose}
                        className={`main_model add-edit-model amc-model hrschy lvlmot ${activeTab === 'traveldesk' ? 'traveldesk-width' : ''} ${activeTab === 'manager' ? 'attendance-movement' : ''}`}

                    >
                        <div className="add-edit-heading">
                            <h6 className="add-edit-head">EDIT OWN TRANSPORT</h6>
                            <i className="fa fa-times" onClick={edithandleClose} />
                        </div>
                        <form>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">
                                    <Row>
                                        <Col md={12} sm={12}>
                                            <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Level :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">

                                                        <Form.Control
                                                            type="text"
                                                            name="level"
                                                            className="form-control form add-input"
                                                            value={selectedRow?.level || ''}
                                                            onChange={(e) => setSelectedRow({ ...selectedRow, level: e.target.value })}
                                                            disabled={true}
                                                            style={{ height: '35px' }}
                                                        />


                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Mode of Travel :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">
                                                        <FloatingLabel label="Select MOT" className="usrhrchy-lbl float-hidden float-select">
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                options={clientMots}
                                                                value={selectedMot}
                                                                onChange={handleChangeMot}
                                                                placeholder="Select MOT"
                                                                isDisabled={disableSelectOnChange}
                                                                styles={{
                                                                    dropdownIndicator: (provided) => ({
                                                                        ...provided,
                                                                        display: disableSelectOnChange ? 'none' : 'block',
                                                                    }),
                                                                }}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Unit :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">
                                                        <FloatingLabel label="Select Unit" className="usrhrchy-lbl float-hidden float-select">
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                options={clientUnit}
                                                                value={selectedUnit}
                                                                onChange={handleChangeUnit}
                                                                placeholder="Select Unit"
                                                                isDisabled={disableSelectOnChange}
                                                                styles={{
                                                                    dropdownIndicator: (provided) => ({
                                                                        ...provided,
                                                                        display: disableSelectOnChange ? 'none' : 'block',
                                                                    }),
                                                                }}
                                                            />
                                                        </FloatingLabel>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Rate Per Unit <span className="myrscnt">(Rs.)</span> :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">
                                                        <Form.Control
                                                            type="number"
                                                            name="ratePerUnit"
                                                            className="form-control form add-input"
                                                            value={selectedRow?.ratePerUnit || ''}
                                                            onChange={(e) => setSelectedRow({ ...selectedRow, ratePerUnit: e.target.value })}
                                                            style={{ height: '35px' }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="popup-bottom-btn usrhrchy btns-save-cancel">
                                {showSaveButton && <SBSaveUpdateBtn btnName="Save" onClickEffect={mysaveBtn} />}
                                {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}
                            </div>
                        </form>
                    </Modal>
                    <Modal
                        show={viewshow}
                        onHide={viewhandleClose}
                        className={`main_model add-edit-model amc-model hrschy lvlmot ${activeTab === 'traveldesk' ? 'traveldesk-width' : ''} ${activeTab === 'manager' ? 'attendance-movement' : ''}`}

                    >
                        <div className="add-edit-heading">
                            <h6 className="add-edit-head">VIEW OWN TRANSPORT</h6>
                            <i className="fa fa-times" onClick={viewhandleClose} />
                        </div>
                        <form>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">
                                    <Row>
                                        <Col md={12} sm={12}>
                                            <Row className="levllst mb-2 vewcnt" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Level :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">

                                                        <Form.Control
                                                            type="text"
                                                            name="level"
                                                            className="form-control form add-input vewinpt"
                                                            value={selectedRow?.level || ''}
                                                            onChange={(e) => setSelectedRow({ ...selectedRow, level: e.target.value })}
                                                            disabled={true}
                                                            style={{ height: '35px' }}
                                                        />


                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-2 vewcnt" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Mode of Travel :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">
                                                        {/* <FloatingLabel label="Select MOT" className="usrhrchy-lbl float-hidden float-select">
                                                            <Select
                                                                className="react-select-container-list model-select"
                                                                classNamePrefix="react-select-list"
                                                                options={clientMots}
                                                                value={selectedMot}
                                                                onChange={handleChangeMot}
                                                                placeholder="Select MOT"
                                                                isDisabled={disableSelectOnChange}
                                                                styles={{
                                                                    dropdownIndicator: (provided) => ({
                                                                        ...provided,
                                                                        display: disableSelectOnChange ? 'none' : 'block',
                                                                    }),
                                                                }}
                                                            />
                                                        </FloatingLabel> */}
                                                        <Form.Control
                                                            type="text"
                                                            name="amountwithbill"
                                                            className="form-control form add-input vewinpt"
                                                            value={selectedRow?.amountWithBill || ''}
                                                            disabled={true}
                                                            onChange={(e) => setSelectedRow({ ...selectedRow, amountWithBill: e.target.value })}
                                                            style={{ height: '35px', boxShadow: 'none', backgroundColor: 'none' }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-2 vewcnt" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Unit :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">
                                                        <Form.Control
                                                            type="text"
                                                            name="unit"
                                                            className="form-control form add-input vewinpt"
                                                            value={selectedRow?.unit || ''}
                                                            disabled={true}
                                                            onChange={(e) => setSelectedRow({ ...selectedRow, unit: e.target.value })}
                                                            style={{ height: '35px', boxShadow: 'none !important;', backgroundColor: 'none !important;' }}
                                                        />

                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-2 vewcnt" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Col md={4} className="cat-col">
                                                    <h6>Rate Per Unit <span className="myrscnt">(Rs.)</span> :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <div className="slctusr">
                                                        <Form.Control
                                                            type="number"
                                                            name="ratePerUnit"
                                                            className="form-control form add-input vewinpt"
                                                            value={selectedRow?.ratePerUnit || ''}
                                                            disabled={true}
                                                            onChange={(e) => setSelectedRow({ ...selectedRow, ratePerUnit: e.target.value })}
                                                            style={{ height: '35px' }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="popup-bottom-btn usrhrchy btns-save-cancel">
                                {showSaveButton && <SBSaveUpdateBtn btnName="Save" onClickEffect={mysaveBtn} />}
                                {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}
                            </div>
                        </form>
                    </Modal>
                </>
            ) : (
                <AccessDenied />
            )}
        </div>
    );
}

export default LevelMotMap;
