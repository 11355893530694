import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/customField.scss";
import "../../../styles/category.scss";
import {
    loadCustomFields,
    loadSubscriptionBySubsId,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import ServiceEngineerQuestionariesTable from "./ServiceEngineerQuestionariesTable";
import AddServiceEngineerQuestionaries from "./AddServiceEngineerQuestionaries";

function ServiceEngineerQuestionaries() {
    let dispatch = useDispatch();
    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    let tableHeader2 = [
        "Sl.No",
        "Field Name",
        "Field Type",
        "IsRequired",
        "Status",
        "Action",
    ];

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const { allSubsById } = useSelector((state) => state.data);
    const { customFields } = useSelector((state) => state.data);

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    // useEffect(() => {
    let trueDataArray = [];
    if (customFields && customFields.length != 0) {
        trueDataArray = customFields.filter((item) => item.status === 1);
    }

    // }, [])

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        dispatch(loadSubscriptionBySubsId(resPassData.subscriptionId));
        fetchCustomFields();
    }, []);

    const fetchCustomFields = () => {
        dispatch(loadCustomFields(setIsLoading));
    };

    const [userList, setUserList] = useState([
        { value: "", label: "", mainModule: "" },
    ]);

    useEffect(() => {
        if (allSubsById) {
            let optiondata = [];
            allSubsById.map((data) => {
                if (data.moduleID === 7) {
                    optiondata.push({
                        value: data.moduleID,
                        label: data.moduleName,
                        mainModule: data?.mainModuleStatus,
                    });
                }
            });
            setUserList(optiondata);
        }
    }, [allSubsById]);

    const formatOptionLabel = ({ label, client }) => (
        <div>
            <div>{label}</div>
            <div>{client}</div>
        </div>
    );

    const [show, setShow] = useState(false);

    const addFieldModuleOpen = () => {
        setShow(true);
    };

    const addFieldModuleClose = () => {
        setShow(false);
    };

    const [editId, setEditId] = useState("");
    const editCustomFiled = (id) => {
        setEditId(id);
        setShow(true);
    };

    // Page navigation
    const Navigate = useNavigate();
    const goToBackPage = () => {
        Navigate(-1);
    };
    // page navigation

    return (
        <div id="main-page">
            {!show && <>
                {/* SIDEBAR START */}
                <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                {/* SIDEBAR END */}

                {/* TOPBAR START */}
                <Topbar
                    toggleMenu={toggleMenu}
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />
                {/* TOPBAR END */}
                <div className="page-wrapper">
                    <div className={`main-body ${toggleshift.style}`}>
                        <div className="page-content">
                            <Row className="search-row">
                                <Col md={10} className="cat-col">
                                    <div className="page-header-text-div">
                                        <div className="back-btn" onClick={goToBackPage}>
                                            <img
                                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                alt=""
                                            />
                                        </div>
                                        <h6 className="page-header-text">Questionnaires For Service Engineer</h6>
                                    </div>
                                </Col>
                                <Col md={2} className="custom-right-col">
                                    {/* <div className="page-name-custom">
                                    <h6>Page Name:&nbsp;&nbsp;</h6>
                                    <h6>
                                        <b>Meetings</b>
                                    </h6>
                                </div> */}
                                    <SbAddBtn
                                        onClickEffect={addFieldModuleOpen}
                                    // disableProp={trueDataArray.length >= 5}
                                    />
                                </Col>
                            </Row>
                            <div>
                                <Row>
                                    <Col md={12} className="cat-col">
                                        <ServiceEngineerQuestionariesTable
                                            isLoading={isLoading}
                                            tableHeader={tableHeader2}
                                            type="page"
                                            customFields={customFields && customFields}
                                            editCustomFiled={editCustomFiled}
                                        />
                                    </Col>
                                </Row>
                            </div>

                        </div>

                    </div>
                </div>
            </>}
            {show && (
                <AddServiceEngineerQuestionaries
                    show={show}
                    addFieldModuleClose={addFieldModuleClose}
                // editId={editId}
                // setEditId={setEditId}
                // fetchCustomFields={fetchCustomFields}
                // customFieldsActiveLength={trueDataArray.length}
                />
            )}
            <Footer />
        </div>
    );
}

export default ServiceEngineerQuestionaries;
