import '../../styles/AddUserDetails.scss'
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import '../../styles/AddUserModel.scss'
import { useEffect, useState } from "react";
import productimg1 from "../../images/DefaultImages/emptyProduct.svg";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SalesBee from "../../images/MatricLogo.svg";
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import { addAMCData } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';

const amcTypeOptions = [
    { value: "Comprehensive AMC", label: "Comprehensive AMC" },
    { value: "Non-Comprehensive AMC", label: "Non-Comprehensive AMC" },
]

const contractDurationOptions = [
    { value: "Quarterly", label: "Quarterly" },
    { value: "Semi Annually", label: "Semi Annually" },
    { value: "Annually", label: "Annually" },
]
const AddAnnualContractData = (props) => {

    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    let dispatch = useDispatch();
    const amcNum = props.amcNum
    const { getSingleAmcData } = useSelector((state) => state.data);
    console.log(getSingleAmcData, 'getSingleAmcData')
    const [amcNumber, setAmcNumber] = useState("")
    const [amcStartDate, setAmcStartDate] = useState("")
    const [amcEndDate, setAmcEndDate] = useState("")
    const [amcType, setAmcType] = useState({ value: "", label: "" })
    const [invoiceDate, setInvoiceDate] = useState("")
    const [productCode, setProductCode] = useState("")
    const [machineName, setMachineName] = useState("")
    const [companyname, setCompanyname] = useState("")
    const [email, setEmail] = useState("")
    const [serialNum, setSerialname] = useState("")
    const [contactNum, setContactNum] = useState("")
    const [installationDate, setInstallationDate] = useState("")
    const [address, setAddress] = useState("")
    const [installedBy, setInstalledBy] = useState({ value: "", label: "" })
    const [serviceDuration, setServiceDuration] = useState("")
    const [contractDuration, setContractDuration] = useState({ value: "", label: "" })

    const [activeKey, setActiveKey] = useState('');

    const [showMachineDetailsForm, setShowMachineDetailsForm] = useState(true);
    const [showMachineDimensionsForm, setShowMachineDimensionsForm] = useState(false);
    const [showMachineImagesForm, setShowMachineImagesForm] = useState(false);
    const [showMachineDetails, setShowMachineDetails] = useState(false);
    const [showMachineDimensions, setShowMachineDimensions] = useState(false);
    const [showAmcDetails, setShowAmcDetails] = useState(false);
    const [showAmcDetailsForm, setShowAmcDetailsForm] = useState(false);
    const [showMachineDetailsHeader, setShowMachineDetailsHeader] = useState(true);
    const [amcDetailsHeader, setShowAmcDetailsHeader] = useState(false);
    const [showMachineDimensionHeader, setShowMachineDimensionHeader] = useState(false);
    const [showMachineImageHeader, setShowMachineImageHeader] = useState(false);
    const [activeMenu, setActiveMenu] = useState("");

    const toggleMachineDetails = () => {
        setShowMachineDetailsForm(true)
        setShowMachineDimensionsForm(false);
        setShowAmcDetailsForm(false)
        setShowMachineImagesForm(false);
        setShowMachineDetails(false)
        setShowAmcDetails(false)
        setShowMachineDimensions(false)
        setShowMachineDetailsHeader(true)
        setShowAmcDetailsHeader(false)
        setShowMachineDimensionHeader(false)
        setShowMachineImageHeader(false)
        setActiveMenu(1);
    }
    const toggleAmcDetails = () => {
        setShowAmcDetailsForm(true)
        setShowMachineDimensionsForm(false);
        setShowMachineDetailsForm(false)
        setShowMachineImagesForm(false);
        setShowMachineDetails(true);
        setShowAmcDetails(false)
        setShowMachineDimensions(false)
        setShowAmcDetailsHeader(true)
        setActiveMenu(2);
        setActiveKey('0')

        setShowMachineDetailsHeader(false)
        setShowMachineDimensionHeader(false)
        setShowMachineImageHeader(false)
    };
    const toggleMachineDimensions = () => {
        setShowMachineDimensionsForm(true);
        setShowMachineDetailsForm(false);
        setShowMachineImagesForm(false);
        setShowMachineDetails(true)
        setShowAmcDetails(true)
        setShowMachineDimensions(false)
        setShowAmcDetailsForm(false)
        setShowMachineDimensionHeader(true)
        setActiveMenu(3);

        setShowMachineDetailsHeader(false)
        setShowAmcDetailsHeader(false)
        setShowMachineImageHeader(false)
        setActiveKey('1')
    };
    const toggleMachineImages = () => {
        setShowMachineImagesForm(true);
        setShowMachineDetailsForm(false);
        setShowMachineDimensionsForm(false);
        setShowAmcDetails(false)
        setShowMachineDetails(true)
        setShowMachineDimensions(true)
        setShowAmcDetails(true)
        setShowAmcDetailsForm(false)
        setShowMachineImageHeader(true)
        setActiveMenu(4);
        setActiveKey('2')

        setShowMachineDetailsHeader(false)
        setShowAmcDetailsHeader(false)
        setShowMachineDimensionHeader(false)
    };

    useEffect(() => {
        if (resPassData && !installedBy.value) {
            setInstalledBy({
                value: resPassData?.subscription?.company?.companyName,
                label: resPassData?.subscription?.company?.companyName
            });
        }
    }, [resPassData, installedBy.value]);

    // useEffect(() => {
    //     if (amcNum) {
    //         dispatch(loadSingleAmcData(amcNum))
    //     }
    // }, [amcNum])

    useEffect(() => {
        if (props.filteredAmc) {
            // if (Object.keys(getSingleAmcData).length !== 0) {
                setAmcNumber(props.filteredAmc[0]?.amcNumber)
                console.log(props.filteredAmc[0]?.amcNumber,"props.filteredAmc[0]?.amcNumber")
                setAmcStartDate(props.filteredAmc[0]?.amcStartDate)
                setAmcEndDate(props.filteredAmc[0]?.amcEndDate)
                amcTypeOptions.map((type) => {
                    if (type.label === props.filteredAmc[0]?.amcType) {
                        setAmcType(type);
                    }
                });
                contractDurationOptions.map((duration) => {
                    if (duration.label === props.filteredAmc[0]?.expiry) {
                        setContractDuration(duration);
                    }
                });
                setInvoiceDate(props.filteredAmc[0]?.invoiceDate)
                setProductCode(props.filteredAmc[0]?.productCode)
                setMachineName(props.filteredAmc[0]?.productName)
                setCompanyname(props.filteredAmc[0]?.companyName)
                setEmail(props.filteredAmc[0]?.companyEmail)
                setSerialname(props.filteredAmc[0]?.companySerialNumber)
                setContactNum(props.filteredAmc[0]?.phoneNumber)
                setInstallationDate(props.filteredAmc[0]?.installationDate)
                setServiceDuration(props.filteredAmc[0]?.numberOfDays)
                setAddress(props.filteredAmc[0]?.address)
            // }
        }
    }, [props.filteredAmc])


    const addAmcData = (e) => {
        e.preventDefault();
        const data = {
            "userID": resPassData.userId,
            "subscriptionID": resPassData.subscriptionId,
            "amcNumber": amcNum,
            "amcStartDate": amcStartDate,
            "amcEndDate": amcEndDate,
            "amcType": amcType.label,
            "invoiceDate": invoiceDate,
            "productCode": productCode,
            "productName": machineName,
            "companyName": companyname,
            "companyEmail": email,
            "companySerialNumber": serialNum,
            "phoneNumber": contactNum,
            "installationDate": installationDate,
            "address": address,
            "installedBy": installedBy.label,
            // "serviceDuration": serviceDuration,
            "numberOfDays": serviceDuration,
            "expiry": contractDuration.label,
        }
        let postAmc = {
            method: "POST",
            mode: 'cors',
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(data)
        }
        console.log(data, 'data')
        dispatch(addAMCData(postAmc, closeModel))
    }


    const closeModel = () => {
        setAmcNumber("")
        setAmcStartDate("")
        setAmcEndDate("")
        setAmcType({ value: "", label: "" })
        setContractDuration("")
        setInvoiceDate("")
        setProductCode("")
        setMachineName("")
        setCompanyname("")
        setEmail("")
        setSerialname("")
        setContactNum("")
        setInstallationDate("")
        setInstalledBy({ value: "", label: "" })
        setServiceDuration("")
        setAddress("")
        props.setFilteredAmc([])
        props.handleClose();
    };

    return (
        <div
            show={props.show}
            onHide={props.handleClose}
        >
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className='machines-logo-div'>
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineDetails}>
                        <div className={`add-user-details-menu-numbers ${activeMenu === 1 ? 'active' : 'inactive'}`} >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name">AMC Details</div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleAmcDetails}>
                        <div className="add-user-details-menus">
                            <div
                                // className="add-user-details-menu-numbers active"
                                className={`add-user-details-menu-numbers ${activeMenu === 2 ? 'active' : 'inactive'}`}
                            >
                                <p className="add-user-details-menu-number-one">
                                    <span>02</span>
                                </p>
                                <div className="add-user-details-menu-name">Product/Company Details</div>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineDimensions}>
                        <div className="add-user-details-menus">
                            <div
                                //  className="add-user-details-menu-numbers active"
                                className={`add-user-details-menu-numbers ${activeMenu === 3 ? 'active' : 'inactive'}`}
                            >
                                <p className="add-user-details-menu-number-one">
                                    <span>03</span>
                                </p>
                                <div className="add-user-details-menu-name">Installation Details</div>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineImages}>
                        <div className="add-user-details-menus">
                            <div
                                // className="add-user-details-menu-numbers active" 
                                className={`add-user-details-menu-numbers ${activeMenu === 4 ? 'active' : 'inactive'}`}
                            >
                                <p className="add-user-details-menu-number-one">
                                    <span>04</span>
                                </p>
                                <div className="add-user-details-menu-name">Images</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    {showMachineDetailsHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>AMC Details</h4>
                            <p>Please enter amc infomation and proceed to the next step.</p>
                        </div>
                    </div>}
                    {amcDetailsHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={toggleMachineDetails} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Product/Company Details</h4>
                            <p>Please enter product/machine infomation and proceed to the next step.</p>
                        </div>
                    </div>}
                    {showMachineDimensionHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={toggleAmcDetails} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Installation Details</h4>
                            <p>Please enter installation infomation and proceed to the next step.</p>
                        </div>
                    </div>}
                    {showMachineImageHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={toggleMachineDimensions} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>AMC Images</h4>
                            <p>Please upload images and proceed to the next.</p>
                        </div>
                    </div>}
                    <div style={{ position: " relative" }} className="amc-single-image">

                        {showMachineDetailsForm && <form>
                            {/* <div className="row"> */}

                            <div className="col-lg-10 col">
                                <div>
                                    <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel
                                                    label="AMC Number"
                                                    className={`${amcNum
                                                        ? "float-input-visible"
                                                        : "float-hidden float-input"
                                                        }`}
                                                >
                                                    <input
                                                        type="text"
                                                        className="form-control add-user-input"
                                                        placeholder="AMC Number"
                                                        value={amcNumber}
                                                        onChange={(e) => { setAmcNumber(e.target.value) }}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel
                                                    label="AMC Start Date"
                                                    // className={`${amcStartDate
                                                    //     ? "float-input-visible"
                                                    //     : "float-hidden float-input"
                                                    //     }`}
                                                    className="float-input-visible"
                                                >
                                                    <input
                                                        type="date"
                                                        id="amcStartDate"
                                                        class="form-control add-user-input"
                                                        placeholder="AMC Start Date"
                                                        value={amcStartDate}
                                                        onChange={(e) => { setAmcStartDate(e.target.value) }}
                                                    />

                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel
                                                    label="AMC End Date"
                                                    // className={`${amcEndDate
                                                    //     ? "float-input-visible"
                                                    //     : "float-hidden float-input"
                                                    //     }`}
                                                    className="float-input-visible"
                                                >
                                                    <input
                                                        type="date"
                                                        className="form-control add-user-input"
                                                        placeholder="AMC End Date"
                                                        value={amcEndDate}
                                                        onChange={(e) => { setAmcEndDate(e.target.value) }}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel
                                                    label="AMC Type"
                                                    className={`${amcType.value !== ""
                                                        ? "float-visible-select"
                                                        : "float-hidden float-select"
                                                        }`}
                                                >
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        placeholder="AMC Type"
                                                        isSearchable={true}
                                                        options={amcTypeOptions}
                                                        value={amcType.value && amcType}
                                                        onChange={(data) => { setAmcType(data) }}
                                                        onInputChange={amcTypeOptions.label}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row add-user-image-container-div">
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel
                                                    label="Invoice Date"
                                                    // className={`${invoiceDate
                                                    //     ? "float-input-visible"
                                                    //     : "float-hidden float-input"
                                                    //     }`}
                                                    className="float-input-visible"
                                                >
                                                    <input
                                                        type="Date"
                                                        className="form-control add-user-input"
                                                        placeholder="Invoice Date"
                                                        value={invoiceDate}
                                                        onChange={(e) => { setInvoiceDate(e.target.value) }}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel
                                                    label="Contract Duration"
                                                    className={`${contractDuration.value !== ""
                                                        ? "float-visible-select"
                                                        : "float-hidden float-select"
                                                        }`}
                                                >
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        placeholder="Contract Duration"
                                                        isSearchable={true}
                                                        options={contractDurationOptions}
                                                        value={contractDuration.value && contractDuration}
                                                        onChange={(data) => { setContractDuration(data) }}
                                                        onInputChange={contractDurationOptions.label}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='machines-next-arrow-button'>
                                    <span class="material-symbols-outlined" onClick={toggleAmcDetails}>
                                        arrow_circle_right
                                    </span>
                                </div>
                            </div>
                            {/* </div> */}

                        </form>}

                        <div className="d-flex justify-content-between" style={{ padding: "0px 25px" }}>

                            <div className="col-lg-5 col">
                                {showAmcDetailsForm && <form>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Product/Machine Code"
                                            className={`${productCode
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Product Code"
                                                value={productCode}
                                                onChange={(e) => { setProductCode(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Product/Machine Name"
                                            className={`${machineName
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Product/Machine Name"
                                                value={machineName}
                                                onChange={(e) => { setMachineName(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Company Name"
                                            className={`${companyname
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Company Name"
                                                value={companyname}
                                                onChange={(e) => { setCompanyname(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Company Email"
                                            className={`${email
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Company Email"
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Company Serial Number"
                                            className={`${serialNum
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Company Serial Number"
                                                value={serialNum}
                                                onChange={(e) => { setSerialname(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Phone Number"
                                            className={`${contactNum
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Phone Number"
                                                value={contactNum}
                                                onChange={(e) => { setContactNum(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className='machines-next-arrow-button'>
                                        <span class="material-symbols-outlined" onClick={toggleMachineDimensions}>
                                            arrow_circle_right
                                        </span>
                                    </div>
                                </form>}
                                {showMachineDimensionsForm && <form>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Installation Date"
                                            // className={`${installationDate
                                            //     ? "float-input-visible"
                                            //     : "float-hidden float-input"
                                            //     }`}
                                            className="float-input-visible"
                                        >
                                            <input
                                                type="date"
                                                className="form-control add-user-input"
                                                placeholder="Installation Date"
                                                value={installationDate}
                                                onChange={(e) => { setInstallationDate(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Installed By"
                                            className={`${installedBy.value !== ""
                                                ? "float-visible-select"
                                                : "float-hidden float-select"
                                                }`}
                                        >
                                            <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                placeholder="Installed By"
                                                isSearchable={true}
                                                value={installedBy}
                                                isDisabled
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Service Duration (no.of days)"
                                            className={`${serviceDuration
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control add-user-input"
                                                placeholder="Service Duration (no.of days)"
                                                value={serviceDuration}
                                                onChange={(e) => { setServiceDuration(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel
                                            label="Address"
                                            className={`${address
                                                ? "float-input-visible"
                                                : "float-hidden float-input"
                                                }`}
                                        >
                                            <textarea
                                                rows="3"
                                                cols="20"
                                                name="instruction"
                                                wrap="hard"
                                                className="form-control form-textarea add-product-input"
                                                id="descriptionArea"
                                                placeholder="Address"
                                                value={address}
                                                onChange={(e) => { setAddress(e.target.value) }}
                                            />
                                        </FloatingLabel>
                                    </div>

                                    <div className='machines-next-arrow-button'>
                                        <span class="material-symbols-outlined" onClick={toggleMachineImages}>
                                            arrow_circle_right
                                        </span>
                                    </div>
                                </form>}
                                {showMachineImagesForm && <form>
                                    <div className="mb-3">
                                        <button type="button" className="btn btn-add-machine-img">
                                            <span
                                                style={{
                                                    color: "#04A96D",
                                                    fontSize: "13px",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <div
                                                    className="d-flex justify-content-center"
                                                    onDragOver={(e) => e.preventDefault()}
                                                // onDrop={handleDrop}
                                                >

                                                </div>

                                                <div
                                                    className="drop-area"
                                                    onDragOver={(e) => e.preventDefault()}
                                                // onDrop={handleDrop}
                                                >
                                                    <h6>Upload Images</h6>
                                                    <br />
                                                    <img
                                                        src="../../../images/DefaultImages/UploadImages.svg"
                                                        alt=""
                                                    />
                                                    <p>Drag and drop images here</p>
                                                    <p>OR</p>
                                                    <SbCancelBtn
                                                        btnName="Browse"
                                                    // onClickEffect={uploadMultiple}
                                                    />
                                                    <p style={{ fontSize: "11px" }}>
                                                        Supports Only JPG, JPEG, PNG
                                                    </p>
                                                </div>
                                                {/* )} */}
                                            </span>
                                        </button>
                                        <input
                                            id="getFile"
                                            type="file"
                                            multiple
                                            name="filetobase64"
                                            accept="image/*"
                                            className="btn-primary upload"
                                            style={{ display: "none" }}
                                        />
                                    </div>
                                    <div className="add-page-save-cancel">
                                        <SBSaveUpdateBtn btnName="Save" onClickEffect={addAmcData} />
                                        <SbCancelBtn
                                            btnName="Cancel"
                                        // onClickEffect={() => {
                                        //     props.handleClose();
                                        // }}
                                        />
                                    </div>
                                </form >}
                            </div>
                            {/* <AccordianPage
                            showMachineDetails={showMachineDetails}
                            showAmcDetails={showAmcDetails}
                            showMachineDimensions={showMachineDimensions}
                            activeKey={activeKey} /> */}

                            <div className="col-lg-6 col machine-details-cards">
                                <Accordion defaultActiveKey="0" activeKey={activeKey} flush>
                                    <Card>
                                        {showMachineDetails && (
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header >
                                                    AMC Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="amc-second-container">
                                                        <div className="amc-second-container-labels">
                                                            <span>
                                                                <p>AMC Number</p>
                                                                : <b>{amcNumber}</b>
                                                            </span>
                                                            <span>
                                                                <p>AMC Start Date</p>
                                                                : <b>{amcStartDate}</b>
                                                            </span>
                                                            <span>
                                                                <p>AMC End Date</p>
                                                                : <b>{amcEndDate}</b>
                                                            </span>
                                                            <span>
                                                                <p>AMC Type</p>
                                                                : <b>{amcType.label}</b>
                                                            </span>
                                                            <span>
                                                                <p>Invoice Date</p>
                                                                : <b>{invoiceDate}</b>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                        {showAmcDetails && (
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header >
                                                    Product/Company Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="amc-second-container">
                                                        <div>
                                                            <div className="amc-second-container-labels">
                                                                <span>
                                                                    <p>Product/Machine Code</p>
                                                                    : <b>{productCode}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Product/Machine Name</p>
                                                                    : <b>{machineName}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Company Name</p>
                                                                    : <b>{companyname}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Company Email</p>
                                                                    : <b>{email}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Serial Number</p>
                                                                    : <b>{serialNum}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Phone Number</p>
                                                                    : <b>{contactNum}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                        {showMachineDimensions && (
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    Installation Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="amc-second-container">
                                                        <div>
                                                            <div className="amc-second-container-labels">
                                                                <span>
                                                                    <p>Installation Date</p>
                                                                    : <b>{installationDate}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Installed By</p>
                                                                    : <b>{installedBy.label}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Service Duration</p>
                                                                    : <b>{serviceDuration}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Address</p>
                                                                    : <b>{address}</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                    </Card>
                                </Accordion>
                            </div>

                        </div>

                    </div>
                </div >

            </div >
        </div >
    );
}

export default AddAnnualContractData;