import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, FloatingLabel, Form, ModalFooter, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { loadClientStageGrop, loadReferredByDropdowndata, loadSubscriptionDropdown, loadUpdateSubscription } from '../../redux/action'
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import SbCancelBtn from '../SbButtons/SbCancelBtn';
import DatePicker from "react-datepicker";

const UpGradeSubscriptionModal = (props) => {

  let dispatch = useDispatch();
  const { subscriptionTypeDropdown } = useSelector((state) => state.data);
  const { getReferredByDropdownData } = useSelector((state) => state.data);
  const { stageDropdown } = useSelector((state) => state.data);
  const [updatedSub, setUpdatedSub] = useState({ value: "", Label: "" });
  const [subScriptionTypeID, setSubscriptionTypeID] = useState()
  const [noOfUsers, setNumberOfusers] = useState();
  const [leadStage, setLeadStage] = useState({ value: "", label: "" });
  const [BDEName, setBDEName] = useState({ value: "", label: "" });
  const [paymentReceived, setpaymentReceived] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const closeModal = () => {
    props.setSubScribeId();
    props.handleClose(); // Close the modal
  };

  useEffect(() => {
    dispatch(loadSubscriptionDropdown());
    dispatch(loadReferredByDropdowndata());
    dispatch(loadClientStageGrop());
  }, []);

  const handleSelectChange = (data) => {
    setUpdatedSub(data)
    setSubscriptionTypeID(data.value)
  }

  const [showAlert, setShowAlert] = useState(false)
  const postUpdateSub = (e) => {
    e.preventDefault();
    if (subScriptionTypeID && props.subscriptionId) {
      let updateData = {
        subscriptionTypeID: parseInt(subScriptionTypeID),
        subscriptionId: props.subscriptionId
      }
      console.log(updateData, "updateData")
      let postSubscription = {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.postTravelizeAuth,
        body: JSON.stringify(updateData),
      };
      dispatch(loadUpdateSubscription(postSubscription, closeModal))
    } else {
      setShowAlert(true)
    }
  }

  return (
    <Modal show={props.show} onHide={props.handleClose} className="category-modal-div">
      <Row className='modale-header-div'>
        <Col md={6} xs={9} className='cat-col'>
          <h6 className='page-header-text'>Upgrade Subscription</h6>
        </Col>
        <Col md={6} xs={3} className='cat-col' align="right">

          <button onClick={closeModal} className='bi bi-x close-popup'></button>
        </Col>
      </Row>
      <hr />
      <div className='cat-col'>
        <Form onSubmit={postUpdateSub}>
          <div className='form-field'>
            <FloatingLabel label='Upgrade Subscription' className={`${updatedSub.value !== '' ? "float-visible-select" : "float-hidden float-select"}`}>
              <Select
                className="react-select-container-list model-select"
                classNamePrefix="react-select-list"
                value={!updatedSub.value ? "" : subscriptionTypeDropdown.value}
                onInputChange={subscriptionTypeDropdown.label}
                onChange={(data) => { handleSelectChange(data) }}
                options={subscriptionTypeDropdown}
                placeholder="Upgrade Subscription"
                isSearchable={true}
              // value={updatedSub} 
              // onChange={handleSelectChange}
              />
            </FloatingLabel>
            {(showAlert === true && !subScriptionTypeID) && (
              <small
                id={`Error`}
                className="form-text text-muted "
              >Please Select Subscription
              </small>
            )}
          </div >
          <div className='form-field'>
            <FloatingLabel label='Lead Stage' className={`${leadStage.value !== '' ? "float-visible-select" : "float-hidden float-select"}`}>
              <Select
                className="react-select-container-list model-select"
                classNamePrefix="react-select-list"
                // value={!updatedSub.value ? "" : subscriptionTypeDropdown.value}
                // onInputChange={subscriptionTypeDropdown.label}
                // onChange={(data) => { handleSelectChange(data) }}
                options={stageDropdown}
                placeholder="Lead Stage"
                isSearchable={true}
              />
            </FloatingLabel>
          </div >
          <div className='form-field'>
            <FloatingLabel label='BDE Name' className={`${BDEName.value !== '' ? "float-visible-select" : "float-hidden float-select"}`}>
              <Select
                className="react-select-container-list model-select"
                classNamePrefix="react-select-list"
                // value={!updatedSub.value ? "" : subscriptionTypeDropdown.value}
                // onInputChange={subscriptionTypeDropdown.label}
                // onChange={(data) => { handleSelectChange(data) }}
                options={getReferredByDropdownData}
                placeholder="BDE Name"
                isSearchable={true}
              />
            </FloatingLabel>
          </div >
          <div className='form-field'>
            <FloatingLabel
              label="Number of Users"
              className={`${noOfUsers
                ? "float-input-visible"
                : "float-hidden float-input"
                }`}
            >
              <input
                type="text"
                className="form-control add-user-input"
                placeholder="Number of Users"
                name="productCode"
              // value={machineCode}
              // onChange={(e) => { setMachineCode(e.target.value) }}
              />
            </FloatingLabel>
          </div >
          <div className='form-field'>
            <FloatingLabel label='Start Date' className={`${startDate.value !== '' ? "float-visible-select" : "float-hidden float-select"}`}>
              <div className="meeting-date-picker-with-icon">
                <DatePicker
                  selected={startDate}
                  className="select-add-meeting-model meeting-date model-input"
                  dateFormat="dd/MM/yyyy"
                  // selectsEnd
                  // startDate={date}
                  // endDate={toDate}
                  minDate={new Date()}
                // onChange={(date) => setDate(date)}
                // maxDate={new Date()}
                />
                <i className="fa fa-calendar-o calander-icon" />
              </div>
            </FloatingLabel>
          </div >
          <div className='form-field'>
            <FloatingLabel label='End Date' className={`${endDate.value !== '' ? "float-visible-select" : "float-hidden float-select"}`}>
              <div className="meeting-date-picker-with-icon">
                <DatePicker
                  selected={endDate}
                  className="select-add-meeting-model meeting-date model-input"
                  dateFormat="dd/MM/yyyy"
                  // selectsEnd
                  // startDate={date}
                  // endDate={toDate}
                  minDate={new Date()}
                // onChange={(date) => setDate(date)}
                // maxDate={new Date()}
                />
                <i className="fa fa-calendar-o calander-icon" />
              </div>
            </FloatingLabel>
          </div >
          <div className='form-field'>
            <FloatingLabel
              label="Payment Recieved"
              className={`${paymentReceived
                ? "float-input-visible"
                : "float-hidden float-input"
                }`}
            >
              <input
                type="text"
                className="form-control add-user-input"
                placeholder="Payment Recieved"
                name="productCode"
              // value={machineCode}
              // onChange={(e) => { setMachineCode(e.target.value) }}
              />
            </FloatingLabel>
          </div >

          <hr />
          <div className='sb-btn-section'>
            <SBSaveUpdateBtn btnName="Update" />
            <SbCancelBtn btnName="Cancel" onClickEffect={closeModal} />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpGradeSubscriptionModal;




