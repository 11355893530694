import React, { useState } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/BaseFare.scss";
import "../../styles/Whatsapp.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import Footer from "../Footer";
import WhatsappTable from "./WhatsappTable";

function Whatsapp() {
    let tableHeader = [
        { name: "MOBILE NO", align: "left", width: "150px" },
        { name: "DATE", align: "left", width: "100px" },
        { name: "TIME", align: "left", width: "100px" },
        { name: "MESSAGE ID", align: "left", width: "120px" },
        { name: "MESSAGE", align: "left", width: "150px" },
        { name: "STATUS", align: "left", width: "120px" },
    ]

    const whatsappData = [
        {
            mobile: 9591617840,
            date: "09 aug 2024",
            time: "09:00 AM",
            messageSentFrom: "Direct Meeting",
            message: "hi...",
            status: "delivered",
        },
        {
            mobile: 9509585855,
            date: "09 aug 2024",
            time: "11:10 AM",
            messageSentFrom: "Scheduled Meeting",
            message: "hi...",
            status: "Failed",
        },
    ]

    // PAGINATION START
    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [show, setShow] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const [openFilterTab, setOpenFilterTab] = useState(false);

    // Page navigation
    let navigate = useNavigate();
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation




    return (
        <div id="main-page">
            {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
                <>
                    {/* SIDEBAR START */}
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    {/* SIDEBAR END */}

                    {/* TOPBAR START */}
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    {/* TOPBAR END */}

                    {/* MAIN BODY START */}
                    <div className="page-wrapper">
                        {/* PAGE CONTENT START */}
                        <div className={`main-body ${toggleshift.style}`}>

                            <div className="page-content">
                                <div style={{ position: "relative" }}>
                                    <Row className='search-row'>
                                        <Col md={3} className='cat-col'>
                                            <div className='page-header-text-div'>
                                                <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                                                <h6 className='page-header-text' >WhatsApp</h6>
                                            </div>
                                        </Col>
                                        {/* <Col md={5} className='cat-col'>
                                            <div className='input-div'>
                                                <input type="search" placeholder='Find a Product' className='form-control setting-input'
                                                    id="metricInfo_search_bar" value={searchValue} onChange={(e) => setSearchData(e.target.value)}
                                                />
                                                <i className='fa fa-search search-icon' ></i>
                                            </div>
                                        </Col> */}
                                        {/* <Col md={4} className='cat-col'>
                                            <div className="search-bar-header" style={{ float: "right" }}>
                                                <div className="page-top-bar-dropdowns page-top-Filters">
                                                    <div className="header-filter-btn" onClick={() => setOpenFilterTab(!openFilterTab)}>
                                                        <p className="card-head">
                                                            <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                                                        </p>
                                                    </div>
                                                    <SbAddBtn onClickEffect={() => setShow(!show)} />
                                                </div>
                                            </div>
                                        </Col> */}
                                    </Row>

                                    {/* {openFilterTab &&
                                        <div className="page-filter-section">
                                            <div style={{ width: "100%" }}>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="clients-filter-input-boxs filter-box-div">
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    padding: "0px 5px",
                                                                }}
                                                            >
                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    options={userReferralsDropdown}
                                                                    placeholder="Select User"
                                                                    value={user}
                                                                    onInputChange={userReferralsDropdown.label}
                                                                    onChange={(data) =>
                                                                        setSelectSearchDrop(data, "user")
                                                                    }
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    padding: "0px 5px",
                                                                }}
                                                            >
                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    options={productOptions}
                                                                    placeholder="Select Product"
                                                                    value={product}
                                                                    onInputChange={productOptions.label}
                                                                    onChange={(data) =>
                                                                        setSelectSearchDrop(data, "product")
                                                                    }
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                            <div style={{
                                                                width: "100%",
                                                                padding: "0px 5px",
                                                            }}>
                                                                <Select
                                                                    className="react-select-container-list"
                                                                    classNamePrefix="react-select-list"
                                                                    options={optionsStatusList}
                                                                    placeholder="Select Status"
                                                                    value={status}
                                                                    onInputChange={optionsStatusList.label}
                                                                    onChange={(data) =>
                                                                        setSelectSearchDrop(data, "status")
                                                                    }
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <div className="clients-filter-input-boxs filter-box-div">
                                                            <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                                                                <span className="input-group-text" id="from-label">
                                                                    From - To
                                                                </span>
                                                                <span className="date-picker-with-icon">
                                                                    <DatePicker
                                                                        selected={fromDate}
                                                                        className="select from-date"
                                                                        id="date-range-picker"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        selectsRange
                                                                        startDate={fromDate}
                                                                        endDate={toDate}
                                                                        onChange={(data) => setSelectSearchDrop(data, "date")}
                                                                        maxDate={new Date()}
                                                                    />
                                                                </span>
                                                                <span><i className="fa fa-calendar-o calander-icon" style={{ right: "15px" }} /></span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="clients-filter-input-boxs filter-box-div">
                                                            <SbCancelBtn onClickEffect={clearSerachBySelect}
                                                                btnName="Clear" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    } */}
                                </div>



                                {/*TABLE START */}
                                <WhatsappTable
                                    tableHeader={tableHeader}
                                    whatsappData={whatsappData}
                                    // isLoading={isLoading}
                                    loading={loading}
                                    setPageSize={setPageSize}
                                    PageSize={PageSize}
                                    setLoading={setLoading}
                                />
                                {/*TABLE END */}

                                {/* PAGE CONTENT END*/}
                            </div>
                        </div>

                        {/* MAIN BODY END */}
                    </div>
                    <Footer />
                </>
            ) : (
                <AccessDenied />
            )}
        </div>
    );
}

export default Whatsapp;


