import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import AddEditFormComponent from "../../ReUseComponents/AddEditFormComponent";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/VhdBranch.scss";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { enableYesNoOptions } from "../../DropdownsData";
import DatePicker from "react-datepicker";

export default function AddEditVendorModule(props) {
  const [countryList, setCountryList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [country, setCountry] = useState({ value: null, label: "" });
  const [zone, setZone] = useState({ value: null, label: "" });
  const [state, setState] = useState({ value: null, label: "" });
  const [city, setCity] = useState({ value: null, label: "" });
  const [fieldReq, setFieldReq] = useState(false);
  const [lineItemsModule, setLineItemsModule] = useState(false);

  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [vendorData, setVendorData] = useState({
    vendorName: "",
    vendorCode: "",
    contactPerson: "",
    contactNumber: "",
    contactEmail: "",
    branchName: "",
    countryId: "",
    zoneId: "",
    stateId: "",
    location: "",
    enableStatus: 1,
  });

  const {
    vendorName,
    vendorCode,
    contactPerson,
    contactNumber,
    contactEmail,
    branchName,
    countryId,
    zoneId,
    stateId,
    location,
    enableStatus,
  } = vendorData;

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setVendorData({ ...vendorData, [name]: value });
  };

  const handleSelectChange = (data, type) => {
    console.log("ON-CHANGE-SELECT:", "Select Type is changing");
  };

  const addVendorDetails = () => {
    console.log("ADD-DATA:", "api data come");
  };

  const closeModel = () => {
    props.handleClose();
    props.setVendorID(null);
  };

  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={props.vendorID ? "Edit Vendor" : "Add Vendor"}
        formSubTitle={
          props.vendorID
            ? "Edit and update the vendor details."
            : "Please fill the vendor details."
        }
        sidebarItems={["Vendor"]}
        childrens={
          <>
            <form>
              <div className="add-branch-to-vhd">
                <div className="add-branch-details">
                  <div className="row">
                    <div className="col my-2">
                      <FloatingLabel
                        label="Vendor Name"
                        className={`${
                          vendorName
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Vendor Name"
                          value={vendorName}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-2">
                      <FloatingLabel
                        label="Vendor Code"
                        className={`${
                          vendorCode
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Vendor Code"
                          value={vendorCode}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-2">
                      <FloatingLabel
                        label="Contact Person"
                        className={`${
                          contactPerson
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Contact Person"
                          value={contactPerson}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-2">
                      <FloatingLabel
                        label="Contact No."
                        className={`${
                          contactNumber
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="number"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Contact No."
                          maxLength={12}
                          value={contactNumber}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-2">
                      <FloatingLabel
                        label="Contact Email"
                        className={`${
                          contactEmail
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="email"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Contact Email"
                          value={contactEmail}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>

                    <div className="col my-2">
                      <FloatingLabel
                        label="Branch"
                        className={`${
                          branchName
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Branch"
                          value={branchName}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col my-2">
                      <FloatingLabel
                        label="Location / Address :"
                        className={`${
                          location
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <textarea
                          rows="3"
                          cols="20"
                          name="shipTo"
                          wrap="hard"
                          className="form-control form-textarea add-user-input"
                          placeholder="Shipping Address"
                          value={location}
                          readOnly={true}
                          // required
                          // onChange={(e) => setLocation(e)}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                </div>
                <div className="row dflex-j-between">
                  {props.branchID && (
                    <div className="dflex-j-start">
                      <div className="mb-2">Enable</div>&nbsp;&nbsp;&nbsp;
                      {enableYesNoOptions.map((item, i) => (
                        <div key={i}>
                          <Form.Check
                            inline
                            label={item?.label}
                            value={item?.value}
                            name="enableStatus"
                            type="radio"
                            id={`inlineradio-enableStatus-${i}`}
                            onChange={handleInputData}
                            checked={item?.value == enableStatus ? true : false}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="add-page-save-cancel">
                    <SBSaveUpdateBtn
                      btnName="Save"
                      onClickEffect={addVendorDetails}
                    />
                    <SbCancelBtn btnName="Cancel" onClickEffect={closeModel} />
                  </div>
                </div>
              </div>
            </form>
          </>
        }
      />
    </div>
  );
}
