import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import AddEditFormComponent from "../../ReUseComponents/AddEditFormComponent";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/VhdBranch.scss";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { enableYesNoOptions } from "../../DropdownsData";
import DatePicker from "react-datepicker";

export default function AddEditPOModel(props) {
  const [countryList, setCountryList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [country, setCountry] = useState({ value: null, label: "" });
  const [zone, setZone] = useState({ value: null, label: "" });
  const [state, setState] = useState({ value: null, label: "" });
  const [city, setCity] = useState({ value: null, label: "" });
  const [fieldReq, setFieldReq] = useState(false);
  const [lineItemsModule, setLineItemsModule] = useState(false);

  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [purchaseOrderData, setPurchaseOrderData] = useState({
    docNum: "",
    poDate: new Date(),
    vendorName: "",
    vendorRef_No: "",
    contactNumber: "",
    contactEmail: "",
    countryId: "",
    zoneId: "",
    stateId: "",
    location: "",
    enableStatus: 1,
  });

  const {
    docNum,
    poDate,
    vendorName,
    vendorRef_No,
    contactNumber,
    contactEmail,
    countryId,
    zoneId,
    stateId,
    location,
    enableStatus,
  } = purchaseOrderData;

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setPurchaseOrderData({ ...purchaseOrderData, [name]: value });
  };

  const handleSelectChange = (data, type) => {
    if (type === "country") {
      setCountry(data);
      purchaseOrderData.countryId = data.value;
    }
  };

  const goToPurchaseOrderLineItems = () => {
    setLineItemsModule(true);
  };

  const backToPrevious = () => {
    setLineItemsModule(false);
  };

  const addPurchaseOrderDetails = () => {
    console.log("ADD-DATA:", "api data come");
  };

  const closeModel = () => {
    props.handleClose();
    props.setPurchaseOrderID(null);
  };
  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={props.purchaseOrderID ? "Purchase Order" : "Purchase Order"}
        formSubTitle={
          props.purchaseOrderID
            ? "Edit and update the purchase order details."
            : "Please fill the purchase order details."
        }
        sidebarItems={["Purchase Order"]}
        childrens={
          <>
            {!lineItemsModule ? (
              <form>
                <div className="add-branch-to-vhd">
                  <div className="add-branch-details">
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="PO Date"
                          className={`${
                            poDate
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <DatePicker
                            selected={poDate}
                            className="form-control form add-input"
                            dateFormat="dd/MM/yyyy"
                            placeholder="PO Date"
                            onChange={(date) => handleInputData(date, "poDate")}
                            // maxDate={new Date()}
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="PO DocEntry"
                          className={`${
                            vendorName
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="PO DocEntry"
                            value={vendorName}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>

                        {/* {fieldReq && vendorName == "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )} */}
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Doc. No."
                          className={`${
                            docNum
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Doc. No."
                            value={docNum}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>

                      <div className="col my-2">
                        <FloatingLabel
                          label="Branch"
                          className={`${
                            docNum
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Branch"
                            value={docNum}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="Vendor Name"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Vendor Name"
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Vendor Ref.No"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Vendor Ref.No"
                            maxLength={10}
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Quotation Ref. No."
                          className={`${
                            contactEmail
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Quotation Ref. No."
                            value={contactEmail}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Quotation Date"
                          className={`${
                            poDate
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <DatePicker
                            selected={poDate}
                            className="form-control form add-input"
                            dateFormat="dd/MM/yyyy"
                            placeholder="Quotation Date"
                            onChange={(date) => handleInputData(date, "poDate")}
                            // maxDate={new Date()}
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="Contact Person"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Contact Person"
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Contact No."
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Contact No."
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Doc. Total"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Doc. Total"
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Tax Amount"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Tax Amount"
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="Shipping Address"
                          className={`${
                            location
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <textarea
                            rows="3"
                            cols="20"
                            name="shipTo"
                            wrap="hard"
                            className="form-control form-textarea add-user-input"
                            placeholder="Shipping Address"
                            value={location}
                            readOnly={true}
                            // required
                            // onChange={(e) => setLocation(e)}
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Billing Address"
                          className={`${
                            location
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <textarea
                            rows="3"
                            cols="20"
                            name="billTo"
                            wrap="hard"
                            className="form-control form-textarea add-user-input"
                            placeholder="Billing Address"
                            value={location}
                            readOnly={true}
                            // required
                            // onChange={(e) => setLocation(e)}
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="Remarks"
                          className={`${
                            location
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <textarea
                            rows="3"
                            cols="20"
                            name="remarks"
                            wrap="hard"
                            className="form-control form-textarea add-user-input"
                            placeholder="Remarks"
                            value={location}
                            readOnly={true}
                            // required
                            // onChange={(e) => setLocation(e)}
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                  <div className="row dflex-j-between">
                    {props.branchID && (
                      <div className="dflex-j-start">
                        <div className="mb-2">Enable</div>&nbsp;&nbsp;&nbsp;
                        {enableYesNoOptions.map((item, i) => (
                          <div key={i}>
                            <Form.Check
                              inline
                              label={item?.label}
                              value={item?.value}
                              name="enableStatus"
                              type="radio"
                              id={`inlineradio-enableStatus-${i}`}
                              onChange={handleInputData}
                              checked={
                                item?.value == enableStatus ? true : false
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="add-page-save-cancel">
                      <SBSaveUpdateBtn
                        btnName="Next"
                        onClickEffect={goToPurchaseOrderLineItems}
                      />
                      <SbCancelBtn
                        btnName="Cancel"
                        onClickEffect={closeModel}
                      />
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <form>
                {" "}
                <div className="add-branch-to-vhd">
                  <div className="add-branch-details">
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="Item Name"
                          className={`${
                            vendorName
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Item Name"
                            value={vendorName}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>

                        {/* {fieldReq && vendorName == "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )} */}
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Item Code"
                          className={`${
                            docNum
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Item Code"
                            value={docNum}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>

                      <div className="col my-2">
                        <FloatingLabel
                          label="Quantity"
                          className={`${
                            docNum
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Quantity"
                            value={docNum}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="UOM"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="UOM"
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Unit Rate"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Unit Rate"
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Line Total"
                          className={`${
                            contactEmail
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Line Total"
                            value={contactEmail}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Discount %"
                          className={`${
                            contactEmail
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Discount %"
                            value={contactEmail}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col my-2">
                        <FloatingLabel
                          label="Tax %"
                          className={`${
                            contactEmail
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Tax %"
                            value={contactEmail}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Tax Code"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Tax Code"
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Tax Amount"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Tax Amount"
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col my-2">
                        <FloatingLabel
                          label="Gross Total"
                          className={`${
                            vendorRef_No
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control form add-input"
                            placeholder="Gross Total"
                            value={vendorRef_No}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                  <div className="row dflex-j-between">
                    {props.branchID && (
                      <div className="dflex-j-start">
                        <div className="mb-2">Enable</div>&nbsp;&nbsp;&nbsp;
                        {enableYesNoOptions.map((item, i) => (
                          <div key={i}>
                            <Form.Check
                              inline
                              label={item?.label}
                              value={item?.value}
                              name="enableStatus"
                              type="radio"
                              id={`inlineradio-enableStatus-${i}`}
                              onChange={handleInputData}
                              checked={
                                item?.value == enableStatus ? true : false
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="add-page-save-cancel">
                      <SBSaveUpdateBtn btnName="Save" />
                      <SbCancelBtn
                        btnName="Previous"
                        onClickEffect={backToPrevious}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </>
        }
      />
    </div>
  );
}
