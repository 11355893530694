import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Claims.scss";
import { Link, useNavigate } from "react-router-dom";
import PendingClaimAdmin from "./PendingClaimAdmin";
import ApprovedClaimAdmin from "./ApprovedClaimAdmin";
import RejectedClaimAdmin from "./RejectedClaimAdmin";
import { baseURL } from "../BaseUrl";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { optionsPageSizeList } from "../DropdownsData";
import Select from "react-select";
import { loggedInRole, loggedInId } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import { exportExcel } from "../DownloadFile";
import * as swal from "../Consturl/SwalAlert";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import ApprovedClaimByManager from "./ApprovedClaimByManager";
import { Col, Row } from "react-bootstrap";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { travelizeAuth } from "../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import {
  loadManagerDropdown,
  loadUserDropdownByManager,
} from "../../redux/action";

function ClaimsAdmin() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const [pendingforApproval, setPendingforApproval] = useState(false);
  const [approvedClaim, setApprovedClaim] = useState(false);
  const [approvedClaimByManager, setApprovedClaimBymanager] = useState(false);
  const [rejectedClaim, setRejectedClaim] = useState(false);
  const [adminClaimDetails, setAdminClaimDetails] = useState([]);
  const [userList, setUserList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [managerID, setManagerID] = useState({
    value: loggedInId,
    label: "Select Manager",
  });
  const [filterUserId, setFilterUserId] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [claimUserId, setClaimUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);

  let dispatch = useDispatch();

  const { managerDropdown } = useSelector((state) => state.data);
  const { userDropdownByManager } = useSelector((state) => state.data);

  const goBackToClaimfromPendingPage = () => {
    setPendingforApproval(!pendingforApproval);
  };

  const goBackToClaimfromApprovedByManagerPage = () => {
    setApprovedClaimBymanager(!approvedClaimByManager);
  };

  const goBackToClaimfromApprovedPage = () => {
    setApprovedClaim(!approvedClaim);
  };

  const goBackToClaimfromRejectedPage = () => {
    setRejectedClaim(!rejectedClaim);
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  useEffect(() => {
    dispatch(loadManagerDropdown());
    dispatch(loadUserDropdownByManager(managerID.value));
  }, [managerID]);

  useEffect(() => {
    fetchAdminClaimDetails();
  }, [filterUserId, fromDate, toDate, currentPage, PageSize, managerID]);

  // GET MANAGER CLAIM DETAILS START
  const fetchAdminClaimDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/Claim/GetClaim?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&managerId=${
        managerID.value
      }&SearchStatusType=Admin&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAdminClaimDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setAdminClaimDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };
  // GET MANAGER CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManagerID({
      value: loggedInId,
      label: "Select Manager",
    });
    setFromDate(new Date());
    setToDate(new Date());
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/ClaimAmountExcelExport?UserID=${filterUserId}&managerId=${
        managerID.value
      }&FromDate=${moment(fromDate).format("DD/MM/YYYY")}&ToDate=${moment(
        toDate
      ).format("DD/MM/YYYY")}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "ClaimDetail-Admin.xlsx"
    );
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  return (
    <div id="main-page">
      {loggedInRole != 2 ? (
        <>
          {!pendingforApproval &&
          !approvedClaim &&
          !rejectedClaim &&
          !approvedClaimByManager ? (
            <>
              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        {/* <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div> */}
                        <h6 className="page-header-text">CLAIMS (Admin)</h6>
                      </div>
                    </Col>
                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div className="cliam-filter-section">
                            <div style={{ width: "100%" }}>
                              <Row>
                                <Col md={10}>
                                  <div className="clients-filter-input-boxs filter-box-div">
                                    <div
                                      style={{
                                        width: "100%",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={managerDropdown}
                                        placeholder="Select Manager"
                                        value={managerID}
                                        onInputChange={managerDropdown.label}
                                        onChange={(data) => setManagerID(data)}
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={userDropdownByManager}
                                        placeholder="Select User"
                                        value={
                                          filterUserId === ""
                                            ? ""
                                            : userDropdownByManager.value
                                        }
                                        onInputChange={
                                          userDropdownByManager.label
                                        }
                                        onChange={(data) =>
                                          setFilterUserId(data.value)
                                        }
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        padding: "0px 5px",
                                      }}
                                      className="input-group from-to-date-select"
                                    >
                                      <span
                                        className="input-group-text"
                                        id="from-label"
                                      >
                                        From - To
                                      </span>
                                      <span className="date-picker-with-icon">
                                        <DatePicker
                                          selected={fromDate}
                                          className="select from-date date-filter"
                                          id="date-range-picker"
                                          dateFormat="dd/MM/yyyy"
                                          selectsRange
                                          startDate={fromDate}
                                          endDate={toDate}
                                          onChange={(date) =>
                                            setSelectSearchDrop(date)
                                          }
                                          maxDate={new Date()}
                                        />
                                      </span>
                                      <span>
                                        <i
                                          className="fa fa-calendar-o calander-icon"
                                          style={{ right: "15px" }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={2}>
                                  <div className="clients-filter-input-boxs filter-box-div">
                                    <SbCancelBtn
                                      onClickEffect={clearSerachBySelect}
                                      btnName="Clear"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* Filter and Header Section */}

                {/* USER TABLE START */}
                <div className="claims-table">
                  <div
                    className="table-responsive claims-scrollbar"
                    onScroll={(e) =>
                      handleScroll(
                        e,
                        adminClaimDetails,
                        totalData,
                        setLoading,
                        setPageSize,
                        PageSize
                      )
                    }
                  >
                    <table className="table claims-manager-table">
                      <thead className="thead-light claims-manager-thead">
                        <tr>
                          <th>USER NAME</th>
                          <th>APPLIED </th>
                          <th>
                            APPROVED{" "}
                            <div className="approved-claim-manager">
                              Manager
                            </div>
                          </th>
                          <th>
                            PENDING APPROVAL{" "}
                            <div className="pending-claim-admin">Admin</div>
                          </th>
                          <th>
                            APPROVED{" "}
                            <div className="pending-claim-admin">Admin</div>
                          </th>
                          <th>
                            REJECTED{" "}
                            <div className="pending-claim-admin">Admin</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonTableData tdata="6" />
                            <SkeletonTableData tdata="6" />
                            <SkeletonTableData tdata="6" />
                            <SkeletonTableData tdata="6" />
                            <SkeletonTableData tdata="6" />
                            <SkeletonTableData tdata="6" />
                            <SkeletonTableData tdata="6" />
                            <SkeletonTableData tdata="6" />
                            <SkeletonTableData tdata="6" />
                            <SkeletonTableData tdata="6" />
                            <SkeletonTableData tdata="6" />
                          </>
                        ) : adminClaimDetails !== "" ? (
                          adminClaimDetails.map((data, i) => (
                            <tr className="claims-list-row-data" key={i}>
                              <td>
                                <div className="table-row-head-Text">
                                  {data?.fullName}
                                </div>
                                <div className="table-row-sub-text">
                                  {data?.countryCode} {data?.contact}
                                </div>
                              </td>
                              <td>{data?.appliedClaimvalue}</td>
                              <td>
                                {data?.manager?.approvedClaimValue != 0 ? (
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setApprovedClaimBymanager(
                                        !approvedClaimByManager
                                      );
                                      setClaimUserId(data?.userID);
                                    }}
                                  >
                                    {data?.manager?.approvedClaimValue}
                                  </Link>
                                ) : (
                                  <Link to="#" className="claim-value-0">
                                    0
                                  </Link>
                                )}
                              </td>
                              <td>
                                {data?.admin?.pendingClaimValue != 0 ? (
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setPendingforApproval(
                                        !pendingforApproval
                                      );
                                      setClaimUserId(data?.userID);
                                    }}
                                  >
                                    {data?.admin?.pendingClaimValue}
                                  </Link>
                                ) : (
                                  <Link to="#" className="claim-value-0">
                                    0
                                  </Link>
                                )}
                              </td>
                              <td>
                                {data?.admin?.approvedClaimValue != 0 ? (
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setApprovedClaim(!approvedClaim);
                                      setClaimUserId(data?.userID);
                                    }}
                                  >
                                    {data?.admin?.approvedClaimValue}
                                  </Link>
                                ) : (
                                  <Link to="#" className="claim-value-0">
                                    0
                                  </Link>
                                )}
                              </td>
                              <td>
                                {data?.admin?.rejectedClaimValue != 0 ? (
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setRejectedClaim(!rejectedClaim);
                                      setClaimUserId(data?.userID);
                                    }}
                                  >
                                    {data?.admin?.rejectedClaimValue}
                                  </Link>
                                ) : (
                                  <Link to="#" className="claim-value-0">
                                    0
                                  </Link>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          "No Data Found"
                        )}
                        {loading && <OnScrollLoading />}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* USER TABLE END */}

                <div className="download-and-pagination">
                  <SbDownloadBtn onClickEffect={exportExcelClaim} />
                </div>
                {/* PAGE CONTENT END*/}

                {/* MAIN BODY END */}
              </div>
            </>
          ) : (
            <>
              {approvedClaimByManager ? (
                <ApprovedClaimByManager
                  approvedClaimByManager={approvedClaimByManager}
                  goBackToClaimfromApprovedByManagerPage={
                    goBackToClaimfromApprovedByManagerPage
                  }
                  claimUserId={claimUserId}
                  fromDate={fromDate}
                  toDate={toDate}
                />
              ) : (
                ""
              )}

              {pendingforApproval ? (
                <PendingClaimAdmin
                  pendingforApproval={pendingforApproval}
                  goBackToClaimfromPendingPage={goBackToClaimfromPendingPage}
                  fromDate={fromDate}
                  toDate={toDate}
                  claimUserId={claimUserId}
                  fetchAdminClaimDetails={fetchAdminClaimDetails}
                />
              ) : (
                ""
              )}

              {approvedClaim ? (
                <ApprovedClaimAdmin
                  approvedClaim={approvedClaim}
                  goBackToClaimfromApprovedPage={goBackToClaimfromApprovedPage}
                  claimUserId={claimUserId}
                  fromDate={fromDate}
                  toDate={toDate}
                />
              ) : (
                ""
              )}

              {rejectedClaim ? (
                <RejectedClaimAdmin
                  rejectedClaim={rejectedClaim}
                  goBackToClaimfromRejectedPage={goBackToClaimfromRejectedPage}
                  claimUserId={claimUserId}
                  fromDate={fromDate}
                  toDate={toDate}
                />
              ) : (
                ""
              )}
            </>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default ClaimsAdmin;
