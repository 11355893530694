import React, { useState } from "react";
import Footer from "../Footer";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";

function JointMeetingDetails() {
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [menuCollapse, setMenuCollapse] = useState(false);
  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation
  const [openDetailModal, setOpenDetailModal] = useState(false);

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={5} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">Joint Meetings Details</h6>
                  </div>
                </Col>
                <Col md={7} className="cat-col"></Col>
              </Row>
              <div className="mi-jm-details-page">
                <div className="jm-user-details-div">
                  <div className="jm-user-detail">
                    <p>
                      Name: <span className="name-text">Samsone</span>
                    </p>
                    <p>
                      Phone: <span className="phone-text">9458748755</span>
                    </p>
                    <p>Email: samsone@gmail.com</p>
                    <p>Timing: 10:30 PM</p>
                    <p>Meeting Purpose: Formal Visit</p>
                  </div>
                  <div className="jm-user-detail">
                    <h4 className="jm-header">Travel Accompany</h4>
                    <div className="jm-members">
                      <div
                        className="img-div"
                        onClick={() => setOpenDetailModal(!openDetailModal)}
                      >
                        <img src="../../../images/Admin1.png" alt="" />
                        <div>Mr Dummy</div>
                      </div>
                      <div
                        className="img-div"
                        onClick={() => setOpenDetailModal(!openDetailModal)}
                      >
                        <img src="../../../images/Admin1.png" alt="" />
                        <div>Mr Dummy</div>
                      </div>
                      <div
                        className="img-div"
                        onClick={() => setOpenDetailModal(!openDetailModal)}
                      >
                        <img src="../../../images/Admin1.png" alt="" />
                        <div>Mr Dummy</div>
                      </div>
                      {openDetailModal && (
                        <div className="member-details">
                          <div className="jm-member-icon-div">
                            <img src="../../../images/Admin1.png" alt="" />
                            <div>Mr Dummy</div>
                            <div
                              className="close-modal"
                              onClick={() =>
                                setOpenDetailModal(!openDetailModal)
                              }
                            >
                              X
                            </div>
                          </div>
                          <div className="other-detail">
                            <p>
                              <i className="fa fa-phone"></i> +91 98475474857
                            </p>
                            <p>
                              <i className="fa fa-envelope"></i> dummy@gmail.com
                            </p>
                            <p>
                              <i className="fa fa-map-marker"></i> JR5Q+MF4,
                              Sirsi, Karnataka 581402, India
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="jm-location-div">
                  <h4 className="jm-header">Location</h4>
                  <div className="jm-location">
                    <div className="location-card">
                      <h4 className="location-header">Your Location</h4>
                      <p>JR5Q+MF4, Sirsi, Karnataka 581402, India</p>
                    </div>
                    <div className="icon-section">
                      <h4 className="location-header">9.0 km</h4>
                      <p className="right-arrow">={`>`}</p>
                    </div>
                    <div className="location-card">
                      <h4 className="location-header">
                        Client Address for meeting
                      </h4>
                      <p>JR5Q+MF4, Sirsi, Karnataka 581402, India</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default JointMeetingDetails;
