import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/Leavetype.scss";
import { baseURL } from "../../BaseUrl";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddLeaveType(props) {
  const [leaveTypeName, setLeaveTypeName] = useState("");
  const [shortName, setShortName] = useState("");
  const [description, setDescription] = useState("");
  const [maxNoOfdays, setMaxNoOfdays] = useState(null);
  const [halfDay, setHalfDay] = useState(true);
  const [isPaidLeave, setIsPaidLeave] = useState(true);
  const [isCancellable, setIsCancellable] = useState(true);
  const [autoApprove, setAutoApprove] = useState(false);
  const [notifyReporting, setNotifyReporting] = useState(true);
  const [notifyUser, setNotifyUser] = useState(true);
  const [notifySubOrdinate, setNotifySubOrdinate] = useState(false);
  const [excludeWeekEnd, setExcludeWeekEnd] = useState(true);
  const [excludeHolidays, setExcludeHolidays] = useState(true);
  const [allowUpdateAttachment, setAllowUpdateAttachment] = useState(true);
  const [allowCarryforword, setAllowCarryforword] = useState(false);
  const [allowPrevDaysLeave, setAllowPrevDaysLeave] = useState(true);
  const [reqBeforeDays, setReqBeforeDays] = useState(0);
  const [attachmentReqForDays, setAttachmentReqForDays] = useState(2);
  const [maxCarryforword, setMaxCarryforword] = useState(5);

  const [enableStatus, setEnableStatus] = useState(1);
  const [openMoreSettings, setOpenMoreSettings] = useState(false);

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const subscriptionID = JSON.parse(
    sessionStorage.getItem("usrlgndtls")
  ).subscriptionId;

  useEffect(() => {
    setOpenMoreSettings(false);
  }, []);

  //  ADDING LEAVE TYPE START
  const addLeaveTypes = () => {
    if (leaveTypeName !== "" && shortName !== "" && maxNoOfdays !== null) {
      const leaveTypeData = {
        subscriptionID: subscriptionID,
        leaveTypeName: leaveTypeName,
        leaveTypeSortName: shortName,
        description: description,
        maxNoOfDays: maxNoOfdays,
        allowHalfDay: halfDay,
        status: enableStatus,
        isPaidLeave: isPaidLeave,
        isCancellable: isCancellable,
        autoApprove: autoApprove,
        notifyReporting: notifyReporting,
        notifyUser: notifyUser,
        notifySubOrdinate: notifySubOrdinate,
        excludeWeekEnd: excludeWeekEnd,
        excludeHolidays: excludeHolidays,
        allowUpdateAttachment: allowUpdateAttachment,
        allowCarryforword: allowCarryforword,
        allowPrevDaysLeave: allowPrevDaysLeave,
        reqBeforeDays: reqBeforeDays,
        attachmentReqForDays: attachmentReqForDays,
        maxCarryforword: maxCarryforword,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(leaveTypeData),
      };

      fetch(`${baseURL}/api/LeaveType/AddLeaveType`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          // console.log("ADD-MEET-PURPOSE::", res);
          if (res.success) {
            props.handleClose();
            props.fetchLeaveTypesData();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            // props.handleClose();
            // swal.showAlert("Fail", `${res.errorMessage}`, "warning");
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          // console.log("ERROR :", error);
          // swal.showAlert("Fail", `${error}`, "warning");
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };
  //  ADDING LEAVE TYPE END

  return (
    <div>
      <Modal
        show={props.show}
        // onHide={props.handleClose}
        className="main_model add-leavetype-model"
      >
        <div className="add-leavetype-heading">
          <h6 className="add-edit-head">ADD LEAVE TYPE</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-leavetype">
            <div className="add-edit-leavetypes-details">
              <div className="row mb-3 p-0">
                <div className="col-md-8 col-sm-8 pl-0">
                  <FloatingLabel
                    label="Leave Type Name"
                    className={`${leaveTypeName
                      ? "float-input-visible"
                      : "float-hidden float-input"
                      }`}
                  >
                    <input
                      type="text"
                      className="form-control form add-leavetype-input"
                      placeholder="Leave Type Name"
                      // value={leaveTypeName}
                      onChange={(e) => setLeaveTypeName(e.target.value)}
                    />
                  </FloatingLabel>
                  {fieldReq && leaveTypeName == "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4 col-sm-4 pr-0">
                  <FloatingLabel
                    label="Short Name"
                    className={`${shortName
                      ? "float-input-visible"
                      : "float-hidden float-input"
                      }`}
                  >
                    <input
                      type="text"
                      className="form-control form add-leavetype-input"
                      placeholder="Short Name"
                      // value={shortName}
                      onChange={(e) => setShortName(e.target.value)}
                    />
                  </FloatingLabel>
                  {fieldReq && shortName == "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mb-4">
                <FloatingLabel
                  label="Description"
                  className={`${description
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}
                >
                  <textarea
                    name="usrtxt"
                    wrap="hard"
                    rows="2"
                    cols="20"
                    className="form-control form-textarea add-leavetype-input"
                    placeholder="Description"
                    // value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FloatingLabel>
              </div>
              <div className="row mb-3 p-0">
                <div className="col-md-7 col-sm-12 pl-0">
                  <FloatingLabel
                    label="Max No. Of Days"
                    className={`${maxNoOfdays
                      ? "float-input-visible"
                      : "float-hidden float-input"
                      }`}
                  >
                    <input
                      type="number"
                      className="form-control form add-leavetype-input"
                      placeholder="Max No. Of Days"
                      // value={maxNoOfdays}
                      onChange={(e) => setMaxNoOfdays(+e.target.value)}
                    />
                  </FloatingLabel>
                  {fieldReq && (maxNoOfdays == null || maxNoOfdays == 0) ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-5 col-sm-12 pr-0">
                  <div className="set-true-false">
                    <div className="color-gray">Allow Half Day Leave</div>

                    <div className="enable-radio-yes-no">
                      <div className="form-check enable-radio-status yes">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="halfDay"
                          checked={halfDay == true ? true : false}
                          onChange={() => setHalfDay(true)}
                        />
                        <label className="form-check-label" htmlFor="halfDay">
                          Yes
                        </label>
                      </div>
                      <div className="form-check enable-radio-status no">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="halfDay1"
                          checked={halfDay == false ? true : false}
                          onChange={() => setHalfDay(false)}
                        />
                        <label className="form-check-label" htmlFor="halfDay1">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row mb-3">
                {" "}
                <div className="half-day-aprrove">
                  <div className="color-green">Leave Type Status Enable</div>

                  <div className="enable-radio-yes-no">
                    <div className="form-check enable-radio-status yes">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="enableStatus"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="enableStatus"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status no">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="enableStatus1"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="enableStatus1"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div
                  className="open-leave-type-2nd-part"
                  onClick={() => setOpenMoreSettings(!openMoreSettings)}
                >
                  {/* <i className="fa fa-chevron-down" /> */}
                  <div className="row-line">
                    ------------------------------------------------- &nbsp;
                    More Settings
                  </div>
                  &nbsp; &nbsp;
                  <i
                    className={
                      openMoreSettings
                        ? "fa fa-chevron-down"
                        : "fa fa-chevron-right"
                    }
                  />
                </div>
              </div>
              {openMoreSettings && (
                <div className="more-settings-part">
                  <div className="row mb-4 p-0">
                    <div className="col-md-4 col-sm-4 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Is Paid Leave</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isPaidLeave"
                              checked={isPaidLeave == true ? true : false}
                              onChange={() => setIsPaidLeave(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isPaidLeave"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isPaidLeave1"
                              checked={isPaidLeave == false ? true : false}
                              onChange={() => setIsPaidLeave(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isPaidLeave1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Is Cancellable</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isCancellable"
                              checked={isCancellable == true ? true : false}
                              onChange={() => setIsCancellable(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isCancellable"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isCancellable1"
                              checked={isCancellable == false ? true : false}
                              onChange={() => setIsCancellable(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isCancellable1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 pr-0">
                      <div className="set-true-false">
                        <div className="color-gray">Auto Approve</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="autoApprove"
                              checked={autoApprove == true ? true : false}
                              onChange={() => setAutoApprove(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="autoApprove"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="autoApprove1"
                              checked={autoApprove == false ? true : false}
                              onChange={() => setAutoApprove(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="autoApprove1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4 p-0">
                    <div className="col-md-4 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Notify Reporting</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="notifyReporting"
                              checked={notifyReporting == true ? true : false}
                              onChange={() => setNotifyReporting(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notifyReporting"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="notifyReporting1"
                              checked={notifyReporting == false ? true : false}
                              onChange={() => setNotifyReporting(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notifyReporting1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Notify User</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="notifyUser"
                              checked={notifyUser == true ? true : false}
                              onChange={() => setNotifyUser(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notifyUser"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="notifyUser1"
                              checked={notifyUser == false ? true : false}
                              onChange={() => setNotifyUser(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notifyUser1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0 ">
                      <div className="set-true-false">
                        <div className="color-gray">Notify SubOrdinate</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="notifySubOrdinate"
                              checked={notifySubOrdinate == true ? true : false}
                              onChange={() => setNotifySubOrdinate(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notifySubOrdinate"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="notifySubOrdinate1"
                              checked={
                                notifySubOrdinate == false ? true : false
                              }
                              onChange={() => setNotifySubOrdinate(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notifySubOrdinate1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4 p-0">
                    <div className="col-md-4 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Exclude WeekEnd</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="excludeWeekEnd"
                              checked={excludeWeekEnd == true ? true : false}
                              onChange={() => setExcludeWeekEnd(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="excludeWeekEnd"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="excludeWeekEnd1"
                              checked={excludeWeekEnd == false ? true : false}
                              onChange={() => setExcludeWeekEnd(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="excludeWeekEnd1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Exclude Holidays</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="excludeHolidays"
                              checked={excludeHolidays == true ? true : false}
                              onChange={() => setExcludeHolidays(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="excludeHolidays"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="excludeHolidays1"
                              checked={excludeHolidays == false ? true : false}
                              onChange={() => setExcludeHolidays(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="excludeHolidays1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0">
                      <div className="set-true-false">
                        <div className="color-gray">Allow Attachment</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="allowUpdateAttachment"
                              checked={
                                allowUpdateAttachment == true ? true : false
                              }
                              onChange={() => setAllowUpdateAttachment(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allowUpdateAttachment"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="allowUpdateAttachment1"
                              checked={
                                allowUpdateAttachment == false ? true : false
                              }
                              onChange={() => setAllowUpdateAttachment(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allowUpdateAttachment1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4 p-0">
                    <div className="col-md-4 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Allow CarryForword</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="allowCarryforword"
                              checked={allowCarryforword == true ? true : false}
                              onChange={() => setAllowCarryforword(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allowCarryforword"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="allowCarryforword1"
                              checked={
                                allowCarryforword == false ? true : false
                              }
                              onChange={() => setAllowCarryforword(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allowCarryforword1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Allow PrevDaysLeave</div>

                        <div className="enable-radio-yes-no">
                          <div className="form-check enable-radio-status yes">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="allowPrevDaysLeave"
                              checked={
                                allowPrevDaysLeave == true ? true : false
                              }
                              onChange={() => setAllowPrevDaysLeave(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allowPrevDaysLeave"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check enable-radio-status no">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="allowPrevDaysLeave1"
                              checked={
                                allowPrevDaysLeave == false ? true : false
                              }
                              onChange={() => setAllowPrevDaysLeave(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allowPrevDaysLeave1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0">
                      <FloatingLabel
                        label="Req. Before Days"
                        className={`${reqBeforeDays
                          ? "float-input-visible"
                          : "float-hidden float-input"
                          }`}
                      >
                        <input
                          type="number"
                          className="form-control form add-leavetype-input"
                          placeholder="Req. Before Days"
                          // value={reqBeforeDays}
                          onChange={(e) => setReqBeforeDays(+e.target.value)}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row mb-2 p-0">
                    <div className="col-md-6 pl-0 ">
                      <FloatingLabel
                        label="Attachment Req. Days"
                        className={`${attachmentReqForDays
                          ? "float-input-visible"
                          : "float-hidden float-input"
                          }`}
                      >
                        <input
                          type="number"
                          className="form-control form add-leavetype-input"
                          placeholder="Attachment Req. Days"
                          value={attachmentReqForDays}
                          onChange={(e) =>
                            setAttachmentReqForDays(+e.target.value)
                          }
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-md-6 pr-0">
                      <FloatingLabel
                        label="Max CarryForword"
                        className={`${maxCarryforword
                          ? "float-input-visible"
                          : "float-hidden float-input"
                          }`}
                      >
                        <input
                          type="number"
                          className="form-control form add-leavetype-input"
                          placeholder="Max CarryForword"
                          value={maxCarryforword}
                          onChange={(e) => setMaxCarryforword(+e.target.value)}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={addLeaveTypes} btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}

export default AddLeaveType;
