import React from 'react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

const EmojiPicker = ({ onEmojiClick }) => {
    return (
        <div style={{ position: 'absolute', bottom: '50px', right: '20px', zIndex: '9999' }}>
            <Picker onSelect={onEmojiClick} />
        </div>
    );
}

export default EmojiPicker;
