import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/UserHierarchy.scss";
import SbAddBtn, { SBSaveUpdateBtn, SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import AccessDenied from "../../../AccessDenied";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";





const clientOptions = [
  { value: '1', label: 'Siva Balan P' },
  { value: '2', label: 'Dinesh L' },
  // ...other options
];

const reportedByOptions = [
  { value: '1', label: 'Siva Balan P' },
  { value: '2', label: 'Shrikant Rajendra Satav' },
  { value: '3', label: 'Valera Kalpen Krishnakant' },
  { value: '4', label: 'Somanagouda Patil' },
  { value: '5', label: 'Naresh P' },
  { value: '6', label: 'Srikanth S' },
  { value: '7', label: 'Vinayak Gudekar' },

];

const assignToOptions = [
  { value: 'user4', label: 'User 4' },
  { value: 'user5', label: 'User 5' },
  { value: 'user6', label: 'User 6' }
];

const productOptions = [
  { value: 'product1', label: 'Product 1' },
  { value: 'product2', label: 'Product 2' },
  { value: 'product3', label: 'Product 3' }
];

const warrantyOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' }
];

const priorityOptions = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' }
];

const exportExcelClaim = () => {

};



// const defaultUser = clientOptions.find(option => option.label === 'Siva Balan P');
function UserHierarchy() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [actionUser, setActionUser] = useState(null);
  const [selectedVerifier, setSelectedVerifier] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("manager");
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [filterMeeting, setFilterMeeting] = useState();
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [myshowSaveButton, setmyShowSaveButton] = useState(true);
  const [myinnershowSaveButton, setmyinnerShowSaveButton] = useState(true);
  const [refresh, setRefresh] = useState(false);


  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const twoLevelClaim = loginDetails?.subscription?.twoLevelClaim;
  const loggedInRole = loginDetails?.desingation?.role?.roleID;
  const [filter, setFilter] = useState(false);
  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");

  };

  const [title, setTitle] = useState();
  const [title2, setTitle2] = useState();
  const [title3, setTitle3] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
  };
  const handleShow = () => {
    setTitle("ADD ATTENDANCE MOVEMENT USER's HIERARCHY");
    setTitle("ADD EXPENSES APPROVAL USER's HIERARCHY");
    setTitle3("ADD TRAVEL DESK USER's HIERARCHY");
    setShow(true);
    setShowElements(true);
    setShowSaveButton(true);
    setmyShowSaveButton(false);
    setmyinnerShowSaveButton(true);
    setDisableSelectOnChange(false);
    setDisablemySelectOnChange(false);
    setRefresh((prev) => !prev);

  };

  const handlemyShow = (name) => {
    setTitle("VIEW ATTENDANCE MOVEMENT USER's HIERARCHY");
    setTitle2("VIEW EXPENSES APPROVAL USER's HIERARCHY");
    setTitle3("VIEW TRAVEL DESK USER's HIERARCHY");
    setSelectedUser({ value: name, label: name });
    setShow(true); // Close any modal or detailed view if open
    setShowElements(false);
    setShowSaveButton(false);
    setDisableSelectOnChange(true);
    setDisablemySelectOnChange(true);
    setRefresh((prev) => !prev);
    setmyShowSaveButton(true);
    setmyinnerShowSaveButton(false);


    // Example: You could perform additional actions related to viewing details
    console.log('View clicked for:', name);
  };
  // const handleEditShow = (name) => {
  //   setSelectedUser({ value: name, label: name });
  //   setShow(true); // Close any modal or detailed view if open
  //   setShowElements(true);
  //   setShowSaveButton(true);


  //   // Example: You could navigate to an edit form or page
  //   console.log('Edit clicked for:', name);
  // };
  const handleChange = (selectedOption) => {
    if (!disableSelectOnChange) {
      setSelectedUser(selectedOption);

    }
  };
  const handlemyChange = (selectedOption) => {
    if (!disablemySelectOnChange) {
      setSelectedUser(selectedOption);

    }
  };
  const [disableSelectOnChange, setDisableSelectOnChange] = useState(false);
  const [disablemySelectOnChange, setDisablemySelectOnChange] = useState(false);

  const handleEditShow = (name) => {
    setTitle("EDIT ATTENDANCE MOVEMENT USER's HIERARCHY");
    setTitle2("EDIT EXPENSES APPROVAL USER's HIERARCHY");
    setTitle3("EDIT TRAVEL DESK USER's HIERARCHY");
    setSelectedUser({ value: name, label: name });
    setShow(true); // Close any modal or detailed view if open
    setShowElements(true);
    setShowSaveButton(true);
    setmyShowSaveButton(false);
    setmyinnerShowSaveButton(true);
    setDisableSelectOnChange(true); // Disable onChange temporarily
    setDisablemySelectOnChange(false);
    setRefresh((prev) => !prev);

    // Example: You could navigate to an edit form or page
    console.log('Edit clicked for:', name);
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setRefresh((prev) => !prev);
  };
  const countOptions = [
    { value: "all", label: "All" },
    { value: "hasmeetings", label: "Has Meeting" },
    { value: "nomeetings", label: "No Meeting" },
  ];
  const { managerDropdown } = useSelector((state) => state.data);
  const [manager, setManager] = useState("");
  const { userDropdown } = useSelector((state) => state.data);
  const [filterUserId, setFilterUserId] = useState("");
  const [selectedOption, setSelectedOption] = useState('');
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [showElements, setShowElements] = useState(true);


  const handleDelete = (index) => {
    const updatedTableData = tableData.filter((_, i) => i !== index);
    setTableData(updatedTableData);
    if (updatedTableData.length === 0) {
      setIsTableVisible(false); // Hide the table if there are no rows left
    }
  };

  // const handleDelete = (index) => {
  //   const newData = [...tableData];
  //   newData.splice(index, 1);
  //   setTableData(newData);
  // };

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const handleVerifierChange = (selectedOption) => {
    setSelectedVerifier(selectedOption);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const adhandleShow = () => {
    // Your logic to add table data or show modal
    console.log("Adding table data or showing modal");
  };


  const handleAddButtonClick = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (selectedOption && ((selectedOption === 'verifier' && selectedVerifier) || (selectedOption === 'manager' && selectedUser))) {

      let newData = {}

      if (selectedOption === 'verifier' && selectedVerifier) {
        newData = {
          name: selectedVerifier.label,
          type: selectedOption,
          priority: tableData.length + 1 // Set the priority as the next number in the sequence
        };
      } else if (selectedOption === 'manager' && selectedUser) {

        newData = {
          name: selectedVerifier.label,
          type: selectedOption,
          priority: tableData.length + 1 // Set the priority as the next number in the sequence
        };
      }
      setTableData([...tableData, newData]);

      setIsTableVisible(true); // Show the table when a new row is added

      // Reset selections
      setSelectedUser(null);
      setSelectedVerifier(null);
      setSelectedOption('');

      // Trigger onClickEffect
      // Call your effect function here
    } else {
      // alert('Please select a user before proceeding.');
    }
  };

  console.log(tableData, "tableData")

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          <div className="page-wrapper">
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content usrhrchy">
                <Row className="search-row usrhrchy">
                  <Col md={3} className="cat-col">
                    <div className="page-header-text-div">
                      <div className="back-btn" onClick={goToBackPage}>
                        <img
                          src="../../../../images/DefaultImages/back-btn-arrow.svg"
                          alt=""
                        />
                      </div>
                      <h6 className="page-header-text">USER HIERARCHY</h6>
                    </div>
                  </Col>
                  <Col md={6} className="cat-col">
                    <Tabs
                      id="uncontrolled-tab-example"
                      activeKey={activeTab}
                      onSelect={(tab) => handleTabChange(tab)}
                      className="local-claim-nav-tab usrhrchy"
                    >
                      <Tab eventKey="manager" title="Attendance Movement"></Tab>
                      <Tab eventKey="expenses" title="Expenses Approval"></Tab>
                      <Tab eventKey="traveldesk" title="Traveldesk Approval"></Tab>
                    </Tabs>
                  </Col>
                  <Col md={3} className="cat-col">
                    <div
                      className="search-bar-header"
                      style={{ float: "right" }}
                    >
                      <div className="page-top-bar-dropdowns page-top-Filters">
                        <div
                          className="header-filter-btn"
                          onClick={() => setFilter(!filter)}
                        >
                          <p className="card-head">
                            <i className="bi bi-sliders" />
                            &nbsp;&nbsp;Filters
                          </p>
                        </div>
                        <SbAddBtn
                          onClickEffect={handleShow}
                        />
                      </div>
                    </div>

                  </Col>
                </Row>

                {filter && (
                  <div className="page-filter-section">
                    <div style={{ width: "100%" }}>
                      <Row className="filters-row">
                        <Col md={3}>
                          <div className="clients-filter-input-boxs filter-box-div">

                            <div
                              style={{
                                width: "100%",
                                padding: "0px 5px",
                              }}
                            >
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={userDropdown}
                                placeholder="Select User"
                                value={
                                  filterUserId === ""
                                    ? ""
                                    : userDropdown.value
                                }
                                onInputChange={userDropdown.label}
                                onChange={(data) =>
                                  setFilterUserId(data.value)
                                }
                                isSearchable={true}
                              />
                            </div>

                          </div>
                        </Col>
                        <Col md={9}>
                          <div className="clients-filter-input-boxs filter-box-div usrhrchy">
                            <SbCancelBtn
                              onClickEffect={clearSerachBySelect}
                              btnName="Clear"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}


                <div className="usrhrchy table-responsive spares-scrollbar">
                  <table className="table spares-table-data">
                    {activeTab === "manager" && (
                      <>
                        <thead className="thead-light spares-thead ">
                          <tr>
                            <th>User Name</th>
                            <th className="usrhrchy-tbcnt">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[
                            'Siva Balan P', 'Shrikant Rajendra Satav', 'Valera Kalpen Krishnakant', 'Somanagouda Patil', 'Naresh P',
                            'Srikanth S', 'Vinayak Gudekar'
                          ].map((name, index) => (
                            <tr key={index} className="spare-table-row-data">
                              <td>{name}</td>

                              <td className="myhrchy-dta">
                                <button className="btn spare-edit-link" onClick={() => handleEditShow(name)}>
                                  <i className="fa fa-edit"></i> Edit
                                </button>
                                <button className="btn spare-edit-link" onClick={() => handlemyShow(name)}>
                                  <i className="fa fa-eye"></i> View
                                </button>
                                <button className="btn spare-edit-link">
                                  <i className="fa fa-trash"></i> Delete
                                </button>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    )}

                    {activeTab === "expenses" && (
                      <>
                        <thead className="thead-light spares-thead">
                          <tr>
                            <th>User Name</th>
                            <th className="usrhrchy-tbcnt">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[
                            'Siva Balan P', 'Shrikant Rajendra Satav', 'Valera Kalpen Krishnakant', 'Somanagouda Patil', 'Naresh P',
                            'Srikanth S', 'Vinayak Gudekar'
                          ].map((name, index) => (
                            <tr key={index} className="spare-table-row-data">
                              <td>{name}</td>

                              <td className="myhrchy-dta">
                                <button className="btn spare-edit-link" onClick={() => handleEditShow(name)}>
                                  <i className="fa fa-edit"></i> Edit
                                </button>
                                <button className="btn spare-edit-link" onClick={() => handlemyShow(name)}>
                                  <i className="fa fa-eye"></i> View
                                </button>
                                <button className="btn spare-edit-link">
                                  <i className="fa fa-trash"></i> Delete
                                </button>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    )}

                    {activeTab === "traveldesk" && (
                      <>
                        <thead className="thead-light spares-thead">
                          <tr>
                            <th>User Name</th>
                            <th className="usrhrchy-tbcnt">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[
                            'Siva Balan P', 'Shrikant Rajendra Satav', 'Valera Kalpen Krishnakant', 'Somanagouda Patil', 'Naresh P',
                            'Srikanth S', 'Vinayak Gudekar'
                          ].map((name, index) => (
                            <tr key={index} className="spare-table-row-data">
                              <td>{name}</td>

                              <td className="myhrchy-dta">
                                <button className="btn spare-edit-link" onClick={() => handleEditShow(name)}>
                                  <i className="fa fa-edit"></i> Edit
                                </button>
                                <button className="btn spare-edit-link" onClick={() => handlemyShow(name)}>
                                  <i className="fa fa-eye"></i> View
                                </button>
                                <button className="btn spare-edit-link">
                                  <i className="fa fa-trash"></i> Delete
                                </button>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    )}
                  </table>

                  <div className="download-and-pagination">
                    <SbDownloadBtn onClickEffect={exportExcelClaim} />
                  </div>

                </div>

              </div>

            </div>

          </div>
          <Footer />

          <Modal
            show={show}
            onHide={handleClose}
            className={`main_model add-edit-model amc-model hrschy ${activeTab === 'traveldesk' ? 'traveldesk-width' : ''} ${activeTab === 'manager' ? 'attendance-movement' : ''}`}

          >
            <div className="add-edit-heading">
              {activeTab === 'manager' && <h6 className="add-edit-head">{title}</h6>}
              {activeTab === 'expenses' && <h6 className="add-edit-head">{title2}</h6>}
              {activeTab === 'traveldesk' && <h6 className="add-edit-head">{title3}</h6>}
              <i className="fa fa-times" onClick={handleClose} />
            </div>
            <form>
              <div className="container add-edit">
                <div className="add-inputs usr-hrchy">
                  {activeTab === "manager" && (
                    <>
                      <Row>
                        <Col md={12} sm={12}>

                          <div className="slctusr mb-3">

                            {/* <FloatingLabel label={selectedUser ? selectedUser.label : "Select User"} className="usrhrchy-lbl float-hidden float-select">
                         
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              options={clientOptions}
                              value={selectedUser}
                              onChange={(selectedOption) => setSelectedUser(selectedOption)}
                              placeholder="Select User"
                            />
                          
                          </FloatingLabel> */}

                            <FloatingLabel label={selectedUser ? selectedUser.label : "Select User"} className="usrhrchy-lbl float-hidden float-select">
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={clientOptions}
                                value={selectedUser}
                                onChange={handleChange}
                                placeholder="Select User"
                                isDisabled={disableSelectOnChange} // Disable the whole select if needed
                                styles={{
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    display: disableSelectOnChange ? 'none' : 'block', // Conditionally hide dropdown indicator
                                  }),
                                }}
                              />
                            </FloatingLabel>

                          </div>
                        </Col>
                        {showElements && (  // Conditional rendering based on showElements state
                          <Col md={12} sm={12}>
                            <div className="slctusr mb-3">
                              <FloatingLabel label="Select Verifier / Manager" className="float-hidden float-select">
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={reportedByOptions}
                                  value={selectedVerifier}
                                  onChange={(selectedOption) => setSelectedVerifier(selectedOption)}
                                  placeholder="Select Verifier / Manager"
                                />
                              </FloatingLabel>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md={12} sm={12}>
                          {showElements && (  // Conditional rendering based on showElements state
                            <div className="usrhrchy-rdbtns">
                              <div>
                                <Form.Check
                                  inline
                                  className="travelBy-radio-btn"
                                  name="travelingBy"
                                  type="radio"
                                  label="Verifier"
                                  value="verifier"
                                  checked={selectedOption === 'verifier'}
                                  onChange={(e) => setSelectedOption(e.target.value)}
                                />
                                <Form.Check
                                  inline
                                  className="travelBy-radio-btn"
                                  name="travelingBy"
                                  type="radio"
                                  label="Manager"
                                  value="manager"
                                  checked={selectedOption === 'manager'}
                                  onChange={(e) => setSelectedOption(e.target.value)}
                                />
                              </div>

                              {/* <SbAddBtn className="myadbtn" onClickEffect={handleAddButtonClick}/> */}

                              <button className="myadbtn" onClick={handleAddButtonClick}><i className="fa fa-plus-circle"></i></button>
                            </div>
                          )}
                        </Col>
                      </Row>


                      <table className="thead-light spares-thead mytbusrhrchy mt-3">
                        <thead className="my-tbhd">
                          <tr>
                            <th>Name</th>
                            {/* <th>Priority</th> */}
                            <th>Type</th>
                            {showElements && (
                              <th>Delete</th>
                            )}
                          </tr>
                        </thead>
                        {/* <tbody>
                            {tableData.map((row, index) => (
                              <tr key={index}>
                                <td>{row.name}</td>
                                <td>{row.priority}</td>
                                
                                <td>{row.type.charAt(0).toUpperCase() + row.type.slice(1) + '1'}</td>
                               
                                
                                <td>
                                  <span className="material-symbols-outlined mybtnclos" onClick={() => handleDelete(index)}>
                                    cancel
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody> */}

                        <tbody>
                          {[
                            { name: 'R Subash', type: 'Verifier1' },
                            { name: 'Dinesh L', type: 'Manager1' },
                            { name: 'Kishore Kumar M.V.', type: 'Manager2' },
                            { name: 'P. S. Vidyashankar', type: 'Manager3' },
                            { name: 'Vinayak Gudekar', type: 'Manager4' },
                          ].map((row, index) => (
                            <tr key={index}>
                              <td onClick={() => handlemyShow(row.name)} style={{ cursor: 'pointer' }}>
                                {row.name}
                              </td>
                              <td>{row.type}</td>
                              <td>
                                {showElements && (
                                  <span className="material-symbols-outlined mybtnclos" onClick={() => handleDelete(index)}>
                                    Cancel
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </>
                  )}

                  {activeTab === "expenses" && (
                    <>


                      <Row>
                        <Col md={12} sm={12}>

                          <div className="slctusr mb-3">

                            {/* <FloatingLabel label={selectedUser ? selectedUser.label : "Select User"} className="usrhrchy-lbl float-hidden float-select">
                         
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              options={clientOptions}
                              value={selectedUser}
                              onChange={(selectedOption) => setSelectedUser(selectedOption)}
                              placeholder="Select User"
                            />
                          
                          </FloatingLabel> */}

                            <FloatingLabel label={selectedUser ? selectedUser.label : "Select User"} className="usrhrchy-lbl float-hidden float-select">
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={clientOptions}
                                value={selectedUser}
                                onChange={handleChange}
                                placeholder="Select User"
                                isDisabled={disableSelectOnChange} // Disable the whole select if needed
                                styles={{
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    display: disableSelectOnChange ? 'none' : 'block', // Conditionally hide dropdown indicator
                                  }),
                                }}
                              />
                            </FloatingLabel>

                          </div>
                        </Col>
                        {showElements && (  // Conditional rendering based on showElements state
                          <Col md={12} sm={12}>
                            <div className="slctusr mb-3">
                              <FloatingLabel label="Select Verifier / Manager" className="float-hidden float-select">
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={reportedByOptions}
                                  value={selectedVerifier}
                                  onChange={(selectedOption) => setSelectedVerifier(selectedOption)}
                                  placeholder="Select Verifier / Manager"
                                />
                              </FloatingLabel>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md={12} sm={12}>
                          {showElements && (  // Conditional rendering based on showElements state
                            <div className="usrhrchy-rdbtns">
                              <div>
                                <Form.Check
                                  inline
                                  className="travelBy-radio-btn"
                                  name="travelingBy"
                                  type="radio"
                                  label="Verifier"
                                  value="verifier"
                                  checked={selectedOption === 'verifier'}
                                  onChange={(e) => setSelectedOption(e.target.value)}
                                />
                                <Form.Check
                                  inline
                                  className="travelBy-radio-btn"
                                  name="travelingBy"
                                  type="radio"
                                  label="Manager"
                                  value="manager"
                                  checked={selectedOption === 'manager'}
                                  onChange={(e) => setSelectedOption(e.target.value)}
                                />
                              </div>

                              {/* <SbAddBtn className="myadbtn" onClickEffect={handleAddButtonClick}/> */}

                              <button className="myadbtn" onClick={handleAddButtonClick}><i className="fa fa-plus-circle"></i></button>
                            </div>
                          )}
                        </Col>
                      </Row>


                      <table className="thead-light spares-thead mytbusrhrchy mt-3">
                        <thead className="my-tbhd">
                          <tr>
                            <th>Name</th>
                            {/* <th>Priority</th> */}
                            <th>Type</th>
                            {showElements && (
                              <th>Delete</th>
                            )}
                          </tr>
                        </thead>


                        <tbody>
                          {[
                            { name: 'R Subash', type: 'Verifier1' },
                            { name: 'Dinesh L', type: 'Manager1' },
                            { name: 'Kishore Kumar M.V.', type: 'Manager2' },
                            { name: 'P. S. Vidyashankar', type: 'Manager3' },
                            { name: 'Vinayak Gudekar', type: 'Manager4' },
                          ].map((row, index) => (
                            <tr key={index}>
                              <td onClick={() => handlemyShow(row.name)} style={{ cursor: 'pointer' }}>
                                {row.name}
                              </td>
                              <td>{row.type}</td>
                              <td>
                                {showElements && (
                                  <span className="material-symbols-outlined mybtnclos" onClick={() => handleDelete(index)}>
                                    Cancel
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <Row>
                        <Col md={12} sm={12}>
                          {/* <div className="slctusr mb-3 mt-3">
                            <FloatingLabel label="Specific Item" className="usrhrchy-lbl float-hidden float-select">
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={clientOptions}
                                value={selectedUser}
                                onChange={handlemyChange}
                                placeholder="Specific Item"
                                isDisabled={disablemySelectOnChange} // Disable the whole select if needed
                                styles={{
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    display: disablemySelectOnChange ? 'none' : 'block', // Conditionally hide dropdown indicator
                                  }),
                                }}
                              />
                            </FloatingLabel>
                          </div>
                          <div className="slctusr mb-3 mt-3">
                            <FloatingLabel label="Specific Item Approver" className="usrhrchy-lbl float-hidden float-select">
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={clientOptions}
                                value={selectedUser}
                                onChange={handlemyChange}
                                placeholder="Specific Item Approver"
                                isDisabled={disablemySelectOnChange} // Disable the whole select if needed
                                styles={{
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    display: disablemySelectOnChange ? 'none' : 'block', // Conditionally hide dropdown indicator
                                  }),
                                }}
                              />
                            </FloatingLabel>
                          </div> */}

                          {/* <h6 className="mb-2 mt-2 text-center">Liquor Approver</h6> */}
                          {/* <div className="slctusr mb-3 mt-3"> */}
                          {/* <FloatingLabel label="Select User" className="float-hidden float-select">
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            options={reportedByOptions}
                            value={selectedVerifier}
                            onChange={(selectedOption) => setSelectedVerifier(selectedOption)}
                            placeholder="Select User"
                          />
                        </FloatingLabel> */}
                          {/* <FloatingLabel label="Select User" className="usrhrchy-lbl float-hidden float-select">
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={clientOptions}
                                value={selectedUser}
                                onChange={handlemyChange}
                                placeholder="Select User"
                                isDisabled={disablemySelectOnChange} // Disable the whole select if needed
                                styles={{
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    display: disablemySelectOnChange ? 'none' : 'block', // Conditionally hide dropdown indicator
                                  }),
                                }}
                              />
                            </FloatingLabel> */}
                          {/* </div> */}
                        </Col>
                      </Row>
                    </>
                  )}

                  {activeTab === "traveldesk" && (
                    <>

                      <Row>
                        <Col md={12} sm={12}>

                          <div className="slctusr mb-3">

                            {/* <FloatingLabel label={selectedUser ? selectedUser.label : "Select User"} className="usrhrchy-lbl float-hidden float-select">
                         
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              options={clientOptions}
                              value={selectedUser}
                              onChange={(selectedOption) => setSelectedUser(selectedOption)}
                              placeholder="Select User"
                            />
                          
                          </FloatingLabel> */}

                            <FloatingLabel label={selectedUser ? selectedUser.label : "Select User"} className="usrhrchy-lbl float-hidden float-select">
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={clientOptions}
                                value={selectedUser}
                                onChange={handleChange}
                                placeholder="Select User"
                                isDisabled={disableSelectOnChange} // Disable the whole select if needed
                                styles={{
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    display: disableSelectOnChange ? 'none' : 'block', // Conditionally hide dropdown indicator
                                  }),
                                }}
                              />
                            </FloatingLabel>

                          </div>
                        </Col>
                        {showElements && (  // Conditional rendering based on showElements state
                          <Col md={12} sm={12}>
                            <div className="slctusr mb-3">
                              <FloatingLabel label="Select User" className="float-hidden float-select">
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={reportedByOptions}
                                  value={selectedVerifier}
                                  onChange={(selectedOption) => setSelectedVerifier(selectedOption)}
                                  placeholder="Select Verifier / Manager"
                                />
                              </FloatingLabel>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md={12} sm={12}>
                          {showElements && (  // Conditional rendering based on showElements state
                            <div className="usrhrchy-rdbtns">
                              <div>
                                <Form.Check
                                  inline
                                  className="travelBy-radio-btn"
                                  name="travelingBy"
                                  type="radio"
                                  label="Verifier"
                                  value="verifier"
                                  checked={selectedOption === 'verifier'}
                                  onChange={(e) => setSelectedOption(e.target.value)}
                                />
                                <Form.Check
                                  inline
                                  className="travelBy-radio-btn"
                                  name="travelingBy"
                                  type="radio"
                                  label="Manager"
                                  value="manager"
                                  checked={selectedOption === 'manager'}
                                  onChange={(e) => setSelectedOption(e.target.value)}
                                />
                              </div>

                              {/* <SbAddBtn className="myadbtn" onClickEffect={handleAddButtonClick}/> */}

                              <button className="myadbtn" onClick={handleAddButtonClick}><i className="fa fa-plus-circle"></i></button>
                            </div>
                          )}
                        </Col>
                      </Row>


                      <table className="thead-light spares-thead mytbusrhrchy mt-3">
                        <thead className="my-tbhd">
                          <tr>
                            <th>Name</th>
                            {/* <th>Priority</th> */}
                            <th>Type</th>
                            {showElements && (
                              <th>Delete</th>
                            )}
                          </tr>
                        </thead>
                        {/* <tbody>
                            {tableData.map((row, index) => (
                              <tr key={index}>
                                <td>{row.name}</td>
                                <td>{row.priority}</td>
                                
                                <td>{row.type.charAt(0).toUpperCase() + row.type.slice(1) + '1'}</td>
                               
                                
                                <td>
                                  <span className="material-symbols-outlined mybtnclos" onClick={() => handleDelete(index)}>
                                    cancel
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody> */}

                        <tbody>
                          {[
                            { name: 'R Subash', type: 'Verifier1' },
                            { name: 'Dinesh L', type: 'Manager1' },
                            { name: 'Kishore Kumar M.V.', type: 'Manager2' },
                            { name: 'P. S. Vidyashankar', type: 'Manager3' },
                            { name: 'Vinayak Gudekar', type: 'Manager4' },
                          ].map((row, index) => (
                            <tr key={index}>
                              <td onClick={() => handlemyShow(row.name)} style={{ cursor: 'pointer' }}>
                                {row.name}
                              </td>
                              <td>{row.type}</td>
                              <td>
                                {showElements && (
                                  <span className="material-symbols-outlined mybtnclos" onClick={() => handleDelete(index)}>
                                    Cancel
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
              <div className="popup-bottom-btn usrhrchy btns-save-cancel">

                {showSaveButton && <SBSaveUpdateBtn btnName="Save" />}
                {myshowSaveButton && <SbCancelBtn btnName="Close" onClickEffect={handleClose} />}
                {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}

              </div>
            </form>
          </Modal>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default UserHierarchy;
