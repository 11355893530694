import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import '../../styles/ProductsViewPage.scss';
import Skeleton from "react-loading-skeleton";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function ViewServiceRequestImages(props) {

    const [isLoading, setIsLoading] = useState(false)

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const images = [
        "https://tse3.mm.bing.net/th?id=OIP.ydV4POSX8yGEu-OXP7_yygHaFk&pid=Api&P=0&h=180",
        "https://tse4.mm.bing.net/th?id=OIP.rwWm3tTFskrMMiW56HJa1AHaGE&pid=Api&P=0&h=180",
        "https://tse1.mm.bing.net/th?id=OIP.LTMa4RpiGjS-VXSTINLSiQHaE7&pid=Api&P=0&h=180",
        "https://tse4.mm.bing.net/th?id=OIP.z7LD3zzUoJN_D-ZSSjXdEQHaEo&pid=Api&P=0&h=180",
        "https://tse4.mm.bing.net/th?id=OIP.fA6BMgRWmXiqhEdmKUnqygHaFf&pid=Api&P=0&h=180",
    ];

    return (
        <div>
            <Modal
                show={props.serviceRequestImages}
                onHide={props.handleClose}
                className="main_model mappedto-users-model"
            >
                <div className="add-edit-client-heading">
                    <h6 className="add-edit-client-head">Images</h6>
                    <i className="fa fa-times" onClick={props.handleClose} />
                </div>
                <div className="product-details-model">
                    <div className="row">
                        {/* <div className="col-md-5 col-sm-12"> */}
                        <div className="product-detail-left-part">
                            <div className="product-big-img">
                                {isLoading ?
                                    <div style={{ padding: "20px 0px" }}>
                                        <Skeleton />
                                    </div>
                                    :
                                    <Swiper
                                        spaceBetween={10}
                                        navigation={false}
                                        thumbs={{ swiper: thumbsSwiper }}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper2"
                                    >
                                        {images.length !== 0 && images.map((data, i) => (
                                            <SwiperSlide>
                                                <Image
                                                    src={data}
                                                    alt="attachment-photo"
                                                    className="product-img"
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                }

                            </div>
                            {isLoading ?
                                <Skeleton />
                                :
                                <div className="carousel-sliding-part">
                                    <Swiper
                                        style={{
                                            "--swiper-navigation-color": "#000000",
                                            "--swiper-pagination-color": "#000000",
                                            "--swiper-navigation-size": "13px",
                                            "--swiper-navigation-sides-offset": "0px",
                                        }}
                                        onSwiper={setThumbsSwiper}
                                        spaceBetween={5}
                                        slidesPerView={4}
                                        // slidesPerGroup={1}
                                        loadSingleProduct
                                        navigation={true}
                                        freeMode={true}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper"
                                    >
                                        {images.length !== 0 && images.map((data, i) => (
                                            <SwiperSlide key={i} >
                                                <div className="product-small-images-row">
                                                    <div className="product-small-image">
                                                        <Image
                                                            src={data}
                                                            alt="attachment-photo"
                                                            className="product-img"
                                                        />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>

                                </div>
                            }
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </Modal>
        </div>
    );
}

