import React, { useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { useState } from "react";
import * as HeaderData from "../../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import { loadClientCategoryDrop, loadSingleClientCategory, postClientCategory } from "../../../redux/action";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";

function AddClientCategory(props) {
  let dispatch = useDispatch();

  const categoryId = props.categoryId;
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const { categoryDropdown } = useSelector((state) => state.data);
  const { singleClientCategoryData } = useSelector((state) => state.data);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryData, setCategoryData] = useState({
    subscriptionID: "",
    name: "",
    description: "",
    addedByID: "",
    addedOn: "",
    addedByUserFullName: "",
    status: 1,
    reportingCategoryIDs: [],
  });

  const {
    subscriptionID,
    name,
    description,
    addedByID,
    addedOn,
    addedByUserFullName,
    status,
    reportingCategoryIDs,
  } = categoryData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
  };

  useEffect(() => {
    dispatch(loadClientCategoryDrop())
  }, [])

  useEffect(() => {
    if (categoryId) {
      dispatch(loadSingleClientCategory(categoryId))
    }
  }, [categoryId])

  useEffect(() => {
    if (categoryId && singleClientCategoryData) {
      const { name, description, strReportingCategoryIDs } = singleClientCategoryData;
      const reportingCategoryIDsArray = strReportingCategoryIDs
      ? strReportingCategoryIDs.split(',').map(id => parseInt(id.trim())).filter(Boolean)
      : [];
      setCategoryData({
        categoryID: categoryId,
        name: name,
        description: description,
        reportingCategoryIDs: reportingCategoryIDsArray
      })
    }
  }, [categoryId, singleClientCategoryData])
  console.log(reportingCategoryIDs, 'reportingCategoryIDs')

  useEffect(() => {
    if (resPassData) {
      setCategoryData({
        subscriptionID: resPassData.subscriptionId,
        name: "",
        description: "",
        addedByID: resPassData.userId,
        addedOn: "",
        addedByUserFullName: resPassData.fullName,
        status: 1,
        reportingCategoryIDs: [],
      });
    }
  }, []);

  const cancelModale = () => {
    props.setCateggoryId("")
    props.categoryModuleClose();
  };

  const [statusValue, setStatusValue] = useState(true);
  const statusFlag = (statusValue) => {
    setStatusValue(!statusValue);
    if (!statusValue === true) {
      setCategoryData({ ...categoryData, status: 1 });
    } else if (!statusValue === false) {
      setCategoryData({ ...categoryData, status: 0 });
    }
  };

  // const handleSelectOption = (selected) => {

  //   const reportingCategoryIDsArray = selected.map(option => parseInt(option.value));

  //   // Update reportingCategoryIDs in the state
  //   setCategoryData((prevData) => ({
  //     ...prevData,
  //     reportingCategoryIDs: reportingCategoryIDsArray,
  //   }));

  //   setSelectedOptions(selected);
  // };

  const handleSelectOption = (selected) => {
    console.log(selected, 'selected')
    const reportingCategoryID = selected ? parseInt(selected.value) : null;

    setCategoryData((prevData) => ({
      ...prevData,
      reportingCategoryIDs: [reportingCategoryID],
    }));

    setSelectedOptions(selected);
  };

  const [fieldReq, setFieldReq] = useState(false);
  const saveCategory = (e) => {
    e.preventDefault();
    if (!name || !description) {
      setFieldReq(true);
    } else {
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(categoryData),
      };
      console.log(categoryData, 'categoryData', categoryId, "categoryId")
      dispatch(postClientCategory(postRequest, cancelModale, categoryId, props.fetchCategoryData));
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.categoryModuleClose}
        className="category-modal-div"
      >
        <Row className="modale-header-div">
          <Col md={6} xs={9} className="cat-col">
            <h6 className="page-header-text">{categoryId ? "Edit Category" : "Add Category"}</h6>
          </Col>
          <Col md={6} xs={3} className="cat-col" align="right">
            <button
              onClick={props.categoryModuleClose}
              className="bi bi-x close-popup"
            ></button>
          </Col>
        </Row>
        <hr />
        <div className="cat-col">
          <Form>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                <FloatingLabel
                  label="Enter Category Name"
                  className={`${name || ""
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}
                >
                  <Form.Control
                    type="text"
                    pattern="/^[0-9]{10}$/"
                    value={name || ""}
                    name="name"
                    onChange={handleInputChange}
                    placeholder="Enter Category Name"
                    autoComplete="off"
                  />
                </FloatingLabel>
                {fieldReq && !name && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Category Name
                  </small>
                )}
              </Form.Group>
            </div>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                <FloatingLabel
                  label="Description"
                  className={`${description || ""
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}
                >
                  <Form.Control
                    as="textarea"
                    type="text"
                    pattern="/^[0-9]{10}$/"
                    className="form-control form-textarea add-user-input"
                    value={description || ""}
                    name="description"
                    onChange={handleInputChange}
                    placeholder="Description"
                    autoComplete="off"
                  />
                </FloatingLabel>
                {fieldReq && !description && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter description
                  </small>
                )}
              </Form.Group>
            </div>
            <div className="mb-3" style={{ position: "relative" }}>
              <FloatingLabel
                label="Select Category To Map"
                className={`${selectedOptions.value
                  ? "float-visible-select"
                  : "float-hidden float-select"
                  }`}
              >
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  options={categoryDropdown}
                  placeholder="Select Category To Map"
                  // value={selectedOptions}
                  value={categoryDropdown.find(category => category.value ===  parseInt(categoryData.reportingCategoryIDs[0]))}
                  onChange={handleSelectOption}
                  isSearchable={true}
                />
              </FloatingLabel>
            </div>
            {/* <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                <FloatingLabel
                  label="Select"
                  className={`${selectedOptions.length !== 0
                    ? "float-visible-select"
                    : "float-hidden float-select"
                    }`}
                >
                  <MultiSelect
                    // className="select-add-user-model"
                    options={categoryDropdown}
                    value={selectedOptions}
                    onChange={handleSelectOption}
                    labelledBy={"Select"}
                  />
                </FloatingLabel>
              </Form.Group>
            </div> */}
            <div className="mt-3">
              <div className="form-field form-status-section">
                <div>
                  <Form.Label className="require">Status</Form.Label>
                </div>
                <div className="btn-status">
                  <Form.Group className="facility-details">
                    <div
                      className={`checkbox ${statusValue && "checkbox--on"}`}
                      name="status"
                      onClick={(e) => statusFlag(statusValue)}
                    >
                      <div className="checkbox__ball">
                        <span
                          className={
                            statusValue ? "enable-text" : "disable-text"
                          }
                        >
                          {statusValue ? "Enable" : "Disable"}
                        </span>
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
            <hr />
            <div className="sb-btn-section">
              <SBSaveUpdateBtn onClickEffect={saveCategory} btnName={categoryId ? "Update" : "Save"} />
              <SbCancelBtn btnName="Cancel" onClickEffect={cancelModale} />
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default AddClientCategory;
