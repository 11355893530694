import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "../../styles/CommonStyle.scss";
import "../../styles/Tracker.scss";
import { baseURL } from "../BaseUrl";
import profileimg from "../../images/profile-1.png";
import GoogleMapComp from "../GoogleMapComp";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import OnScrollLoading from "../OnScrollLoading";
import GoogleAccessModal from "../GoogleAccessModal";

function TrackuserRoute(props) {
  const [meetingRouteDetails, setMeetingRouteDetails] = useState([]);

  let distanceMatrix = sessionStorage.getItem("distanceMatrixID");
  const [mapView, setMapView] = useState(false);
  useEffect(() => {
    if (distanceMatrix == 1) {
      setMapView(true);
    } else {
      setMapView(false);
    }
  }, [distanceMatrix]);

  const [accessModal, setAccessModal] = useState(false);
  const giveAccess = () => {
    setAccessModal(true);
  };

  const closeAccess = () => {
    setAccessModal(false);
  };

  const mapAccesss = () => {
    setAccessModal(false);
    setMapView(true);
  };

  // const routePositions = [
  //   { lat: 15.3509769, lng: 75.1406829 },
  //   { lat: 15.3563233, lng: 75.1430117 },
  //   { lat: 15.3563517, lng: 75.1431867 },
  //   { lat: 15.3647073, lng: 75.1478713 },
  //   { lat: 15.3648516, lng: 75.1480492 },
  // ];

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [routelatLan, setRouteLatLan] = useState({
    lat: null,
    lng: null,
  });

  const [startLatLan, setStartLatLan] = useState({
    lat: null,
    lng: null,
  });

  const [endLatLan, setEndLatLan] = useState({
    lat: null,
    lng: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  useEffect(() => {
    fetchMeetingsRoute();
  }, [props.meetingId]);

  const fetchMeetingsRoute = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Activity/GetMeetingActivities/${props.meetingId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          console.log("MEETING-ROUTE-RESPONSE::", res.result);

          setMeetingRouteDetails(res.result);
          setRouteLatLan({
            lat: +res.result.mapCordinates[0].cordLoc.latitude ?? null,
            lng: +res.result.mapCordinates[1].cordLoc.longitude ?? null,
          });
          if (
            res.result.mapCordinates[0].startPoint == "Yes" &&
            res.result.mapCordinates[0].endPoint == "No"
          ) {
            // alert("START YES", "END NO");
            setStartLatLan({
              lat: +res.result.mapCordinates[0].cordLoc.latitude ?? null,
              lng: +res.result.mapCordinates[0].cordLoc.longitude ?? null,
            });
          } else {
            setEndLatLan({
              lat: +res.result.mapCordinates[0].cordLoc.latitude ?? null,
              lng: +res.result.mapCordinates[0].cordLoc.longitude ?? null,
            });
          }
          if (
            res.result.mapCordinates[1].startPoint == "No" &&
            res.result.mapCordinates[1].endPoint == "Yes"
          ) {
            setEndLatLan({
              lat: +res.result.mapCordinates[1].cordLoc.latitude ?? null,
              lng: +res.result.mapCordinates[1].cordLoc.longitude ?? null,
            });
          } else {
            setStartLatLan({
              lat: +res.result.mapCordinates[1].cordLoc.latitude ?? null,
              lng: +res.result.mapCordinates[1].cordLoc.longitude ?? null,
            });
          }
          setIsLoading(false);
        } else {
          setMeetingRouteDetails("");
          setIsLoading(false);
        }
      });
  };

  console.log("DATA:", routelatLan, startLatLan, endLatLan);

  return (
    <div id="main-page">
      <>
        {/* MAIN BODY START */}
        <div className="page-wrapper">
          {/* PAGE CONTENT START */}
          {props.viewonuserroute ? (
            <div className={`main-body ${props.toggleshift.style}`}>
              <div className="page-content">
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div
                          className="back-btn"
                          onClick={props.goBacktomeetingsdetails}
                        >
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">TRACK USER ROUTE</h6>
                      </div>
                    </Col>
                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      ></div>
                    </Col>
                  </Row>
                </div>
                {/* TRACKER TABLE START */}
                <div className="tracker-table">
                  <div className="table-responsive tracker-tabel-scroll">
                    <table className="table trackers-list-table">
                      <thead className="thead-light trackers-list-thead">
                        <tr>
                          <th>NAME</th>
                          <th>MOBILE</th>
                          <th>DATE</th>
                          <th>KMS. TRAVELLED</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>

                      <tbody>
                        {meetingRouteDetails.length != 0 ? (
                          <tr
                            key={meetingRouteDetails?.meetingID}
                            className="track-list-row-data"
                          >
                            <td>
                              <span className="track-list-name d-flex">
                                <img
                                  className="table-data-track-imgages"
                                  src={profileimg}
                                  alt="John Doe"
                                />
                                <div className="table-row-head-Text">
                                  {meetingRouteDetails?.fullName}
                                </div>
                              </span>
                            </td>
                            <td>{meetingRouteDetails?.mobile}</td>
                            <td>
                              {meetingRouteDetails?.mapCordinates?.length >
                                0 && (
                                <>
                                  {moment(
                                    meetingRouteDetails?.mapCordinates[0]
                                      ?.cordDate
                                  ).format("DD MMM YYYY")}
                                </>
                              )}
                            </td>
                            <td>{meetingRouteDetails?.distanceTravelled}</td>
                            <td>
                              <span
                                className="td-print-column d-flex"
                                onClick={handlePrint}
                              >
                                <i className="bi bi-printer" />
                                &nbsp;&nbsp; Print
                              </span>
                            </td>
                          </tr>
                        ) : (
                          <tr>-----NO DATA FOUND-----</tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* TRACKER TABLE END */}
                <div className="trakuser-route-map">
                  <div className="row view-on-map">
                    {isLoading ? (
                      <>
                        <OnScrollLoading />
                      </>
                    ) : startLatLan.lat &&
                      startLatLan.lng &&
                      endLatLan.lat &&
                      endLatLan.lng ? (
                      <div className="col-md-12 track-user-google-map">
                        {mapView ? (
                          <div className="google-map-box" ref={componentRef}>
                            <GoogleMapComp
                              routelatLan={routelatLan}
                              startLatLan={startLatLan}
                              endLatLan={endLatLan}
                              pageType="TrackuserRoute"
                              // routePositions={routePositions}
                            />
                          </div>
                        ) : (
                          <div className="google-map-box p-2 empty-map">
                            <div className="overlay-div">
                              <div className="over-lay-text-div">
                                <h6 className="color-secondary">
                                  This Feature is not available in your
                                  Subscription...!
                                </h6>
                                <p className="mt-3">
                                  If you still want to view the map&nbsp;&nbsp;{" "}
                                  <span
                                    className="complete-status status-badge"
                                    style={{ cursor: "pointer" }}
                                    onClick={giveAccess}
                                  >
                                    Click Here
                                  </span>
                                </p>
                              </div>
                            </div>
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.56659496218!2d77.46612702802196!3d12.95428023487456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1692190317657!5m2!1sen!2sin"
                              width="100%"
                              height="450"
                              allowFullScreen
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </div>
                        )}
                        {accessModal && (
                          <GoogleAccessModal
                            accessModal={accessModal}
                            closeAccess={closeAccess}
                            mapAccesss={mapAccesss}
                          />
                        )}
                      </div>
                    ) : (
                      "No Data Found"
                    )}
                  </div>
                </div>

                {/* PAGE CONTENT END*/}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* MAIN BODY END */}
        </div>
      </>
    </div>
  );
}

export default TrackuserRoute;
