import React, { useState } from "react";
import "../../src/styles/SignIn.scss";
import travelize from "../images/metric-favicon.svg";
import travelizeSalesBeeLogo from "../images/MatricLogo.svg";
import { Col, Row, Image } from "react-bootstrap";

const PasswordChange = () => {
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

  return (
    <div><>
          <div className="container-fluid">
            <div className="sigin-page-body">
              <Row>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  className="signIn-container-left-div"
                >
                  <div className="signIn-container-logo">
                    <Image
                      src={travelizeSalesBeeLogo}
                      alt="MetricInfo"
                      className="signIn-logo"
                    />
                  </div>
                  <div className="signIn-container-gif">
                    <Image
                      src="../images/sign-animate.gif"
                      alt="MetricInfo Motion Logo"
                      className="signIn-moving-img"
                    />
                  </div>
                </Col>

                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  className="signIn-container-right-div"
                >
                  <div className="metricinfo-logo">
                    <Image
                      src={travelize}
                      alt="MetricInfo Logo"
                      className="sb-trv-logo"
                    />
                  </div>
                  <div className="signin-header login-part">
                    <div className="login-header-part">
                      <h2 className="mb-2">Change Password!</h2>
                      <h4>Please change your password before login...!</h4>
                    </div>
                    <form className="login-form">
                      <div className="form-group mb-4">
                                        <div className="icon-with-input before">
                                            <i className="fa fa-lock"></i>
                                            <input
                                                type={passwordType}
                                                className="form-control signIn-form-control"
                                                placeholder=" Old password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <div className="pass-hide-show">
                                                {passwordType === "password" ? (
                                                    <i
                                                        className="bi bi-eye-slash pass-hide-show"
                                                        onClick={togglePassword}
                                                    />
                                                ) : (
                                                    <i
                                                        className="bi bi-eye pass-hide-show"
                                                        onClick={togglePassword}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        {/* <label>Password</label> */}
                                        <div className="icon-with-input before">
                                            <i className="fa fa-lock"></i>
                                            <input
                                                type={passwordType}
                                                className="form-control signIn-form-control"
                                                placeholder="New password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <div className="pass-hide-show">
                                                {passwordType === "password" ? (
                                                    <i
                                                        className="bi bi-eye-slash pass-hide-show"
                                                        onClick={togglePassword}
                                                    />
                                                ) : (
                                                    <i
                                                        className="bi bi-eye pass-hide-show"
                                                        onClick={togglePassword}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        {/* <label>Password</label> */}
                                        <div className="icon-with-input before">
                                            <i className="fa fa-lock"></i>
                                            <input
                                                type={passwordType}
                                                className="form-control signIn-form-control"
                                                placeholder="Confirm password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <div className="pass-hide-show">
                                                {passwordType === "password" ? (
                                                    <i
                                                        className="bi bi-eye-slash pass-hide-show"
                                                        onClick={togglePassword}
                                                    />
                                                ) : (
                                                    <i
                                                        className="bi bi-eye pass-hide-show"
                                                        onClick={togglePassword}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="signin-button mt-2">
                                        <button type="submit" className="btn btn-signin-button">
                                            Update Password
                                        </button>
                                    </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
    </div>
  );
};

export default PasswordChange;
