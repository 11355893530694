import React, { useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Products.scss";
import "../../styles/AddPage.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import moment from "moment";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import ViewServiceRequestImages from "./ViewServiceRequestImages";
import { SkeletonMeetPurpose } from "../Skeleton Loading/SkeletonMeetPurpose";

function ViewServiceRequest() {
    let tableHeader = [
        { name: "PRODUCT", align: "left", width: "150px" },
        { name: "QUESTIONS", align: "left", width: "150px" },
        { name: "ANSWERS", align: "left", width: "150px" },
    ];

    const serviceRequest = [
        {
            product: "washing machine",
            comment: "Experiencing loud noise during washing machine spin cycle.",
            ans: "Let's check for loose items and level the machine first; if issue persists, schedule a technician.",
        },
        {
            product: "Jcb",
            comment: " machinery is experiencing frequent breakdowns.",
            ans: "Ensure regular maintenance checks and follow recommended operating procedures to minimize downtime.",
        },
    ];

    let navigate = useNavigate();
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [show, setShow] = useState(false);

    const [menuCollapse, setMenuCollapse] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [PageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [filterArray, setFilterArray] = useState([]);
    const [fromDate, setFromDate] = useState(
        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    );
    const [toDate, setToDate] = useState(new Date());

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    // PAGINATION START
    const [pageDataSize, setPageDataSize] = useState(0);
    const pageDataFrom = currentPage * PageSize - PageSize + 1;
    const pageDataTill = pageDataFrom + pageDataSize - 1;
    // PAGINATION END

    const [feedBackImages, setFeedBackImages] = useState(false)
    const handleViewAttachments = () => {
        setFeedBackImages(true);
    }


    // Page navigation
    const goToBackPage = () => {
        navigate(-1);
    };
    // page navigation

    // Open Filter div
    const [filter, setFilter] = useState(false);
    // Open Filter div

    const setSelectSearchDrop = (data, type) => {
        let list = [];
        if (type === "date") {
            const [start, end] = data;
            if (filterArray.length !== 0) {
                if (start) {
                    list = filterArray.filter((item) => item.key !== "FromDate");
                }
                if (end) {
                    list = filterArray.filter((item) => item.key !== "ToDate");
                }
            }
            if (start && end == null) {
                list.push({
                    key: "FromDate",
                    value: moment(new Date(start)).format("DD/MM/YYYY"),
                });
            }
            setFromDate(start);
            if (end) {
                list.push({
                    key: "ToDate",
                    value: moment(new Date(end)).format("DD/MM/YYYY"),
                });
            }
            setToDate(end);
        }
        setFilterArray(list);
    };

    const [serviceRequestImages, setServiceRequestImage] = useState(false)
    const viewServiceRequestImages = () => {
        setServiceRequestImage(true)
    }
    const handleClose = () => {
        setServiceRequestImage(false);
    };


    return (
        <div id="main-page">
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
                <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                        <div className="user-table">
                            <Row className='search-row'>
                                <Col md={3} className='cat-col'>
                                    <div className='page-header-text-div'>
                                        <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                                        <h6 className='page-header-text' >VIEW SERVICE REQUEST</h6>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                        <div className="clients-table">
                            <Row className='search-row order-details-row'>
                                <Col md={6} className='cat-col'>
                                    <ul className="order-detail-list">
                                        <li><div className="span1">Service Id:</div> &nbsp;&nbsp;<div className="span2"><b>86</b></div></li>
                                        <li><div className="span1">Date:</div> &nbsp;&nbsp;<div className="span2"><b>04-03-2024</b></div></li>
                                        <li><div className="span1">Company:</div> &nbsp;&nbsp;<div className="span2"><b>RNS</b></div></li>
                                        <li><div className="span1">Email:</div> &nbsp;&nbsp;<div className="span2"><b>naveen@gmail.com</b></div></li>
                                        <li><div className="span1">Phone:</div> &nbsp;&nbsp;<div className="span2"><b>9963252767</b></div></li>
                                    </ul>
                                </Col>
                                <Col md={6} className='cat-col'>
                                    <ul className="order-detail-list">

                                        {/* <li><div className="span1">Product:</div> &nbsp;&nbsp;<div className="span2"><b>JCB</b></div></li> */}
                                        <li><div className="span1">Rating:</div> &nbsp;&nbsp;<div className="span2"><b>4.5/5</b></div></li>
                                        <li><div className="span1">Priority:</div> &nbsp;&nbsp;<div className="span2"><b>High</b></div></li>
                                        <li><div className="span1">Escalation Manager:</div> &nbsp;&nbsp;<div className="span2"><b>Dummy</b></div></li>
                                    </ul>
                                </Col>
                            </Row>


                        </div>
                        <div className="sb-table-div sb-table-setion">
                            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table table-responsive" className='basefare-table'>
                                        <TableHead className='custom-table-header'>
                                            <TableRow>
                                                {tableHeader.map((name, i) => (
                                                    <TableCell align={name.align} key={i} style={{ minWidth: name.width }}>
                                                        {name.name}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        {isLoading ? (
                                            <>
                                                {tableHeader.map((name, i) => (
                                                    <SkeletonMeetPurpose key={i} />
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {serviceRequest.length === 0 ? (
                                                    <TableBody>
                                                        <TableRow align="center">
                                                            <TableCell align="center" colSpan={tableHeader.length}>
                                                                <div style={{ padding: "20px 20px" }}>
                                                                    <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                                    <span>No Data Found...!</span>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                ) : (
                                                    <>

                                                        <TableBody>
                                                            {serviceRequest.map((data) => (
                                                                <TableRow>
                                                                    <TableCell>{data.product}</TableCell>
                                                                    <TableCell>{data.comment}</TableCell>
                                                                    <TableCell>{data.ans}</TableCell>
                                                                </TableRow>

                                                            ))}

                                                        </TableBody>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>

                    </div>

                </div>

            </div>

        </div >

    );
}

export default ViewServiceRequest;
