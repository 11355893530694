import React, { useState, useEffect } from "react";
import { Modal, Button, Tab, Col, Row, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/EntertainmentExpensesApproval.scss";
import SbAddBtn, { SBSaveUpdateBtn, SbDownloadBtn, SbAddExpenseBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import AccessDenied from "../../../AccessDenied";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from "react-multi-select-component";


// const defaultUser = clientOptions.find(option => option.label === 'Siva Balan P');
function EntertainmentExpensesApproval() {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedVerifier, setSelectedVerifier] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [mydatashow, setmyDataShow] = useState(false);
    const [vchrshow, setvchrshow] = useState(false);
    const [activeTab, setActiveTab] = useState("manager");
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [myshowSaveButton, setmyShowSaveButton] = useState(true);
    const [myinnershowSaveButton, setmyinnerShowSaveButton] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedVoucher, setSelectedVoucher] = useState({});
    const [myselectedVoucher, setmysetSelectedVoucher] = useState({});



    const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
    const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    const twoLevelClaim = loginDetails?.subscription?.twoLevelClaim;
    const loggedInRole = loginDetails?.desingation?.role?.roleID;
    const [filter, setFilter] = useState(false);
    const [filterUserId, setFilterUserId] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [myshowForm, setmyShowForm] = useState(false);
    const [mynextshowForm, setmynextshowForm] = useState(false);
    const [viewItem, setViewItem] = useState(null);

    const [designation, setDesignation] = useState('');


    const handleDesignationChange = (e) => {
        setDesignation(e.target.value);
    };
    //* CLEAR SELECT START *//
    const clearSerachBySelect = () => {
        setFilterUserId("");

    };
    const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);
    const handleClose4 = () => setShow4(false);
    const handleClose5 = () => setShow5(false);
    const handleShow2 = () => setShow2(true);
    const handleShow3 = () => setShow3(true);
    const handleShow4 = () => setShow4(true);
    const handleShow5 = () => setShow5(true);



    const mydatahandleshow = (item) => {
        setViewItem(item);
        setShow3(true);
    };
    const myuserDropdown = [
        { value: 'hod', label: 'HOD' },
        { value: 'regionalHead', label: 'Regional Head' },
        { value: 'director', label: 'Director' },
    ];

    const handleMultiSelectChange = (selectedOptions) => {
        setFilterUserId(selectedOptions);
    };
    // State initialization and handling


    const myhandleClose = () => {
        setShowForm(false);
    };



    const userDropdownvoucher = [
        { value: 'vo1', label: 'NAV001' },
        { value: 'vo2', label: 'NAV002' },
        { value: 'vo3', label: 'NAV003' }
    ]

    const userDropdown = [
        { value: 'hotel', label: 'Hotel' },
        { value: 'conveyance', label: 'Conveyance Charges' },
        { value: 'staff', label: 'Staff Welfare' },
        { value: 'sundry', label: 'Sundry Expense' }
    ];
    const accts = [
        { value: '1', label: '44343003-Lodging And Boarding' },
        { value: '2', label: '44343005-Tour Conveyance Charges' },
        { value: '3', label: '44370101-Staff Welfare' },
        { value: '4', label: '44343007-Tour Sundry Expenses' }
    ];
    const [fromDate, setFromDate] = useState(
        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    );
    const [isEdit, setIsEdit] = useState(false);
    // const navigate = useNavigate();
    // const handleViewClick = (voucherNo) => {
    //     navigate(`/travelmanagement/TravelExpenseVoucherDetails/${voucherNo}`);
    // };

    const [toDate, setToDate] = useState(new Date());
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { userDropdownByManager } = useSelector((state) => state.data);
    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    };

    const [expenseType, setExpenseType] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);

    const setSelectSearchDrop = (data, type) => {

    };


    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const handleChange = (selectedOption) => {
        if (!disableSelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const handlemyChange = (selectedOption) => {
        if (!disablemySelectOnChange) {
            setSelectedUser(selectedOption);

        }
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleShowForm();
        myhandleShowForm();
        console.log("Form Submitted");



    };
    const mysaveBtn = () => {
        setmyShowForm(false);
        setmynextshowForm(false);

    }
    const mysaveBtn2 = () => {
        setmyShowForm(false);
        setmynextshowForm(false);


    }
    const vchrhandleClose = () => {
        setvchrshow(false);
        setShow(false);
        setmyShowForm(false);
        setmynextshowForm(false);

    }

    const myshowhandleClose = () => {
        setmyShowForm(false);
        setmynextshowForm(false);
    }
    const [disableSelectOnChange, setDisableSelectOnChange] = useState(false);
    const [disablemySelectOnChange, setDisablemySelectOnChange] = useState(false);


    // const vchrhandleClose = () => {
    //     setvchrshow(false);
    //     setmyShowForm(false);
    // };

    const myhandleShowForm = () => {
        setmynextshowForm(false);
        setmyShowForm(true);
        setvchrshow(true);
        setShow(false);
    };
    const handleClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShow(false);
        setmyShowForm(true);
        setmynextshowForm(false);
        setvchrshow(false);
    };
    // const myshowhandleClose = () => {
    //     setmyShowForm(false);
    // };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setRefresh((prev) => !prev);
    };

    const [selectedOption, setSelectedOption] = useState('actual');
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [showElements, setShowElements] = useState(true);


    const handleDelete = (index) => {
        const updatedTableData = tableData.filter((_, i) => i !== index);
        setTableData(updatedTableData);
        if (updatedTableData.length === 0) {
            setIsTableVisible(false); // Hide the table if there are no rows left
        }
    };
    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                <input className="mots-lst"
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null} // Prevent the default checkbox behavior
                />
                <label>{props.label}</label>
            </components.Option>
        );
    };

    const exportExcelClaim = () => {

    };

    const handleUserChange = (selectedOption) => {
        setSelectedUser(selectedOption);
    };

    const handleVerifierChange = (selectedOption) => {
        setSelectedVerifier(selectedOption);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };
    const adhandleShow = () => {
        // Your logic to add table data or show modal
        console.log("Adding table data or showing modal");
    };

    // const mydatahandleshow = () => {
    //     setmysetSelectedVoucher();
    //     setmyDataShow(true);
    //     setShow3(true);
    // }
    const myvchrhandleClose = () => {
        setmyDataShow(false);
    };
    // const handleSelectChange = (selectedOption) => {
    //     setSelectedDesignation(selectedOption);
    // };

    // const handleInputChange = (e) => {
    //     setMaxApprovalAmount(e.target.value);
    // };
    const edithandleShow = (item) => {
        setSelectedVoucher();
        setvchrshow(true);
        setShow2(true);
        setSelectedDesignation(item.name);
        setMaxApprovalAmount(item.account);

    };
    const handleShow = () => {

        setShow(true);
        setShowElements(true);
        setShowSaveButton(true);
        setmyShowSaveButton(false);
        setmyinnerShowSaveButton(true);
        setDisableSelectOnChange(false);
        setDisablemySelectOnChange(false);
        setRefresh((prev) => !prev);

    };

    const [selectedDesignation, setSelectedDesignation] = useState(null);
    const [maxApprovalAmount, setMaxApprovalAmount] = useState('');
    const handleSelectChange = (selectedOption) => {
        // setSelectedDesignation(selectedOption);
        setSelectedAccount(selectedOption.value);
    };

    const handleInputChange = (e) => {
        setMaxApprovalAmount(e.target.value);
    };
    const handleShowForm = () => {
        setmynextshowForm(true);
        setShow(true);
        setShowForm(true);
        setmyShowForm(false);
        // Toggle the form visibility or handle the form show logic here
        setRefresh(prevState => !prevState);
    };
    // const myhandleShowForm = () => {
    //     setShowForm(false);
    //     setmyShowForm(true);
    //     // Toggle the form visibility or handle the form show logic here
    //     setRefresh(prevState => !prevState);
    // };

    console.log(tableData, "tableData")

    return (
        <div id="main-page">
            {userDetails !== null && userDetails !== "" ? (
                <>
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    <div className="page-wrapper">
                        <div className={`main-body ${toggleshift.style}`}>
                            <div className="page-content usrhrchy">
                                <Row className="search-row usrhrchy">
                                    <Col md={4} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToBackPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">ENTERTAINMENT EXPENSES APPROVAL</h6>
                                        </div>
                                    </Col>
                                    <Col md={4} className="cat-col"></Col>
                                    <Col md={4} className="cat-col">
                                        <div
                                            className="search-bar-header"
                                            style={{ float: "right" }}
                                        >
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                {/* <div
                                                    className="header-filter-btn"
                                                    onClick={() => setFilter(!filter)}
                                                >
                                                    <p className="card-head">
                                                        <i className="bi bi-sliders" />
                                                        &nbsp;&nbsp;Filters
                                                    </p>
                                                </div> */}
                                                <SbAddBtn
                                                    onClickEffect={handleShow}
                                                />
                                                {/* <Button variant="primary" onClick={handleShow}>
                                                    Launch demo modal
                                                </Button> */}
                                            </div>
                                        </div>

                                    </Col>


                                </Row>

                                <div className="usrhrchy table-responsive spares-scrollbar">
                                    <table className="table spares-table-data">
                                        <thead className="thead-light spares-thead">
                                            <tr>
                                                <th>Designation</th>
                                                <th>Max. Approval Amount <span className="myrscnt">(Rs.)</span></th>
                                                <th className="mytablaction">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[
                                                { name: 'HOD', account: '5000.00' },
                                                { name: 'Regional Head', account: '2000.00' },
                                                { name: 'Director', account: '100000.00' }
                                            ].map((item, index) => (
                                                <tr key={index} className="spare-table-row-data">
                                                    <td>{item.name}</td>
                                                    <td>{item.account}</td>
                                                    <td className="myhrchy-dta">

                                                        <button className="btn spare-edit-link" onClick={() => edithandleShow(item)}>
                                                            <i className="fa fa-edit"></i> Edit
                                                        </button>
                                                        {/* <Link to={`/travelmanagement/TravelExpenseVoucherDetails/${item.voucherNo}`}> */}
                                                        <button className="btn spare-edit-link" onClick={() => mydatahandleshow(item)}>
                                                            <i className="fa fa-eye"></i> View
                                                        </button>
                                                        {/* </Link> */}
                                                        <button className="btn spare-edit-link">
                                                            <i className="fa fa-trash"></i> Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="download-and-pagination">
                                        <SbDownloadBtn onClickEffect={exportExcelClaim} />
                                    </div>

                                </div>




                            </div>

                        </div>

                    </div>
                    <Footer />

                    <Modal show={show} onHide={handleClose} className="main_model add-edit-model amc-model travexpse entrmnt-modal">
                        <div className="add-edit-heading">

                            <h6 className="add-edit-head">Add Entertainment Expenses Approval</h6>
                            <i className="fa fa-times" onClick={handleClose} />
                        </div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>Add Entertainment Expenses Approval</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">

                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={4} className="cat-col">
                                            <h6>Designation :</h6>
                                        </Col>
                                        <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            {/* <FloatingLabel label='Select Designation' className="float-hidden float-select"> */}
                                            <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                options={myuserDropdown}
                                                placeholder="Select Designation"
                                                value={myuserDropdown.find(option => option.value === selectedAccount)}
                                                onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                isSearchable={true}
                                                style={{ height: '35px' }}
                                            />
                                            {/* </FloatingLabel> */}

                                        </Col>
                                    </Row>

                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={4} className="cat-col">
                                            <h6>Maximum Approval Amount :</h6>
                                        </Col>
                                        <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            {/* <FloatingLabel label='Enter Tour Purpose' className="float-hidden float-input"> */}
                                            <Form.Control
                                                type="number"
                                                name="clientEmail"
                                                className="form-control form add-input"
                                                placeholder=""
                                                style={{ height: '35px' }}

                                            />
                                            {/* </FloatingLabel> */}
                                        </Col>
                                    </Row>


                                </div>
                                <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">

                                    {showSaveButton && <SBSaveUpdateBtn btnName="Save" onClickEffect={mysaveBtn} />}
                                    {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}

                                </div>
                            </div>
                        </Modal.Body>

                    </Modal>
                    {/* <Modal show={show2} onHide={handleClose2} className="main_model add-edit-model amc-model travexpse">
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Entertainment Expenses Approval</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">

                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={4} className="cat-col">
                                            <h6>Designation :</h6>
                                        </Col>
                                        <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            
                                            <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                options={myuserDropdown}
                                                placeholder="Select Designation"
                                                value={myuserDropdown.find(option => option.value === selectedAccount)}
                                                onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                isSearchable={true}
                                                style={{ height: '35px' }}
                                            />
                                           

                                        </Col>
                                    </Row>

                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={4} className="cat-col">
                                            <h6>Maximum Approval Amount :</h6>
                                        </Col>
                                        <Col md={8} className="cat-col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                            
                                            <Form.Control
                                                type="number"
                                                name="clientEmail"
                                                className="form-control form add-input"
                                                placeholder=""
                                                style={{ height: '35px' }}

                                            />
                                            
                                        </Col>
                                    </Row>

                                    <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp">

                                        {showSaveButton && <SBSaveUpdateBtn btnName="Save" onClickEffect={mysaveBtn} />}
                                        {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />}

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                    </Modal> */}

                    <Modal show={show2} onHide={handleClose2} className="main_model add-edit-model amc-model travexpse entrmnt-modal">
                        <div className="add-edit-heading">

                            <h6 className="add-edit-head">Edit Entertainment Expenses Approval</h6>
                            <i className="fa fa-times" onClick={handleClose2} />
                        </div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>{isEdit} Edit Entertainment Expenses Approval</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">
                                    <Row className="levllst mb-2">
                                        <Col md={4} className="cat-col">
                                            <h6>Designation :</h6>
                                        </Col>
                                        <Col md={8} className="cat-col">
                                            {/* <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                options={myuserDropdown}
                                                placeholder="Select Designation"
                                                value={myuserDropdown.find(option => option.value === selectedDesignation)}
                                                onChange={handleSelectChange}
                                                isSearchable={true}
                                            /> */}
                                            {/* <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                options={myuserDropdown}
                                                placeholder="Select Designation"
                                                value={myuserDropdown.find(option => option.value === selectedAccount)}
                                                onChange={(data) => setSelectedAccount(data ? data.value : null)}
                                                isSearchable={true}
                                                styles={{ container: (provided) => ({ ...provided, height: '35px' }) }}
                                            /> */}
                                            <Form.Control
                                                type="text"
                                                name="designation"
                                                className="form-control form add-input"
                                                placeholder=""
                                                value={selectedDesignation}
                                                onChange={handleSelectChange}
                                                style={{ height: '35px' }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="levllst mb-2">
                                        <Col md={4} className="cat-col">
                                            <h6>Maximum Approval Amount :</h6>
                                        </Col>
                                        <Col md={8} className="cat-col">
                                            <Form.Control
                                                type="number"
                                                name="maxApprovalAmount"
                                                className="form-control form add-input"
                                                placeholder=""
                                                value={maxApprovalAmount}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>

                                </div>
                                <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">
                                    {showSaveButton && <SBSaveUpdateBtn btnName="Save" onClickEffect={mysaveBtn} />}
                                    {myinnershowSaveButton && <SbCancelBtn btnName="Cancel" onClickEffect={handleClose2} />}

                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>


                    {/* <Modal show={show3} onHide={handleClose3} className="main_model add-edit-model amc-model travexpse">
                        <Modal.Header closeButton>
                            <Modal.Title>View Entertainment Expenses Approval</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">

                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                        <Col md={8} className="cat-col mylst-dta"><h6>Designation :</h6><p>{name}</p></Col>

                                    </Row>

                                    <Row className="levllst mb-2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col md={8} className="cat-col mylst-dta"><h6>Maximum Approval Amount :</h6><p>{account}</p></Col>

                                    </Row>

                                    <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp">

                                       
                                        {myinnershowSaveButton && <SbCancelBtn btnName="Close" onClickEffect={handleClose} />}

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                    </Modal> */}

                    <Modal show={show3} onHide={handleClose3} className="main_model add-edit-model amc-model travexpse entrmnt-modal">
                        <div className="add-edit-heading">

                            <h6 className="add-edit-head">View Entertainment Expenses Approval</h6>
                            <i className="fa fa-times" onClick={handleClose3} />
                        </div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>View Entertainment Expenses Approval</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                            <div className="container add-edit">
                                <div className="add-inputs usr-hrchy">
                                    {viewItem ? (
                                        <>
                                            <Row className="levllst mb-2">
                                                <Col md={4} className="cat-col">
                                                    <h6>Designation :</h6>
                                                </Col>
                                                <Col md={4} className="cat-col">

                                                    <p>{viewItem.name}</p>
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-2">
                                                <Col md={4} className="cat-col">
                                                    <h6>Maximum Approval Amount :</h6>
                                                </Col>
                                                <Col md={4} className="cat-col">

                                                    <p>{viewItem.account}</p>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <>
                                            <Row className="levllst mb-2">
                                                <Col md={4} className="cat-col">
                                                    <h6>Designation :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={myuserDropdown}
                                                        placeholder="Select Designation"
                                                        value={selectedDesignation}
                                                        onChange={handleSelectChange}
                                                        isSearchable={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="levllst mb-2">
                                                <Col md={4} className="cat-col">
                                                    <h6>Maximum Approval Amount :</h6>
                                                </Col>
                                                <Col md={8} className="cat-col">
                                                    <Form.Control
                                                        type="number"
                                                        name="maxApprovalAmount"
                                                        className="form-control form add-input"
                                                        placeholder=""
                                                        value={maxApprovalAmount}
                                                        onChange={handleInputChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )}

                                </div>
                                <div className="popup-bottom-btn usrhrchy btns-save-cancel trvexp mt-4">
                                    {myinnershowSaveButton && <SbCancelBtn btnName="Close" onClickEffect={handleClose3} />}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>


                </>
            ) : (
                <AccessDenied />
            )
            }
        </div >
    );
}

export default EntertainmentExpensesApproval;
